import dayjs from 'dayjs';
import { camelCase, isNil } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Report } from 'api/report';
import { scoreIndexToColor, scoreIndexToLabel } from 'api/telemetryProperty';
import { Dataset as BarChartDataset } from 'components/ui/atoms/BarChart/BarChart';
import { CellData } from 'components/ui/atoms/HeatMapGrid/components/Cell';
import { HeatMapGridPropsWithKey } from 'components/ui/molecules/HeatMapGridCollection/HeatMapGridCollection';
import { MappedBubbleProps } from 'components/ui/molecules/LiquidFillGaugeCollection/LiquidFillGaugeCollection';
import {
  Dataset as StackedBarChartDataset,
  DatasetCategoryEntry,
} from 'components/ui/molecules/StackedBarChartCollection/StackedBarChartCollection';
import { timestampTimezoneUnaware } from 'utilities/utilities';
import { Props as SummaryTextProps } from './SummaryText';

const NO_DATA_LABEL = 'No data';

export const prepareBubbleData = (
  telemetryProps: {
    [key: string]: {
      name: string;
      unit: string;
    };
  },
  report?: Report,
): MappedBubbleProps[] => {
  if (!report?.content?.averageValues) return [];

  return Object.entries(report.content.averageValues).map(([key, value]) => {
    const telemetryKey = Object.keys(telemetryProps).find(
      (telemetryObjectKey) => {
        return camelCase(telemetryObjectKey) === key;
      },
    );

    return {
      label: telemetryKey ? telemetryProps[telemetryKey].name : key,
      value: value.mean ? value.mean.toString() : undefined,
      unit: telemetryKey ? telemetryProps[telemetryKey].unit : '',
      scoreIndex: value.healthScore || 0,
    };
  });
};

export const prepareDailyScore = (report?: Report): BarChartDataset => {
  if (!report?.content?.dailyScores) return [];

  return Object.entries(report.content.dailyScores).map(([key, value]) => {
    return {
      label: dayjs(key).format('ddd'),
      value: isNil(value) ? undefined : value,
    };
  });
};

export const prepareParametersBreakdown = (
  report?: Report,
): StackedBarChartDataset => {
  if (!report?.content?.parameterBreakdown) return [] as any;

  let parametersData: Record<string, any> = {};

  Object.keys(report.content.parameterBreakdown).forEach((key) => {
    const data = report.content.parameterBreakdown[camelCase(key)].filter(
      ({ value }) => !isNil(value) && (value as number) > 0,
    );

    parametersData = { ...parametersData, [key]: data };
  });

  Object.keys(parametersData).forEach((key) => {
    const sum = (parametersData[key] as DatasetCategoryEntry[])
      .map(({ value }) => value)
      .reduce((acc, value) => acc + (value as number), 0);

    if (sum > 100) {
      const param = parametersData[key] as DatasetCategoryEntry[];
      const noDataParam = param.find(({ label }) => label === NO_DATA_LABEL);

      if (noDataParam) noDataParam.value += 100 - sum;
    }
  });

  return parametersData;
};

export const prepareHeatmapData = (
  telemetryProps: {
    [key: string]: {
      name: string;
      unit: string;
    };
  },
  report?: Report,
): HeatMapGridPropsWithKey[] => {
  if (!report?.content?.hourlyParameterBreakdown) return [];

  return Object.entries(report.content.hourlyParameterBreakdown).map(
    ([telemetryPropertyKey, gridData]) => {
      const telemetryKey = Object.keys(telemetryProps).find(
        (telemetryObjectKey) =>
          camelCase(telemetryObjectKey) === camelCase(telemetryPropertyKey),
      ) as string;

      const matrixData: CellData[][] = [];
      const xAxisLabels: string[] = [];
      const yAxisLabels: string[] = [];

      let tempDataIdx = -1;
      gridData.segments.forEach(({ healthScore, timestamp, mean }) => {
        const date = dayjs(timestampTimezoneUnaware(timestamp));

        const xAxisLabel = date.format('hA');
        if (!xAxisLabels.includes(xAxisLabel)) xAxisLabels.push(xAxisLabel);

        const yAxisLabel = date.format('ddd');
        if (!yAxisLabels.includes(yAxisLabel)) yAxisLabels.push(yAxisLabel);

        if (Number(date.format('H')) === 0) {
          tempDataIdx += 1;
        }

        const cellData = {
          value: mean,
          color: scoreIndexToColor(healthScore || 0),
        };

        if (!matrixData[tempDataIdx]) {
          matrixData[tempDataIdx] = [cellData];
        } else {
          matrixData[tempDataIdx].push(cellData);
        }
      });

      const telemetryScoreData =
        report.content.scoreBands[telemetryPropertyKey];
      const legendData = !telemetryScoreData
        ? undefined
        : Object.entries(telemetryScoreData).map(([scoreIndex, label]) => ({
            label,
            color: scoreIndexToColor(parseInt(scoreIndex, 10)),
          }));

      const title = telemetryKey
        ? `${telemetryProps[telemetryKey].name} (${telemetryProps[telemetryKey].unit})`
        : '';

      return {
        title,
        subtitle: (
          <FormattedMessage
            defaultMessage="{percent}% of {property} readings were in the {score} range"
            values={{
              percent: gridData.value,
              property: telemetryKey
                ? telemetryProps[telemetryKey].name
                : telemetryPropertyKey,
              score: scoreIndexToLabel(gridData.healthScore as number),
            }}
          />
        ),
        legendData,
        data: matrixData,
        yLabels: yAxisLabels,
        xLabels: xAxisLabels,
        unit: telemetryKey ? telemetryProps[telemetryKey].unit : '',
        telemetryPropertyKey,
      };
    },
  ) as HeatMapGridPropsWithKey[];
};

export const prepareSummaryParams = (
  telemetryProps: {
    [key: string]: {
      name: string;
      unit: string;
    };
  },
  report?: Report,
): SummaryTextProps => {
  if (!report?.content?.summary)
    return {
      overallScore: undefined,
      highestScore: undefined,
      bestParameterValue: undefined,
      bestParameterName: undefined,
      worstParameterName: undefined,
      worstParameterValue: undefined,
      dayName: undefined,
    };

  const { summary } = report.content;

  const bestParamCategory = summary.bestPerformingParameter?.category;
  const bestParam =
    telemetryProps[bestParamCategory]?.name || bestParamCategory;

  const worseParamCategory = summary.worstPerformingParameter?.category;
  const worseParam =
    telemetryProps[worseParamCategory]?.name || worseParamCategory;

  const bestDayStamp = summary.highestDayScore.timestamps[0] || undefined;
  const bestDayName = bestDayStamp
    ? dayjs(bestDayStamp).format('dddd')
    : undefined;

  return {
    overallScore: summary.overallScore,
    highestScore: summary.highestDayScore?.value,
    bestParameterValue: summary.bestPerformingParameter?.value,
    bestParameterName: bestParam,
    worstParameterName: worseParam,
    worstParameterValue: summary.worstPerformingParameter?.value,
    dayName: bestDayName,
  };
};
