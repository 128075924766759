import React from 'react';
import Routing from './components/Routing';

const Org: React.FC = () => {
  return (
    <div data-testid="org">
      <Routing />
    </div>
  );
};

export default Org;
