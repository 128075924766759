import { getIntl } from 'components/Intl';

export enum IAQReportTemplate {
  Overview = 'overview',
  ParameterBreakdown = 'parameter_breakdown',
}

export enum IAQScoreBand {
  Standard = 'standard',
}

export const getReportTemplateToLabelMap = (
  reportTemplate: IAQReportTemplate,
): string =>
  ({
    [IAQReportTemplate.Overview]: getIntl().formatMessage({
      defaultMessage: 'Overview',
    }),
    [IAQReportTemplate.ParameterBreakdown]: getIntl().formatMessage({
      defaultMessage: 'Parameter Breakdown',
    }),
  }[reportTemplate] || reportTemplate);
