import { Layout, Typography, Button, Row, Col, message, Select } from 'antd';
import { camelCase, snakeCase } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams, Outlet } from 'react-router-dom';
import {
  useDeleteConfigurationMutation,
  useGetConfigurationListQuery,
  useGetDefinitionsQuery,
} from 'api/configuration';
import { useGetOrgTreeQuery } from 'api/org';
import { selectOrgNameByOrgId as orgSelector } from 'api/org/utils';
import Card from 'components/ui/molecules/Card';
import Table from 'components/ui/molecules/Table';
import {
  confirmPopup,
  getTableSkeleton,
  sortByParam,
} from 'utilities/utilities';
import { getFullPath } from 'views/Devices/views/Provision/components/Routing';
import { Routes as CandidateRoutes } from 'views/Devices/views/Provision/components/Routing/Routes';
import { Routes } from 'views/Settings/views/Importer/components/Routes';
import { getImporterNameSearch } from '../../utilities';
import { columns, ConfigurationRow } from './config';

import styles from './styles.module.scss';

const { Title } = Typography;
const { Option } = Select;

const List: React.FC = () => {
  const orgNodesQuery = useGetOrgTreeQuery();
  const selectOrgNameByOrgId = useMemo(
    () => orgSelector(orgNodesQuery.data || {}),
    [orgNodesQuery.isSuccess],
  );
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const intl = useIntl();
  const [deleteConfiguration] = useDeleteConfigurationMutation();
  const definitionsQuery = useGetDefinitionsQuery({});

  // Importer configuration instances
  const [configurations, setConfigurations] = useState<ConfigurationRow[]>(
    getTableSkeleton(
      ['credentialName', 'importerName', 'org', 'dataLastReceived'],
      3,
    ),
  );

  // When filtering on importer type(s), filter is pushed to the URL query
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const importerNames = useMemo(
    () => searchParams.getAll('importerName').map(camelCase),
    [searchParams],
  );

  // get configuration list when at least one importer is set
  const {
    data: configurationList,
    isLoading,
    isError,
  } = useGetConfigurationListQuery({
    importerName: importerNames.map(snakeCase),
  });

  useEffect(() => {
    if (isLoading) {
      setConfigurations(
        getTableSkeleton(
          ['credentialName', 'importerName', 'org', 'dataLastReceived'],
          3,
        ),
      );
      return;
    }

    if (isError || !configurationList) {
      setConfigurations([]);
      return;
    }

    const formattedData = configurationList
      .map(({ id, importerName, orgId, meta: { name }, dataLastReceived }) => ({
        key: id,
        importer: importerName,
        importerName: definitionsQuery.data
          ? definitionsQuery.data[camelCase(importerName)]?.name || '-'
          : '-',
        credentialName: name,
        dataLastReceived,
        org: selectOrgNameByOrgId(orgId),
      }))
      .sort(sortByParam('importer')) as ConfigurationRow[];

    setConfigurations(formattedData);
  }, [
    configurationList,
    isLoading,
    isError,
    definitionsQuery.data,
    selectOrgNameByOrgId,
  ]);

  useEffect(() => {
    setSearch(getImporterNameSearch(importerNames));
  }, [importerNames]);

  const handleOnClickEdit = (configurationId: string): void => {
    navigate({
      pathname: Routes.EDIT.replace(':configurationId', configurationId),
      search: getImporterNameSearch(importerNames),
    });
  };

  const handleOnClickDelete = (configurationId: string): void => {
    confirmPopup(
      intl.formatMessage({
        defaultMessage: 'Are you sure you want to delete this configuration?',
      }),
      () => {
        deleteConfiguration(configurationId)
          .unwrap()
          .then(() => {
            message.success(
              intl.formatMessage({
                defaultMessage: 'The configuration has been deleted.',
              }),
            );
            setConfigurations((currentData) =>
              currentData.filter(({ key }) => key !== configurationId),
            );
          });
      },
      intl,
    );
  };

  const filterOptions = Object.values(definitionsQuery.data || {}).map(
    (definition) => (
      <Option
        value={camelCase(definition.importerName)}
        key={definition.importerName}
        data-testid={`importer-filter-option-${definition.importerName}`}
      >
        {definition.name}
      </Option>
    ),
  );

  const handleFilterChange = (names: string[]) => {
    setSearch(getImporterNameSearch(names));
  };

  return (
    <Layout data-testid="importer-list">
      <Card className={styles.card}>
        <Row align="middle" justify="space-between">
          <Col>
            <Title data-testid="title">
              <FormattedMessage defaultMessage="Connectors" />
            </Title>
          </Col>
          <Col>
            <Link
              to={{
                pathname: Routes.ADD,
                search: getImporterNameSearch(importerNames),
              }}
            >
              <Button
                data-testid="button-add"
                type="primary"
                shape="round"
                icon={<AiOutlinePlus className={styles.newButtonIcon} />}
              >
                <FormattedMessage defaultMessage="New Connector" />
              </Button>
            </Link>
          </Col>
        </Row>
        <Row align="middle" justify="space-around">
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            bordered={false}
            placeholder={
              <FormattedMessage defaultMessage="Select one or more connector types to filter." />
            }
            value={importerNames}
            onChange={handleFilterChange}
            data-testid="select-importer-filter"
          >
            {filterOptions}
          </Select>
        </Row>
        <Table<ConfigurationRow>
          data={configurations}
          columns={columns<ConfigurationRow>(
            handleOnClickEdit,
            handleOnClickDelete,
          )}
        />
      </Card>
      {importerNames.length >= 1 && configurations.length > 0 && !isLoading && (
        <Row justify="end">
          <Button type="dashed" shape="round">
            <Link
              to={{
                pathname:
                  importerNames.length === 1
                    ? getFullPath(CandidateRoutes.CANDIDATE_LIST, true)
                    : getFullPath(CandidateRoutes.DEVICE_ADD, true),
                search:
                  importerNames.length === 1
                    ? getImporterNameSearch(importerNames)
                    : '',
              }}
              data-testid="provision-link"
            >
              <FormattedMessage defaultMessage="Click here to provision devices" />
            </Link>
          </Button>
        </Row>
      )}
      <Outlet />
    </Layout>
  );
};

export default List;
