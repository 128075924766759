import { useAuth0 } from '@auth0/auth0-react';

import axios from 'axios';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLazyGetCurrentUserQuery, User } from 'api/user';
import Loader from 'components/ui/atoms/Loader';

const setAxiosRequestInterceptor = (
  getAccessTokenSilently: () => Promise<string>,
): void => {
  /* istanbul ignore next */
  axios.interceptors.request.use(async (config) => {
    const requestConfig = config;
    const accessToken = await getAccessTokenSilently();

    /* istanbul ignore next */
    if (requestConfig.headers)
      requestConfig.headers.Authorization = `Bearer ${accessToken}`;

    return requestConfig;
  });
};

type Props = {
  children: React.ReactChild;
  currentUser?: User;
  setUser: (user: User) => void;
};

// TODO: move it somewhere later
const appConfig = {
  urlCss: 'https://cdn-auth.metrikus.io/auth0/v4/index.css',
  urlJs: 'https://cdn-auth.metrikus.io/auth0/v4/index.js',
  color: '#0177ee',
  logoPath: 'https://www.metrikus.io/hubfs/metrikus-logo-colour.svg',
};

const Auth: React.FC<Props> = ({ children, setUser, currentUser }) => {
  const intl = useIntl();
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  const setAuthData = async () => {
    try {
      setAxiosRequestInterceptor(getAccessTokenSilently);
      const user = await getCurrentUser().unwrap();
      setUser(user);
    } catch (err) {
      logout();
    }
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated)
      loginWithRedirect({
        appState: {
          returnTo: window.location.href,
        },
        fragment: window.btoa(JSON.stringify(appConfig)),
      });

    if (isAuthenticated) setAuthData();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return (
    <Loader
      text={intl.formatMessage({ defaultMessage: 'Metrikus is loading...' })}
      visible={isLoading}
    >
      <>{currentUser && children}</>
    </Loader>
  );
};

export default Auth;
