import { Space, Typography } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartData,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ReactElement, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  config,
  BAR_EMPTY_DATASET_COLOR,
  getDefaultDatasetConfig,
} from './config';

import styles from './styles.module.scss';

const { Title } = Typography;

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export type Dataset = {
  label: string;
  value?: number;
}[];

type Props = {
  dataSet: Dataset;
  noDataLabel: string;
  unit?: string;
  title?: string | ReactElement;
};

export const DailyScoreBars: React.FC<Props> = ({
  dataSet,
  noDataLabel,
  title,
  unit = '',
}) => {
  const [processedData, setProcessedData] = useState<
    ChartData<'bar', (number | null)[], unknown>
  >({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    setProcessedData({
      labels: dataSet.map((item) => item.label),
      datasets: [
        {
          ...getDefaultDatasetConfig((value) =>
            value === null ? '' : `${value}${unit}`,
          ),
          data: dataSet.map(({ value }) => value || null),
        },
        {
          ...getDefaultDatasetConfig((value) =>
            value !== null ? noDataLabel : '',
          ),
          data: dataSet.map(({ value }) => (value ? null : 0)),
          backgroundColor: BAR_EMPTY_DATASET_COLOR,
        },
      ],
    });
  }, [dataSet, noDataLabel, unit]);

  return (
    <Space direction="vertical" className={styles.wrapper} size={24}>
      <Title className={styles.title} level={5}>
        {title}
      </Title>
      <Bar options={config} data={processedData} plugins={[ChartDataLabels]} />
    </Space>
  );
};

export default DailyScoreBars;
