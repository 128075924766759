import { UserRow } from './config';

export const filterBySearchString = (
  searchString: string,
): ((row: UserRow) => boolean) =>
  searchString.length === 0
    ? () => true
    : ({ name, email, org }) =>
        name?.toLowerCase().includes(searchString) ||
        email?.toLowerCase().includes(searchString) ||
        (org?.meta.name.toLowerCase() || '').includes(searchString);
