import { Route, Routes as RouterRoutes } from 'react-router-dom';

import Report from 'views/Pdf/views/Report';

import { Routes } from './Routes';

const Routing: React.FC = () => {
  return (
    <RouterRoutes>
      <Route path={Routes.REPORT} element={<Report />} />
    </RouterRoutes>
  );
};

export default Routing;
