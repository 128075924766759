import { Typography, Space } from 'antd';
import React, { ReactElement } from 'react';
import cn from 'classnames';

import Figure from 'components/ui/atoms/Figure';
import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
  title?: string | ReactElement;
};

const Background: React.FC<Props> = ({ children, title = '' }) => {
  return (
    <div className={cn(styles.wrapper, { [styles.wrapperPadding]: title })}>
      {title && (
        <Title className={styles.title} level={5}>
          {title}
        </Title>
      )}

      <figure className={styles.circle} />
      <Figure position={{ left: '8vw', top: '5vh' }} />
      <Figure type="minus" position={{ right: '10vw', top: '20vh' }} />
      <Figure position={{ bottom: '20vh', right: '30vw' }} size="7vw" />
      <Figure
        position={{ bottom: '10vh', left: '10vw' }}
        size="8vw"
        opacity={40}
      />
      <Space
        direction="vertical"
        align="center"
        size="large"
        className={styles.content}
      >
        {children}
      </Space>
    </div>
  );
};

export default Background;
