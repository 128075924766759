import { Layout, Menu } from 'antd';
import React, { useCallback, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Helmet from 'components/Helmet';
import { LangContext } from 'components/Intl';
import LanguageSwitcherInline from 'components/LanguageSwitcherInline';
import { getLanguages, languagesMapKey } from 'utilities/constants';
import useFlags, { Flag } from 'utilities/hooks/useFlags';

import Navbar from './components/Navbar';
import Routing from './components/Routing';
import { Routes } from './components/Routing/Routes';

import { menuList } from './config';
import styles from './styles.module.scss';

const { Content, Sider } = Layout;
const { Item } = Menu;

const Settings: React.FC = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { lang } = useContext(LangContext);
  const flags = useFlags([
    Flag.BMS_MODULE_VISIBILITY,
    Flag.ORG_MODULE_VISIBILITY,
  ]);
  const rootLocation = pathname.split('/')[2];

  const getHiddenModuleNames = useCallback(
    () =>
      Object.entries(flags)
        .filter(([_, isVisible]) => Boolean(!isVisible))
        .map(([flagName]) => flagName.split('_')[0]),
    [flags],
  );

  const getMenuItems = useCallback(() => {
    const hiddenModules = getHiddenModuleNames();
    return menuList.filter(({ key }) => !hiddenModules.includes(key));
  }, [flags, menuList]);

  useEffect(() => {
    const hiddenModules = getHiddenModuleNames();
    const currentModule = menuList.find(({ route }) =>
      pathname.includes(route),
    );

    if (!currentModule) return;
    if (hiddenModules.includes(currentModule.key)) {
      navigate({ pathname: Routes.USER });
    }
  }, [flags, pathname]);

  const languageData = getLanguages();

  return (
    <Layout>
      <Navbar />

      <Layout data-testid="view-settings" className={styles.layout}>
        <Helmet title={intl.formatMessage({ defaultMessage: 'Settings' })} />
        <Sider
          width={parseInt(styles.sliderWidth, 10)}
          className={styles.slider}
          breakpoint="lg"
        >
          <Menu
            mode="inline"
            selectedKeys={[rootLocation]}
            className={styles.menu}
            data-testid="settings-menu"
          >
            {getMenuItems().map(({ route, icon: Ico, label }) => (
              <Item key={route} icon={<Ico />}>
                <NavLink to={route}>{label}</NavLink>
              </Item>
            ))}

            <Item
              key="lang"
              icon={languageData[lang as languagesMapKey].flag}
              className={styles.lang}
            >
              <LanguageSwitcherInline />
            </Item>
          </Menu>
        </Sider>
        <Content className={styles.content} data-testid="settings-content">
          <Routing />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Settings;
