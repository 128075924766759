import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import AnimatedRouter from 'components/AnimatedRoutes';
import { Routes as RootRoutes } from 'components/Routing/Routes';

import ApiKey from 'views/Settings/views/ApiKey';
import Bms from 'views/Settings/views/Bms';
import Importer from 'views/Settings/views/Importer';
import Org from 'views/Settings/views/Org';
import User from 'views/Settings/views/User';

import { Routes } from './Routes';

export const getFullPath = (
  route: Routes | null = null,
  absolute = false,
): string => {
  let fullPath = `/${route}`;

  if (absolute) fullPath = `/${RootRoutes.SETTINGS}${fullPath}`;

  return fullPath;
};

const Routing: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <AnimatedRouter pathLevelWatch={2}>
      <Route
        path="/"
        element={
          pathname.substring(1) === RootRoutes.SETTINGS && (
            <Navigate replace to={getFullPath(Routes.USER, true)} />
          )
        }
      />
      <Route path={`${Routes.USER}/*`} element={<User />} />
      <Route path={`${Routes.IMPORTER}/*`} element={<Importer />} />
      <Route path={`${Routes.API_KEY}/*`} element={<ApiKey />} />
      <Route path={`${Routes.ORG}/*`} element={<Org />} />
      <Route path={`${Routes.BMS}/*`} element={<Bms />} />
    </AnimatedRouter>
  );
};

export default Routing;
