import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { CSSProperties, forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchAddressQuery } from 'api/space';

export type Props = {
  disabled?: boolean;
  debounceTimeout?: number;
  onChange?: (value: any) => void;
  style?: CSSProperties;
  className?: string;
};

const AddressSearch = forwardRef<
  { focus: () => void; blur: () => void; scrollTo: () => void },
  Props
>(
  (
    { disabled = false, debounceTimeout = 800, onChange, style, className },
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const intl = useIntl();

    const { data, isFetching } = useSearchAddressQuery(query, { skip: !query });

    const handleOnChange = (value: any) => {
      if (!data || !onChange) return;
      onChange(data.results.find(({ id }) => id === value.key));
    };

    const handleOnSearch = debounce((userInput) => {
      const cleanedUserInput = userInput.trim();

      if (!cleanedUserInput) return;
      setQuery(cleanedUserInput);
    }, debounceTimeout);

    return (
      <Select
        labelInValue
        filterOption={false}
        showSearch
        onSearch={handleOnSearch}
        onChange={handleOnChange}
        notFoundContent={isFetching ? <Spin size="small" /> : null}
        options={(data?.results || []).map(({ address, id }) => ({
          label: address.freeformAddress,
          value: id,
          key: id,
        }))}
        disabled={disabled}
        ref={ref}
        style={style}
        data-testid="address-search"
        className={className}
        aria-label={intl.formatMessage({ defaultMessage: 'address search' })}
      />
    );
  },
);

export default AddressSearch;
