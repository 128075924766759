import { Form as AntdForm, FormInstance, Select } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { LocationToLabelMap, SpaceNode, SpaceNodeCategory } from 'api/space';

const { Item } = AntdForm;
const { Option } = Select;

type Props = {
  spaceNodes?: { [spaceId: string]: SpaceNode } | undefined;
  form: FormInstance<any>;
  disabled?: boolean;
  buildingSelected: string | undefined;
  floorSelected: string | undefined;
  hideRooms?: boolean;
  setBuildingSelected: (buildingId: string) => void;
  setFloorSelected: (floorId: string) => void;
  setLocationId?: (locationId: string) => void;
};

const LocationSelector: React.FC<Props> = ({
  spaceNodes,
  form,
  disabled,
  buildingSelected,
  floorSelected,
  hideRooms,
  setBuildingSelected,
  setFloorSelected,
  setLocationId,
}) => {
  const intl = useIntl();
  const types = useMemo(() => {
    const nodeTypes = [
      {
        name: SpaceNodeCategory.BUILDING,
        parentId: null,
        required: true,
        onChange: (val: string) => {
          setBuildingSelected(val);
          setLocationId?.(val);

          form.resetFields([SpaceNodeCategory.FLOOR, SpaceNodeCategory.ROOM]);
        },
      },
      {
        name: SpaceNodeCategory.FLOOR,
        parentId: buildingSelected,
        required: true,
        onChange: (val: string): void => {
          setFloorSelected(val);
          setLocationId?.(val);

          form.resetFields([SpaceNodeCategory.ROOM]);
        },
      },
    ];

    if (!hideRooms)
      nodeTypes.push({
        name: SpaceNodeCategory.ROOM,
        parentId: floorSelected,
        required: false,
        onChange:
          setLocationId ||
          (() => {
            // do nothing
          }),
      });

    return nodeTypes;
  }, [
    buildingSelected,
    floorSelected,
    hideRooms,
    setBuildingSelected,
    setFloorSelected,
    setLocationId,
    form,
  ]);

  return (
    <>
      {types.map(({ name, parentId, required, onChange }) => {
        return (
          <Item
            label={LocationToLabelMap[name as SpaceNodeCategory]}
            name={name}
            rules={
              required
                ? [
                    {
                      required: true,
                      message: (
                        <FormattedMessage defaultMessage="This field is required." />
                      ),
                    },
                  ]
                : []
            }
            key={name}
          >
            <Select
              placeholder={LocationToLabelMap[name as SpaceNodeCategory]}
              disabled={disabled}
              allowClear={!required}
              onChange={onChange}
              data-testid={`${name}-select`}
              aria-label={intl.formatMessage(
                {
                  defaultMessage: '{val} dropdown',
                },
                { val: name },
              )}
              aria-expanded="false"
              alt-text={intl.formatMessage(
                {
                  defaultMessage: 'A dropdown list to select a {val}',
                },
                { val: name },
              )}
            >
              {spaceNodes &&
                Object.values(spaceNodes)
                  .filter(
                    (space) =>
                      space.category === name && space.parentId === parentId,
                  )
                  .map((space) => (
                    <Option
                      key={space.id}
                      data-testid={`${name}-select-${space.id}`}
                      value={space.id}
                    >
                      <span>{space.meta.name}</span>
                    </Option>
                  ))}
            </Select>
          </Item>
        );
      })}
    </>
  );
};

export default LocationSelector;
