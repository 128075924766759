import { NOT_RELEVANT } from 'views/Settings/views/Bms/utilities';
import { DataSourceRow } from './config';

export const filterBySearchString = (
  searchString: string,
): ((row: DataSourceRow) => boolean) =>
  searchString.length === 0
    ? () => true
    : ({ searchName, externalId }) => {
        const containsExternalId =
          externalId && externalId.length
            ? externalId.includes(searchString)
            : false;

        return searchName.includes(searchString) || containsExternalId;
      };

export const filterByProperty = (
  propertyIds: string[],
): ((row: DataSourceRow) => boolean) =>
  propertyIds.length === 0
    ? () => true
    : ({ properties }) =>
        !!properties.find(({ propertyName }) =>
          propertyIds.includes(propertyName),
        );

export const filterByIsLinked = (
  isLinked: boolean | string,
): ((row: DataSourceRow) => boolean) =>
  isLinked === NOT_RELEVANT ? () => true : (row) => row.isLinked === isLinked;
