/* eslint-disable react/jsx-props-no-spreading */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Row,
  Space,
  message,
  Typography,
  Empty,
} from 'antd';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  SpaceNodeCategory,
  SpaceNode,
  useCreateSpaceNodesMutation,
  useDeleteSpaceNodesMutation,
  useUpdateSpaceNodeMutation,
} from 'api/space';
import { handleEnter } from 'components/LocationTray/utils';
import SaveButton from '../SaveButton';
import sharedStyles from '../sharedStyles.module.scss';
import styles from './styles.module.scss';
import { FloorRow, getRoomChanges, resetInputRows } from './utils';

const { Item, List, ErrorList } = Form;
const { Paragraph } = Typography;

type FloorsInput = Record<number, string>;

type Props = {
  floors: SpaceNode[];
  unsavedChanges: boolean;
  setLocationTrayIsSaving: (isSaving: boolean) => void;
  setUnsavedChanges: (isFieldsChanged: boolean) => void;
  onFormContinue?: () => void;
};

const BuildingRoomsForm: React.FC<Props> = ({
  floors,
  unsavedChanges,
  setUnsavedChanges,
  setLocationTrayIsSaving,
  onFormContinue,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [existingFloors, setExistingFloors] = useState<FloorRow[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [floorsInput, setFloorsInput] = useState<FloorsInput>({});

  const [createSpaceNodes, createSpaceNodesResult] =
    useCreateSpaceNodesMutation();
  const [updateSpaceNode, updateSpaceNodeResult] = useUpdateSpaceNodeMutation();
  const [deleteSpaceNodes, deleteSpaceNodesResult] =
    useDeleteSpaceNodesMutation();

  useEffect(() => {
    if (
      createSpaceNodesResult.isLoading ||
      updateSpaceNodeResult.isLoading ||
      deleteSpaceNodesResult.isLoading
    ) {
      setLocationTrayIsSaving(true);
      return;
    }

    if (
      createSpaceNodesResult.isError ||
      updateSpaceNodeResult.isError ||
      deleteSpaceNodesResult.isError
    ) {
      setLocationTrayIsSaving(false);
      resetInputRows(floors, setExistingFloors, form, setUnsavedChanges);
      return;
    }

    if (
      createSpaceNodesResult.isSuccess ||
      updateSpaceNodeResult.isSuccess ||
      deleteSpaceNodesResult.isSuccess
    ) {
      message.success(intl.formatMessage({ defaultMessage: 'Rooms updated.' }));
      setLocationTrayIsSaving(false);
      setFormSubmitted(true);
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createSpaceNodesResult,
    deleteSpaceNodesResult,
    updateSpaceNodeResult,
    form,
  ]);

  // If the floor list changes, reset the form
  useEffect(() => {
    if (floors)
      resetInputRows(floors, setExistingFloors, form, setUnsavedChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floors, form]);

  const onSubmit = async (values: any, originals: FloorRow[]) => {
    const { added, removed, modified } = getRoomChanges(values, originals);

    if (added.length > 0) {
      createSpaceNodes(
        added.map((row) => ({
          parentId: row.parentId,
          category: SpaceNodeCategory.ROOM,
          meta: {
            name: row.name,
            description: '',
          },
        })),
      );
    }

    if (removed.length > 0) {
      deleteSpaceNodes(
        removed.map((row) => row.id || '').filter((id) => id !== ''),
      );
    }

    if (modified.length > 0) {
      modified
        .filter(({ id }) => Boolean(id))
        .forEach(({ id, name }) => {
          updateSpaceNode({
            spaceId: id as string,
            body: {
              meta: {
                name,
                description: '',
              },
            },
          });
        });
    }
  };

  const handleFloorInputChange = (floor: number, value: string) => {
    setFloorsInput({ ...floorsInput, [floor]: value });
  };

  const getFloorInput = (floor: number) => {
    return floorsInput[floor] || '';
  };

  const clearFloorInput = (floor: number) => {
    setFloorsInput({ ...floorsInput, [floor]: '' });
  };

  const formChanged = () => {
    setUnsavedChanges(!isEqual(form.getFieldsValue().rows, existingFloors));
  };

  return (
    <Form
      form={form}
      className={styles.form}
      onFinish={(values) => onSubmit(values, existingFloors)}
      onFieldsChange={formChanged}
      onChange={() => setFormSubmitted(false)}
      data-testid="edit-rooms-form"
    >
      {floors.length === 0 ? (
        <Empty
          className={styles.empty}
          description={<FormattedMessage defaultMessage="No Floors Added..." />}
        >
          <Button type="primary" onClick={onFormContinue}>
            <FormattedMessage defaultMessage="Create floors" />
          </Button>
        </Empty>
      ) : (
        <>
          <div className={sharedStyles.scrollableArea}>
            <List name="rows">
              {(fields) => (
                <>
                  {fields.map((field) => (
                    <div key={field.key}>
                      <Paragraph className={sharedStyles.subHeader}>
                        {form.getFieldsValue().rows[field.key].name}
                      </Paragraph>
                      <List name={[field.name, 'children']}>
                        {(innerFields, { add, remove }, { errors }) => (
                          <>
                            <Space
                              className={styles.roomInputRow}
                              align="baseline"
                            >
                              <Item>
                                <Input
                                  placeholder={intl.formatMessage({
                                    defaultMessage: 'Name your room',
                                  })}
                                  data-testid="input-room-name"
                                  onChange={(e) =>
                                    handleFloorInputChange(
                                      field.name,
                                      e.target.value,
                                    )
                                  }
                                  value={getFloorInput(field.name)}
                                  onPressEnter={() => {
                                    add({ name: getFloorInput(field.name) });
                                    clearFloorInput(field.name);
                                  }}
                                  onKeyDown={handleEnter}
                                />
                              </Item>
                              <Item>
                                <Button
                                  data-testid="add-button"
                                  type="link"
                                  className={sharedStyles.addButton}
                                  icon={<PlusCircleOutlined />}
                                  onClick={() => {
                                    add({ name: getFloorInput(field.name) }, 0);
                                    clearFloorInput(field.name);
                                  }}
                                />
                              </Item>
                              <ErrorList errors={errors} />
                            </Space>
                            {innerFields.length > 0 && <br />}
                            {innerFields.map((child) => (
                              <Space
                                className={styles.roomInputRow}
                                align="baseline"
                                key={child.key}
                              >
                                <Item
                                  name={[child.name, 'name']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing room name',
                                    },
                                  ]}
                                >
                                  <Input />
                                </Item>
                                <Item>
                                  <Button
                                    data-testid={`remove-button-${field.key}-${child.key}`}
                                    type="link"
                                    className={sharedStyles.deleteButton}
                                    icon={<MinusCircleOutlined />}
                                    onClick={() => remove(child.name)}
                                  />
                                </Item>
                              </Space>
                            ))}
                          </>
                        )}
                      </List>
                    </div>
                  ))}
                </>
              )}
            </List>
          </div>
          <Item className={sharedStyles.formSubmitRow}>
            <Row justify="center">
              <Button
                data-testid="discard-button"
                type="ghost"
                className={sharedStyles.formButton}
                disabled={!unsavedChanges}
                onClick={() => {
                  resetInputRows(
                    floors,
                    setExistingFloors,
                    form,
                    setUnsavedChanges,
                  );
                }}
              >
                <FormattedMessage defaultMessage="Discard" />
              </Button>
              <SaveButton
                isSaved={formSubmitted}
                isDisabled={!unsavedChanges}
              />
            </Row>
          </Item>
        </>
      )}
    </Form>
  );
};

export default BuildingRoomsForm;
