import { Tag, Table, Space, Tooltip, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactChild } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { TiDeleteOutline } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';

import { TelemetryFeed, DataSinkLifecycle } from 'api/dataSink';
import { getIntl } from 'components/Intl';
import { TableRow } from 'components/ui/molecules/Table';

export type DataSinkRow = TableRow & {
  name: string;
  searchName: string;
  id: string;
  lifecycle: string;
  locationId: string;
  location: string;
  sources: TelemetryFeed[];
};

export type SinkDetailsRow = TableRow & {
  name: string;
  externalId: string;
  telemetryProperties: string[];
  id: string;
  telemetryFeedId: string | undefined;
};

export const columnsDataSink = (
  onClickEdit: (dataSinkId: string) => void,
): ColumnsType<DataSinkRow> => {
  const intl = getIntl();
  return [
    Table.SELECTION_COLUMN,
    {
      title: <FormattedMessage defaultMessage="Name" />,
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="ID" />,
      key: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.id.localeCompare(b.id),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Lifecycle" />,
      key: 'lifecycle',
      render: (
        value: DataSinkRow,
        { hideActions, lifecycle }: DataSinkRow,
      ): ReactChild => {
        if (hideActions) return value.lifecycle;

        if (lifecycle === DataSinkLifecycle.ACTIVE)
          return (
            <Tag color="success">
              <FormattedMessage defaultMessage="Active" />
            </Tag>
          );

        if (lifecycle === DataSinkLifecycle.DELETED)
          return (
            <Tag color="error">
              <FormattedMessage defaultMessage="Deleted" />
            </Tag>
          );

        return <Tag>{lifecycle}</Tag>;
      },
    },
    {
      title: <FormattedMessage defaultMessage="Location" />,
      key: 'location',
      dataIndex: 'location',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Sources" />,
      key: 'sources',
      sorter: (a, b) => a.sources.length - b.sources.length,
      render: (
        value: DataSinkRow,
        { hideActions, sources }: DataSinkRow,
      ): number | ReactChild => {
        if (hideActions) return value.name;
        return sources.length;
      },
    },
    {
      title: '',
      key: 'actions',
      width: 48,
      render: ({ hideActions, key }: SinkDetailsRow): null | ReactChild => {
        if (hideActions) return <></>;
        const aria = intl.formatMessage(
          {
            defaultMessage: 'edit {val}',
          },
          { val: `${key}` },
        );

        return (
          <Space size="middle" data-testid="buttons-actions">
            <Tooltip title={<FormattedMessage defaultMessage="Edit" />}>
              <Button
                type="link"
                icon={<FiEdit2 alt-text={aria} aria-label={aria} />}
                onClick={() => onClickEdit(key)}
                data-testid={`edit-button-${key}`}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

export const columnsSinkDetails = (
  onClickUnlink: (telemetryFeedId: string | undefined) => void,
) => [
  {
    title: <FormattedMessage defaultMessage="Source name" />,
    key: 'name',
    dataIndex: 'name',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: <FormattedMessage defaultMessage="External ID" />,
    key: 'externalId',
    dataIndex: 'externalId',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: <FormattedMessage defaultMessage="Telemetry Properties" />,
    key: 'properties',
    ellipsis: {
      showTitle: false,
    },
    render: (
      value: SinkDetailsRow,
      { hideActions, telemetryProperties }: SinkDetailsRow,
    ): ReactChild => {
      if (hideActions) return value.name;
      return telemetryProperties.join(', ');
    },
  },
  {
    title: <FormattedMessage defaultMessage="ID" />,
    key: 'id',
    dataIndex: 'id',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: <FormattedMessage defaultMessage="Unlink" />,
    key: 'unlink',
    width: 80,
    render: ({
      hideActions,
      telemetryFeedId,
      key,
    }: SinkDetailsRow): null | ReactChild => {
      if (hideActions) return null;
      return (
        <Space size="middle" data-testid="buttons-actions">
          <Tooltip title={<FormattedMessage defaultMessage="Unlink" />}>
            <Button
              type="link"
              shape="circle"
              icon={<TiDeleteOutline />}
              onClick={() => onClickUnlink(telemetryFeedId)}
              data-testid={`unlink-button-${key}`}
            />
          </Tooltip>
        </Space>
      );
    },
  },
];
