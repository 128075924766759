import { Typography, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

const { Title } = Typography;

export type Props = {
  value?: number;
};

const OverallValue: React.FC<Props> = ({ value }) => {
  return (
    <Space className={styles.wrapper} size={0} direction="vertical">
      <Title level={value ? 1 : 4}>
        {value ? `${value}%` : <FormattedMessage defaultMessage="No data" />}
      </Title>
      <Title level={3}>
        <FormattedMessage defaultMessage="Overall score" />
      </Title>
    </Space>
  );
};

export default OverallValue;
