import { Space, Tooltip, Button } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { ReactChild } from 'react';

import { FiEdit2 } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { DataSourceLifecycle } from 'api/dataSource/dataSource';

import { getIntl } from 'components/Intl';
import { TableRow } from 'components/ui/molecules/Table';

export const LifecycleColorMap = {
  [DataSourceLifecycle.CANDIDATE]: '#ebb400',
  [DataSourceLifecycle.OPERATIONAL]: '#6ed137',
  [DataSourceLifecycle.DETACHED]: '#f50',
  [DataSourceLifecycle.DISABLED]: '#777b7c',
  [DataSourceLifecycle.RMA]: '#2db7f5',
};
export type DeviceRow = TableRow & {
  name: string;
  locationId: string;
  location: string;
  created: string;
  searchDump: string[];
};

export const breadcrumbsDelimiter = ' / ';

export const columns = (
  onClickEdit: (key: string) => void,
): ColumnsType<DeviceRow> => {
  const intl = getIntl();

  return [
    {
      title: <FormattedMessage defaultMessage="Device Name" />,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.toString().localeCompare(b.name),
    },
    {
      title: <FormattedMessage defaultMessage="Location" />,
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => a.location.toString().localeCompare(b.location),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Date added" />,
      dataIndex: 'created',
      key: 'created',
      defaultSortOrder: 'descend',
      sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
      ellipsis: {
        showTitle: false,
      },
      render: (
        value: string,
        { hideActions, created }: DeviceRow,
      ): ReactChild => {
        if (hideActions) return value;
        return dayjs(value).format('DD-MM-YYYY');
      },
    },
    {
      title: '',
      key: 'action',
      width: 80,
      render: ({
        hideActions,
        key: deviceId,
      }: DeviceRow): null | ReactChild => {
        if (hideActions) return null;
        const aria = intl.formatMessage({
          defaultMessage: 'edit device',
        });
        return (
          <Space size="middle" data-testid="buttons-actions">
            <Tooltip title={<FormattedMessage defaultMessage="Edit" />}>
              <Button
                type="link"
                icon={<FiEdit2 />}
                onClick={() => onClickEdit(deviceId)}
                data-testid={`edit-button-${deviceId}`}
                aria-label={aria}
                alt-text={aria}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};
