import { ReportConfigRow } from './config';

export enum FilterDataOptions {
  searchTerm = 'searchTerm',
  frequency = 'frequency',
  reportType = 'reportType',
}

export const filterBySearchTerm = (
  searchString: string,
): ((row: ReportConfigRow) => boolean) =>
  searchString.length === 0
    ? () => true
    : ({ name }) => name.toLowerCase().includes(searchString);

export const filterByFrequency = (
  frequencyFilter: string | null,
): ((row: ReportConfigRow) => boolean) =>
  frequencyFilter === null
    ? () => true
    : ({ frequency }) => frequencyFilter === frequency;

export const filterByReportType = (
  reportTypeFilter: string,
): ((row: ReportConfigRow) => boolean) =>
  reportTypeFilter.length === 0
    ? () => true
    : ({ reportType }) => reportTypeFilter === reportType;
