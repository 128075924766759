import { AiOutlineApi } from 'react-icons/ai';
import { FaBuilding } from 'react-icons/fa';
import { FiKey, FiUser } from 'react-icons/fi';
import { IoMdSettings } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import { Routes } from './components/Routing/Routes';

export const menuList = [
  {
    icon: FiUser,
    label: <FormattedMessage defaultMessage="Users" />,
    route: Routes.USER,
    key: 'users',
  },
  {
    icon: FiKey,
    label: <FormattedMessage defaultMessage="API Keys" />,
    route: Routes.API_KEY,
    key: 'api',
  },
  {
    icon: FaBuilding,
    label: <FormattedMessage defaultMessage="Organisations" />,
    route: Routes.ORG,
    key: 'org',
  },
  {
    icon: IoMdSettings,
    label: <FormattedMessage defaultMessage="BMS" />,
    route: Routes.BMS,
    key: 'bms',
  },
  {
    icon: AiOutlineApi,
    label: <FormattedMessage defaultMessage="Connectors" />,
    route: Routes.IMPORTER,
    key: 'importer',
  },
];
