import { Button, Row, Col, Typography, Empty } from 'antd';
import cn from 'classnames';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { SpaceNodeCollection, useGetSpaceNodeListQuery } from 'api/space';
import {
  getSelectedBuilding,
  getSelectedFloor,
  getSelectedFloors,
  getTrayConfig,
} from 'components/LocationTray/utils';
import {
  LocationSelectorStateConfig,
  LocationSelectorTray,
} from 'ducks/space/slice';

import SpaceCard from '../../components/SpaceCard';
import FloorSelector from './components/FloorSelector';

import styles from './styles.module.scss';

// Content to populate the list of rooms for the selected floor
const getRoomList = (
  selectedFloorId: string | undefined,
  spaceNodes: SpaceNodeCollection,
  onSpaceSelect: (spaceNodeId: string) => void,
) => {
  const roomNodes = Object.values(spaceNodes)
    .filter(({ parentId }) => parentId === selectedFloorId)
    .map((spaceNode) => (
      <SpaceCard
        spaceNode={spaceNode}
        key={spaceNode.id}
        onClick={onSpaceSelect}
        className={styles.roomCard}
      />
    ));

  return roomNodes.length ? (
    roomNodes
  ) : (
    <Empty
      className={cn(styles.roomListEmpty, {
        [styles.floorSelected]: !selectedFloorId,
      })}
      description={
        !selectedFloorId ? (
          <FormattedMessage defaultMessage="Select a floor to see rooms." />
        ) : (
          <FormattedMessage defaultMessage="No rooms found." />
        )
      }
      key="empty"
      data-testid="room-list-empty"
    />
  );
};

type Props = {
  locationSelectorTray: LocationSelectorTray;
  orgId?: string;
  selectorsSettings: { [selectorType: string]: LocationSelectorStateConfig };
  setSelectedFloorId: (
    locationSelectorTray: LocationSelectorTray,
    spaceNodeId: string,
  ) => void;
  setActiveLocationId: (spaceNodeId: string) => void;
  openLocationTray: (
    locationSelectorTray: LocationSelectorTray,
    open: boolean,
  ) => void;
};

const SpaceSelector: React.FC<Props> = ({
  locationSelectorTray,
  orgId,
  selectorsSettings,
  setSelectedFloorId,
  setActiveLocationId,
  openLocationTray,
}) => {
  const { data: spaceNodes, isFetching } = useGetSpaceNodeListQuery(
    { orgId },
    { skip: !orgId },
  );

  const config = getTrayConfig(selectorsSettings, locationSelectorTray);

  const {
    selectedFloor,
    floors = [],
    selectedBuilding,
  } = useMemo(
    () =>
      !spaceNodes
        ? {}
        : {
            selectedFloor: getSelectedFloor(spaceNodes, config),
            floors: getSelectedFloors(spaceNodes, config),
            selectedBuilding: getSelectedBuilding(spaceNodes, config),
          },
    [spaceNodes, config],
  );

  // On space selection, set the active location and close the selector
  const onSpaceSelect = (spaceNodeId: string) => {
    setActiveLocationId(spaceNodeId);
    openLocationTray(locationSelectorTray, false);
  };

  return selectedBuilding && floors.length > 0 ? (
    <>
      <FloorSelector
        locationSelectorTray={locationSelectorTray}
        floors={floors}
        selectedFloor={selectedFloor}
        setSelectedFloorId={setSelectedFloorId}
        setActiveLocationId={onSpaceSelect}
      />
      <div className={styles.roomList}>
        {selectedFloor && (
          <Row>
            <Col flex="auto" className={styles.roomListTitle}>
              <Typography.Text>
                {selectedFloor.meta.name} /{' '}
                <FormattedMessage defaultMessage="Rooms" />
              </Typography.Text>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={() => onSpaceSelect(selectedFloor.id)}
              >
                <FormattedMessage defaultMessage="Select whole floor" />
              </Button>
            </Col>
          </Row>
        )}
        {getRoomList(selectedFloor?.id, spaceNodes || {}, onSpaceSelect)}
      </div>
    </>
  ) : (
    <>
      {!isFetching && floors.length === 0 && (
        <Empty
          className={styles.locationTrayEmpty}
          description={
            selectedBuilding ? (
              <FormattedMessage defaultMessage="Building has no floors." />
            ) : (
              <FormattedMessage defaultMessage="Select a building..." />
            )
          }
          data-testid="location-tray-empty-message"
        />
      )}
    </>
  );
};

export default SpaceSelector;
