import { CellData } from './components/Cell';

export const getMinMax = (data: CellData[][]): Record<string, number> => {
  const flatArray = data.reduce(
    (i: number[], o) => [...i, ...o.map(({ value }) => value || 0)],
    [],
  );
  const max = Math.max(...flatArray);
  const min = Math.min(...flatArray);
  return { min, diff: max - min };
};
