/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Space, Button, Dropdown } from 'antd';
import cn from 'classnames';
import React from 'react';
import { SpaceNode } from 'api/space';

import { LocationSelectorTray } from 'ducks/space/slice';
import styles from './styles.module.scss';
import { FloorViews, getSettingsMenu } from './utils';

type Floor = {
  spaceNode?: SpaceNode;
  ghost?: boolean;
  floorNumber?: number;
};

// Create a new list of floors with missing floors left out
const getRealFloors = (floors: SpaceNode[]): Floor[] => {
  return floors.map((f) => ({ spaceNode: f }));
};

// Create a new list of floors with missing floors filled in
const getFloorsWithMissing = (floors: SpaceNode[]): Floor[] => {
  const floorsMap = Object.fromEntries(
    floors.map((floor) => {
      const floorNumber: number = floor.systemMeta.floorNumber || 0;
      return [floorNumber, floor];
    }),
  );

  const lowestFloorNumber =
    (floors[0].systemMeta?.floorNumber || 0) < 0
      ? Math.abs(floors[0].systemMeta.floorNumber || 0)
      : 0;

  const highestFloorNumberIdx =
    (floors[floors.length - 1].systemMeta.floorNumber || 0) +
    lowestFloorNumber +
    1;

  const allFloors: Floor[] = Array.from(Array(highestFloorNumberIdx)).map(
    (empty, idx) => {
      const floor = floorsMap[idx - lowestFloorNumber];
      return floor
        ? { spaceNode: floor }
        : { ghost: true, floorNumber: idx - lowestFloorNumber };
    },
  );

  return allFloors;
};

const getFloorButtons = (
  locationSelectorTray: LocationSelectorTray,
  floors: Floor[],
  setSelectedFloorId: (
    locationSelectorTray: LocationSelectorTray,
    spaceNodeId: string,
  ) => void,
  setActiveLocationId: (
    locationSelectorTray: LocationSelectorTray,
    spaceNodeId: string,
  ) => void,
  floorsView: FloorViews,
  selectedFloor?: SpaceNode,
) => {
  const floorButtons: any[] = [];

  const setSelectedFloorIfDefined = (floor?: SpaceNode) => {
    if (floor) {
      setSelectedFloorId(locationSelectorTray, floor.id);
    }
  };

  const floorDoubleClicked = (floor?: SpaceNode) => {
    if (floor) {
      setSelectedFloorId(locationSelectorTray, floor.id);
      setActiveLocationId(locationSelectorTray, floor.id);
    }
  };

  // Iterate through floors backwards (top to bottom)
  floors.reverse().forEach((floor, index) => {
    const i = floors.length - (index + 1);
    const ghost = floor.ghost === true;
    const selected =
      selectedFloor !== undefined
        ? floor.spaceNode?.id === selectedFloor.id
        : false;
    let floorNumberStr = (
      floor.floorNumber ||
      floor.spaceNode?.systemMeta?.floorNumber ||
      0
    ).toString();

    floorNumberStr = floorNumberStr.padStart(2, '0');
    floorNumberStr = floorNumberStr === '00' ? 'G' : floorNumberStr;

    const color: 'red' | 'green' | 'orange' | 'default' = 'default';

    floorButtons.push(
      <Space
        direction="horizontal"
        className={styles.floor}
        style={{ zIndex: i }}
        key={floor.spaceNode?.id || i}
      >
        <Button
          className={cn(styles.floorButton, color)}
          type={selected ? 'primary' : 'default'}
          onClick={() => setSelectedFloorIfDefined(floor.spaceNode)}
          color={color}
          disabled={ghost}
        >
          {floorNumberStr}
        </Button>
        <div
          className={cn(
            styles.floorOutline,
            styles[color],
            styles[floorsView],
            {
              [styles.selected]: selected,
              [styles.disabled]: ghost,
            },
          )}
          onClick={() => setSelectedFloorIfDefined(floor.spaceNode)}
          onDoubleClick={() => floorDoubleClicked(floor.spaceNode)}
          data-testid={
            ghost ? `floor-ghost-${floorNumberStr}` : 'floor-outline'
          }
        />
      </Space>,
    );
  });

  return floorButtons;
};

type Props = {
  locationSelectorTray: LocationSelectorTray;
  floors: SpaceNode[];
  selectedFloor?: SpaceNode;
  floorsView: FloorViews;
  showGhostFloors?: boolean;
  setSelectedFloorId: (tray: LocationSelectorTray, spaceNodeId: string) => void;
  setActiveLocationId: (spaceNodeId: string) => void;
  setShowGhostFloors: (showGhostFloors: boolean) => void;
  setFloorsView: (floorsView: FloorViews) => void;
};

const FloorSelector: React.FC<Props> = ({
  locationSelectorTray,
  floors,
  selectedFloor,
  setSelectedFloorId,
  setActiveLocationId,
  floorsView,
  showGhostFloors = true,
  setShowGhostFloors,
  setFloorsView,
}) => {
  let allFloors: Floor[] = [];

  if (!showGhostFloors) {
    allFloors = getRealFloors(floors);
  } else {
    allFloors = getFloorsWithMissing(floors);
  }

  const floorButtons = getFloorButtons(
    locationSelectorTray,
    allFloors,
    setSelectedFloorId,
    setActiveLocationId,
    floorsView,
    selectedFloor,
  );

  // Called when a settings option is clicked
  const handleSettingsClick = (menuInfo: { key: string }) => {
    if (menuInfo.key === 'change_view') {
      setFloorsView(floorsView === 'perspective' ? 'angled' : 'perspective');
    } else if (menuInfo.key === 'show_hide_missing_floors') {
      setShowGhostFloors(!showGhostFloors);
    }
  };

  return (
    <div className={styles.wrapper} data-testid="floor-buttons-wrapper">
      <div
        className={cn(styles.scrollContainer, styles[floorsView])}
        data-testid="floor-buttons-scroll-container"
      >
        {floorButtons}
      </div>
      <Dropdown
        menu={{
          onClick: handleSettingsClick,
          items: getSettingsMenu(floorsView),
        }}
        trigger={['click']}
        className={styles.viewOptionsButton}
      >
        <Button type="text" color="white" />
      </Dropdown>
    </div>
  );
};

export default FloorSelector;
