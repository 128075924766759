import L from 'leaflet';

import globalStyles from 'styles/global.module.scss';

type GridLayer = L.GridLayer & {
  createTile: (coords: L.Point, onDrawEnd: L.DoneCallback) => HTMLDivElement;
};

// https://leafletjs.com/examples/extending/extending-2-layers.html
export const getGridLayer = (
  minZoom = 0,
  tileSize = 256,
  color = globalStyles.colorGrid,
): GridLayer => {
  const gridLayer = new L.GridLayer({
    attribution: undefined,
    tileSize,
    minZoom,
    keepBuffer: 10,
    updateInterval: 300,
  }) as GridLayer;

  gridLayer.createTile = (coords, onDrawEnd) => {
    const tile = L.DomUtil.create('div');

    requestAnimationFrame(() => {
      tile.style.outline = `1px solid ${color}`;
      onDrawEnd(undefined, tile);
    });

    return tile;
  };

  return gridLayer;
};

export const getImageSize = (
  imgSrc: string,
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = imgSrc;
  });
