import React from 'react';
import { AiOutlineApi } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { Routes as RootRoutes } from 'components/Routing/Routes';
import { Routes as SettingRoutes } from 'views/Settings/components/Routing/Routes';

import Form from '../views/Form';
import List from '../views/List';

import { Routes } from './Routes';
import styles from './styles.module.scss';

export const getFullPath = (
  route: Routes | null = null,
  absolute = false,
): string => {
  let fullPath = `/${SettingRoutes.IMPORTER}`;

  if (route && [Routes.ADD, Routes.EDIT].includes(route))
    fullPath += `/${route}`;

  if (absolute) fullPath = `/${RootRoutes.SETTINGS}${fullPath}`;

  return fullPath;
};

const Icon = () => <AiOutlineApi size="30px" className={styles.ico} />;

const Routing: React.FC = () => {
  return (
    <ReactRoutes>
      <Route path="*" data-testid="route-importer-list" element={<List />}>
        <Route
          path={Routes.ADD}
          data-testid="route-importer-add"
          element={
            <Form
              title={
                <>
                  <Icon />
                  <FormattedMessage defaultMessage="New Connector" />
                </>
              }
            />
          }
        />
        <Route
          path={Routes.EDIT}
          data-testid="route-importer-edit"
          element={
            <Form
              title={
                <>
                  <Icon />
                  <FormattedMessage defaultMessage="Edit Connector" />
                </>
              }
            />
          }
        />
      </Route>
    </ReactRoutes>
  );
};

export default Routing;
