import { Layout } from 'antd';
import { useIntl } from 'react-intl';
import Helmet from 'components/Helmet';
import Routing from './components/Routing';

const { Content } = Layout;

const Devices: React.FC = () => {
  const intl = useIntl();
  return (
    <Layout data-testid="view-devices">
      <Content data-testid="devices-content">
        <Helmet title={intl.formatMessage({ defaultMessage: 'Devices' })} />
        <Routing />
      </Content>
    </Layout>
  );
};

export default Devices;
