import { Space, Typography } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartJsTitle,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { useEffect, useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';

import { scoreIndexToColor } from 'api/telemetryProperty';
import ChartLegend, { LegendItem } from 'components/ui/atoms/ChartLegend';

import getConfig, { getDefaultDatasetConfig } from './config';
import styles from './styles.module.scss';

const { Title } = Typography;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartJsTitle,
  Tooltip,
  Legend,
);

export type BarChartDataSet = {
  label: string;
  percentageValue: number;
  color: string;
  borderRadius?: any;
  borderSkipped?: boolean;
  borderWidth?: number;
};

export type Props = {
  title: string;
  unit: string;
  dataSets: BarChartDataSet[];
  onRenderComplete?: () => void;
  showNoDataPlaceholder?: boolean;
};

const StackedBarChart: React.FC<Props> = ({
  title,
  unit,
  dataSets,
  onRenderComplete,
  showNoDataPlaceholder = false,
}) => {
  const intl = useIntl();
  const [processedDataSets, setProcessedDataSets] = useState<BarChartDataSet[]>(
    [],
  );

  const config = useMemo(() => getConfig(() => onRenderComplete?.()), []);

  useEffect(() => {
    const filteredData = dataSets.filter(
      ({ percentageValue }) => percentageValue !== null,
    );

    if (filteredData.length === 0) {
      setProcessedDataSets(
        showNoDataPlaceholder
          ? [
              {
                label: intl.formatMessage({
                  defaultMessage: 'No data',
                }),
                percentageValue: 100,
                color: scoreIndexToColor(-1),
              },
            ]
          : [],
      );
    } else {
      setProcessedDataSets(filteredData);
    }
  }, [dataSets, showNoDataPlaceholder]);

  const chartData: ChartData<'bar', number[], string> = useMemo(() => {
    const totalLength = processedDataSets.length - 1;
    return {
      labels: [''],
      datasets: processedDataSets.map(
        ({ label, percentageValue, color }, idx) => {
          const isFirst = idx === 0;
          const isLast = idx === totalLength;
          return {
            ...getDefaultDatasetConfig(isFirst, isLast),
            label,
            data: [percentageValue],
            backgroundColor: color,
          };
        },
      ),
    };
  }, [processedDataSets]);

  const legendData: LegendItem[] = useMemo(
    () =>
      chartData.datasets.map(({ data, label, backgroundColor }) => ({
        label: `${data[0]}% ${label}`,
        color: `${backgroundColor}`,
      })),
    [chartData],
  );

  return (
    <Space className={styles.container} direction="vertical">
      <Title className={styles.title} level={3}>
        <span>{title}</span>
        <span
          className={styles.titleUnit}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: `(${unit})` }}
        />
      </Title>
      <div className={styles.chartWrapper}>
        <Bar data={chartData} options={config} />
      </div>
      <ChartLegend data={legendData} />
    </Space>
  );
};

export default StackedBarChart;
