/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Form as AntdForm,
  Input,
  message,
  Select,
  Tabs,
  TreeSelect,
} from 'antd';
import { camelCase, snakeCase } from 'lodash';
import React, {
  ReactChild,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useNavigate,
  useLocation,
  useParams,
  createSearchParams,
} from 'react-router-dom';

import {
  ConfigurationDefinition,
  ConfigurationValues,
  SetConfigurationRequest,
  useCreateConfigurationMutation,
  useGetDefinitionsQuery,
  useLazyGetConfigurationListQuery,
  useUpdateConfigurationMutation,
} from 'api/configuration';
import { Org, OrgCategory, useGetOrgTreeQuery } from 'api/org';

import { orgTreeSelectData } from 'api/org/utils';
import Loader from 'components/ui/atoms/Loader';
import Modal from 'components/ui/molecules/Modal';
import { formatObjectKeys } from 'utilities/utilities';

import { getFullPath } from '../../components/Routing';
import { getImporterNameSearch } from '../../utilities';
import {
  prepareFields,
  prepareFiles,
  prepareImporterOptions,
} from './utilities';

const { Item, useForm } = AntdForm;

type Props = {
  title: string | ReactChild;
  userOrgId: string | undefined;
};

const Form: React.FC<Props> = ({ title, userOrgId }) => {
  const orgNodesQuery = useGetOrgTreeQuery();
  const orgNode = useMemo(
    () =>
      userOrgId && orgNodesQuery.isSuccess
        ? orgNodesQuery.data[userOrgId]
        : undefined,
    [orgNodesQuery, userOrgId],
  );
  const orgTreeSelect = useMemo(
    () => orgTreeSelectData((orgNodesQuery.data || {}) as Record<string, Org>),
    [orgNodesQuery],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isPopulatingData, setIsPopulatingData] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [fields, setFields] = useState<ReactElement[]>([]);
  const [files, setFiles] = useState<ReactElement[]>([]);
  const [tab, setTab] = useState<string>('fields');
  const [overriding, setOverriding] = useState<Record<string, boolean>>({});
  const [importerOptions, setImporterOptions] = useState<ReactElement[]>([]);
  const [activeImporter, setActiveImporter] =
    useState<ConfigurationDefinition>();

  const { configurationId } = useParams<'configurationId'>();
  const [form] = useForm();
  const intl = useIntl();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const importerNames = useMemo(
    () => searchParams.getAll('importerName').map(camelCase),
    [searchParams],
  );
  const definitionsQuery = useGetDefinitionsQuery({});

  const [getConfigurationList] = useLazyGetConfigurationListQuery();
  const [createConfiguration] = useCreateConfigurationMutation();
  const [updateConfiguration] = useUpdateConfigurationMutation();

  // Overriding is a string: boolean map with entries for each override-able
  // autogenerated config field. If true, the user has toggled the input to
  // manually enter a value for that field.
  const setOverride = (key: string, active: boolean) => {
    setOverriding({
      ...overriding,
      [key]: active,
    });

    if (!active) {
      form.setFieldsValue({
        [camelCase(key)]: undefined,
      });
    }
  };

  // When the definitions list updates, update the importer dropdown options
  useEffect(() => {
    prepareImporterOptions(setImporterOptions)(definitionsQuery.data || {});
  }, [definitionsQuery]);

  // Ensure the importer selected in the dropdown and in the form matches the
  // first importer named in the URL query params
  useEffect(() => {
    if (importerNames.length > 0 && definitionsQuery.data && !configurationId) {
      const defaultImporterName = importerNames[0];
      if (definitionsQuery.data[defaultImporterName]) {
        form.setFieldsValue({ importerName: defaultImporterName });
      }
      setActiveImporter(definitionsQuery.data[defaultImporterName]);
    }
  }, [importerNames, definitionsQuery, form, configurationId]);

  // If the importer selected in the dropdown changes, prepare the form fields
  useEffect(() => {
    if (activeImporter) {
      prepareFields(setFields)(
        activeImporter,
        configurationId,
        overriding,
        setOverride,
      );
      prepareFiles(setFiles)(activeImporter, configurationId);

      if (!configurationId) {
        const defaultValues = Object.fromEntries(
          Object.values(activeImporter.fields)
            .filter(({ default: def }) => Boolean(def))
            .map(({ key, default: defaultValue }) => [
              camelCase(key),
              defaultValue,
            ]),
        );

        form.setFieldsValue(defaultValues);
      }
    }
  }, [activeImporter, configurationId, form, overriding]);

  // If we are editing a specific importer, request its data and populate the
  // form
  useEffect(() => {
    if (configurationId && definitionsQuery.isSuccess) {
      setIsPopulatingData(true);
      getConfigurationList({ configurationId })
        .unwrap()
        .then(([data]) => {
          const importer = definitionsQuery.data[camelCase(data.importerName)];
          setActiveImporter(importer);

          const configurationValues = Object.fromEntries(
            Object.entries(data.configurationValues).map(([key, value]) => {
              const field = importer?.fields[camelCase(key)];
              if (typeof field === 'undefined' || field.secret)
                return [key, false];

              return [key, value];
            }),
          );

          form.setFieldsValue({
            ...configurationValues,
            ...data.configurationFiles,
            importerName: camelCase(data.importerName),
            configurationName: data.meta.name,
            selectedOrgId: data.orgId,
          });
        })
        .finally(() => setIsPopulatingData(false));
    }
  }, [definitionsQuery, configurationId, form]);

  // Return to the list view with the correct URL query params
  const backToList = (): void => {
    setIsVisible(false);
    setTimeout(() => {
      navigate({
        pathname: getFullPath(null, true),
        search: getImporterNameSearch(importerNames),
      });
    }, 400); // wait for fade out animation
  };

  const onImporterChange = (value: string) => {
    navigate({
      search: createSearchParams({ importerName: value }).toString(),
    });
  };

  const handleSave = (promise: Promise<any>): void => {
    setIsLoading(true);

    promise
      .then(() => {
        message.success(
          intl.formatMessage({
            defaultMessage: 'The configuration has been saved.',
          }),
        );
        backToList();
      })
      .catch(() => {
        // do nothing
      })
      .finally(() => setIsLoading(false));
  };

  const onFormSubmit = (): void => {
    if (activeImporter) {
      form.validateFields().then((values) => {
        const {
          importerName,
          configurationName,
          selectedOrgId,
          ...configurationValues
        } = values;

        // Remove any keys relating to files because we can't POST or PATCH
        // files, they have to be uploaded separately.
        if (activeImporter.files) {
          Object.keys(activeImporter.files).forEach((key) => {
            delete configurationValues[key];
          });
        }

        // Remove amy secret values that have not been editted
        Object.entries(activeImporter.fields)
          .filter(([_, field]) => field.secret)
          .forEach(([key, _]) => {
            if (configurationValues[key] === false)
              delete configurationValues[key];
          });

        const saveValues: SetConfigurationRequest = {
          configurationValues: formatObjectKeys(
            configurationValues,
            snakeCase,
          ) as ConfigurationValues,
          meta: {
            name: configurationName,
            description: '',
          },
        };

        if (configurationId) {
          handleSave(
            updateConfiguration({
              configuration: saveValues,
              configurationId,
            }).unwrap(),
          );
        } else {
          saveValues.importerName = snakeCase(importerName);
          saveValues.orgId = selectedOrgId || userOrgId || '';

          if (activeImporter?.associatedProducts.length === 1) {
            [saveValues.productId] = activeImporter.associatedProducts;
          }
          handleSave(createConfiguration(saveValues).unwrap());
        }
      });
      backToList();
    }
  };

  // True if this importer requires one or more configuration files
  const hasFiles =
    activeImporter && Object.keys(activeImporter.files).length > 0;

  const tabItems = [
    {
      label: <FormattedMessage defaultMessage="Fields" />,
      key: 'fields',
      children: fields,
      disabled: false,
    }, // remember to pass the key prop
  ];

  if (hasFiles && configurationId)
    tabItems.push({
      label: <FormattedMessage defaultMessage="Files" />,
      key: 'files',
      children: files,
      disabled: !hasFiles,
    });

  return (
    <Modal
      title={title}
      visible={isVisible}
      data-testid="device-configuration-form"
      footer={[
        <Button
          key="save"
          type="primary"
          shape="round"
          loading={isLoading}
          disabled={!activeImporter || tab === 'files'}
          onClick={onFormSubmit}
          data-testid="button-save"
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>,
        <Button
          key="cancel"
          type="ghost"
          shape="round"
          onClick={backToList}
          disabled={isLoading}
          data-testid="button-cancel"
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Button>,
      ]}
    >
      <Loader
        text={intl.formatMessage({ defaultMessage: 'Loading data...' })}
        visible={isPopulatingData}
      >
        <AntdForm name="basic" form={form} layout="vertical">
          <Item
            label={<FormattedMessage defaultMessage="Connector Type" />}
            name="importerName"
          >
            <Select
              data-testid="select-importer"
              disabled={!!configurationId}
              placeholder={<FormattedMessage defaultMessage="Please select" />}
              onChange={onImporterChange}
            >
              {importerOptions}
            </Select>
          </Item>

          {orgNode && orgNode.category === OrgCategory.PLATFORM && (
            <Item
              label={<FormattedMessage defaultMessage="Organisation" />}
              name="selectedOrgId"
              tooltip={
                <FormattedMessage defaultMessage="The company or organisation that owns this connector instance." />
              }
            >
              <TreeSelect
                style={{ width: '100%' }}
                treeLine={{ showLeafIcon: false }}
                treeData={orgTreeSelect}
                data-testid="tree-select"
                disabled={!!configurationId}
              />
            </Item>
          )}

          <Item
            label={<FormattedMessage defaultMessage="Name" />}
            name="configurationName"
            tooltip={
              <FormattedMessage defaultMessage="Choose a name that will help you identify this connector instance later." />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="This field is required" />
                ),
              },
            ]}
          >
            <Input data-testid="configuration-input-name" />
          </Item>

          {!isPopulatingData && activeImporter && (
            <Tabs
              defaultActiveKey="fields"
              size="small"
              onChange={setTab}
              items={tabItems}
            />
          )}
        </AntdForm>
      </Loader>
    </Modal>
  );
};

export default Form;
