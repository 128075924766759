import { FormInstance } from 'antd';
import { isEqual } from 'lodash';
import {
  CreateSpaceNodeBody,
  SpaceNode,
  SpaceNodeCategory,
  UpdateSpaceNodeBody,
} from 'api/space';

export type RoomRow = {
  name: string;
  id: string;
};

export type FloorRow = {
  name: string;
  number?: number;
  id?: string;
  children?: FloorRow[];
  parentId?: string;
};

export const resetInputRows = (
  floors: SpaceNode[],
  setExistingFloors: React.Dispatch<React.SetStateAction<FloorRow[]>>,
  form: FormInstance<any>,
  setUnsavedChanges?: React.Dispatch<boolean>,
) => {
  const inputRows = floors
    .map((floor) => ({
      name: floor.meta.name,
      number: floor.systemMeta.floorNumber,
      id: floor.id,
      children: [...(floor.children || [])]
        .map((room) => ({
          name: room.meta.name.trim(),
          id: room.id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    }))
    .sort((a, b) => (b.number || 0) - (a.number || 0));

  setExistingFloors(inputRows);
  form.setFieldsValue({ rows: inputRows });
  if (setUnsavedChanges) setUnsavedChanges(false);
};

export const getRoomChanges = (values: any, originals: FloorRow[]) => {
  const added: FloorRow[] = [];
  const removed: FloorRow[] = [];
  const modified: FloorRow[] = [];

  values.rows.forEach((floorRow: FloorRow) => {
    const original = originals.find((o) => o.id === floorRow.id);

    floorRow.children?.forEach((child: FloorRow) => {
      if (child.id === undefined) {
        added.push({ ...child, parentId: floorRow.id });
      } else if (original !== undefined) {
        const originalChild = original.children?.find((c) => c.id === child.id);

        if (originalChild !== undefined && !isEqual(child, originalChild)) {
          modified.push(child);
        }
      }
    });

    original?.children?.forEach((originalChild) => {
      const child = floorRow.children?.find((c) => c.id === originalChild.id);

      if (child === undefined) {
        removed.push(originalChild);
      }
    });
  });

  return { added, removed, modified };
};
