import styles from 'styles/global.module.scss';

const removeElements = (elements: NodeListOf<Element>): void => {
  elements.forEach((elem) => elem.remove());
};

const canvasToImages = (container: HTMLElement): void => {
  const canvas = container.querySelectorAll(
    'canvas',
  ) as NodeListOf<HTMLCanvasElement>;
  const count = canvas.length;
  const properties = ['width', 'height', 'position', 'left', 'top'] as const;

  for (let i = 0; i < count; i += 1) {
    const elem = canvas[i];
    const base64 = elem.toDataURL('image/png');
    const img = document.createElement('img');

    img.src = base64;
    img.className = elem.className;
    img.dataset.pdfPrint = '1';

    properties.forEach((key) => {
      img.style[key] = elem.style[key];
    });

    elem.parentNode?.insertBefore(img, elem);
  }
};

const removeNonContainerNodes = (doc: Document, selector: string) => {
  const body = doc.querySelector('body');
  if (!body) throw new Error('Cannot find document body');

  const container = doc.querySelector(selector);
  if (!container)
    throw new Error(`Unable to find element by selector: ${selector}`);

  const containerCopy = container.cloneNode(true);

  while (body.firstChild) body.removeChild(body.firstChild);
  body.appendChild(containerCopy);
};

export const setupHTML = async (
  document: Document,
  uuid: string,
): Promise<string> =>
  new Promise((resolve, reject) => {
    window.requestAnimationFrame(() => {
      try {
        const providerSelector = `[data-uuid="${uuid}"]`;
        canvasToImages(document.querySelector(providerSelector) as HTMLElement);

        const parser = new DOMParser();
        const documentToPrint = parser.parseFromString(
          document.documentElement.innerHTML,
          'text/html',
        );

        const providerElement = documentToPrint.querySelector(
          providerSelector,
        ) as HTMLElement;
        if (!providerElement)
          throw new Error('Unable to select provider element');

        providerElement.style.transform = 'none';

        (
          documentToPrint.querySelector('body') as HTMLBodyElement
        ).style.backgroundColor = styles.backgroundColor;

        removeNonContainerNodes(documentToPrint, providerSelector);
        removeElements(document.querySelectorAll('[data-pdf-print]'));
        removeElements(documentToPrint.querySelectorAll('canvas'));

        resolve(documentToPrint.documentElement.innerHTML);
      } catch (err) {
        reject(err);
      }
    });
  });

export const setupDocumentForPrint = async (
  document: Document,
  uuid: string,
): Promise<boolean> => {
  try {
    const providerSelector = `[data-uuid="${uuid}"]`;
    const documentToPrint = document;

    const providerElement = documentToPrint.querySelector(
      providerSelector,
    ) as HTMLElement;
    if (!providerElement) throw new Error('Unable to select provider element');

    providerElement.style.transform = 'none';
    (
      documentToPrint.querySelector('body') as HTMLBodyElement
    ).style.backgroundColor = styles.backgroundColor;

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
