import { Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineZoomIn } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';
import { MapContext, ZOOM_DELTA } from '../../LeafletMap';

const ZoomInButton: React.FC = () => {
  const intl = useIntl();
  const map = useMap();
  const mapContext = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  const updateZoom = () => {
    if (!map) return;
    setIsDisabled(map.getZoom() + ZOOM_DELTA <= map.getMaxZoom());
  };

  useEffect(() => {
    if (!map) return () => undefined;

    map.on('zoomend', updateZoom);
    return () => {
      map.off('zoomend', updateZoom);
    };
  }, [map]);

  useEffect(() => {
    if (!mapContext.maxZoom) return;
    updateZoom();
  }, [mapContext.maxZoom]);

  const handleZoomIn = () => {
    if (!map) return;
    map.zoomIn(ZOOM_DELTA);
  };

  return (
    <Button
      data-testid="zoom-in-button"
      disabled={!isDisabled}
      onClick={handleZoomIn}
      aria-label={intl.formatMessage({
        defaultMessage: 'Zoom in',
      })}
      alt-text={intl.formatMessage({
        defaultMessage: 'Button to zoom in on map',
      })}
    >
      <AiOutlineZoomIn />
    </Button>
  );
};

export default ZoomInButton;
