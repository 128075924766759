import { Empty, Layout, message } from 'antd';
import classNames from 'classnames';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  getReportTypeToColorMap,
  useDeleteReportConfigurationMutation,
  useGetReportConfigurationsQuery,
} from 'api/report';
import { getLocationName, useGetSpaceNodeListQuery } from 'api/space';
import Table from 'components/ui/molecules/Table';

import globalStyles from 'styles/global.module.scss';
import { confirmPopup, getTableSkeleton } from 'utilities/utilities';
import { Routes } from 'views/Insights/components/Routing/Routes';

import AddConfigurationBtn from './components/AddConfigurationBtn';
import Filter from './components/Filter';
import Header from './components/Header';
import { columns, ReportConfigRow } from './config';
import styles from './styles.module.scss';

import {
  filterByFrequency,
  filterByReportType,
  filterBySearchTerm,
  FilterDataOptions,
} from './utilities';

type FilterData = {
  [FilterDataOptions.searchTerm]: string;
  [FilterDataOptions.frequency]: string | null;
  [FilterDataOptions.reportType]: string;
};

type Props = {
  orgId?: string;
};

const ReportConfigurationList: React.FC<Props> = ({ orgId }) => {
  const [filterOptions, setFilterOptions] = useState<FilterData>({
    [FilterDataOptions.searchTerm]: '',
    [FilterDataOptions.frequency]: null,
    [FilterDataOptions.reportType]: '',
  });
  const [filteredReportConfigs, setFilteredReportConfigs] = useState<
    ReportConfigRow[]
  >([]);
  const [reportConfigs, setReportConfigs] = useState<ReportConfigRow[]>(
    getTableSkeleton(
      ['color', 'name', 'reportType', 'description', 'lastRan', 'frequency'],
      3,
    ),
  );

  const {
    data: spaceNodes,
    isFetching: isSpaceNodesFetching,
    isError: isSpaceNodesError,
  } = useGetSpaceNodeListQuery({ orgId }, { skip: !orgId });

  const {
    data: reportConfiguration,
    isFetching: isReportConfigurationFetching,
    isError: isReportConfigurationError,
  } = useGetReportConfigurationsQuery(orgId, {
    skip: !spaceNodes || !orgId,
  });
  const [deleteReportConfiguration] = useDeleteReportConfigurationMutation();

  const navigate = useNavigate();
  const intl = useIntl();
  const isLoading = isSpaceNodesFetching || isReportConfigurationFetching;
  const isError = isSpaceNodesError || isReportConfigurationError;

  useEffect(() => {
    setFilteredReportConfigs([]);
    setReportConfigs([]);
  }, [isError]);

  // update table when state and/or data changes
  useEffect(() => {
    if (!spaceNodes || !reportConfiguration) return;

    if (isLoading) {
      setFilteredReportConfigs(
        getTableSkeleton(
          [
            'color',
            'name',
            'reportType',
            'description',
            'lastRan',
            'frequency',
          ],
          3,
        ),
      );
      return;
    }

    const data = isArray(reportConfiguration)
      ? reportConfiguration
      : [reportConfiguration];

    setReportConfigs(
      data.map((config) => ({
        id: config.id,
        key: config.id,
        name: config.name,
        description: config.description,
        lastRan: config.lastRan?.toLocaleString(),
        frequency: config.values.period,
        reportType: config.reportType,
        color: getReportTypeToColorMap(config.reportType),
        locationName: getLocationName(config.values.locationId, spaceNodes),
        locationId: config.values.locationId,
      })),
    );
  }, [isLoading, reportConfiguration, spaceNodes]);

  useEffect(() => {
    if (reportConfigs.length === 0) {
      setFilteredReportConfigs([]);
      return;
    }

    setFilteredReportConfigs(
      reportConfigs
        .filter(filterBySearchTerm(filterOptions[FilterDataOptions.searchTerm]))
        .filter(filterByFrequency(filterOptions[FilterDataOptions.frequency]))
        .filter(
          filterByReportType(filterOptions[FilterDataOptions.reportType]),
        ),
    );
  }, [reportConfigs, filterOptions]);

  const onClickDownload = (reportConfigurationId: string) => {
    navigate(
      Routes.DOWNLOAD.replace(':reportConfigurationId', reportConfigurationId),
    );
  };

  const onClickEdit = (reportConfigurationId: string) => {
    navigate(
      Routes.EDIT.replace(':reportConfigurationId', reportConfigurationId),
    );
  };

  const onClickDelete = (reportConfigurationId: string) => {
    confirmPopup(
      intl.formatMessage({
        defaultMessage: 'Are you sure you want to delete this report config?',
      }),
      () => {
        deleteReportConfiguration(reportConfigurationId)
          .unwrap()
          .then(() => {
            message.success(
              intl.formatMessage({
                defaultMessage: 'The report configuration has been deleted.',
              }),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
      intl,
    );
  };

  const onFilterOptionsChange = (
    key: FilterDataOptions,
    value: string | null,
  ) =>
    setFilterOptions({
      ...filterOptions,
      [key]: value,
    });

  return (
    <Layout
      data-testid="view-insights-list"
      className={classNames(
        globalStyles.fullPageLayout,
        styles.reportConfigList,
      )}
    >
      <Header onSearch={onFilterOptionsChange} />
      <Filter
        frequencyFilterValue={filterOptions[FilterDataOptions.frequency]}
        onFilterOptionsChange={onFilterOptionsChange}
      />
      <Table<ReportConfigRow>
        data={filteredReportConfigs}
        autoScroll={true}
        rowClassName={globalStyles.tableRow}
        columns={columns(onClickDownload, onClickEdit, onClickDelete)}
        emptyText={
          <Empty
            description={
              <FormattedMessage defaultMessage="No report configurations have been found." />
            }
            className={classNames(styles.empty, globalStyles.center)}
          >
            <AddConfigurationBtn />
          </Empty>
        }
      />
      <Outlet />
    </Layout>
  );
};

export default ReportConfigurationList;
