import React from 'react';

import Form from './components/Form';
import Success from './components/Success';

const DeviceCandidateModal: React.FC = () => {
  const [formVisible, setFormVisible] = React.useState(true);

  return (
    <>
      {formVisible ? (
        <Form handleAfterSubmit={() => setFormVisible(false)} />
      ) : (
        <Success />
      )}
    </>
  );
};

export default DeviceCandidateModal;
