import { camelCase } from 'lodash';
import { useState, useEffect } from 'react';
import {
  useGetTelemetryPropertyListQuery,
  scoreIndexToColor,
} from 'api/telemetryProperty';
import StackedBarChart, {
  Props as StackedBarChartProps,
} from 'components/ui/atoms/StackedBarChart';
import Card from 'components/ui/molecules/Card';

import styles from './styles.module.scss';

export type DatasetCategoryEntry = {
  healthScore: number;
  label: string;
  value: number;
};

export type Dataset = {
  [categoryKey: string]: DatasetCategoryEntry[];
};

type Props = {
  data: Dataset;
  columns: number;
  showNoDataPlaceholder?: boolean;
  onContentLoaded?: () => void;
};

type StackedBarChartPropsWithKey = StackedBarChartProps & {
  telemetryPropertyKey: string;
};

const StackedBarChartCollection: React.FC<Props> = ({
  data = [],
  columns = 2,
  showNoDataPlaceholder = false,
  onContentLoaded = undefined,
}) => {
  const [parsedData, setPersedData] = useState<StackedBarChartPropsWithKey[]>(
    [],
  );
  const telemetryPropertyListQuery = useGetTelemetryPropertyListQuery({});

  useEffect(() => {
    if (!telemetryPropertyListQuery.isSuccess) return;

    const promises: Promise<void>[] = [];

    const tmpData = Object.entries(data)
      .map(([telemetryPropertyKey, chartData]) => {
        const telemetryProperty = telemetryPropertyListQuery.data.find(
          ({ category }) => camelCase(category) === telemetryPropertyKey,
        );

        let resolveAnimationComplete: (value: void | PromiseLike<void>) => void;
        const promise = new Promise<void>((resolve) => {
          resolveAnimationComplete = resolve;
        });
        promises.push(promise);

        return {
          title: telemetryProperty?.meta?.name || '',
          unit: telemetryProperty?.unit || '',
          telemetryPropertyKey,
          onRenderComplete: () => resolveAnimationComplete(),
          dataSets: chartData.map(({ label, value, healthScore }) => ({
            label,
            percentageValue: value,
            color: scoreIndexToColor(healthScore),
          })),
        };
      })
      .filter(Boolean);

    setPersedData(tmpData.length > 0 ? tmpData : []);

    Promise.all(promises).then(() => onContentLoaded?.());
  }, [data, telemetryPropertyListQuery.isSuccess]);

  return (
    <div
      className={styles.container}
      style={{ '--columns': columns.toString() } as React.CSSProperties}
    >
      {parsedData.map(({ telemetryPropertyKey, ...chartProps }) => (
        <div className={styles.columnCardWrapper} key={telemetryPropertyKey}>
          <Card
            testId={`stacked-bar-chart-${telemetryPropertyKey}`}
            className={styles.card}
          >
            <StackedBarChart
              showNoDataPlaceholder={showNoDataPlaceholder}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...chartProps}
            />
          </Card>
        </div>
      ))}
    </div>
  );
};

export default StackedBarChartCollection;
