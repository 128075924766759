import { connect } from 'react-redux';
import {
  setSelectedBuildingId,
  LocationSelectorTray,
  setUnsavedChanges,
  setLocationTrayIsSaving,
} from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  space: {
    locationTray: { unsavedChanges },
  },
}: RootState) => ({
  unsavedChanges,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedBuildingId: (
    locationSelectorTray: LocationSelectorTray,
    spaceId: string,
  ) => {
    dispatch(
      setSelectedBuildingId({
        type: locationSelectorTray,
        payloadValue: spaceId,
      }),
    );
  },
  setUnsavedChanges: (isUnsavedChanges: boolean) =>
    dispatch(setUnsavedChanges(isUnsavedChanges)),
  setLocationTrayIsSaving: (isSaving: boolean) =>
    dispatch(setLocationTrayIsSaving(isSaving)),
});

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
