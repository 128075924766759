import { RcFile } from 'antd/lib/upload';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export const getThumbnailFromFile = async (file: RcFile): Promise<string> => {
  if (!file.type.includes('image')) return Promise.resolve('');

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result as string);
  });
};

export const getFileDetailsLabel = (
  fileSize: number,
  uploadProgress: number,
): ReactElement => {
  const bytesToMB = (bytes: number) => (bytes / 1048576).toFixed(2);
  if (uploadProgress && uploadProgress < 100) {
    const uploadedFileSize = fileSize * (uploadProgress / 100);
    return (
      <FormattedMessage
        defaultMessage="{current}MB of {total}MB"
        values={{
          current: bytesToMB(uploadedFileSize),
          total: bytesToMB(fileSize),
        }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="{fileSize}MB"
      values={{ fileSize: bytesToMB(fileSize) }}
    />
  );
};

export const validateFile = (
  file: RcFile,
  fileSizeLimitInMB: number,
  allowedFileTypes: string[],
  allowedFileTypesLabel: ReactElement,
): ReactElement | undefined => {
  if (file.size > fileSizeLimitInMB * 1024 * 1024)
    return (
      <FormattedMessage
        defaultMessage="File size too large. Files must be up to {fileSizeLimitInMB}MB"
        values={{ fileSizeLimitInMB }}
      />
    );

  if (!allowedFileTypes.includes(file.type))
    return (
      <FormattedMessage
        defaultMessage="Wrong file type. Upload a {fileTypes}"
        values={{ fileTypes: allowedFileTypesLabel }}
      />
    );

  return undefined;
};
