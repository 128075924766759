import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { baseApi } from 'api/api';
import { Meta } from 'api/meta';
import { OrgTag } from './tags';

export enum Endpoint {
  GetOrgList = '/org',
  GetOrgTree = '/org/tree',
  CreateOrg = '/org',
  UpdateOrg = '/org/:orgId',
}

export enum OrgCategory {
  PLATFORM = 'platform',
  RESELLER = 'reseller',
  COMPANY = 'company',
  REGION = 'region',
  DIVISION = 'division',
  DEPARTMENT = 'department',
}

export enum OrgLifecycle {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export type Org = {
  id: string;
  parentId?: string;
  path: string;
  category: OrgCategory;
  lifecycle: OrgLifecycle;
  buildingIds: string[];
  meta: Meta;
  children?: Org[];
};

export type OrgNodes = Record<string, Org>;

export type GetOrgTreeResponse = Promise<Org[]>;
export type GetOrgResponse = Promise<Org>;
export type GetOrgListResponse = Promise<Org[]>;
export type SetOrgRequest = {
  parentId?: string;
  category?: OrgCategory;
  meta: {
    name: string;
    description: string;
  };
};

export const orgLifecycleToLabel: Record<OrgLifecycle, ReactElement> = {
  [OrgLifecycle.ACTIVE]: <FormattedMessage defaultMessage="Active" />,
  [OrgLifecycle.DELETED]: <FormattedMessage defaultMessage="Deleted" />,
};

export const orgCategoryToLabel: Record<OrgCategory, ReactElement> = {
  [OrgCategory.COMPANY]: <FormattedMessage defaultMessage="Company" />,
  [OrgCategory.DEPARTMENT]: <FormattedMessage defaultMessage="Department" />,
  [OrgCategory.DIVISION]: <FormattedMessage defaultMessage="Division" />,
  [OrgCategory.PLATFORM]: <FormattedMessage defaultMessage="Platform" />,
  [OrgCategory.REGION]: <FormattedMessage defaultMessage="Region" />,
  [OrgCategory.RESELLER]: <FormattedMessage defaultMessage="Reseller" />,
};

export type OrgCategoryToLabelKey = keyof typeof orgCategoryToLabel;

type OrgTree = { [orgId: string]: Org };

export const orgApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getOrgTree(orgId)
    getOrgTree: build.query<OrgTree, string | void>({
      query: (orgId?: string) => ({
        url: Endpoint.GetOrgTree,
        options: { method: 'GET', params: { orgId } },
      }),
      transformResponse: (response: Org[]): OrgTree => {
        const result: OrgTree = {};
        const mutableResponse = [...response];

        const idMapping: { [orgId: string]: number } = mutableResponse.reduce(
          (prev, current, i) => {
            return { ...prev, [current.id]: i };
          },
          {},
        );

        mutableResponse.forEach((orgNode) => {
          result[orgNode.id] = orgNode;

          if (orgNode.parentId === undefined) {
            return;
          }

          const parent = mutableResponse[idMapping[orgNode.parentId]];

          if (parent !== undefined) {
            parent.children = [...(parent.children || []), orgNode];
          }
        });

        return result;
      },
      providesTags: [OrgTag.OrgList],
    }),
    // * getOrgList()
    getOrgList: build.query<Awaited<GetOrgListResponse>, void>({
      query: () => ({
        url: Endpoint.GetOrgList,
        options: { method: 'GET' },
      }),
      providesTags: [OrgTag.OrgList],
    }),
    // * getOrg(orgId)
    getOrg: build.query<Org | undefined, string>({
      query: (orgId: string) => ({
        url: Endpoint.GetOrgList,
        options: { method: 'GET' },
      }),
      providesTags: [OrgTag.OrgList],
      transformResponse: (response: Org[], _, orgId) => {
        return response.find(({ id }) => id === orgId);
      },
    }),
    // * createOrg(org)
    createOrg: build.mutation<Awaited<GetOrgResponse>, SetOrgRequest>({
      query: (org: SetOrgRequest) => ({
        url: Endpoint.CreateOrg,
        options: { method: 'POST', data: org },
      }),
      invalidatesTags: [OrgTag.OrgList],
    }),
    // * updateOrg(orgId, org)
    updateOrg: build.mutation<
      Awaited<GetOrgResponse>,
      { org: SetOrgRequest; orgId: string }
    >({
      query: ({ org, orgId }: { org: SetOrgRequest; orgId: string }) => ({
        url: Endpoint.UpdateOrg.replace(':orgId', orgId),
        options: { method: 'PATCH', data: org },
      }),
      invalidatesTags: [OrgTag.OrgList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrgTreeQuery,
  useGetOrgListQuery,
  useGetOrgQuery,
  useCreateOrgMutation,
  useUpdateOrgMutation,
} = orgApi;
