import { configureStore } from '@reduxjs/toolkit';
import { SpaceNodeCategory } from 'api/space';
import { auth } from 'ducks/auth/slice';
import { space } from 'ducks/space/slice';

export const TEST_ORG_ID = 'org-1';

export const TEST_BUILDING_NAME = 'Test Building';
export const TEST_BUILDING = {
  id: 'spc-1',
  parentId: null,
  orgId: TEST_ORG_ID,
  path: '1',
  category: SpaceNodeCategory.BUILDING,
  lifecycle: 'active',
  latitude: 1.300732,
  longitude: 50.055021,
  meta: {
    name: TEST_BUILDING_NAME,
    description: 'test',
  },
  systemMeta: {
    streetAddress: '2 Test Street, Milton Keynes, UK',
  },
};

export const TEST_BUILDING_NAME_2 = 'Test Building 2';
export const TEST_BUILDING_2 = {
  id: 'spc-100',
  parentId: null,
  orgId: TEST_ORG_ID,
  path: '100',
  category: SpaceNodeCategory.BUILDING,
  lifecycle: 'active',
  latitude: 1.300732,
  longitude: 50.055021,
  meta: {
    name: TEST_BUILDING_NAME_2,
    description: '',
  },
  systemMeta: {
    streetAddress: '100 Test Street, Milton Keynes, UK',
  },
};

export const TEST_FLOOR_NAMES = ['Ground Floor', '1st Floor', '2nd Floor'];
export const TEST_FLOORS = TEST_FLOOR_NAMES.map((name, index) => ({
  id: `spc-${index + 2}`,
  parentId: 'spc-1',
  orgId: TEST_ORG_ID,
  path: `1.${index + 2}`,
  category: SpaceNodeCategory.FLOOR,
  lifecycle: 'active',
  latitude: null,
  longitude: null,
  meta: {
    name,
    description: '',
  },
  systemMeta: {
    floorNumber: index,
    floorplan: 'f1.png',
  },
}));

export const TEST_ROOM_NAMES = ['Boardroom', 'Open Plan', 'Small Meeting Room'];
export const TEST_ROOMS = TEST_ROOM_NAMES.map((name, index) => ({
  id: `spc-${index + TEST_FLOORS.length + 2}`,
  parentId: 'spc-2',
  orgId: TEST_ORG_ID,
  path: `1.2.${index + TEST_FLOORS.length + 2}`,
  category: SpaceNodeCategory.ROOM,
  lifecycle: 'active',
  latitude: null,
  longitude: null,
  meta: {
    name,
    description: '',
  },
  systemMeta: {},
}));

export const TEST_ROOM = {
  id: `spc-${TEST_FLOORS.length + 2}`,
  parentId: 'spc-2',
  orgId: 'org-1',
  path: `1.2.${TEST_FLOORS.length + 2}`,
  category: SpaceNodeCategory.ROOM,
  lifecycle: 'active',
  latitude: null,
  longitude: null,
  meta: {
    name: 'Boardroom',
    description: '',
  },
  systemMeta: {},
};

export const TEST_NONSEQ_FLOOR_NAMES = ['Ground Floor', 'Top Floor'];
export const TEST_NONSEQ_FLOORS = TEST_NONSEQ_FLOOR_NAMES.map(
  (name, index) => ({
    id: `spc-${index + 2}`,
    parentId: 'spc-1',
    orgId: TEST_ORG_ID,
    path: `1.${index + 2}`,
    category: SpaceNodeCategory.FLOOR,
    lifecycle: 'active',
    latitude: null,
    longitude: null,
    meta: {
      name,
      description: '',
    },
    systemMeta: {
      floorNumber: index === 0 ? 0 : 5,
    },
  }),
);

export const getStore = (
  selectedBuildingId: string,
  selectedFloorId: string,
  activeLocationId: string,
  orgId: string = TEST_ORG_ID,
  isLocationTrayOpened = true,
): any =>
  configureStore({
    reducer: { auth, space },
    preloadedState: {
      auth: {
        currentUser: {
          name: 'UserName',
          userId: 'auth',
          identityProviders: ['auth0'],
          email: 'email@email.com',
          orgId,
          orgTree: '1',
          createdAt: '2020-01-01T17:34:21.350000+00:00',
          synced: true,
          roles: ['admin'],
        },
      },
      space: {
        floorplanUploadProgress: 0,
        locationTray: {
          mode: 'view',
          showGhostFloors: true,
          floorsView: 'perspective',
          isSaving: false,
          unsavedChanges: false,
          selectorsSettings: {
            main: {
              open: isLocationTrayOpened,
              selectedBuildingId,
              selectedFloorId,
            },
            temporary: {
              open: false,
              selectedBuildingId: '',
              selectedFloorId: '',
            },
          },
        },
        activeLocationId,
      },
    },
  });

export const TEST_BUILDING_COLLECTION = {
  'spc-1': {
    id: 'spc-1',
    parentId: null,
    orgId: TEST_ORG_ID,
    path: '1',
    category: SpaceNodeCategory.BUILDING,
    lifecycle: 'active',
    latitude: 1.300732,
    longitude: 50.055021,
    meta: {
      name: 'Test Building 1',
      description: '',
    },
    systemMeta: {
      streetAddress: 'Test address 1',
    },
  },
  'spc-2': {
    id: 'spc-2',
    parentId: null,
    orgId: TEST_ORG_ID,
    path: '2',
    category: SpaceNodeCategory.BUILDING,
    lifecycle: 'active',
    latitude: 23.300732,
    longitude: 10.055021,
    meta: {
      name: 'Test Building 2',
      description: '',
    },
    systemMeta: {
      streetAddress: 'Test address 2',
    },
  },
  'spc-3': {
    id: 'spc-3',
    parentId: null,
    orgId: TEST_ORG_ID,
    path: '3',
    category: SpaceNodeCategory.BUILDING,
    lifecycle: 'active',
    latitude: 1.300732,
    longitude: 50.055021,
    meta: {
      name: 'Test Building 3',
      description: '',
    },
    systemMeta: {
      streetAddress: 'Test address 3',
    },
  },
};

export const spaceNodes = {
  [TEST_BUILDING.id]: TEST_BUILDING,
  [TEST_BUILDING_2.id]: TEST_BUILDING_2,
  [TEST_FLOORS[0].id]: TEST_FLOORS[0],
  [TEST_FLOORS[1].id]: TEST_FLOORS[1],
  [TEST_ROOMS[0].id]: TEST_ROOMS[0],
  [TEST_ROOMS[1].id]: TEST_ROOMS[1],
};

export const spaceList = [
  TEST_BUILDING_COLLECTION['spc-1'],
  {
    ...TEST_BUILDING_COLLECTION['spc-2'],
    parent_id: 'spc-1',
    path: '1.2',
    category: 'floor',
    system_meta: { floor_number: 1 },
    meta: {
      name: 'f1',
      description: '',
      created: '2022-02-04T19:18:32.816426+00:00',
      modified: '2022-02-04T19:18:32.826498+00:00',
    },
  },
];
