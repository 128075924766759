import { FormattedMessage } from 'react-intl';

export type FloorViews = 'perspective' | 'angled';

// Menu for adjusting non-essential UI settings
export const getSettingsMenu = (floorsView: FloorViews): Array<any> => [
  {
    key: 'change_view',
    label:
      floorsView === 'angled' ? (
        <FormattedMessage defaultMessage="Switch to profile view" />
      ) : (
        <FormattedMessage defaultMessage="Switch to isometric view" />
      ),
  },
  {
    key: 'show_hide_missing_floors',
    label:
      floorsView === 'angled' ? (
        <FormattedMessage defaultMessage="Hide Missing Floors" />
      ) : (
        <FormattedMessage defaultMessage="Show Missing Floors" />
      ),
  },
];
