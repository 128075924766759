import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { SpaceNode, SpaceNodeCategory, SpaceNodeCollection } from './space';

export const prepareData = (data: SpaceNode[]): Array<any> => {
  const results: Array<any> = [];
  data.forEach(({ id, parentId, meta, category, children }) => {
    const hasChildren = typeof children !== 'undefined';

    results.push({
      id,
      pId: parentId,
      category,
      value: id,
      title: meta.name,
      isLeaf:
        (category !== SpaceNodeCategory.BUILDING && !hasChildren) ||
        (hasChildren && isEmpty(children)),
    });

    if (hasChildren)
      prepareData(children).forEach((item) => results.push(item));
  });

  return results;
};

export const getLocationName = (
  spaceId: string,
  spaceNodes: SpaceNodeCollection,
): string => {
  if (typeof spaceNodes[spaceId] === 'undefined') return '';

  let locationName = '';
  spaceNodes[spaceId].path.split('.').forEach((spaceNodeId, idx) => {
    const space = spaceNodes[`spc-${spaceNodeId}`];
    if (typeof space !== 'undefined')
      locationName += idx === 0 ? space.meta.name : `, ${space.meta.name}`;
  });

  return locationName;
};

export const LocationToLabelMap: Record<SpaceNodeCategory, React.ReactChild> = {
  [SpaceNodeCategory.BUILDING]: (
    <FormattedMessage defaultMessage="Select building" />
  ),
  [SpaceNodeCategory.FLOOR]: <FormattedMessage defaultMessage="Select floor" />,
  [SpaceNodeCategory.ROOM]: <FormattedMessage defaultMessage="Select room" />,
  [SpaceNodeCategory.ZONE]: <FormattedMessage defaultMessage="Select zone" />,
};
