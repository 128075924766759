import React, { useEffect, useState } from 'react';
import { User } from 'api/user';

type Props = {
  children: React.ReactChild | React.ReactChild[];
  apiKey: string;
  currentUser?: User;
};

const Pendo: React.FC<Props> = ({ children, apiKey, currentUser }) => {
  const [pendoLoaded, setPendoLoaded] = useState(false);

  const onPendoLoad = () => {
    const { initialize } = (window as any).pendo;

    initialize({
      visitor: {
        id: currentUser?.userId,
        email: currentUser?.email,
        full_name: currentUser?.name,
      },
      account: {
        id: currentUser?.orgId,
      },
    });

    setPendoLoaded(true);
  };

  useEffect(() => {
    if (!pendoLoaded && typeof currentUser !== 'undefined') {
      const script = document.createElement('script');
      script.src = `https://cdn.eu.pendo.io/agent/static/${apiKey}/pendo.js`;
      script.async = true;
      script.onload = onPendoLoad;
      document.body.appendChild(script);
    }
  }, [apiKey, currentUser]);

  return <>{children}</>;
};

export default Pendo;
