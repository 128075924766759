import { Form as AntdForm, Input, Button, message } from 'antd';
import { isEmpty } from 'lodash';
import React, { ReactChild, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  OrgCategory,
  SetOrgRequest,
  useCreateOrgMutation,
  useGetOrgQuery,
  useGetOrgTreeQuery,
  useUpdateOrgMutation,
} from 'api/org';
import Loader from 'components/ui/atoms/Loader';
import Modal from 'components/ui/molecules/Modal';

import { getFullPath } from 'views/Settings/views/Org/components/Routing';

const { useForm, Item } = AntdForm;
const { TextArea } = Input;

type Props = {
  title: string | ReactChild;
  userOrgId?: string;
};

const Form: React.FC<Props> = ({ title, userOrgId }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { orgId } = useParams<'orgId'>();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [createOrg] = useCreateOrgMutation();
  const [updateOrg] = useUpdateOrgMutation();
  const orgNodes = useGetOrgTreeQuery();
  const org = useGetOrgQuery(orgId || '', {
    skip: !orgId || !orgId.length || !orgNodes.isSuccess,
  });

  const backToList = (): void => {
    setIsVisible(false);

    // Return to the list after waiting for the fade out animation
    setTimeout(() => navigate(getFullPath(null, true)), 400);
  };

  useEffect(() => {
    if (
      orgNodes.isFetching ||
      org.isFetching ||
      isEmpty(orgNodes.data) ||
      !org.isSuccess
    )
      return;

    form.setFieldsValue({
      name: org.data.meta.name,
      description: org.data.meta.description,
      category: org.data.category,
      parentId: org.data.parentId,
    });
  }, [orgNodes.isFetching, org.isFetching, orgId, org.data]);

  const handleSaveOrg = (promise: Promise<any>): void => {
    setIsLoading(true);

    promise
      .then(() => {
        message.success(
          intl.formatMessage({
            defaultMessage: 'The organisation has been saved.',
          }),
        );
        backToList();
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(({ name, description }) => {
        const valuesToSave: SetOrgRequest = {
          meta: {
            name,
            description: description || '',
          },
          parentId: userOrgId,
          category: OrgCategory.COMPANY,
        };

        if (orgId)
          handleSaveOrg(updateOrg({ org: valuesToSave, orgId }).unwrap());
        else handleSaveOrg(createOrg(valuesToSave).unwrap());
      })
      .catch(() => {
        // Do nothing
      });
  };

  return (
    <div data-testid="view-org-form">
      <Modal
        title={title}
        visible={isVisible}
        data-testid="org-form"
        footer={[
          <Button
            key="save"
            type="primary"
            shape="round"
            loading={isLoading}
            onClick={handleFormSubmit}
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>,
          <Button
            key="cancel"
            type="ghost"
            shape="round"
            onClick={backToList}
            disabled={isLoading}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>,
        ]}
      >
        <Loader
          text={intl.formatMessage({
            defaultMessage: 'Loading organisation data...',
          })}
          visible={org.isFetching}
        >
          <AntdForm name="basic" form={form} layout="vertical">
            <Item
              label={<FormattedMessage defaultMessage="Name" />}
              name="name"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required" />
                  ),
                },
              ]}
            >
              <Input data-testid="input-name" />
            </Item>

            <Item
              label={<FormattedMessage defaultMessage="Description" />}
              name="description"
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                data-testid="input-description"
              />
            </Item>
          </AntdForm>
        </Loader>
      </Modal>
    </div>
  );
};

export default Form;
