/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { TelemetryRecord } from 'api/dataSource';
import { ProductProperty } from 'api/product';
import styles from './styles.module.scss';
import { telemetryPropertyConfig } from './config';

type Props = { telemetry: TelemetryRecord; property?: ProductProperty };

const TelemetryTile: React.FC<Props> = ({
  telemetry: { value, category },
  property,
}) => {
  let formattedValue = useMemo(
    () =>
      value && typeof value !== 'string'
        ? value.toFixed(telemetryPropertyConfig[category]?.decimals ?? 0)
        : value,
    [value, category],
  );

  const telemetryLabelProps = {
    defaultMessage: property?.name,
    id: property?.propertyKey,
  };

  if (typeof formattedValue === 'undefined' || formattedValue === null) {
    formattedValue = '---';
  }

  return (
    <div className={styles.tile} data-testid="telemetry-tile">
      <p>{`${formattedValue} ${property?.telemetryProperty?.unit}`}</p>
      <p className={styles.tileCategory}>
        {property?.propertyKey && <FormattedMessage {...telemetryLabelProps} />}
      </p>
    </div>
  );
};

export default TelemetryTile;
