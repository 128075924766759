import { FormInstance, message } from 'antd';

import { isEqual } from 'lodash';
import { SpaceNode } from 'api/space';

import { getIntl } from 'components/Intl';
import { RoomRow } from '../BuildingRoomsForm/utils';

export type FloorRow = {
  name: string;
  number?: number;
  id?: string;
  children?: RoomRow[];
};

export const resetInputRows = (
  floors: SpaceNode[],
  setExistingFloors: React.Dispatch<React.SetStateAction<FloorRow[]>>,
  form: FormInstance<any>,
  setUnsavedChanges?: React.Dispatch<boolean>,
): void => {
  const inputRows = floors
    .map((floor) => ({
      name: floor.meta.name,
      number: floor.systemMeta.floorNumber,
      id: floor.id,
    }))
    .sort((a, b) => (b.number || 0) - (a.number || 0));

  setExistingFloors(inputRows);
  form.setFieldsValue({ rows: inputRows });

  if (setUnsavedChanges) setUnsavedChanges(true);
};

export const getFloorChanges = (rows: FloorRow[], originals: FloorRow[]) => {
  const added: FloorRow[] = [];
  const removed: FloorRow[] = [];
  const modified: FloorRow[] = [];

  rows.forEach((row) => {
    if (row.id === undefined) {
      added.push(row);
    } else {
      originals.forEach((original) => {
        if (row.id === original.id && !isEqual(row, original)) {
          modified.push(row);
        }
      });
    }
  });

  originals.forEach((original) => {
    const found = rows.find((row) => row.id === original.id);

    if (!found) {
      removed.push(original);
    }
  });

  return { added, removed, modified };
};

export const uniqueFloorNumber = ({ getFieldsValue }: any, index: number) => ({
  validator: () => {
    const { rows } = getFieldsValue();
    const row = rows[index];

    if (row === undefined) {
      return Promise.resolve();
    }

    const nos: number[] = rows.map((r: FloorRow) => r.number);
    const unique = nos.filter((n) => n === row.number).length === 1;

    return unique
      ? Promise.resolve()
      : Promise.reject(new Error('Floor numbers must be unique.'));
  },
});

export const validateFloor = (
  newFloorName: string,
  newFloorNumber: number | undefined,
  rows: FloorRow[],
): boolean => {
  const intl = getIntl();
  if (['undefined', ''].includes(`${newFloorNumber}`)) {
    message.error(
      intl.formatMessage({
        defaultMessage: 'Please enter a valid floor number',
      }),
    );
    return false;
  }
  if (rows.some(({ number }) => number === newFloorNumber)) {
    message.error(
      intl.formatMessage({
        defaultMessage: 'Floor number already exists',
      }),
    );
    return false;
  }
  if (!newFloorName || newFloorName.length < 1) {
    message.error(
      intl.formatMessage({
        defaultMessage: 'Missing floor name',
      }),
    );
    return false;
  }
  return true;
};
