import cn from 'classnames';
import styles from './styles.module.scss';

export type LegendItem = {
  label: string;
  color: string;
};

type Props = {
  data: LegendItem[];
  className?: string;
};

const ChartLegend: React.FC<Props> = ({ data, className }) => {
  return (
    <div data-testid="chart-legend" className={cn(styles.wrapper, className)}>
      {data.map(({ color, label }) => (
        <p className={styles.item} key={label}>
          <span className={styles.figure} style={{ backgroundColor: color }} />
          <span className={styles.label}>{label}</span>
        </p>
      ))}
    </div>
  );
};

export default ChartLegend;
