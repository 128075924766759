import { ReactChild } from 'react';
import { Tag, Table, Space, Tooltip, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';

import { TableRow } from 'components/ui/molecules/Table';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { TiDeleteOutline } from 'react-icons/ti';

export type PropertyRow = {
  propertyId: string;
  propertyName: string;
  propertyCategory: string;
};

export type DataSourceRow = TableRow & {
  name: string;
  searchName: string;
  externalId: string;
  properties: PropertyRow[];
  isLinked: boolean;
};

export const getColumns = (
  onClickDelete?: (key: string) => void,
): ColumnsType<DataSourceRow> => {
  const columns: ColumnsType<DataSourceRow> = [
    {
      title: <FormattedMessage defaultMessage="Name" />,
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="External ID" />,
      key: 'externalId',
      dataIndex: 'externalId',
      sorter: (a, b) => a.externalId.localeCompare(b.externalId),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Telemetry Properties" />,
      key: 'properties',
      ellipsis: {
        showTitle: false,
      },
      render: (
        value: DataSourceRow,
        { hideActions, properties }: DataSourceRow,
      ): ReactChild => {
        if (hideActions) return value.name;
        const string = properties
          .map(({ propertyName }) => propertyName)
          .join(', ');
        return (
          <Tooltip placement="topLeft" title={string}>
            {string}
          </Tooltip>
        );
      },
    },
    {
      title: <FormattedMessage defaultMessage="Linked" />,
      key: 'linked',
      ellipsis: {
        showTitle: false,
      },
      render: (
        value: DataSourceRow,
        { hideActions, isLinked }: DataSourceRow,
      ): ReactChild => {
        if (hideActions) return value.name;

        return isLinked ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            <FormattedMessage defaultMessage="Yes" />
          </Tag>
        ) : (
          <Tag icon={<CloseCircleOutlined />} color="error">
            <FormattedMessage defaultMessage="No" />
          </Tag>
        );
      },
    },
  ];

  if (typeof onClickDelete === 'function') {
    columns.push({
      title: '',
      key: 'action',
      width: 50,
      render: ({ hideActions, key }: DataSourceRow): null | ReactChild => {
        if (hideActions) return null;
        return (
          <Space size="middle" data-testid="buttons-actions">
            <Tooltip title={<FormattedMessage defaultMessage="De-select" />}>
              <Button
                type="link"
                shape="circle"
                icon={<TiDeleteOutline />}
                onClick={() => onClickDelete(key)}
                data-testid={`delete-button-${key}`}
              />
            </Tooltip>
          </Space>
        );
      },
    });
  } else {
    columns.push(Table.SELECTION_COLUMN);
  }

  return columns;
};
