import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { ReactChild } from 'react';
import { FormattedMessage } from 'react-intl';

import { TableRow } from 'components/ui/molecules/Table';
import { getAttributeKeyTitle } from 'utilities/product';

export type DeviceCandidateListRow = TableRow & {
  lifecycle: string;
  name: string;
  created: string;
  importerName?: string;
  deviceId: string;
  productId: string;
  dataSearch: string[];
};

export const getColumns = (
  productSpecificColumns: string[][] = [],
): ColumnsType<DeviceCandidateListRow> => {
  const dynamicColumnNameToString = (
    obj: DeviceCandidateListRow,
    columnName: keyof DeviceCandidateListRow,
  ): string => (obj[columnName] ? (obj[columnName] as string) : '').toString();

  const columns: ColumnsType<DeviceCandidateListRow> =
    productSpecificColumns.map(([key, columnName]) => ({
      title: getAttributeKeyTitle(key, columnName),
      dataIndex: key,
      key,
      sorter: (a, b) => {
        const columnKey = key as keyof DeviceCandidateListRow;
        return dynamicColumnNameToString(a, columnKey).localeCompare(
          dynamicColumnNameToString(b, columnKey),
        );
      },
      ellipsis: {
        showTitle: false,
      },
    }));

  const formatDate = (
    value: string,
    { hideActions }: DeviceCandidateListRow,
  ): ReactChild => {
    if (hideActions) return value;
    return dayjs(value).format('DD-MM-YYYY');
  };

  columns.push({
    title: <FormattedMessage defaultMessage="First seen" />,
    dataIndex: 'created',
    key: 'created',
    sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
    ellipsis: {
      showTitle: false,
    },
    render: formatDate,
  });

  return columns;
};
