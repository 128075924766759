import { baseApi } from 'api/api';
import { DataSinkTypeTag } from './tags';

export enum Endpoint {
  GetDataSinkTypes = '/data_sink_type',
}

export type DataSinkType = {
  id: string;
  orgId: string | null;
  name: string;
  description: string;
};

export type GetDataSinkTypeListResponse = Promise<DataSinkType[]>;

export const dataSinkTypeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getDataSinkTypeList(typeId)
    getDataSinkTypeList: build.query<DataSinkType[], string | undefined>({
      query: (typeId?: string) => ({
        url: Endpoint.GetDataSinkTypes,
        options: { method: 'GET', params: { typeId } },
      }),
      providesTags: [DataSinkTypeTag.DataSinkTypeList],
    }),
  }),
  overrideExisting: false,
});

export const { useGetDataSinkTypeListQuery } = dataSinkTypeApi;
