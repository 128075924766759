import type { InputRef } from 'antd';
import {
  Button,
  Row,
  Form,
  Input,
  Space,
  InputNumber,
  FormInstance,
  message,
} from 'antd';
import { debounce, isString } from 'lodash';
import { useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  SpaceNode,
  SpaceNodeCategory,
  useCreateSpaceNodesMutation,
  useUpdateSpaceNodeMutation,
} from 'api/space';
import AddressSearch from 'components/ui/atoms/AddressSearch';
import { LocationSelectorTray } from 'ducks/space/slice';

import SaveButton from '../SaveButton/SaveButton';

import sharedStyles from '../sharedStyles.module.scss';
import styles from './styles.module.scss';

const { Item } = Form;
const { TextArea } = Input;

const resetForm = (
  form: FormInstance<any>,
  create: boolean,
  setIsAddressManual: (manual: boolean) => void,
  selectedBuilding?: SpaceNode,
) => {
  if (!create && selectedBuilding) {
    setIsAddressManual(true);
    form.setFieldsValue({
      name: selectedBuilding?.meta.name || '',
      description: selectedBuilding?.meta.description || '',
      latitude: selectedBuilding?.latitude || 0,
      longitude: selectedBuilding?.longitude || 0,
      streetAddress: selectedBuilding?.systemMeta?.streetAddress || '',
    });
  } else {
    setIsAddressManual(false);
    form.setFieldsValue({
      name: '',
      description: '',
      latitude: 0,
      longitude: 0,
      streetAddress: '',
    });
  }
};

const focusFormIfCreating = (
  nameInput: InputRef | null,
  selectedBuilding?: SpaceNode,
) => {
  if (nameInput !== null && selectedBuilding === undefined) {
    nameInput.focus();
  }
};

type Props = {
  locationSelectorTray: LocationSelectorTray;
  selectedBuilding?: SpaceNode;
  floors: SpaceNode[];
  create: boolean;
  unsavedChanges: boolean;
  setSelectedBuildingId: (
    locationSelectorTray: LocationSelectorTray,
    spaceId: string,
  ) => void;
  onFormContinue?: () => void;
  setUnsavedChanges: (isFieldsChanged: boolean) => void;
  setLocationTrayIsSaving: (isSaving: boolean) => void;
};

const BuildingDetailsForm: React.FC<Props> = ({
  locationSelectorTray,
  selectedBuilding,
  floors,
  create,
  setSelectedBuildingId,
  unsavedChanges,
  setUnsavedChanges,
  setLocationTrayIsSaving,
  onFormContinue = () => {
    // do nothing
  },
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isAddressManual, setIsAddressManual] = useState(false);
  const nameInputRef = useRef<InputRef>(null);
  const addressSearchRef = useRef<{
    focus: () => void;
    blur: () => void;
    scrollTo: () => void;
  }>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [createSpaceNodes, createSpaceNodesResult] =
    useCreateSpaceNodesMutation();
  const [updateSpaceNode, updateSpaceNodeResult] = useUpdateSpaceNodeMutation();

  // On mount, focus the first input if a new building is being created
  useEffect(
    () => focusFormIfCreating(nameInputRef.current, selectedBuilding),
    [selectedBuilding],
  );

  // If the selected building changes, reset the form
  useEffect(() => {
    resetForm(form, create, setIsAddressManual, selectedBuilding);
    focusFormIfCreating(nameInputRef.current, selectedBuilding);
  }, [selectedBuilding, form]);

  useEffect(() => {
    if (createSpaceNodesResult.isLoading || updateSpaceNodeResult.isLoading) {
      setLocationTrayIsSaving(true);
      return;
    }

    if (!create) {
      if (updateSpaceNodeResult.isSuccess) {
        message.success(
          intl.formatMessage({ defaultMessage: 'Building updated.' }),
        );
        setUnsavedChanges(false);
      }

      setFormSubmitted(updateSpaceNodeResult.isSuccess);
    } else {
      if (createSpaceNodesResult.isSuccess) {
        setSelectedBuildingId(
          locationSelectorTray,
          createSpaceNodesResult.data[0].id,
        );
        onFormContinue();

        message.success(
          intl.formatMessage({ defaultMessage: 'Building created.' }),
        );
        setUnsavedChanges(false);
      }

      setFormSubmitted(createSpaceNodesResult.isSuccess);
    }

    setLocationTrayIsSaving(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    create,
    createSpaceNodesResult,
    updateSpaceNodeResult,
    locationSelectorTray,
  ]);

  const onAddressChange = (value: any) => {
    form.setFieldsValue({
      latitude: value.position.lat,
      longitude: value.position.lon,
    });
  };

  const focusAddressSearch = debounce(
    () => addressSearchRef.current?.focus(),
    100,
  );

  const toggleAddressManual = () => {
    if (!isAddressManual) {
      form.setFieldsValue({
        streetAddress:
          form.getFieldValue('streetAddress')?.address?.freeformAddress,
      });
    }

    setIsAddressManual(!isAddressManual);

    if (isAddressManual) {
      focusAddressSearch();
    }
  };

  const onSubmit = async (values: Record<string, any>) => {
    if (
      values.streetAddress === undefined ||
      values.streetAddress === '' ||
      values.latitude === undefined ||
      values.longitude === undefined
    ) {
      if (isAddressManual) {
        toggleAddressManual();
      } else {
        addressSearchRef.current?.focus();
      }

      return;
    }

    const streetAddress = isString(values.streetAddress)
      ? values.streetAddress
      : values.streetAddress.address.freeformAddress;

    const data = {
      meta: {
        name: values.name,
        description: values?.description || '',
      },
      latitude: values.latitude,
      longitude: values.longitude,
      streetAddress,
      category: SpaceNodeCategory.BUILDING,
    };

    if (!create && selectedBuilding) {
      updateSpaceNode({ spaceId: selectedBuilding.id, body: data });
    } else {
      createSpaceNodes(data);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onChange={() => {
        setUnsavedChanges(true);
        setFormSubmitted(false);
      }}
      data-testid="create-building-form"
      className={styles.form}
    >
      <div className={sharedStyles.scrollableArea}>
        <Item
          label={<FormattedMessage defaultMessage="Building name" />}
          className={sharedStyles.subHeader}
          name="name"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="Please input a building name" />
              ),
            },
          ]}
        >
          <Input
            ref={nameInputRef}
            data-testid="input-name"
            className={styles.inputHeaders}
          />
        </Item>
        <Item
          className={sharedStyles.subHeader}
          label={<FormattedMessage defaultMessage="Building description" />}
          name="description"
          rules={[
            {
              required: false,
              message: (
                <FormattedMessage defaultMessage="Please input a description" />
              ),
            },
          ]}
        >
          <TextArea
            autoSize={{ minRows: 2, maxRows: 4 }}
            data-testid="input-description"
          />
        </Item>
        <Item
          label={<FormattedMessage defaultMessage="Address" />}
          name="streetAddress"
          className={sharedStyles.subHeader}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="Please enter a valid address" />
              ),
            },
          ]}
        >
          {!isAddressManual ? (
            <AddressSearch
              disabled={isAddressManual}
              onChange={onAddressChange}
              ref={addressSearchRef}
              className={sharedStyles.subHeader}
            />
          ) : (
            <Input data-testid="input-address-manual" />
          )}
        </Item>
        <Item
          className={styles.streetAddress}
          extra={
            <Button
              type="link"
              onClick={toggleAddressManual}
              data-testid="manual-address-toggle"
            >
              {isAddressManual ? (
                <FormattedMessage defaultMessage="Search for an address" />
              ) : (
                <FormattedMessage defaultMessage="Enter location manually" />
              )}
            </Button>
          }
        >
          <Space>
            <Item
              label={<FormattedMessage defaultMessage="Latitude" />}
              name="latitude"
              className={sharedStyles.subHeader}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -90,
                  max: 90,
                  message: (
                    <FormattedMessage defaultMessage="Latitude must be a value between -90 and 90" />
                  ),
                },
              ]}
            >
              <InputNumber
                className={styles.latLngInput}
                disabled={!isAddressManual}
                data-testid="input-latitude"
                min={-90}
                max={90}
              />
            </Item>
            <Item
              label={<FormattedMessage defaultMessage="Longitude" />}
              name="longitude"
              className={sharedStyles.subHeader}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: -180,
                  max: 180,
                  message: (
                    <FormattedMessage defaultMessage="Longitude must be a value between -180 and 180" />
                  ),
                },
              ]}
            >
              <InputNumber
                className={styles.latLngInput}
                disabled={!isAddressManual}
                data-testid="input-longitude"
                min={-180}
                max={180}
              />
            </Item>
          </Space>
        </Item>
      </div>
      <Item className={sharedStyles.formSubmitRow}>
        <Row justify="center">
          <Button
            type="ghost"
            className={sharedStyles.formButton}
            data-testid="discard-button"
            onClick={() => {
              resetForm(form, create, setIsAddressManual, selectedBuilding);
              setUnsavedChanges(false);
            }}
            disabled={!unsavedChanges}
          >
            <FormattedMessage defaultMessage="Discard" />
          </Button>
          <SaveButton isSaved={formSubmitted} isDisabled={!unsavedChanges} />
          {create || !floors.length ? (
            <Button
              type="primary"
              className={sharedStyles.formButton}
              onClick={onFormContinue}
              data-testid="next-button"
              disabled={create || unsavedChanges}
            >
              <FormattedMessage defaultMessage="Next" />
            </Button>
          ) : null}
        </Row>
      </Item>
    </Form>
  );
};

export default BuildingDetailsForm;
