import { Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { MdOutlineGpsFixed } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';
import { MapContext } from '../../LeafletMap';

export const ALLOWED_CENTER_OFFSET = 4;

const CenterButton: React.FC = () => {
  const intl = useIntl();
  const map = useMap();
  const mapContext = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  const toggleButtonDisabled = () => {
    if (!mapContext.center) return;

    const diffLat = Math.abs(mapContext.center.lat - map.getCenter().lat);
    const diffLng = Math.abs(mapContext.center.lng - map.getCenter().lng);

    setIsDisabled(
      diffLat < ALLOWED_CENTER_OFFSET &&
        diffLng < ALLOWED_CENTER_OFFSET &&
        map.getZoom() === mapContext.minZoom,
    );
  };

  useEffect(() => {
    if (!map || !mapContext.center) return () => undefined;

    map.on('moveend', toggleButtonDisabled);
    map.on('zoomend', toggleButtonDisabled);

    return () => {
      map.off('moveend', toggleButtonDisabled);
      map.off('zoomend', toggleButtonDisabled);
    };
  }, [map, mapContext.center]);

  const handleClick = () => {
    if (!map || !mapContext.bounds) return;

    map.fitBounds(mapContext.bounds, { maxZoom: 0 });
    setIsDisabled(true);
  };

  return (
    <Button
      data-testid="center-button"
      onClick={handleClick}
      disabled={isDisabled}
      aria-label={intl.formatMessage({
        defaultMessage: 'Center map',
      })}
      alt-text={intl.formatMessage({
        defaultMessage: 'Button to center the map',
      })}
    >
      <MdOutlineGpsFixed />
    </Button>
  );
};

export default CenterButton;
