import { Layout } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import Helmet from 'components/Helmet';

import Routing from './components/Routing';

const { Content } = Layout;

const Map: React.FC = () => {
  const intl = useIntl();
  return (
    <Layout data-testid="view-map">
      <Content data-testid="map-content">
        <Helmet title={intl.formatMessage({ defaultMessage: 'Map' })} />
        <Routing />
      </Content>
    </Layout>
  );
};

export default Map;
