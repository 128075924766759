import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import Download from 'views/Insights/views/Download';
import ReportConfigurationList from 'views/Insights/views/ReportConfigurationList';
import ReportForm from 'views/Insights/views/ReportForm';

import { Routes } from './Routes';

const Routing: React.FC = () => {
  const intl = useIntl();
  return (
    <ReactRoutes>
      <Route path="*" element={<ReportConfigurationList />}>
        <Route
          path={Routes.ADD}
          element={
            <ReportForm
              title={intl.formatMessage({
                defaultMessage: 'New report configuration',
              })}
            />
          }
        />

        <Route
          path={Routes.EDIT}
          element={
            <ReportForm
              title={intl.formatMessage({
                defaultMessage: 'Edit report configuration',
              })}
            />
          }
        />

        <Route path={Routes.DOWNLOAD} element={<Download />} />
      </Route>
    </ReactRoutes>
  );
};

export default Routing;
