import { Table } from 'antd';
import { TableRow } from 'components/ui/molecules/Table';
import { FormattedMessage } from 'react-intl';

export type DataSinkRow = TableRow & {
  locationId: string;
  name: string;
  searchName: string;
  sources: number;
  location: string;
};

export const columns = [
  {
    title: <FormattedMessage defaultMessage="Sink name" />,
    key: 'name',
    dataIndex: 'name',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: <FormattedMessage defaultMessage="Sources" />,
    key: 'sources',
    dataIndex: 'sources',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: <FormattedMessage defaultMessage="Location" />,
    key: 'location',
    dataIndex: 'location',
    ellipsis: {
      showTitle: false,
    },
  },
  Table.SELECTION_COLUMN,
];
