import Icon from '@ant-design/icons';
import { Col, Layout, Row, Space } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import Routing, { flagToUrlMapping } from 'components/Routing';
import { Routes } from 'components/Routing/Routes';

import Search from 'components/ui/molecules/Search';
import Menu from 'components/ui/organisms/Menu';
import useFlags, { Flag } from 'utilities/hooks/useFlags';
import Notificator from './components/Notificator';
import { menuItems } from './config';

import styles from './styles.module.scss';
import 'styles/antd_overwrites/index.scss';

const { Header, Content } = Layout;

const App: React.FC = () => {
  const { pathname } = window.location;
  const isHeaderVisible = !pathname.includes(Routes.PDF);

  const flags = useFlags([
    Flag.HOME_TAB,
    Flag.MAP_TAB,
    Flag.INSIGHTS_TAB,
    Flag.DEVICES_TAB,
    Flag.SETTINGS_TAB,
    Flag.GLOBAL_SEARCH,
  ]);

  const availableRoutes = flags
    ? Object.entries(flags)
        .filter(([_, flagValue]) => Boolean(flagValue))
        .map(([flagName]) => flagToUrlMapping[flagName as Flag] as Routes)
    : [];

  // not needed as feature flag
  availableRoutes.push(Routes.PDF);

  return (
    <Router>
      <Layout className={styles.layout}>
        {isHeaderVisible && (
          <Header className={styles.header} data-testid="app-header">
            <Row>
              <Col span={5}>
                <Space size="large" role="banner">
                  <Icon
                    component={Logo}
                    className={styles.logo}
                    title="metrikus-icon"
                  />
                  <Notificator />
                </Space>
              </Col>
              <Col flex={1}>
                <Menu
                  items={menuItems.filter(({ url }) =>
                    availableRoutes.includes(url),
                  )}
                />
              </Col>
              <Col span={5}>
                {typeof flags !== 'undefined' && flags[Flag.GLOBAL_SEARCH] && (
                  <Search />
                )}
              </Col>
            </Row>
          </Header>
        )}
        <Content id="app-content" data-testid="app-content">
          <Routing
            availableRoutes={availableRoutes}
            restrictRoutes={
              typeof flags !== 'undefined' && Object.keys(flags).length > 0
            }
          />
        </Content>
      </Layout>
    </Router>
  );
};

export default App;
