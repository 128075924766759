import React from 'react';

import styles from './styles.module.scss';

type Props = {
  size?: string;
  opacity?: number;
  type?: 'plus' | 'minus';
  position?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
};

const Figure: React.FC<Props> = ({
  size = '4vw',
  type = 'plus',
  opacity = 100,
  position = {},
}) => {
  const cssSize = {
    '--size': size,
  } as React.CSSProperties;

  return (
    <figure
      className={styles.figure}
      style={{ ...position, ...cssSize }}
      data-testid="figure"
    >
      <p style={{ opacity: opacity / 100 }} />
      {type === 'plus' && (
        <p className={styles.plus} style={{ opacity: opacity / 100 }} />
      )}
    </figure>
  );
};

export default Figure;
