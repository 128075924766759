import { Button, Typography } from 'antd';
import { FaClipboard } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';

import styles from '../../styles.module.scss';

const { Title } = Typography;

type Props = {
  isVisible: boolean;
  onAction: () => void;
};

const NoBuildingModal: React.FC<Props> = ({ isVisible, onAction }) => {
  return (
    <Modal
      title={
        <>
          <FaClipboard className={styles.ico} />
          <FormattedMessage defaultMessage="Upload floor plan" />
        </>
      }
      backgroundClickable
      visible={isVisible}
      footer={[
        <Button
          key="button"
          data-testid="btn-upload-floor-plan"
          type="primary"
          onClick={onAction}
        >
          <FormattedMessage defaultMessage="Create building" />
        </Button>,
      ]}
    >
      <Title className={styles.text} level={5}>
        <FormattedMessage defaultMessage="You have no buildings, please create a building before uploading floor plans." />
      </Title>
    </Modal>
  );
};

export default NoBuildingModal;
