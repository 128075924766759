import { KeyboardEventHandler } from 'react';
import { SpaceNode, SpaceNodeCollection } from 'api/space';
import { selectFloorsByBuilding, selectSpaceNode } from 'ducks/space/selectors';
import {
  LocationSelectorStateConfig,
  LocationSelectorTray,
} from 'ducks/space/slice';

export enum LocationTrayTab {
  Space = 'Space',
  Create = 'Create',
  Edit = 'Edit',
  Building = 'Building',
}

export type getSpaceNodeFn = (
  spaceNodes: SpaceNodeCollection,
  config: LocationSelectorStateConfig,
) => SpaceNode | undefined;

export const getTrayConfig = (
  configs: {
    [selectorType: string]: LocationSelectorStateConfig;
  },
  locationSelectorTray: LocationSelectorTray,
): LocationSelectorStateConfig => {
  return configs[locationSelectorTray];
};

export const getSelectedFloors = (
  spaceNodes: SpaceNodeCollection,
  config: LocationSelectorStateConfig,
): SpaceNode[] => {
  return selectFloorsByBuilding(spaceNodes, config.selectedBuildingId);
};

export const getSelectedFloor: getSpaceNodeFn = (spaceNodes, config) => {
  return selectSpaceNode(spaceNodes, config.selectedFloorId);
};

export const getSelectedBuilding: getSpaceNodeFn = (spaceNodes, config) => {
  return selectSpaceNode(spaceNodes, config.selectedBuildingId);
};

export const handleEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
  if (event.key.toLowerCase() === 'enter') {
    event.preventDefault();
  }
};
