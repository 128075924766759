import { getIntl } from 'components/Intl';

export const getAttributeKeyTitle = (
  attributeKey: string,
  attributeName: string,
): string => {
  const mapping: Record<string, string> = {
    displayName: getIntl().formatMessage({
      defaultMessage: 'Vendor name',
    }),
    macAddress: getIntl().formatMessage({
      defaultMessage: 'MAC address',
    }),
  };

  return mapping[attributeKey] ?? attributeName;
};
