import { ReactNode } from 'react';
import ControlBar, {
  HorizontalPlacement,
  VerticalPlacement,
} from '../ControlBar';

type Props = {
  topLeft?: ReactNode[];
  topRight?: ReactNode[];
  bottomLeft?: ReactNode[];
  bottomRight?: ReactNode[];
};

const Controls: React.FC<Props> = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
}) => {
  return (
    <>
      {topLeft && (
        <ControlBar
          positionHorizontal={HorizontalPlacement.Left}
          positionVertical={VerticalPlacement.Top}
        >
          {topLeft}
        </ControlBar>
      )}
      {topRight && (
        <ControlBar
          positionHorizontal={HorizontalPlacement.Right}
          positionVertical={VerticalPlacement.Top}
        >
          {topRight}
        </ControlBar>
      )}
      {bottomLeft && (
        <ControlBar
          positionHorizontal={HorizontalPlacement.Left}
          positionVertical={VerticalPlacement.Bottom}
        >
          {bottomLeft}
        </ControlBar>
      )}
      {bottomRight && (
        <ControlBar
          positionHorizontal={HorizontalPlacement.Right}
          positionVertical={VerticalPlacement.Bottom}
        >
          {bottomRight}
        </ControlBar>
      )}
    </>
  );
};

export default Controls;
