import { useEffect, useState } from 'react';
import {
  Report,
  ReportConfig,
  ReportType,
  useGetReportQuery,
} from 'api/report';
import IndoorAirQuality from './components/IndoorAirQuality';

const reportMap: Record<
  string,
  React.ElementType<{
    reportId: string;
    reportConfig?: ReportConfig;
    reportType: ReportType;
  }>
> = {
  [ReportType.IndoorAirQuality]: IndoorAirQuality,
};

type Props = {
  reportType?: ReportType;
  reportId: string;
  reportConfig?: ReportConfig;
};

const ReportTypes: React.FC<Props> = ({
  reportType,
  reportId,
  reportConfig,
}) => {
  const [report, setReport] = useState<Report | undefined>(undefined);
  const reportQuery = useGetReportQuery(reportId, {
    skip: Boolean(reportType),
  });

  useEffect(() => {
    if (!reportQuery.isSuccess || reportQuery.isFetching) return;
    setReport(reportQuery.data);
  }, [reportType, reportQuery]);

  const ReportComponent = reportMap[
    report?.reportType || (reportType as ReportType)
  ] as React.FC<Props>;

  if (!reportType && !report) return <></>;

  return (
    <ReportComponent
      reportId={reportId}
      reportConfig={reportConfig}
      reportType={reportType || (report?.reportType as ReportType)}
    />
  );
};

export default ReportTypes;
