/* eslint-disable camelcase */
import { camelCase } from 'lodash';
import { baseApi } from 'api/api';
import { Meta } from 'api/meta';
import { TelemetryProperty } from 'api/telemetryProperty';
import { ProductTag } from './tags';

export enum Endpoint {
  GetProductList = '/product',
}

export type ProductProperty = {
  name: string;
  description: string;
  propertyKey: string;
  telemetryProperty: TelemetryProperty;
};

export type ProductAttribute = {
  name: string;
  display: boolean;
  attributeKey: string;
};

export type ProductSystemMeta = {
  attributes: ProductAttribute[];
};

export type Product = {
  provider: string;
  model: string;
  id: string;
  properties: ProductProperty[];
  systemMeta: ProductSystemMeta;
  displayAttributes: ProductAttribute[];
  meta: Meta;
};

export type GetProductListResponse = Record<string, Product>;
export type GetProductListParams = string | string[] | void;

export const productApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getProductList(spaceId, orgId, category, includeInactive)
    getProductList: build.query<GetProductListResponse, GetProductListParams>({
      query: (productId) => ({
        url: Endpoint.GetProductList,
        options: {
          method: 'GET',
          params: {
            productId,
          },
        },
      }),
      transformResponse: (result: Product[]) => {
        if (!result) return {};

        return Object.fromEntries(
          result.map((product) => [
            product.id,
            {
              ...product,
              displayAttributes:
                product.displayAttributes?.map((attr) => ({
                  ...attr,
                  attributeKey: camelCase(attr.attributeKey),
                })) || [],
            },
          ]),
        );
      },
      providesTags: [ProductTag.ProductList],
    }),
    // * getProductById(productId)
    getProductById: build.query<Product | undefined, string>({
      query: (productId) => ({
        url: Endpoint.GetProductList,
        options: {
          method: 'GET',
          params: {
            productId,
          },
        },
      }),
      transformResponse: (result: Product[]) => {
        if (!result) return undefined;
        return result[0];
      },
      providesTags: [ProductTag.ProductList],
    }),
  }),
});

export const { useGetProductListQuery, useGetProductByIdQuery } = productApi;
