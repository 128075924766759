import { Form, Input, message, Button } from 'antd';

import React, { ReactChild, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useUpdateDataSourceMutation,
  useGetDataSourceByIdQuery,
} from 'api/dataSource';

import Loader from 'components/ui/atoms/Loader';
import Modal from 'components/ui/molecules/Modal';
import { getFullPath } from 'views/Devices/components/Routing';

const { useForm, Item } = Form;

type Props = {
  title: string | ReactChild;
};

const DeviceForm: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { deviceId = '' } = useParams<'deviceId'>();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopulatingData, setIsPopulatingData] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const dataSource = useGetDataSourceByIdQuery(deviceId, { skip: !deviceId });
  const [updateDataSource] = useUpdateDataSourceMutation();

  useEffect(() => {
    setIsPopulatingData(dataSource.isFetching);

    if (dataSource.isSuccess)
      form.setFieldsValue({
        name: dataSource.data.meta.name,
      });
  }, [dataSource, form]);

  const backToList = (): void => {
    setIsVisible(false);

    setTimeout(() => navigate(getFullPath(null, true)), 400); // wait for fade out animation
  };

  const handleSave = (promise: Promise<any>): void => {
    setIsLoading(true);

    promise
      .then(() => {
        message.success(
          intl.formatMessage({ defaultMessage: 'The device has been saved.' }),
        );
        backToList();
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFormSubmit = (): void => {
    form.validateFields().then((values) => {
      if (deviceId)
        handleSave(updateDataSource({ device: values, deviceId }).unwrap());
    });
  };

  return (
    <div data-testid="view-device-form">
      <Modal
        title={title}
        visible={isVisible}
        data-testid="device-form"
        footer={[
          <Button
            key="save"
            type="primary"
            shape="round"
            loading={isLoading}
            onClick={handleFormSubmit}
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>,
          <Button
            key="cancel"
            type="ghost"
            shape="round"
            onClick={backToList}
            disabled={isLoading}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>,
        ]}
      >
        <Loader
          text={intl.formatMessage({
            defaultMessage: 'Loading device data...',
          })}
          visible={isPopulatingData}
        >
          <Form name="basic" form={form} layout="vertical">
            <Item
              label="Name"
              name="name"
              tooltip={
                <FormattedMessage defaultMessage="Please select a name for this device. The name should help you identify it in the future." />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required" />
                  ),
                },
              ]}
            >
              <Input />
            </Item>
          </Form>
        </Loader>
      </Modal>
    </div>
  );
};

export default DeviceForm;
