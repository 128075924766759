import { Input } from 'antd';
import cn from 'classnames';
import { debounce } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { BiSearchAlt2 } from 'react-icons/bi';
import { MdClear } from 'react-icons/md';
import { useIntl } from 'react-intl';

import globalStyles from 'styles/global.module.scss';
import useIsMount from 'utilities/hooks/useIsMount';
import styles from './styles.module.scss';

type Props = {
  placeholder?: string;
  className?: string;
  delay?: number;
  onChange?: (searchString: string) => void;
};

const Search: React.FC<Props> = ({
  placeholder,
  className,
  delay = 200,
  onChange = () => {
    // do nothing
  },
}) => {
  const intl = useIntl();
  const isMount = useIsMount();
  const [value, setValue] = useState('');

  const debouncedOnChange = debounce((searchString: string) => {
    onChange(searchString.trim().toLowerCase());
  }, delay);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const Icon = value.length ? MdClear : BiSearchAlt2;

  useEffect(() => {
    if (!isMount) debouncedOnChange(value);
  }, [value]);

  return (
    <Input
      data-testid="search-input"
      placeholder={
        placeholder ??
        intl.formatMessage({
          defaultMessage: 'Search',
        })
      }
      value={value}
      size="large"
      className={cn(styles.input, className)}
      suffix={
        <Icon
          className={cn(styles.icon, { [globalStyles.pointer]: value.length })}
          onClick={() => setValue('')}
        />
      }
      onChange={handleOnChange}
      role="searchbox"
    />
  );
};

export default Search;
