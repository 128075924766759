export enum xLabelsPositions {
  TOP,
  BOTTOM,
}

export enum yLabelsPositions {
  LEFT,
  RIGHT,
}

export const sampleScoreBands = {
  co2: {
    5: '<700',
    4: '700-800',
    3: '800-1000',
    2: '1000-1500',
    1: '1500+',
    0: 'No data',
  },
  humidity: {
    5: '>40',
    4: '36-39',
    3: '33-35',
    2: '30-32',
    1: '<29',
    0: 'No data',
  },
};

type OperationalHours = Record<string, { start: number; end: number } | null>;

export const operationalHoursSample: OperationalHours = {
  monday: {
    start: 585, // 9h00
    end: 1080, // 18h00 (1080 minutes = 18 hours; 18 from 00:00)
  },
  tuesday: {
    start: 540,
    end: 1080,
  },
  wednesday: {
    start: 540,
    end: 1080,
  },
  thursday: {
    start: 540,
    end: 1080,
  },
  friday: {
    start: 540,
    end: 1080,
  },
  saturday: null,
  sunday: null,
};

type RowData = {
  [categoryKey: string]: {
    healthScore: number;
    value: number;
    segments: {
      healthScore: number;
      timestamp: string;
      mean: number | null;
    }[];
  };
};

export const newData: RowData = {
  co2: {
    healthScore: 4,
    value: 40,
    segments: [
      {
        mean: 408.95,
        healthScore: 5,
        timestamp: '2021-01-04T00:00:00+00:00',
      },
      {
        mean: 411.35,
        healthScore: 5,
        timestamp: '2021-01-04T01:00:00+00:00',
      },
      {
        mean: 411.84,
        healthScore: 5,
        timestamp: '2021-01-04T02:00:00+00:00',
      },
      {
        mean: 417.78,
        healthScore: 5,
        timestamp: '2021-01-04T03:00:00+00:00',
      },
      {
        mean: 419.11,
        healthScore: 5,
        timestamp: '2021-01-04T04:00:00+00:00',
      },
      {
        mean: 425.63,
        healthScore: 5,
        timestamp: '2021-01-04T05:00:00+00:00',
      },
      {
        mean: 430.29,
        healthScore: 5,
        timestamp: '2021-01-04T06:00:00+00:00',
      },
      {
        mean: 434.03,
        healthScore: 5,
        timestamp: '2021-01-04T07:00:00+00:00',
      },
      {
        mean: 458.41,
        healthScore: 5,
        timestamp: '2021-01-04T08:00:00+00:00',
      },
      {
        mean: 857.14,
        healthScore: 3,
        timestamp: '2021-01-04T09:00:00+00:00',
      },
      {
        mean: 1230.17,
        healthScore: 2,
        timestamp: '2021-01-04T10:00:00+00:00',
      },
      {
        mean: 1203.89,
        healthScore: 2,
        timestamp: '2021-01-04T11:00:00+00:00',
      },
      {
        mean: 1213.1,
        healthScore: 2,
        timestamp: '2021-01-04T12:00:00+00:00',
      },
      {
        mean: 1495.77,
        healthScore: 2,
        timestamp: '2021-01-04T13:00:00+00:00',
      },
      {
        mean: 1672.88,
        healthScore: 1,
        timestamp: '2021-01-04T14:00:00+00:00',
      },
      {
        mean: 1294.37,
        healthScore: 2,
        timestamp: '2021-01-04T15:00:00+00:00',
      },
      {
        mean: 1204.72,
        healthScore: 2,
        timestamp: '2021-01-04T16:00:00+00:00',
      },
      {
        mean: 1242.08,
        healthScore: 2,
        timestamp: '2021-01-04T17:00:00+00:00',
      },
      {
        mean: 1042.89,
        healthScore: 2,
        timestamp: '2021-01-04T18:00:00+00:00',
      },
      {
        mean: 854.14,
        healthScore: 3,
        timestamp: '2021-01-04T19:00:00+00:00',
      },
      {
        mean: 696.9,
        healthScore: 5,
        timestamp: '2021-01-04T20:00:00+00:00',
      },
      {
        mean: 617.41,
        healthScore: 5,
        timestamp: '2021-01-04T21:00:00+00:00',
      },
      {
        mean: 551.56,
        healthScore: 5,
        timestamp: '2021-01-04T22:00:00+00:00',
      },
      {
        mean: 513.34,
        healthScore: 5,
        timestamp: '2021-01-04T23:00:00+00:00',
      },
      {
        mean: 487.12,
        healthScore: 5,
        timestamp: '2021-01-05T00:00:00+00:00',
      },
      {
        mean: 464.3,
        healthScore: 5,
        timestamp: '2021-01-05T01:00:00+00:00',
      },
      {
        mean: 449.44,
        healthScore: 5,
        timestamp: '2021-01-05T02:00:00+00:00',
      },
      {
        mean: 442.52,
        healthScore: 5,
        timestamp: '2021-01-05T03:00:00+00:00',
      },
      {
        mean: 440.7,
        healthScore: 5,
        timestamp: '2021-01-05T04:00:00+00:00',
      },
      {
        mean: 441.23,
        healthScore: 5,
        timestamp: '2021-01-05T05:00:00+00:00',
      },
      {
        mean: 442.35,
        healthScore: 5,
        timestamp: '2021-01-05T06:00:00+00:00',
      },
      {
        mean: 441.35,
        healthScore: 5,
        timestamp: '2021-01-05T07:00:00+00:00',
      },
      {
        mean: 438.39,
        healthScore: 5,
        timestamp: '2021-01-05T08:00:00+00:00',
      },
      {
        mean: 503.32,
        healthScore: 5,
        timestamp: '2021-01-05T09:00:00+00:00',
      },
      {
        mean: 819.18,
        healthScore: 3,
        timestamp: '2021-01-05T10:00:00+00:00',
      },
      {
        mean: 840.78,
        healthScore: 3,
        timestamp: '2021-01-05T11:00:00+00:00',
      },
      {
        mean: 723.14,
        healthScore: 4,
        timestamp: '2021-01-05T12:00:00+00:00',
      },
      {
        mean: 760.33,
        healthScore: 4,
        timestamp: '2021-01-05T13:00:00+00:00',
      },
      {
        mean: 1196.8,
        healthScore: 2,
        timestamp: '2021-01-05T14:00:00+00:00',
      },
      {
        mean: 1120.99,
        healthScore: 2,
        timestamp: '2021-01-05T15:00:00+00:00',
      },
      {
        mean: 1067.98,
        healthScore: 2,
        timestamp: '2021-01-05T16:00:00+00:00',
      },
      {
        mean: 1041.92,
        healthScore: 2,
        timestamp: '2021-01-05T17:00:00+00:00',
      },
      {
        mean: 1272.72,
        healthScore: 2,
        timestamp: '2021-01-05T18:00:00+00:00',
      },
      {
        mean: 905.38,
        healthScore: 3,
        timestamp: '2021-01-05T19:00:00+00:00',
      },
      {
        mean: 763.62,
        healthScore: 4,
        timestamp: '2021-01-05T20:00:00+00:00',
      },
      {
        mean: 627.62,
        healthScore: 5,
        timestamp: '2021-01-05T21:00:00+00:00',
      },
      {
        mean: 545.33,
        healthScore: 5,
        timestamp: '2021-01-05T22:00:00+00:00',
      },
      {
        mean: 498.56,
        healthScore: 5,
        timestamp: '2021-01-05T23:00:00+00:00',
      },
      {
        mean: 472.1,
        healthScore: 5,
        timestamp: '2021-01-06T00:00:00+00:00',
      },
      {
        mean: 457.91,
        healthScore: 5,
        timestamp: '2021-01-06T01:00:00+00:00',
      },
      {
        mean: 447.99,
        healthScore: 5,
        timestamp: '2021-01-06T02:00:00+00:00',
      },
      {
        mean: 447.08,
        healthScore: 5,
        timestamp: '2021-01-06T03:00:00+00:00',
      },
      {
        mean: 445.72,
        healthScore: 5,
        timestamp: '2021-01-06T04:00:00+00:00',
      },
      {
        mean: 442.42,
        healthScore: 5,
        timestamp: '2021-01-06T05:00:00+00:00',
      },
      {
        mean: 437.17,
        healthScore: 5,
        timestamp: '2021-01-06T06:00:00+00:00',
      },
      {
        mean: 434.46,
        healthScore: 5,
        timestamp: '2021-01-06T07:00:00+00:00',
      },
      {
        mean: 450.43,
        healthScore: 5,
        timestamp: '2021-01-06T08:00:00+00:00',
      },
      {
        mean: 792.6,
        healthScore: 4,
        timestamp: '2021-01-06T09:00:00+00:00',
      },
      {
        mean: 1162.99,
        healthScore: 2,
        timestamp: '2021-01-06T10:00:00+00:00',
      },
      {
        mean: 1125.99,
        healthScore: 2,
        timestamp: '2021-01-06T11:00:00+00:00',
      },
      {
        mean: 1193.9,
        healthScore: 2,
        timestamp: '2021-01-06T12:00:00+00:00',
      },
      {
        mean: 1150.15,
        healthScore: 2,
        timestamp: '2021-01-06T13:00:00+00:00',
      },
      {
        mean: 1393.96,
        healthScore: 2,
        timestamp: '2021-01-06T14:00:00+00:00',
      },
      {
        mean: 1334.43,
        healthScore: 2,
        timestamp: '2021-01-06T15:00:00+00:00',
      },
      {
        mean: 1373.72,
        healthScore: 2,
        timestamp: '2021-01-06T16:00:00+00:00',
      },
      {
        mean: 1432.04,
        healthScore: 2,
        timestamp: '2021-01-06T17:00:00+00:00',
      },
      {
        mean: 1283.97,
        healthScore: 2,
        timestamp: '2021-01-06T18:00:00+00:00',
      },
      {
        mean: 969.34,
        healthScore: 3,
        timestamp: '2021-01-06T19:00:00+00:00',
      },
      {
        mean: 773.54,
        healthScore: 4,
        timestamp: '2021-01-06T20:00:00+00:00',
      },
      {
        mean: 653.07,
        healthScore: 5,
        timestamp: '2021-01-06T21:00:00+00:00',
      },
      {
        mean: 572.96,
        healthScore: 5,
        timestamp: '2021-01-06T22:00:00+00:00',
      },
      {
        mean: 521.95,
        healthScore: 5,
        timestamp: '2021-01-06T23:00:00+00:00',
      },
      {
        mean: 497.36,
        healthScore: 5,
        timestamp: '2021-01-07T00:00:00+00:00',
      },
      {
        mean: 480.36,
        healthScore: 5,
        timestamp: '2021-01-07T01:00:00+00:00',
      },
      {
        mean: 473.63,
        healthScore: 5,
        timestamp: '2021-01-07T02:00:00+00:00',
      },
      {
        mean: 463.12,
        healthScore: 5,
        timestamp: '2021-01-07T03:00:00+00:00',
      },
      {
        mean: 456.06,
        healthScore: 5,
        timestamp: '2021-01-07T04:00:00+00:00',
      },
      {
        mean: 451.21,
        healthScore: 5,
        timestamp: '2021-01-07T05:00:00+00:00',
      },
      {
        mean: 445.43,
        healthScore: 5,
        timestamp: '2021-01-07T06:00:00+00:00',
      },
      {
        mean: 443.71,
        healthScore: 5,
        timestamp: '2021-01-07T07:00:00+00:00',
      },
      {
        mean: 447.43,
        healthScore: 5,
        timestamp: '2021-01-07T08:00:00+00:00',
      },
      {
        mean: 553.65,
        healthScore: 5,
        timestamp: '2021-01-07T09:00:00+00:00',
      },
      {
        mean: 808.4,
        healthScore: 3,
        timestamp: '2021-01-07T10:00:00+00:00',
      },
      {
        mean: 1143.05,
        healthScore: 2,
        timestamp: '2021-01-07T11:00:00+00:00',
      },
      {
        mean: 1039.01,
        healthScore: 2,
        timestamp: '2021-01-07T12:00:00+00:00',
      },
      {
        mean: 990.07,
        healthScore: 3,
        timestamp: '2021-01-07T13:00:00+00:00',
      },
      {
        mean: 1203.03,
        healthScore: 2,
        timestamp: '2021-01-07T14:00:00+00:00',
      },
      {
        mean: 1195.83,
        healthScore: 2,
        timestamp: '2021-01-07T15:00:00+00:00',
      },
      {
        mean: 1253.03,
        healthScore: 2,
        timestamp: '2021-01-07T16:00:00+00:00',
      },
      {
        mean: 1461.82,
        healthScore: 2,
        timestamp: '2021-01-07T17:00:00+00:00',
      },
      {
        mean: 1500.72,
        healthScore: 1,
        timestamp: '2021-01-07T18:00:00+00:00',
      },
      {
        mean: 1141.47,
        healthScore: 2,
        timestamp: '2021-01-07T19:00:00+00:00',
      },
      {
        mean: 957.91,
        healthScore: 3,
        timestamp: '2021-01-07T20:00:00+00:00',
      },
      {
        mean: 788.17,
        healthScore: 4,
        timestamp: '2021-01-07T21:00:00+00:00',
      },
      {
        mean: 676.98,
        healthScore: 5,
        timestamp: '2021-01-07T22:00:00+00:00',
      },
      {
        mean: 613.36,
        healthScore: 5,
        timestamp: '2021-01-07T23:00:00+00:00',
      },
      {
        mean: 575.79,
        healthScore: 5,
        timestamp: '2021-01-08T00:00:00+00:00',
      },
      {
        mean: 551.06,
        healthScore: 5,
        timestamp: '2021-01-08T01:00:00+00:00',
      },
      {
        mean: 524.78,
        healthScore: 5,
        timestamp: '2021-01-08T02:00:00+00:00',
      },
      {
        mean: 503.87,
        healthScore: 5,
        timestamp: '2021-01-08T03:00:00+00:00',
      },
      {
        mean: 487.18,
        healthScore: 5,
        timestamp: '2021-01-08T04:00:00+00:00',
      },
      {
        mean: 484.74,
        healthScore: 5,
        timestamp: '2021-01-08T05:00:00+00:00',
      },
      {
        mean: 477.04,
        healthScore: 5,
        timestamp: '2021-01-08T06:00:00+00:00',
      },
      {
        mean: 470.89,
        healthScore: 5,
        timestamp: '2021-01-08T07:00:00+00:00',
      },
      {
        mean: 470.0,
        healthScore: 5,
        timestamp: '2021-01-08T08:00:00+00:00',
      },
      {
        mean: 556.96,
        healthScore: 5,
        timestamp: '2021-01-08T09:00:00+00:00',
      },
      {
        mean: 718.76,
        healthScore: 4,
        timestamp: '2021-01-08T10:00:00+00:00',
      },
      {
        mean: 695.83,
        healthScore: 5,
        timestamp: '2021-01-08T11:00:00+00:00',
      },
      {
        mean: 942.08,
        healthScore: 3,
        timestamp: '2021-01-08T12:00:00+00:00',
      },
      {
        mean: 936.84,
        healthScore: 3,
        timestamp: '2021-01-08T13:00:00+00:00',
      },
      {
        mean: 853.86,
        healthScore: 3,
        timestamp: '2021-01-08T14:00:00+00:00',
      },
      {
        mean: 823.12,
        healthScore: 3,
        timestamp: '2021-01-08T15:00:00+00:00',
      },
      {
        mean: 738.35,
        healthScore: 4,
        timestamp: '2021-01-08T16:00:00+00:00',
      },
      {
        mean: 762.42,
        healthScore: 4,
        timestamp: '2021-01-08T17:00:00+00:00',
      },
      {
        mean: 752.21,
        healthScore: 4,
        timestamp: '2021-01-08T18:00:00+00:00',
      },
      {
        mean: 631.51,
        healthScore: 5,
        timestamp: '2021-01-08T19:00:00+00:00',
      },
      {
        mean: 581.66,
        healthScore: 5,
        timestamp: '2021-01-08T20:00:00+00:00',
      },
      {
        mean: 571.07,
        healthScore: 5,
        timestamp: '2021-01-08T21:00:00+00:00',
      },
      {
        mean: 546.06,
        healthScore: 5,
        timestamp: '2021-01-08T22:00:00+00:00',
      },
      {
        mean: 523.08,
        healthScore: 5,
        timestamp: '2021-01-08T23:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T00:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T01:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T02:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T03:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T04:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T05:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T06:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T07:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T08:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T09:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T10:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T11:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T12:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T13:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T14:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T15:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T16:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T17:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T18:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T19:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T20:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T21:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T22:00:00+00:00',
      },
      {
        mean: 425.2,
        healthScore: 5,
        timestamp: '2021-01-09T23:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T00:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T01:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T02:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T03:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T04:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T05:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T06:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T07:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T08:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T09:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T10:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T11:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T12:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T13:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T14:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T15:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T16:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T17:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T18:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T19:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T20:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T21:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T22:00:00+00:00',
      },
      {
        mean: 621.0,
        healthScore: 5,
        timestamp: '2021-01-10T23:00:00+00:00',
      },
    ],
  },
  humidity: {
    healthScore: 5,
    value: 90,
    segments: [
      {
        mean: 45.86,
        healthScore: 5,
        timestamp: '2021-01-04T00:00:00+00:00',
      },
      {
        mean: 45.04,
        healthScore: 5,
        timestamp: '2021-01-04T01:00:00+00:00',
      },
      {
        mean: 45.03,
        healthScore: 5,
        timestamp: '2021-01-04T02:00:00+00:00',
      },
      {
        mean: 45.54,
        healthScore: 5,
        timestamp: '2021-01-04T03:00:00+00:00',
      },
      {
        mean: 45.91,
        healthScore: 5,
        timestamp: '2021-01-04T04:00:00+00:00',
      },
      {
        mean: 45.79,
        healthScore: 5,
        timestamp: '2021-01-04T05:00:00+00:00',
      },
      {
        mean: 45.59,
        healthScore: 5,
        timestamp: '2021-01-04T06:00:00+00:00',
      },
      {
        mean: 45.03,
        healthScore: 5,
        timestamp: '2021-01-04T07:00:00+00:00',
      },
      {
        mean: 43.28,
        healthScore: 5,
        timestamp: '2021-01-04T08:00:00+00:00',
      },
      {
        mean: 42.25,
        healthScore: 5,
        timestamp: '2021-01-04T09:00:00+00:00',
      },
      {
        mean: 41.08,
        healthScore: 5,
        timestamp: '2021-01-04T10:00:00+00:00',
      },
      {
        mean: 38.31,
        healthScore: 4,
        timestamp: '2021-01-04T11:00:00+00:00',
      },
      {
        mean: 38.34,
        healthScore: 4,
        timestamp: '2021-01-04T12:00:00+00:00',
      },
      {
        mean: 41.06,
        healthScore: 5,
        timestamp: '2021-01-04T13:00:00+00:00',
      },
      {
        mean: 41.3,
        healthScore: 5,
        timestamp: '2021-01-04T14:00:00+00:00',
      },
      {
        mean: 37.51,
        healthScore: 4,
        timestamp: '2021-01-04T15:00:00+00:00',
      },
      {
        mean: 37.97,
        healthScore: 4,
        timestamp: '2021-01-04T16:00:00+00:00',
      },
      {
        mean: 41.38,
        healthScore: 5,
        timestamp: '2021-01-04T17:00:00+00:00',
      },
      {
        mean: 39.76,
        healthScore: 4,
        timestamp: '2021-01-04T18:00:00+00:00',
      },
      {
        mean: 38.9,
        healthScore: 4,
        timestamp: '2021-01-04T19:00:00+00:00',
      },
      {
        mean: 40.79,
        healthScore: 5,
        timestamp: '2021-01-04T20:00:00+00:00',
      },
      {
        mean: 42.99,
        healthScore: 5,
        timestamp: '2021-01-04T21:00:00+00:00',
      },
      {
        mean: 44.39,
        healthScore: 5,
        timestamp: '2021-01-04T22:00:00+00:00',
      },
      {
        mean: 45.08,
        healthScore: 5,
        timestamp: '2021-01-04T23:00:00+00:00',
      },
      {
        mean: 45.52,
        healthScore: 5,
        timestamp: '2021-01-05T00:00:00+00:00',
      },
      {
        mean: 45.58,
        healthScore: 5,
        timestamp: '2021-01-05T01:00:00+00:00',
      },
      {
        mean: 45.63,
        healthScore: 5,
        timestamp: '2021-01-05T02:00:00+00:00',
      },
      {
        mean: 45.74,
        healthScore: 5,
        timestamp: '2021-01-05T03:00:00+00:00',
      },
      {
        mean: 45.74,
        healthScore: 5,
        timestamp: '2021-01-05T04:00:00+00:00',
      },
      {
        mean: 45.59,
        healthScore: 5,
        timestamp: '2021-01-05T05:00:00+00:00',
      },
      {
        mean: 45.44,
        healthScore: 5,
        timestamp: '2021-01-05T06:00:00+00:00',
      },
      {
        mean: 44.74,
        healthScore: 5,
        timestamp: '2021-01-05T07:00:00+00:00',
      },
      {
        mean: 43.59,
        healthScore: 5,
        timestamp: '2021-01-05T08:00:00+00:00',
      },
      {
        mean: 42.55,
        healthScore: 5,
        timestamp: '2021-01-05T09:00:00+00:00',
      },
      {
        mean: 45.62,
        healthScore: 5,
        timestamp: '2021-01-05T10:00:00+00:00',
      },
      {
        mean: 47.11,
        healthScore: 5,
        timestamp: '2021-01-05T11:00:00+00:00',
      },
      {
        mean: 46.35,
        healthScore: 5,
        timestamp: '2021-01-05T12:00:00+00:00',
      },
      {
        mean: 48.21,
        healthScore: 5,
        timestamp: '2021-01-05T13:00:00+00:00',
      },
      {
        mean: 47.51,
        healthScore: 5,
        timestamp: '2021-01-05T14:00:00+00:00',
      },
      {
        mean: 41.32,
        healthScore: 5,
        timestamp: '2021-01-05T15:00:00+00:00',
      },
      {
        mean: 42.83,
        healthScore: 5,
        timestamp: '2021-01-05T16:00:00+00:00',
      },
      {
        mean: 43.78,
        healthScore: 5,
        timestamp: '2021-01-05T17:00:00+00:00',
      },
      {
        mean: 46.72,
        healthScore: 5,
        timestamp: '2021-01-05T18:00:00+00:00',
      },
      {
        mean: 42.54,
        healthScore: 5,
        timestamp: '2021-01-05T19:00:00+00:00',
      },
      {
        mean: 43.77,
        healthScore: 5,
        timestamp: '2021-01-05T20:00:00+00:00',
      },
      {
        mean: 45.56,
        healthScore: 5,
        timestamp: '2021-01-05T21:00:00+00:00',
      },
      {
        mean: 46.62,
        healthScore: 5,
        timestamp: '2021-01-05T22:00:00+00:00',
      },
      {
        mean: 47.36,
        healthScore: 5,
        timestamp: '2021-01-05T23:00:00+00:00',
      },
      {
        mean: 47.54,
        healthScore: 5,
        timestamp: '2021-01-06T00:00:00+00:00',
      },
      {
        mean: 47.59,
        healthScore: 5,
        timestamp: '2021-01-06T01:00:00+00:00',
      },
      {
        mean: 47.54,
        healthScore: 5,
        timestamp: '2021-01-06T02:00:00+00:00',
      },
      {
        mean: 47.5,
        healthScore: 5,
        timestamp: '2021-01-06T03:00:00+00:00',
      },
      {
        mean: 47.36,
        healthScore: 5,
        timestamp: '2021-01-06T04:00:00+00:00',
      },
      {
        mean: 47.3,
        healthScore: 5,
        timestamp: '2021-01-06T05:00:00+00:00',
      },
      {
        mean: 47.07,
        healthScore: 5,
        timestamp: '2021-01-06T06:00:00+00:00',
      },
      {
        mean: 46.49,
        healthScore: 5,
        timestamp: '2021-01-06T07:00:00+00:00',
      },
      {
        mean: 45.3,
        healthScore: 5,
        timestamp: '2021-01-06T08:00:00+00:00',
      },
      {
        mean: 47.49,
        healthScore: 5,
        timestamp: '2021-01-06T09:00:00+00:00',
      },
      {
        mean: 50.43,
        healthScore: 5,
        timestamp: '2021-01-06T10:00:00+00:00',
      },
      {
        mean: 47.69,
        healthScore: 5,
        timestamp: '2021-01-06T11:00:00+00:00',
      },
      {
        mean: 48.4,
        healthScore: 5,
        timestamp: '2021-01-06T12:00:00+00:00',
      },
      {
        mean: 48.46,
        healthScore: 5,
        timestamp: '2021-01-06T13:00:00+00:00',
      },
      {
        mean: 50.51,
        healthScore: 5,
        timestamp: '2021-01-06T14:00:00+00:00',
      },
      {
        mean: 48.92,
        healthScore: 5,
        timestamp: '2021-01-06T15:00:00+00:00',
      },
      {
        mean: 48.73,
        healthScore: 5,
        timestamp: '2021-01-06T16:00:00+00:00',
      },
      {
        mean: 50.87,
        healthScore: 5,
        timestamp: '2021-01-06T17:00:00+00:00',
      },
      {
        mean: 48.12,
        healthScore: 5,
        timestamp: '2021-01-06T18:00:00+00:00',
      },
      {
        mean: 46.45,
        healthScore: 5,
        timestamp: '2021-01-06T19:00:00+00:00',
      },
      {
        mean: 48.31,
        healthScore: 5,
        timestamp: '2021-01-06T20:00:00+00:00',
      },
      {
        mean: 48.96,
        healthScore: 5,
        timestamp: '2021-01-06T21:00:00+00:00',
      },
      {
        mean: 49.04,
        healthScore: 5,
        timestamp: '2021-01-06T22:00:00+00:00',
      },
      {
        mean: 49.13,
        healthScore: 5,
        timestamp: '2021-01-06T23:00:00+00:00',
      },
      {
        mean: 49.29,
        healthScore: 5,
        timestamp: '2021-01-07T00:00:00+00:00',
      },
      {
        mean: 49.4,
        healthScore: 5,
        timestamp: '2021-01-07T01:00:00+00:00',
      },
      {
        mean: 49.56,
        healthScore: 5,
        timestamp: '2021-01-07T02:00:00+00:00',
      },
      {
        mean: 49.68,
        healthScore: 5,
        timestamp: '2021-01-07T03:00:00+00:00',
      },
      {
        mean: 49.98,
        healthScore: 5,
        timestamp: '2021-01-07T04:00:00+00:00',
      },
      {
        mean: 50.2,
        healthScore: 5,
        timestamp: '2021-01-07T05:00:00+00:00',
      },
      {
        mean: 50.24,
        healthScore: 5,
        timestamp: '2021-01-07T06:00:00+00:00',
      },
      {
        mean: 50.06,
        healthScore: 5,
        timestamp: '2021-01-07T07:00:00+00:00',
      },
      {
        mean: 49.47,
        healthScore: 5,
        timestamp: '2021-01-07T08:00:00+00:00',
      },
      {
        mean: 48.58,
        healthScore: 5,
        timestamp: '2021-01-07T09:00:00+00:00',
      },
      {
        mean: 50.74,
        healthScore: 5,
        timestamp: '2021-01-07T10:00:00+00:00',
      },
      {
        mean: 51.2,
        healthScore: 5,
        timestamp: '2021-01-07T11:00:00+00:00',
      },
      {
        mean: 49.46,
        healthScore: 5,
        timestamp: '2021-01-07T12:00:00+00:00',
      },
      {
        mean: 48.69,
        healthScore: 5,
        timestamp: '2021-01-07T13:00:00+00:00',
      },
      {
        mean: 46.2,
        healthScore: 5,
        timestamp: '2021-01-07T14:00:00+00:00',
      },
      {
        mean: 44.86,
        healthScore: 5,
        timestamp: '2021-01-07T15:00:00+00:00',
      },
      {
        mean: 45.17,
        healthScore: 5,
        timestamp: '2021-01-07T16:00:00+00:00',
      },
      {
        mean: 46.02,
        healthScore: 5,
        timestamp: '2021-01-07T17:00:00+00:00',
      },
      {
        mean: 43.85,
        healthScore: 5,
        timestamp: '2021-01-07T18:00:00+00:00',
      },
      {
        mean: 43.47,
        healthScore: 5,
        timestamp: '2021-01-07T19:00:00+00:00',
      },
      {
        mean: 43.07,
        healthScore: 5,
        timestamp: '2021-01-07T20:00:00+00:00',
      },
      {
        mean: 45.8,
        healthScore: 5,
        timestamp: '2021-01-07T21:00:00+00:00',
      },
      {
        mean: 46.06,
        healthScore: 5,
        timestamp: '2021-01-07T22:00:00+00:00',
      },
      {
        mean: 46.21,
        healthScore: 5,
        timestamp: '2021-01-07T23:00:00+00:00',
      },
      {
        mean: 46.36,
        healthScore: 5,
        timestamp: '2021-01-08T00:00:00+00:00',
      },
      {
        mean: 46.58,
        healthScore: 5,
        timestamp: '2021-01-08T01:00:00+00:00',
      },
      {
        mean: 47.11,
        healthScore: 5,
        timestamp: '2021-01-08T02:00:00+00:00',
      },
      {
        mean: 48.37,
        healthScore: 5,
        timestamp: '2021-01-08T03:00:00+00:00',
      },
      {
        mean: 49.97,
        healthScore: 5,
        timestamp: '2021-01-08T04:00:00+00:00',
      },
      {
        mean: 49.76,
        healthScore: 5,
        timestamp: '2021-01-08T05:00:00+00:00',
      },
      {
        mean: 49.99,
        healthScore: 5,
        timestamp: '2021-01-08T06:00:00+00:00',
      },
      {
        mean: 49.63,
        healthScore: 5,
        timestamp: '2021-01-08T07:00:00+00:00',
      },
      {
        mean: 46.36,
        healthScore: 5,
        timestamp: '2021-01-08T08:00:00+00:00',
      },
      {
        mean: 44.97,
        healthScore: 5,
        timestamp: '2021-01-08T09:00:00+00:00',
      },
      {
        mean: 44.42,
        healthScore: 5,
        timestamp: '2021-01-08T10:00:00+00:00',
      },
      {
        mean: 43.17,
        healthScore: 5,
        timestamp: '2021-01-08T11:00:00+00:00',
      },
      {
        mean: 44.5,
        healthScore: 5,
        timestamp: '2021-01-08T12:00:00+00:00',
      },
      {
        mean: 42.21,
        healthScore: 5,
        timestamp: '2021-01-08T13:00:00+00:00',
      },
      {
        mean: 41.91,
        healthScore: 5,
        timestamp: '2021-01-08T14:00:00+00:00',
      },
      {
        mean: 42.29,
        healthScore: 5,
        timestamp: '2021-01-08T15:00:00+00:00',
      },
      {
        mean: 41.45,
        healthScore: 5,
        timestamp: '2021-01-08T16:00:00+00:00',
      },
      {
        mean: 41.97,
        healthScore: 5,
        timestamp: '2021-01-08T17:00:00+00:00',
      },
      {
        mean: 39.71,
        healthScore: 4,
        timestamp: '2021-01-08T18:00:00+00:00',
      },
      {
        mean: 39.95,
        healthScore: 4,
        timestamp: '2021-01-08T19:00:00+00:00',
      },
      {
        mean: 41.32,
        healthScore: 5,
        timestamp: '2021-01-08T20:00:00+00:00',
      },
      {
        mean: 43.92,
        healthScore: 5,
        timestamp: '2021-01-08T21:00:00+00:00',
      },
      {
        mean: 44.7,
        healthScore: 5,
        timestamp: '2021-01-08T22:00:00+00:00',
      },
      {
        mean: 45.05,
        healthScore: 5,
        timestamp: '2021-01-08T23:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T00:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T01:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T02:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T03:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T04:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T05:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T06:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T07:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T08:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T09:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T10:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T11:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T12:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T13:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T14:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T15:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T16:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T17:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T18:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T19:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T20:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T21:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T22:00:00+00:00',
      },
      {
        mean: 45.2,
        healthScore: 5,
        timestamp: '2021-01-09T23:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T00:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T01:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T02:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T03:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T04:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T05:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T06:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T07:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T08:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T09:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T10:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T11:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T12:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T13:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T14:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T15:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T16:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T17:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T18:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T19:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T20:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T21:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T22:00:00+00:00',
      },
      {
        mean: 51.0,
        healthScore: 5,
        timestamp: '2021-01-10T23:00:00+00:00',
      },
    ],
  },
};
