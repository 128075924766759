import { RcFile } from 'antd/lib/upload';
import doRequest, { baseApi } from 'api/api';
import { Meta } from 'api/meta';
import { setFloorplanUploadProgress } from 'ducks/space/slice';
import { SpaceTag } from './tags';

export enum Endpoint {
  GetSpaceNodeList = '/space',
  CreateSpaceNode = '/space',
  DeleteSpaceNodes = '/space',
  UpdateSpaceNode = '/space/:spaceId',
  GetSpaceNodeTree = '/space/:spaceId/tree',
  SearchAddress = '/space/search/address',
  GetFloorPlan = '/space/:spaceId/floorplan',
  CreateFloorPlan = '/space/:spaceId/floorplan',
  DeleteFloorPlan = '/space/:spaceId/floorplan',
  UpdateFloorPlanScale = '/space/:spaceId/floorplan/scale',
}

export enum SpaceNodeCategory {
  BUILDING = 'building',
  FLOOR = 'floor',
  ROOM = 'room',
  ZONE = 'zone',
}

export const SPACE_NODE_ID_PREFIX = 'spc-';

export type FloorPlan = {
  displayName: string;
  data: {
    url: string;
    size: number;
    baseUrl: string;
    containerAccessToken: string;
    spaceId: string;
  };
};

export type SpaceNodeSystemMeta = {
  floorNumber?: number;
  streetAddress?: string;
  floorplan?: string;
};

export type SpaceNode = {
  id: string;
  parentId: string | null;
  orgId: string;
  path: string;
  category: SpaceNodeCategory;
  lifecycle: string;
  latitude: number | null;
  longitude: number | null;
  meta: Meta;
  systemMeta: SpaceNodeSystemMeta;
  children?: SpaceNode[];
};

export type SearchAddressResult = {
  type: string;
  id: string;
  score: number;
  entityType: string;
  matchConfidence: {
    score: number;
  };
  address: {
    municipalitySubdivision: string;
    municipality: string;
    countrySecondarySubdivision: string;
    countrySubdivision: string;
    countryCode: string;
    country: string;
    countryCodeISO3: string;
    freeformAddress: string;
  };
  position: {
    lat: number;
    lon: number;
  };
};

export type SearchAddressResponse = {
  summary: {
    query: string;
    queryType: string;
    queryTime: number;
    numResults: number;
    offset: number;
    totalResults: number;
    fuzzyLevel: number;
  };
  results: SearchAddressResult[];
};

export type SpaceNodeCollection = { [spaceId: string]: SpaceNode };

export type GetSpaceNodeListResponse = Promise<SpaceNode[]>;

export type CreateSpaceNodeResponse = Promise<SpaceNode[]>;

export type DeleteSpaceNodeResponse = Promise<string[]>;

export type UpdateSpaceNodeResponse = Promise<SpaceNode>;

export type GetSpaceNodeTreeResponse = Promise<SpaceNode[]>;

export type GetFloorPlanResponse = Promise<FloorPlan>;

export type CreateSingleSpaceNodeBody = {
  orgId?: string;
  category: string;
  parentId?: string;
  latitude?: number;
  longitude?: number;
  floorNumber?: number;
  streetAddress?: string;
  meta: Meta;
};

export type CreateSpaceNodeBody =
  | CreateSingleSpaceNodeBody
  | CreateSingleSpaceNodeBody[];

export type UpdateSpaceNodeBody = {
  latitude?: number;
  longitude?: number;
  floorNumber?: number;
  streetAddress?: string;
  meta?: Meta;
};

type GetSpaceNodeListParams = {
  spaceId?: string[];
  orgId?: string;
  category?: SpaceNodeCategory;
  includeInactive?: boolean;
};

export const spaceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getSpaceNodeList(spaceId, orgId, category, includeInactive)
    getSpaceNodeList: build.query<SpaceNodeCollection, GetSpaceNodeListParams>({
      query: ({ spaceId, orgId, category, includeInactive }) => ({
        url: Endpoint.GetSpaceNodeList,
        options: {
          method: 'GET',
          params: {
            spaceId,
            orgId,
            category,
            includeInactive,
          },
        },
      }),
      transformResponse: (result: SpaceNode[]) => {
        if (!result) return {};

        return Object.fromEntries(
          result.map((spaceNode) => [spaceNode.id, spaceNode]),
        );
      },
      providesTags: [SpaceTag.SpaceNodes],
    }),
    // * createSpaceNodes(space)
    createSpaceNodes: build.mutation<SpaceNode[], CreateSpaceNodeBody>({
      query: (space) => ({
        url: Endpoint.CreateSpaceNode,
        options: {
          method: 'POST',
          data: space,
        },
      }),
      invalidatesTags: [SpaceTag.SpaceNodes],
    }),
    // * deleteSpaceNodes(spaceIds)
    deleteSpaceNodes: build.mutation<string[], string[]>({
      query: (spaceIds) => ({
        url: Endpoint.DeleteSpaceNodes,
        options: {
          method: 'DELETE',
          params: {
            spaceId: spaceIds,
          },
        },
      }),
      invalidatesTags: [SpaceTag.SpaceNodes],
    }),
    // * updateSpaceNodes(spaceId, body)
    updateSpaceNode: build.mutation<
      SpaceNode,
      { spaceId: string; body: UpdateSpaceNodeBody }
    >({
      query: ({ spaceId, body }) => ({
        url: Endpoint.UpdateSpaceNode.replace(':spaceId', spaceId),
        options: {
          method: 'PATCH',
          data: body,
        },
      }),
      invalidatesTags: [SpaceTag.SpaceNodes],
    }),
    // * getSpaceNodeTree(spaceId)
    getSpaceNodeTree: build.query<SpaceNode[], string>({
      query: (spaceId) => ({
        url: Endpoint.GetSpaceNodeTree.replace(':spaceId', spaceId),
        options: {
          method: 'GET',
        },
      }),
      transformResponse: (result: SpaceNode[]) => {
        if (!result) return [];

        const map: Record<string, SpaceNode> = {};
        const output: SpaceNode[] = [];

        result.forEach((item) => {
          map[item.id] = { ...item, children: [] };
        });

        result.forEach((item) => {
          if (item.parentId === null) {
            output.push(map[item.id]);
          } else {
            const parent = map[item.parentId];
            if (parent) {
              parent.children?.push(map[item.id]);
            }
          }
        });

        return output;
      },
      providesTags: [SpaceTag.SpaceNodes],
    }),
    // * searchAddress(query)
    searchAddress: build.query<SearchAddressResponse, string>({
      query: (query) => ({
        url: Endpoint.SearchAddress,
        options: {
          method: 'GET',
          params: {
            query,
          },
        },
      }),
    }),
    // * getFloorPlan(spaceId)
    getFloorPlan: build.query<FloorPlan, string>({
      query: (spaceId) => ({
        url: Endpoint.GetFloorPlan.replace(':spaceId', spaceId),
        options: {
          method: 'GET',
        },
      }),
      providesTags: [SpaceTag.Floorplan],
    }),
    // * uploadFloorPlan(spaceId, file, displayName)
    uploadFloorPlan: build.mutation<
      FloorPlan,
      { spaceId: string; file: RcFile; displayName: string }
    >({
      queryFn: async ({ spaceId, file, displayName }, api) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
          return await doRequest(
            Endpoint.CreateFloorPlan.replace(':spaceId', spaceId),
            {
              method: 'POST',
              data: formData,
              params: {
                displayName,
                // TODO: replace this tmp fix in HELIX-981
                stepsForward: 1,
              },
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (upload) => {
                const uploadloadProgress = Math.round(
                  (100 * upload.loaded) / upload.total,
                );
                api.dispatch(setFloorplanUploadProgress(uploadloadProgress));
              },
            },
          );
        } catch (err: any) {
          return {
            error: {
              status: err.status,
              data: err.message,
            },
          };
        }
      },
      invalidatesTags: [SpaceTag.Floorplan, SpaceTag.SpaceNodes],
    }),
    // * deleteFloorPlan(spaceId, filename)
    deleteFloorPlan: build.mutation<
      void,
      { spaceId: string; filename: string }
    >({
      query: ({ spaceId, filename }) => ({
        url: Endpoint.DeleteFloorPlan.replace(':spaceId', spaceId),
        options: {
          method: 'DELETE',
          params: {
            filename,
          },
        },
      }),
      invalidatesTags: [SpaceTag.Floorplan, SpaceTag.SpaceNodes],
    }),
  }),
});

export const {
  useSearchAddressQuery,
  useGetSpaceNodeTreeQuery,
  useGetSpaceNodeListQuery,
  useGetFloorPlanQuery,
  useUploadFloorPlanMutation,
  useDeleteFloorPlanMutation,
  useCreateSpaceNodesMutation,
  useUpdateSpaceNodeMutation,
  useDeleteSpaceNodesMutation,
} = spaceApi;
