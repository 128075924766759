import { Avatar, Divider, Typography } from 'antd';
import { FaBuilding } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { SpaceNode } from 'api/space';
import { avatarColour } from 'utilities/utilities';

import styles from './styles.module.scss';

const { Text } = Typography;
const emptyPlaceholder = '---';

type Props = {
  selectedBuilding?: SpaceNode;
  floorCount?: number | string;
  roomCount?: number | string;
};

const Footer: React.FC<Props> = ({
  selectedBuilding,
  floorCount = emptyPlaceholder,
  roomCount = emptyPlaceholder,
}) => (
  <>
    <Divider className={styles.divider} />
    <div className={styles.footer} data-testid="footer">
      <Avatar
        shape="square"
        className={styles.buildingAvatar}
        style={{ backgroundColor: avatarColour(selectedBuilding?.meta.name) }}
      >
        {selectedBuilding ? (
          selectedBuilding.meta.name.substring(0, 1)
        ) : (
          <FaBuilding className={styles.icon} />
        )}
      </Avatar>
      <div className={styles.buildingLabels}>
        <Text>{selectedBuilding?.meta.name || emptyPlaceholder}</Text>
        <Text>{selectedBuilding?.meta.description || emptyPlaceholder}</Text>
      </div>
      <div className={styles.buildingStat}>
        <Text>
          <FormattedMessage defaultMessage="FLOORS" />
        </Text>
        <Text>{floorCount}</Text>
      </div>
      <div className={styles.buildingStat}>
        <Text>
          <FormattedMessage defaultMessage="ROOMS" />
        </Text>
        <Text>{roomCount}</Text>
      </div>
    </div>
  </>
);

export default Footer;
