import React from 'react';
import { Route, Routes as ReactRouter } from 'react-router-dom';

import FloorPlan from 'views/Map/views/FloorPlan';
import FloorPlanUpload from 'views/Map/views/FloorPlanUpload';

import { Routes } from './Routes';

const Routing: React.FC = () => (
  <ReactRouter>
    <Route path="*" element={<FloorPlan />}>
      <Route path={Routes.UPLOAD} element={<FloorPlanUpload />} />
    </Route>
  </ReactRouter>
);

export default Routing;
