import { message } from 'antd';
import { useState, useEffect, useMemo, createContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { getPDF } from 'api/pdf';
import Loader from 'components/ui/atoms/Loader';

import styles from './styles.module.scss';
import { setupHTML, setupDocumentForPrint } from './utilities';

export enum PrintState {
  Idle, // initail state
  Processing, // when child component is set but not yet ready to print
  Ready, // set by child component when ready to print
  Success, // after successfull print / transform
  Failure, // after failed print / transform
}

export enum GenerationMode {
  Frontend,
  Backend,
}

/* Sample usage in component:
import { PdfProviderContext } from 'components/PdfProvider';

// PDF CONTENT
const SamplePdfContent: React.FC = () => {
  const { setChildrenReadyToPrint } = useContext(PdfProviderContext);

  // needs to be set after making sure all the component elements were rendered
  setChildrenReadyToPrint(true);
  return <>Sample</>
};

// VIEW
const SomeView: React.FC = () => {
  const [showChildrenToPrint, setShowChildrenToPrint] =
    useState<boolean>(false);

  return (
    <PdfProvider>
      <SamplePdfContent />
    </PdfProvider>
  );
};
*/

export const PdfProviderContext = createContext({
  setChildrenReadyToPrint: (ready: boolean): void => {
    // pragma: no cover
  },
});

type Props = {
  fileName?: string;
  children?: React.ReactChild | null;
  onPrintStateChanged?: (result: PrintState) => void;
  generationMode?: GenerationMode;
};

const PdfProvider: React.FC<Props> = ({
  children,
  onPrintStateChanged = () => undefined,
  generationMode = GenerationMode.Frontend,
  fileName = 'pdf',
}) => {
  const intl = useIntl();
  const uuid = useMemo(() => uuidv4(), []);
  const [printState, setPrintState] = useState<PrintState>(PrintState.Idle);
  const [childrenReadyToPrint, setChildrenReadyToPrint] = useState(false);

  const printProcessInProgress = () => {
    const isInProgress = printState === PrintState.Idle && childrenReadyToPrint;
    if (isInProgress) console.error('Cannot submit concurrent print request');
    return isInProgress;
  };

  const sendPrintContent = () => {
    if (printProcessInProgress()) return;

    setupHTML(document, uuid)
      .then((data) => {
        setPrintState(PrintState.Ready);
        return getPDF(data);
      })
      .then((blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.dataset.testid = 'pdf-link';

        link.download = `${fileName}.pdf`;
        link.click();

        setPrintState(PrintState.Success);
      })
      .catch((err: any) => {
        setPrintState(PrintState.Failure);
        message.error(
          intl.formatMessage({
            defaultMessage: 'Error occurred during pdf processing',
          }),
        );
      })
      .finally(() => setChildrenReadyToPrint(false));
  };

  const exposePrintContent = () => {
    if (printProcessInProgress()) return;

    setupDocumentForPrint(document, uuid)
      .then(() => setPrintState(PrintState.Ready))
      .catch(() => setPrintState(PrintState.Failure));
  };

  useEffect(() => {
    if (children === null || typeof children === 'undefined') return;
    setPrintState(PrintState.Processing);
  }, [children]);

  useEffect(() => {
    if (!childrenReadyToPrint) return;

    const printCallback =
      generationMode === GenerationMode.Frontend
        ? sendPrintContent
        : exposePrintContent;

    printCallback();
  }, [childrenReadyToPrint]);

  useEffect(() => {
    onPrintStateChanged(printState);
  }, [printState]);

  // such a construct is needed to properly handle tests
  const updateChildrenReadyToPrint = (isReady: boolean) =>
    setChildrenReadyToPrint(isReady);

  return (
    <div className="wrap-data">
      <PdfProviderContext.Provider
        value={{ setChildrenReadyToPrint: updateChildrenReadyToPrint }}
      >
        {printState === PrintState.Processing && (
          <Loader
            visible={true}
            text={intl.formatMessage({
              defaultMessage: 'PDF is generating...',
            })}
            style={{
              position: 'fixed',
              zIndex: 101,
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backdropFilter: 'blur(5px)',
            }}
          />
        )}
        <div
          className={styles.wrapper}
          data-uuid={uuid}
          data-pdf={printState === PrintState.Ready ? 'ready' : ''}
        >
          {children}
        </div>
      </PdfProviderContext.Provider>
    </div>
  );
};

export default PdfProvider;
