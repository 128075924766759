import { NOT_RELEVANT } from 'views/Settings/views/Bms/utilities';
import { DataSinkLifecycle } from 'api/dataSink';
import { DataSinkRow } from './config';

export const filterBySearchString = (
  searchString: string,
): ((row: DataSinkRow) => boolean) =>
  searchString.length === 0
    ? () => true
    : ({ searchName }) => searchName.includes(searchString);

export const filterByIsActive = (
  isActive: boolean | string,
): ((row: DataSinkRow) => boolean) =>
  isActive === NOT_RELEVANT
    ? () => true
    : (row) =>
        (row.lifecycle === DataSinkLifecycle.ACTIVE && isActive === true) ||
        (row.lifecycle === DataSinkLifecycle.DELETED && isActive === false);
