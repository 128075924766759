import { Org, OrgNodes } from './org';

export type TreeSelectOption = {
  title: string;
  value: string;
  children: TreeSelectOption[];
};

export const orgTreeSelectData = (
  orgNodes: Record<string, Org>,
): TreeSelectOption[] => {
  const sorted = Object.values(orgNodes).sort(
    (a, b) => a.path.split('.').length - b.path.split('.').length,
  );

  if (sorted.length === 0) {
    return [];
  }

  const roots = sorted.filter(
    (node) => node.path.split('.').length === sorted[0].path.split('.').length,
  );

  const mapToOption = (nodes?: Org[]): any => {
    return nodes
      ? nodes.map((node) => ({
          title: node.meta.name,
          value: node.id,
          key: node.id,
          children: mapToOption(node.children),
        }))
      : [];
  };

  return mapToOption(roots);
};

export const selectOrgNameByOrgId =
  (orgNodes: OrgNodes) =>
  (orgId: string, placeholder = '-'): string =>
    orgNodes[orgId]?.meta.name || placeholder;
