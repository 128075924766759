import { Badge } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

dayjs.extend(duration);

const DEFAULT_COLOUR_BOUNDS = {
  green: dayjs.duration(15, 'm').asMilliseconds(), // Green up to 15 mins
  orange: dayjs.duration(60, 'm').asMilliseconds(), // Orange up to 60 mins
  red: Infinity, // All other values red
} as Record<string, number>;

const never = <FormattedMessage defaultMessage="Never" />;

type Props = {
  timestamp?: string | null;
  defaultText?: string; // Text to display if time is undefined
};

// Displays a time difference as a small abbreviated badge e.g. 4s or 12m or 6d
const TimeBadge: React.FC<Props> = ({ timestamp, defaultText }) => {
  const [now, setNow] = useState(dayjs());
  const time = dayjs(timestamp);

  // Update the current time once per second, whilst the component is mounted
  useEffect(() => {
    const interval = setInterval(() => setNow(dayjs()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isNil(timestamp)) {
    return (
      <Badge
        text={defaultText || never}
        showZero
        color="grey"
        style={{ color: 'inherit' }}
      />
    );
  }

  const ms = Math.abs(now.diff(time));
  const seconds = Math.abs(now.diff(time, 'second'));
  const minutes = Math.abs(now.diff(time, 'minute'));
  const hours = Math.abs(now.diff(time, 'hour'));
  const days = Math.abs(now.diff(time, 'day'));
  let color = 'grey';
  let text = defaultText || 'Never';

  Object.keys(DEFAULT_COLOUR_BOUNDS)
    .reverse()
    .forEach((c) => {
      if (ms < DEFAULT_COLOUR_BOUNDS[c]) color = c;
    });

  if (ms < 1000) {
    text = '< 1s';
  } else if (seconds < 60) {
    text = `${seconds}s`;
  } else if (minutes < 60) {
    text = `${minutes}m`;
  } else if (hours < 24) {
    text = `${hours}h`;
  } else {
    text = `${days}d`;
  }

  return (
    <Badge text={text} showZero color={color} style={{ color: 'inherit' }} />
  );
};

export default TimeBadge;
