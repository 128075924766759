import { Result } from 'antd';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import AnimatedRoutes from 'components/AnimatedRoutes';
import LocationGroup from 'components/App/components/LocationGroup';
import { Flag } from 'utilities/hooks/useFlags';

import Devices from 'views/Devices';
import Home from 'views/Home';
import Insights from 'views/Insights';
import Map from 'views/Map';
import Pdf from 'views/Pdf';
import Settings from 'views/Settings';

import { Routes } from './Routes';

export const flagToUrlMapping: Partial<Record<Flag, Routes>> = {
  [Flag.MAP_TAB]: Routes.MAP,
  [Flag.INSIGHTS_TAB]: Routes.INSIGHTS,
  [Flag.DEVICES_TAB]: Routes.DEVICES,
  [Flag.SETTINGS_TAB]: Routes.SETTINGS,
  [Flag.HOME_TAB]: Routes.HOME,
};

const routeList = [
  { url: Routes.MAP, component: Map },
  { url: Routes.INSIGHTS, component: Insights },
  { url: Routes.DEVICES, component: Devices },
  { url: Routes.SETTINGS, component: Settings },
  { url: Routes.PDF, component: Pdf },
  { url: Routes.HOME, component: Home },
];

type Props = {
  availableRoutes: Routes[];
  restrictRoutes: boolean;
};

const Routing: React.FC<Props> = ({ availableRoutes, restrictRoutes }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const basePath = useMemo(
    () => (pathname !== '/' ? `${pathname.split('/')[1]}` : pathname) as Routes,
    [pathname],
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (
      restrictRoutes &&
      availableRoutes.length > 0 &&
      !availableRoutes.includes(basePath)
    ) {
      navigate(availableRoutes[0]);
    }
  }, [availableRoutes, navigate, basePath, restrictRoutes]);

  return (
    <>
      {[
        Routes.HOME.toString(),
        Routes.DEVICES.toString(),
        Routes.MAP.toString(),
      ].includes(basePath) && <LocationGroup />}
      <AnimatedRoutes pathLevelWatch={1}>
        {routeList
          .filter(
            ({ url }) => !restrictRoutes || availableRoutes?.includes(url),
          )
          .map(({ url, component: View }) => (
            <Route path={`${url}/*`} key={url} element={<View />} />
          ))}
        <Route
          path="*"
          element={
            <Result
              status="404"
              title={<FormattedMessage defaultMessage="Page not found." />}
              subTitle={
                <FormattedMessage defaultMessage="Please contact your system administrator." />
              }
            />
          }
        />
      </AnimatedRoutes>
    </>
  );
};

export default Routing;
