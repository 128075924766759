import { Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineZoomOut } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';
import { MapContext, ZOOM_DELTA } from '../../LeafletMap';

const ZoomOutButton: React.FC = () => {
  const intl = useIntl();
  const map = useMap();
  const mapContext = useContext(MapContext);
  const [isDisabled, setIsDisabled] = useState(true);

  const updateZoom = () => {
    if (!map) return;
    setIsDisabled(map.getZoom() - ZOOM_DELTA >= map.getMinZoom());
  };

  useEffect(() => {
    if (!map) return () => undefined;

    map.on('zoomend', updateZoom);
    return () => {
      map.off('zoomend', updateZoom);
    };
  }, [map]);

  useEffect(() => {
    if (!mapContext.maxZoom) return;
    updateZoom();
  }, [mapContext.maxZoom]);

  const handleZoomOut = () => {
    if (!map) return;
    map.zoomOut(ZOOM_DELTA);
  };

  return (
    <>
      <Button
        data-testid="zoom-out-button"
        disabled={!isDisabled}
        onClick={handleZoomOut}
        aria-label={intl.formatMessage({
          defaultMessage: 'Zoom out',
        })}
        alt-text={intl.formatMessage({
          defaultMessage: 'Button to zoom out on map',
        })}
      >
        <AiOutlineZoomOut />
      </Button>
    </>
  );
};

export default ZoomOutButton;
