import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Meta } from 'api/meta';
import { SpaceNode, SpaceNodeCollection, SpaceNodeSystemMeta } from 'api/space';

import { LocationTraySize } from 'components/LocationTray';
import Empty from 'components/ui/atoms/Empty';

import SpaceCard from '../../../../components/SpaceCard';
import { appendRecentlySelectedBuildingId } from '../../utils';

import styles from './styles.module.scss';

type Props = {
  filterBy: string;
  buildings: SpaceNodeCollection;
  onBuildingSelect: (buildingId: string) => void;
  onResultsCountChanged: (count: number) => void;
  traySize: LocationTraySize;
};

const filterFn =
  (filterString: string) =>
  ({ meta, systemMeta }: { meta: Meta; systemMeta: SpaceNodeSystemMeta }) =>
    meta.name.toLowerCase().includes(filterString) ||
    systemMeta.streetAddress?.toLowerCase().includes(filterString);

const BuildingCollection: React.FC<Props> = ({
  filterBy = '',
  buildings,
  onBuildingSelect,
  onResultsCountChanged,
}) => {
  const [filteredItems, setFilteredItems] = useState<SpaceNode[]>([]);

  // apply filter
  useEffect(() => {
    const buildingList = Object.values(buildings);

    if (filterBy.length > 0) {
      const filtered = buildingList.filter(filterFn(filterBy));
      setFilteredItems(filtered);
    } else {
      setFilteredItems(
        buildingList.sort((a, b) => a.meta.name.localeCompare(b.meta.name)),
      );
    }
  }, [filterBy, buildings]);

  useEffect(() => {
    onResultsCountChanged(filteredItems.length);
  }, [filteredItems]);

  const handleOnClick = (buildingId: string) => {
    appendRecentlySelectedBuildingId(buildingId);
    onBuildingSelect(buildingId);
  };

  return (
    <div data-testid="building-collection" className={styles.container}>
      {filteredItems.length > 0 &&
        filteredItems.map((building) => (
          <SpaceCard
            key={building.id}
            className={styles.spaceCard}
            onClick={handleOnClick}
            spaceNode={building}
          />
        ))}
      {filteredItems.length === 0 && (
        <Empty
          labelClassName={styles.emptyLabel}
          description={<FormattedMessage defaultMessage="No buildings found" />}
        />
      )}
    </div>
  );
};

export default BuildingCollection;
