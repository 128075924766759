import { Button, Empty } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'views/Devices/components/Routing/Routes';

type Props = {
  activeLocationId?: string;
  openLocationTray: (payload: boolean) => void;
};

export const EmptyMessage: React.FC<Props> = ({
  activeLocationId,
  openLocationTray,
}) => {
  const navigate = useNavigate();
  let emptyText = (
    <FormattedMessage defaultMessage="No devices in selected location." />
  );
  let buttonText = <FormattedMessage defaultMessage="Add  device" />;
  let onClickAction = () => navigate(Routes.ADD);

  if (typeof activeLocationId === 'undefined' || activeLocationId === '') {
    emptyText = (
      <FormattedMessage defaultMessage="Please select a location to see your devices." />
    );
    buttonText = <FormattedMessage defaultMessage="Select location" />;
    onClickAction = () => openLocationTray(true);
  }

  return (
    <Empty description={emptyText}>
      <Button
        data-testid="button-add"
        type="primary"
        shape="round"
        onClick={onClickAction}
      >
        {buttonText}
      </Button>
    </Empty>
  );
};

export default EmptyMessage;
