import { connect } from 'react-redux';
import {
  setShowGhostFloors,
  setFloorsView,
  LocationTrayFloorViews,
} from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  space: {
    locationTray: { showGhostFloors, floorsView },
  },
}: RootState) => ({ showGhostFloors, floorsView });

const mapDispatchToProps = (dispatch: any) => {
  return {
    setShowGhostFloors: (showGhostFloors: boolean) => {
      dispatch(setShowGhostFloors(showGhostFloors));
    },
    setFloorsView: (floorsView: LocationTrayFloorViews) => {
      dispatch(setFloorsView(floorsView));
    },
  };
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
