import React from 'react';
import Routing from './components/Routing';

const ApiKey: React.FC = () => {
  return (
    <div data-testid="api-key">
      <Routing />
    </div>
  );
};

export default ApiKey;
