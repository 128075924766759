import React from 'react';
import { FaWeight } from 'react-icons/fa';
import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/AnimatedRoutes';
import { Routes as RootRoutes } from 'components/Routing/Routes';

import DeviceCandidateList from 'views/Devices/views/Provision/views/DeviceCandidateList';
import DeviceCandidateModal from 'views/Devices/views/Provision/views/DeviceCandidateModal';
import ImporterList from 'views/Devices/views/Provision/views/ImporterList';

import { Routes } from './Routes';
import styles from './styles.module.scss';

export const getFullPath = (route: string, absolute = false): string => {
  let fullPath = `/${Routes.DEVICE_ADD}`;

  if (route === Routes.CANDIDATE_LIST) fullPath += `/${Routes.CANDIDATE_LIST}`;
  else if (route === Routes.CANDIDATE_PROVISION)
    fullPath += `/${Routes.CANDIDATE_LIST}/${Routes.CANDIDATE_PROVISION}`;

  if (absolute) fullPath = `/${RootRoutes.DEVICES}${fullPath}`;

  return fullPath;
};

export const Ico = () => <FaWeight size="30px" className={styles.ico} />;

const Routing: React.FC = () => {
  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route index element={<ImporterList />} />
      <Route
        path={`${Routes.CANDIDATE_LIST}/*`}
        element={<DeviceCandidateList />}
      >
        <Route
          path={Routes.CANDIDATE_PROVISION}
          element={<DeviceCandidateModal />}
        />
      </Route>
    </AnimatedRoutes>
  );
};

export default Routing;
