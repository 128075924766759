import { Button, Space, Typography, Result, Row, Tooltip } from 'antd';
import { isEmpty, uniqWith, isEqual, snakeCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  ConfigurationDefinition,
  getDefinitions,
  useGetConfigurationListQuery,
  useGetDefinitionsQuery,
} from 'api/configuration';
import {
  DataSource,
  DataSourceImporterType,
  DataSourceLifecycle,
  GetDataSourceListPayload,
  useLazyGetDataSourceListQuery,
} from 'api/dataSource';
import { useGetProductListQuery } from 'api/product';
import { Routes as RootRoutes } from 'components/Routing/Routes';

import Figure from 'components/ui/atoms/Figure';
import Card from 'components/ui/molecules/Card';
import Search from 'components/ui/molecules/Search';
import Table from 'components/ui/molecules/Table';
import Background from 'components/ui/organisms/Background';

import { getAttributeKeyTitle } from 'utilities/product';
import {
  getTableSkeleton,
  sortByParam,
  startsWithVowel,
} from 'utilities/utilities';
import { Routes } from 'views/Devices/views/Provision/components/Routing/Routes';
import { getFullPath as getSettingsFullPath } from 'views/Settings/views/Importer/components/Routing';

import { getColumns, DeviceCandidateListRow } from './config';
import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
  orgId?: string;
};

const DeviceCandidateList: React.FC<Props> = ({ orgId }) => {
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const intl = useIntl();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const importerName = searchParams.get('importerName') || undefined;

  const [isLoading, setIsLoading] = useState(false);
  const [candidates, setCandidates] = useState<DataSource[]>([]);
  const [activeRow, setActiveRow] = useState<
    DeviceCandidateListRow | undefined
  >();
  const [data, setData] = useState<DeviceCandidateListRow[]>([]);
  const [filteredData, setFilteredData] = useState<DeviceCandidateListRow[]>(
    [],
  );
  const [activeImporter, setActiveImporter] =
    useState<ConfigurationDefinition>();
  const [attrColumns, setAttrColumns] = useState<string[][]>([]);
  const [configurations, setConfigurations] = useState(-1);

  const [tableSkeleton, setTableSkeleton] = useState<DeviceCandidateListRow[]>(
    getTableSkeleton(['created'], 3),
  );
  const [getDataSourceList] = useLazyGetDataSourceListQuery();
  const definitionsQuery = useGetDefinitionsQuery({});
  const configurationList = useGetConfigurationListQuery(
    { importerName: [importerName as string] },
    {
      skip: !importerName || !Object.keys(definitionsQuery.data || {}).length,
    },
  );

  // When the active importer changes, fetch the associated products
  const { data: products } = useGetProductListQuery(
    activeImporter?.associatedProducts,
    {
      skip: !activeImporter?.associatedProducts.length,
    },
  );

  const goTo = (params: any) => {
    setConfigurations(-1);
    navigate(params);
  };

  // set configurations
  useEffect(() => {
    if (configurationList.isSuccess) {
      setConfigurations(configurationList.data.length);
    }
  }, [configurationList]);

  // If importer name changes, update the active importer
  useEffect(() => {
    if (!importerName) {
      goTo({ pathname: Routes.DEVICE_ADD });
      return;
    }

    if (
      definitionsQuery.isSuccess &&
      Object.keys(definitionsQuery.data).length
    ) {
      setActiveImporter(definitionsQuery.data[importerName]);
    } else {
      getDefinitions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importerName, definitionsQuery]);

  // When the products change, update the columns
  useEffect(() => {
    if (!products) return;

    if (Object.keys(products).length > 0) {
      const columns = Object.values(products)
        .map((product) =>
          product.displayAttributes.map(({ name, attributeKey }) => [
            attributeKey,
            name,
          ]),
        )
        .reduce((prev, next) => prev.concat(next), []);

      setAttrColumns(uniqWith(columns, isEqual));
    } else {
      setAttrColumns([['name', 'Name']]);
    }
  }, [products]);

  // When the columns change, update the table skeleton
  useEffect(() => {
    setTableSkeleton(
      getTableSkeleton([...attrColumns.map(([attr]) => attr), 'created'], 3),
    );
  }, [attrColumns]);

  const handleOnClick = (): void => {
    if (importerName && typeof activeRow !== 'undefined') {
      navigate({
        pathname: Routes.CANDIDATE_PROVISION.replace(
          ':deviceId',
          activeRow.deviceId,
        ),
        search: createSearchParams({ importerName }).toString(),
      });
    }
  };

  useEffect(() => {
    const params: GetDataSourceListPayload = {
      importerName: importerName
        ? (snakeCase(importerName) as DataSourceImporterType)
        : undefined,
      orgId,
      lifecycle: DataSourceLifecycle.CANDIDATE,
    };
    setIsLoading(true);
    getDataSourceList(params)
      .unwrap()
      .then(setCandidates)
      .catch(() => setCandidates([]))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // map candidates to table data
  useEffect(() => {
    if (!isLoading) {
      if (candidates.length === 0) {
        setData([]);
        setFilteredData([]);
      } else if (candidates.length) {
        try {
          const formattedData = candidates
            .map(
              ({
                id,
                productId,
                attributes,
                meta: { name, created },
                systemMeta: { importerName: iName },
              }) => ({
                key: id,
                created,
                importerName: iName,
                productId,
                dataSearch: attrColumns.map(
                  ([attributeKey]) =>
                    attributes[attributeKey]?.toLowerCase() || '',
                ),
                ...Object.fromEntries(
                  attrColumns.map(([attributeKey]) => [
                    attributeKey,
                    attributes[attributeKey] || '',
                  ]),
                ),
                // Needs to be last so it doesn't get wiped by attributes
                deviceId: id,
                name,
              }),
            )
            .sort(sortByParam('key')) as DeviceCandidateListRow[];

          setData(formattedData);
          setFilteredData(formattedData);
        } catch (err) {
          setData([]);
          setFilteredData([]);
        }
      }
    }
  }, [candidates, products, isLoading, attrColumns]);

  const onRow = (record: DeviceCandidateListRow) => {
    return {
      onClick: () => {
        setActiveRow(activeRow?.key === record.key ? undefined : record);
      },
    };
  };

  const rowClassName = (record: Record<string, unknown>) => {
    return activeRow && record.key === activeRow.key ? 'isActive' : '';
  };

  const handleOnSearch = (cleanValue: string) => {
    const filteredList =
      cleanValue.length === 0
        ? data
        : data.filter(({ key, dataSearch }) => {
            const findItem = dataSearch.find((phrase) =>
              phrase.includes(cleanValue),
            );

            if (!findItem && key === activeRow?.key) {
              setActiveRow(undefined);
            }

            return findItem;
          });

    setFilteredData(filteredList);
  };

  const labelSpecificItems = attrColumns?.map(([columnKey, columnName]) =>
    getAttributeKeyTitle(columnKey, columnName),
  );

  const labelSearch = intl.formatMessage(
    {
      defaultMessage: 'Filter by {elements}',
    },
    {
      elements: intl.formatList(labelSpecificItems, {
        type: 'disjunction',
      }),
    },
  );

  return (
    <Background>
      <Card>
        {typeof activeImporter !== 'undefined' ? (
          <>
            <Figure
              type="minus"
              size="3vw"
              position={{ left: '5vw', top: '30vh' }}
            />
            <Figure
              position={{ top: '8vh', right: '15vw' }}
              size="3vw"
              opacity={30}
            />
            <Figure
              position={{ bottom: '15vh', right: '10vw' }}
              size="3vw"
              type="minus"
            />
            <div className={styles.contentWrap}>
              <Title level={2}>
                <FormattedMessage
                  defaultMessage="Provision {vowel, select, true {an} other {a}} {name} device"
                  values={{
                    name: activeImporter?.name ?? '',
                    vowel: startsWithVowel(activeImporter?.name),
                  }}
                />
              </Title>
              <Space direction="vertical" size="large" className={styles.space}>
                <Search
                  onChange={handleOnSearch}
                  placeholder={labelSearch}
                  className={styles.search}
                />
                <Card className={styles.card}>
                  <Table<DeviceCandidateListRow>
                    data={!isLoading ? filteredData : tableSkeleton}
                    columns={getColumns(attrColumns)}
                    onRow={onRow}
                    rowClassName={rowClassName}
                    autoScroll={true}
                  />
                </Card>
                <Row justify="space-between">
                  <Space size="middle">
                    <Button
                      key="add"
                      type="primary"
                      shape="round"
                      onClick={handleOnClick}
                      disabled={isEmpty(activeRow)}
                    >
                      <FormattedMessage defaultMessage="Set up device" />
                    </Button>
                    <Button
                      key="backToList"
                      type="default"
                      shape="round"
                      onClick={() =>
                        goTo({
                          pathname: `/${RootRoutes.DEVICES}/${Routes.DEVICE_ADD}`,
                        })
                      }
                    >
                      <FormattedMessage defaultMessage="Back" />
                    </Button>
                  </Space>
                  <Tooltip
                    title={
                      <FormattedMessage
                        values={{
                          count: configurations >= 0 ? configurations : 0,
                        }}
                        defaultMessage="You have {count, plural, one {# active connector} other {# active connectors}} of this type."
                      />
                    }
                    open={
                      configurations === -1
                        ? false
                        : configurations === 0 || undefined
                    }
                  >
                    <Button
                      type={configurations === 0 ? 'primary' : 'dashed'}
                      onClick={() =>
                        goTo({
                          pathname: getSettingsFullPath(null, true),
                          search: createSearchParams({
                            importerName: importerName || '',
                          }).toString(),
                        })
                      }
                    >
                      <FormattedMessage defaultMessage="Manage Connectors" />
                    </Button>
                  </Tooltip>
                </Row>
              </Space>
            </div>
          </>
        ) : (
          <Result
            status="warning"
            title={
              <FormattedMessage defaultMessage="Connector not recognised." />
            }
            extra={
              <Button
                onClick={() =>
                  goTo({
                    pathname: `/${RootRoutes.DEVICES}/${Routes.DEVICE_ADD}`,
                  })
                }
              >
                <FormattedMessage defaultMessage="Go Back" />
              </Button>
            }
          />
        )}
      </Card>
      <Outlet />
    </Background>
  );
};

export default DeviceCandidateList;
