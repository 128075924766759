import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import Empty from 'components/ui/atoms/Empty';
import ReportTypes from 'views/Insights/views/ReportTypes';

export const Report: React.FC = () => {
  const { reportId } = useParams<'reportId'>();

  if (!reportId) {
    return (
      <Empty
        description={<FormattedMessage defaultMessage="Report not found" />}
      />
    );
  }

  return <ReportTypes reportId={reportId} />;
};

export default Report;
