/* eslint-disable react/no-danger */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalStyles from 'styles/global.module.scss';
import styles from './styles.module.scss';

export type Props = {
  label: string;
  value?: string;
  color?: string;
  unit: string;
};

const LiquidFillGauge: React.FC<Props> = ({
  label,
  unit,
  value,
  color = globalStyles.colorNoData,
}) => {
  const displayValue =
    typeof value === 'undefined' ? (
      <FormattedMessage defaultMessage="No data" />
    ) : (
      value
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.bubbleBackgroundWrapper}>
        <svg viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            data-testid="liquidfillgauge-ciricle"
            cx="46.5"
            cy="46.5"
            r="46"
            stroke={color}
          />
          <mask
            id="a"
            style={{ maskType: 'alpha' } as React.CSSProperties}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="93"
            height="93"
          >
            <circle cx="46.5" cy="46.5" r="45.3" fill="#fff" stroke="#fff" />
          </mask>
          <g mask="url(#a)" fill={color}>
            <path
              opacity=".1"
              d="M117 24s-16-10-42-1-88 6-88 6l7 90h117l6-95Z"
            />
            <path
              opacity=".3"
              d="M-17 38s39-25 65-16c27 9 59 5 59 5v95H-50l2-76 31-8Z"
            />
            <path
              opacity=".8"
              d="M-12 29s31-8 58 1c26 9 66-10 66-10v103H-6l-6-94Z"
            />
          </g>
        </svg>
      </div>
      <div className={styles.value}>
        <span>{displayValue}</span>
        {value && <span dangerouslySetInnerHTML={{ __html: unit }} />}
      </div>
      <div
        className={styles.label}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </div>
  );
};

export default LiquidFillGauge;
