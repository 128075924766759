export type TelemetryPropertyParams = {
  decimals: number;
};

export type TelemetryPropertyConfig = Record<string, TelemetryPropertyParams>;

export const telemetryPropertyConfig: TelemetryPropertyConfig = {
  temperature: {
    decimals: 1,
  },
  pm10: {
    decimals: 1,
  },
  'pm2.5': {
    decimals: 1,
  },
  humidity: {
    decimals: 0,
  },
  light: {
    decimals: 0,
  },
  sound: {
    decimals: 0,
  },
  sound_pressure: {
    decimals: 0,
  },
  co2: {
    decimals: 0,
  },
  voc: {
    decimals: 0,
  },
};
