import { Button, Typography } from 'antd';
import cn from 'classnames';
import { useEffect, useRef, useState, ReactElement } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
  allowedFileTypesLabel: ReactElement;
  fileSizeLimitInMB: number;
};

const UploadArea: React.FC<Props> = ({
  allowedFileTypesLabel,
  fileSizeLimitInMB,
}) => {
  const [isDragOver, setDragOver] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapperElem = wrapperRef.current;
    if (!wrapperElem) return () => undefined;

    const handleDragOver = (): void => setDragOver(true);
    const handleDragLeave = (): void => setDragOver(false);

    wrapperElem.addEventListener('dragover', handleDragOver);
    wrapperElem.addEventListener('dragleave', handleDragLeave);

    return () => {
      wrapperElem.removeEventListener('dragover', handleDragOver);
      wrapperElem.removeEventListener('dragleave', handleDragLeave);
    };
  }, [wrapperRef]);

  return (
    <div
      ref={wrapperRef}
      className={cn(styles.wrapper, {
        [styles.active]: isDragOver,
      })}
      data-testid="upload-area"
    >
      <FiUploadCloud className={styles.icon} />

      <Title className={styles.title} level={5}>
        <FormattedMessage defaultMessage="Select a file or drag and drop here" />
      </Title>

      <Title level={5}>
        <FormattedMessage
          defaultMessage="{fileTypes} ({fileSize}MB size limit)"
          values={{
            fileTypes: allowedFileTypesLabel,
            fileSize: fileSizeLimitInMB,
          }}
        />
      </Title>

      <Button
        type="primary"
        data-testid="upload-button"
        className={styles.button}
      >
        <FormattedMessage defaultMessage="Select file" />
      </Button>
    </div>
  );
};

export default UploadArea;
