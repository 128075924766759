import Icon from '@ant-design/icons';
import { Popover, Button } from 'antd';
import React from 'react';

import { useIntl } from 'react-intl';
import { ReactComponent as Buildings } from 'assets/svg/buildings.svg';

import LocationTray from 'components/LocationTray';

import { LocationSelectorTray } from 'ducks/space/slice';

import styles from './styles.module.scss';

type Props = {
  isTrayOpen: boolean;
  openLocationTray: (payload: boolean) => void;
  onActiveLocationIdChanged: (spaceId: string) => void;
};

const LocationGroup: React.FC<Props> = ({
  isTrayOpen,
  openLocationTray,
  onActiveLocationIdChanged,
}) => {
  const intl = useIntl();
  return (
    <div data-testid="location-group-wrapper">
      <Popover
        placement="rightBottom"
        content={
          <LocationTray
            locationSelectorTray={LocationSelectorTray.MAIN}
            onActiveLocationIdChanged={onActiveLocationIdChanged}
          />
        }
        trigger="click"
        open={isTrayOpen}
        onOpenChange={(visible) => openLocationTray(visible)}
        overlayClassName={styles.selectorPopover}
      >
        <Button
          className={styles.button}
          data-testid="location-button"
          aria-label="location-selector-button"
          alt-text={intl.formatMessage({
            defaultMessage:
              'Button for location selector modal to edit buildings, floors, rooms and spaces',
          })}
        >
          <Icon
            component={Buildings}
            className={styles.icon}
            aria-label="location-selector-button-icon"
            alt-text={intl.formatMessage({
              defaultMessage:
                'Building icon for location selector modal to edit buildings, floors, rooms and spaces',
            })}
          />
        </Button>
      </Popover>
    </div>
  );
};

export default LocationGroup;
