import { FormattedMessage } from 'react-intl';
import { baseApi } from 'api/api';
import { getIntl } from 'components/Intl';
import {
  getReportTemplateToLabelMap as getReportTemplateToLabelMapIAQ,
  IAQReportTemplate,
  IAQScoreBand,
} from './reportIAQ';
import { ReportTag } from './tags';

export enum Endpoint {
  GetReportConfigurations = '/report/configuration',
  CreateReportConfiguration = '/report/configuration',
  UpdateReportConfiguration = '/report/configuration/:reportConfigurationId',
  DeleteReportConfiguration = '/report/configuration/:reportConfigurationId',
  GenerateReport = '/report/configuration/:reportId/generate',
  GetReports = '/report',
  GetReport = '/report/:reportId',
}

export enum ReportType {
  IndoorAirQuality = 'indoor_air_quality',
}

export const getReportTypeToLabelMap = (reportType: ReportType): string =>
  ({
    [ReportType.IndoorAirQuality]: getIntl().formatMessage({
      defaultMessage: 'Indoor Air Quality',
    }),
  }[reportType] || reportType);

export enum Schedule {
  Weekly = 'week',
  Monthly = 'month',
}

export const ScheduleToLabelMap: Record<Schedule, React.ReactChild> = {
  [Schedule.Weekly]: <FormattedMessage defaultMessage="Weekly" />,
  [Schedule.Monthly]: <FormattedMessage defaultMessage="Monthly" />,
};

export const getReportTypeToColorMap = (reportType: ReportType): string =>
  ({
    [ReportType.IndoorAirQuality]: '#00bcd4',
  }[reportType] || '');

export type ReportTemplate = IAQReportTemplate;

export const getReportTemplates = (reportType: ReportType): ReportTemplate[] =>
  ({
    [ReportType.IndoorAirQuality]: Object.values(IAQReportTemplate),
  }[reportType] || []);

export const getReportTemplateToLabelMap = (
  reportType: ReportType,
  reportTemplate: ReportTemplate,
): string =>
  ({
    [ReportType.IndoorAirQuality]: getReportTemplateToLabelMapIAQ,
  }[reportType](reportTemplate) || reportTemplate);

export type ScoreBand = IAQScoreBand;

export const getDefaultReportTypeScoreBand = (
  reportType: ReportType,
): ScoreBand =>
  ({
    [ReportType.IndoorAirQuality]: IAQScoreBand.Standard,
  }[reportType]);

export type Report = {
  name: string;
  id: string;
  orgId: string;
  reportType: ReportType;
  created: string;
  content: {
    address: string;
    template: ReportTemplate;
    telemetryProperties: string[];
    period: Schedule;
    overallScore: number;
    dailyScores: {
      [date: string]: number | undefined;
    };
    summary: {
      overallScore: number;
      highestDayScore: {
        value: number;
        timestamps: string[];
      };
      bestPerformingParameter: {
        value: number;
        healthScore: number;
        category: string;
      };
      worstPerformingParameter: {
        value: number;
        healthScore: number;
        category: string;
      };
    };
    workingRange: {
      [key: string]: {
        start: number;
        end: number;
      };
    };
    scoreBands: {
      [key: string]: {
        [key: string]: string;
      };
    };
    telemetryPropertiesNameAndUnit: {
      [key: string]: {
        name: string;
        unit: string;
      };
    };
    averageValues: {
      [key: string]: {
        mean: number;
        healthScore?: number;
      };
    };
    parameterBreakdown: {
      [key: string]: {
        healthScore: number;
        label?: string;
        value?: number;
      }[];
    };
    hourlyParameterBreakdown: {
      [key: string]: {
        healthScore?: number;
        value: number;
        segments: {
          mean: number;
          timestamp: string;
          healthScore: number;
          numberOfValues: number;
        }[];
      };
    };
  };
  runTime: string;
  start: string;
  end: string;
  dataSinks: string[];
  sourceConfigurationId: string;
  version: number;
};

export type ReportConfigValues = {
  locationId: string;
  period: Schedule;
  template: ReportTemplate;
  scoreBand: ScoreBand;
};

export type ReportConfig = {
  id: string;
  name: string;
  description: string;
  orgId: string;
  reportType: ReportType;
  enabled: boolean;
  lastRan: string;
  emails: string[];
  unsubscribed: string[];
  values: ReportConfigValues;
};

export type CreateReportConfigPayload = Omit<
  ReportConfig,
  'id' | 'unsubscribed' | 'enabled' | 'lastRan'
>;

export type GetReportConfigsResponse = ReportConfig[];
export type CreateReportConfigResponse = ReportConfig;
export type EditReportConfigPayload = Pick<
  ReportConfig,
  'name' | 'description' | 'emails' | 'values'
>;

export type GetGeneratedReportsByReportConfigIdResponse = Report;

export const reportApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getReportConfigurations(orgId)
    getReportConfigurations: build.query<
      GetReportConfigsResponse,
      string | void
    >({
      query: (orgId) => ({
        url: Endpoint.GetReportConfigurations,
        options: {
          method: 'GET',
          params: {
            orgId,
          },
        },
      }),
      providesTags: [ReportTag.ConfigurationList],
    }),
    // * getReportConfiguration(reportConfigurationId)
    getReportConfiguration: build.query<ReportConfig | undefined, string>({
      query: (reportConfigurationId) => ({
        url: Endpoint.GetReportConfigurations,
        options: {
          method: 'GET',
          params: {
            reportConfigurationId,
          },
        },
      }),
      transformResponse: (
        response: GetReportConfigsResponse,
        _,
        reportConfigurationId,
      ) => {
        if (!response) return undefined;
        return response.find((report) => report.id === reportConfigurationId);
      },
      providesTags: [ReportTag.ConfigurationList],
    }),
    // * createReportConfiguration(report)
    createReportConfiguration: build.mutation<
      CreateReportConfigResponse,
      CreateReportConfigPayload
    >({
      query: (report) => ({
        url: Endpoint.CreateReportConfiguration,
        options: {
          method: 'POST',
          data: report,
        },
      }),
      invalidatesTags: [ReportTag.ConfigurationList],
    }),
    // * updateReportConfiguration({ reportConfigurationId, data })
    updateReportConfiguration: build.mutation<
      CreateReportConfigResponse,
      { reportConfigurationId: string; data: EditReportConfigPayload }
    >({
      query: ({ reportConfigurationId, data }) => ({
        url: Endpoint.UpdateReportConfiguration.replace(
          ':reportConfigurationId',
          reportConfigurationId,
        ),
        options: {
          method: 'PATCH',
          data,
        },
      }),
      invalidatesTags: [ReportTag.ConfigurationList],
    }),
    // * deleteReportConfiguration(reportConfigurationId)
    deleteReportConfiguration: build.mutation<void, string>({
      query: (reportConfigurationId) => ({
        url: Endpoint.DeleteReportConfiguration.replace(
          ':reportConfigurationId',
          reportConfigurationId,
        ),
        options: {
          method: 'DELETE',
        },
      }),
      invalidatesTags: [ReportTag.ConfigurationList],
    }),
    // * getGeneratedReportsByReportConfigId({ orgId, reportConfigurationId })
    getGeneratedReportsByReportConfigId: build.query<
      GetGeneratedReportsByReportConfigIdResponse[],
      { orgId: string; reportConfigurationId: string }
    >({
      query: ({ orgId, reportConfigurationId }) => ({
        url: Endpoint.GetReports,
        options: {
          params: { orgId, reportConfigurationId },
        },
      }),
      providesTags: [ReportTag.ReportList],
    }),
    // * getReport(reportId)
    getReport: build.query<Report, string>({
      query: (reportId) => ({
        url: Endpoint.GetReport.replace(':reportId', reportId),
        options: {
          method: 'GET',
        },
      }),
      providesTags: [ReportTag.ReportList],
    }),
    // * generateReport(reportConfigurationId)
    generateReport: build.mutation<Report, string>({
      query: (reportConfigurationId) => ({
        url: Endpoint.GenerateReport.replace(
          ':reportId',
          reportConfigurationId,
        ),
        options: {
          method: 'POST',
        },
      }),
      invalidatesTags: [ReportTag.ReportList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportConfigurationsQuery,
  useGetReportConfigurationQuery,
  useGetGeneratedReportsByReportConfigIdQuery,
  useCreateReportConfigurationMutation,
  useUpdateReportConfigurationMutation,
  useDeleteReportConfigurationMutation,
  useGetReportQuery,
  useGenerateReportMutation,
} = reportApi;
