import { getIntl } from 'components/Intl';
import globalStyles from 'styles/global.module.scss';

export const scoreIndexToColor = (scoreIndex: number): string =>
  ({
    5: '#34C759', // green - very good score
    4: '#35DC99', // light green - good score
    3: '#FFBD2E', // yellow - medium score
    2: '#FF5F57', // red - bad score
    1: '#DA4842', // dark red - very bad score
  }[scoreIndex] || globalStyles.colorNoData);

export const scoreIndexToLabel = (scoreIndex: number): string => {
  const intl = getIntl();

  return (
    {
      5: intl.formatMessage({ defaultMessage: 'very good' }),
      4: intl.formatMessage({ defaultMessage: 'good' }),
      3: intl.formatMessage({ defaultMessage: 'medium' }),
      2: intl.formatMessage({ defaultMessage: 'bad' }),
      1: intl.formatMessage({ defaultMessage: 'very bad' }),
    }[scoreIndex] || ''
  );
};
