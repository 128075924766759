import { Space } from 'antd';
import React from 'react';
import { scoreIndexToColor } from 'api/telemetryProperty';
import LiquidFillGauge, {
  Props as BubbleProps,
} from 'components/ui/atoms/LiquidFillGauge';

export type MappedBubbleProps = Omit<BubbleProps, 'color'> & {
  scoreIndex: number;
};

type Props = {
  bubbles: MappedBubbleProps[];
};

const LiquidFillGaugeCollection: React.FC<Props> = ({ bubbles }) => {
  return (
    <Space size="middle">
      {bubbles.map(({ label, value, unit, scoreIndex }) => (
        <LiquidFillGauge
          key={label}
          value={value}
          label={label}
          unit={unit}
          color={scoreIndexToColor(scoreIndex)}
        />
      ))}
    </Space>
  );
};

export default LiquidFillGaugeCollection;
