import { Modal as AntModal } from 'antd';
import cn from 'classnames';
import React, { ReactChild, ReactElement } from 'react';

import styles from './styles.module.scss';

type Props = {
  title?: string | ReactChild;
  visible: boolean;
  footer?: ReactElement[] | null;
  children: ReactChild;
  width?: string | number;
  closable?: boolean;
  disableBackgroundBlur?: boolean;
  backgroundClickable?: boolean;
  centered?: boolean;
  bodyStyle?: React.CSSProperties;
  onCancel?: () => void;
};

const Modal: React.FC<Props> = ({
  title = '',
  visible,
  footer = null,
  children,
  width,
  closable = false,
  disableBackgroundBlur = false,
  backgroundClickable = false,
  centered,
  bodyStyle,
  onCancel,
}) => {
  return (
    <AntModal
      className={styles.modal}
      width={width}
      title={title}
      open={visible}
      mask={!(disableBackgroundBlur || backgroundClickable)}
      wrapClassName={cn({ [styles.backgroundClickable]: backgroundClickable })}
      closable={closable}
      footer={footer}
      centered={centered}
      bodyStyle={bodyStyle}
      onCancel={onCancel}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
