import {
  Button,
  Col,
  Row,
  Typography,
  Affix,
  Empty,
  Popover,
  Badge,
} from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState, Key, useCallback } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import {
  DataSourceImporterType,
  useGetDataSourceListQuery,
} from 'api/dataSource';
import { useGetTelemetryPropertyListQuery } from 'api/telemetryProperty';

import Card from 'components/ui/molecules/Card';
import Search from 'components/ui/molecules/Search';
import Table from 'components/ui/molecules/Table';

import globalStyles from 'styles/global.module.scss';
import { getTableSkeleton } from 'utilities/utilities';
import { NOT_RELEVANT } from 'views/Settings/views/Bms/utilities';

import AssignToSinkModal from './components/AssignToSinkModal';
import Filter, { FilterData } from './components/Filter';
import { getColumns, DataSourceRow, PropertyRow } from './config';
import styles from './styles.module.scss';
import {
  filterByIsLinked,
  filterBySearchString,
  filterByProperty,
} from './utilities';

const { Title } = Typography;
const { div: AnimatedDiv } = motion;

type Props = {
  orgId?: string;
};

const DataSources: React.FC<Props> = ({ orgId }) => {
  const [isAssignToSinkModalVisible, setIsAssignToSinkModalVisible] =
    useState(false);
  const [dataSourceCount, setDataSourceCount] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [filterData, setFilterData] = useState<FilterData>({
    propertyIds: [],
    isLinked: NOT_RELEVANT,
  });
  const [availableProperties, setAvailableProperties] = useState<string[]>([]);
  const [dataSources, setDataSources] = useState<DataSourceRow[]>(
    getTableSkeleton(['name', 'externalId', 'properties', 'linked'], 3),
  );
  const [filteredDataSources, setFilteredDataSources] = useState<
    DataSourceRow[]
  >([]);
  const [selectedDataSources, setSelectedDataSources] = useState<
    DataSourceRow[]
  >([]);
  const [selectedDataSourceKeys, setSelectedDataSourceKeys] = useState<Key[]>(
    [],
  );
  const telemetryPropertyListQuery = useGetTelemetryPropertyListQuery({});
  const dataSourcesListQuery = useGetDataSourceListQuery(
    {
      orgId,
      importerName: DataSourceImporterType.BMS,
    },
    {
      skip:
        !orgId ||
        isAssignToSinkModalVisible ||
        (!telemetryPropertyListQuery.isSuccess &&
          !telemetryPropertyListQuery.data?.length),
    },
  );

  // apply filters
  useEffect(() => {
    setFilteredDataSources(
      dataSources
        .filter(filterBySearchString(searchString))
        .filter(filterByProperty(filterData.propertyIds))
        .filter(filterByIsLinked(filterData.isLinked)),
    );
  }, [dataSources, searchString, filterData]);

  // fetch data sources
  useEffect(() => {
    if (
      !telemetryPropertyListQuery.isSuccess ||
      !dataSourcesListQuery.isSuccess
    )
      return;

    const uniqueProperties = new Set<string>();

    setDataSources(
      dataSourcesListQuery.data.map((item) => {
        const properties: PropertyRow[] = Object.keys(item.properties)
          .map((key) => {
            const property = telemetryPropertyListQuery.data.find(
              ({ category }) => category === key,
            );

            if (typeof property === 'undefined') return undefined;

            uniqueProperties.add(property?.meta?.name || key);
            return {
              propertyId: property.id,
              propertyName: property.meta?.name,
              propertyCategory: property.category,
            };
          })
          .filter((row): row is PropertyRow => Boolean(row));

        return {
          name: item.meta.name,
          searchName: item.meta.name.toLocaleLowerCase(),
          key: item.id,
          externalId: item.externalId,
          properties,
          isLinked: item.feeds?.length > 0,
        };
      }),
    );

    setAvailableProperties(Array.from(uniqueProperties));
    setDataSourceCount(dataSourcesListQuery.data.length);
  }, [dataSourcesListQuery]);

  const getFiltersCount = useCallback(
    () =>
      filterData.propertyIds.length +
      (filterData.isLinked === NOT_RELEVANT ? 0 : 1),
    [filterData],
  );

  const onRowsSelectionChange = (
    selectedRowKeys: Key[],
    selectedRows: DataSourceRow[],
  ) => {
    setSelectedDataSources(selectedRows);
    setSelectedDataSourceKeys(selectedRowKeys);
  };

  const onClickDelete = (dataSourceKey: any) => {
    setSelectedDataSources((currentSelected) =>
      currentSelected.filter(({ key }) => key !== dataSourceKey),
    );
    setSelectedDataSourceKeys((currentKeys) =>
      currentKeys.filter((key) => key !== dataSourceKey),
    );
  };

  const handleOnRow = (record: DataSourceRow) => ({
    onClick: () => {
      if (selectedDataSourceKeys.includes(record.key)) {
        onClickDelete(record.key);
      } else {
        setSelectedDataSources([...selectedDataSources, record]);
        setSelectedDataSourceKeys([...selectedDataSourceKeys, record.key]);
      }
    },
  });

  return (
    <>
      {isAssignToSinkModalVisible && (
        <AssignToSinkModal
          dataSourceIds={selectedDataSources.map(({ key, properties }) => ({
            dataSourceId: key,
            properties,
          }))}
          onClose={() => {
            setSelectedDataSources([]);
            setSelectedDataSourceKeys([]);
            setIsAssignToSinkModalVisible(false);
          }}
        />
      )}
      <Title>
        <FormattedMessage defaultMessage="Data sources management" />
      </Title>
      <Card className={styles.card}>
        <Row align="middle" justify="space-between">
          <Col span={8} className={styles.columnLeft}>
            <Search onChange={setSearchString} />
          </Col>
          <Col span={8} className={styles.columnCenter}>
            <Title level={5}>
              <FormattedMessage
                values={{ count: dataSourceCount }}
                defaultMessage="{count, plural, =0 {no data sources} one {# data source} other {# data sources}}"
              />
            </Title>
          </Col>
          <Col span={8} className={globalStyles.textRight}>
            <Popover
              placement="left"
              content={
                <Filter
                  onFilter={setFilterData}
                  properties={availableProperties}
                />
              }
              title={<FormattedMessage defaultMessage="Filter" />}
              trigger="click"
            >
              <Badge count={getFiltersCount()}>
                <Button
                  shape="round"
                  icon={<IoIosArrowBack />}
                  data-testid="btn-filter"
                >
                  <FormattedMessage defaultMessage="Filter" />
                </Button>
              </Badge>
            </Popover>
          </Col>
        </Row>
        <Table<DataSourceRow>
          rowSelection={{
            type: 'checkbox',
            onChange: onRowsSelectionChange,
            selectedRowKeys: selectedDataSourceKeys,
          }}
          onRow={handleOnRow}
          data={filteredDataSources}
          columns={getColumns()}
          pagination={{ pageSize: 100 }}
          emptyText={
            <Empty
              description={
                searchString.length || getFiltersCount() ? (
                  <FormattedMessage defaultMessage="No data sources found that meets your criteria." />
                ) : (
                  <FormattedMessage defaultMessage="No records." />
                )
              }
            />
          }
        />
      </Card>
      <Card className={styles.card}>
        <Table<DataSourceRow>
          data={selectedDataSources}
          columns={getColumns(onClickDelete)}
          emptyText={
            <FormattedMessage defaultMessage="Please select at least one data source from the above list." />
          }
          pagination={{ pageSize: 100 }}
        />
      </Card>
      <AnimatePresence exitBeforeEnter>
        {selectedDataSourceKeys.length > 0 && (
          <AnimatedDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className={styles.buttonWrapper}>
              <Affix offsetBottom={20}>
                <Button
                  shape="round"
                  type="primary"
                  data-testid="assign-button"
                  onClick={() => setIsAssignToSinkModalVisible(true)}
                >
                  <FormattedMessage
                    values={{ count: selectedDataSourceKeys.length }}
                    defaultMessage="Assign {count, plural, one {# data source} other {# data sources}} to sink"
                  />
                </Button>
              </Affix>
            </div>
          </AnimatedDiv>
        )}
      </AnimatePresence>
    </>
  );
};

export default DataSources;
