import { ComponentType } from 'react';

import { FaTrafficLight } from 'react-icons/fa';
import { MdSettingsInputAntenna } from 'react-icons/md';
import { ReactComponent as AwairIco } from 'assets/svg/products/awair.svg';

export interface ImporterIconParams {
  color: string;
  icon: ComponentType;
  altText: string;
  altLabel: string;
}

export const AWAIR_OMNI_ICON_PARAMS: ImporterIconParams = {
  color: '#ffc2c2',
  icon: AwairIco,
  altText: 'Awair icon',
  altLabel: 'Awair icon',
};

export const DENSITY_TRAFFIC_ICON_PARAMS: ImporterIconParams = {
  color: '#b6c3df',
  icon: FaTrafficLight,
  altText: 'Density icon',
  altLabel: 'Density icon',
};

export const importerNameIconMapping: Record<string, ImporterIconParams> = {
  awairOmni: AWAIR_OMNI_ICON_PARAMS,
  densityTraffic: DENSITY_TRAFFIC_ICON_PARAMS,
};

export const productProviderIconMapping: Record<string, ImporterIconParams> = {
  awair: AWAIR_OMNI_ICON_PARAMS,
  density: DENSITY_TRAFFIC_ICON_PARAMS,
};

const defaultImporterStyle = {
  color: '#ddd',
  icon: MdSettingsInputAntenna,
  altText: 'Icon for connector',
  altLabel: 'Connector icon',
};

export const getImporterStyle = (importerName: string): ImporterIconParams =>
  importerNameIconMapping[importerName] ?? defaultImporterStyle;

export const getProductStyle = (provider: string): ImporterIconParams =>
  productProviderIconMapping[provider] ?? defaultImporterStyle;
