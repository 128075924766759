import { baseApi } from 'api/api';
import { Meta } from 'api/meta';
import { UserTag } from 'api/user/tags';
import { ApiKeyTag } from './tags';

export enum Endpoint {
  GetAPIKeyList = '/api_key',
  CreateAPIKey = '/api_key',
  DeleteAPIKey = '/api_key/:apiKeyId',
  RegenerateAPIKey = '/api_key/:apiKeyId/regenerate',
  UpdateAPIKeyRole = '/api_key/:apiKeyId/roles/:role',
}

export type APIKey = {
  id: string;
  keyId: string;
  serviceAccountId: string;
  serviceAccountEmail: string;
  orgId: string;
  meta: Meta;
};

export type APIKeyWithSecret = {
  key: string;
  keyInfo: APIKey;
};

export type CreateAPIKeyBody = {
  orgId: string;
  meta: Meta;
};

export type GetAPIKeyListResponse = Promise<APIKey[]>;
export type CreateAPIKeyResponse = Promise<APIKeyWithSecret>;
export type RegenerateAPIKeyResponse = Promise<APIKeyWithSecret>;

export const apiKeyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getAPIKeyList(apiKeyIds)
    getAPIKeyList: build.query<APIKey[], string[] | void>({
      query: (apiKeyIds) => ({
        url: Endpoint.GetAPIKeyList,
        options: {
          method: 'GET',
          params: {
            apiKeyId: apiKeyIds,
          },
        },
      }),
      providesTags: [ApiKeyTag.KeyList],
    }),
    // * getAPIKey(apiKeyId)
    getAPIKey: build.query<APIKey | undefined, string>({
      query: (apiKeyId: string) => ({
        url: Endpoint.GetAPIKeyList.replace(':apiKey', apiKeyId),
        options: { method: 'GET' },
      }),
      providesTags: [ApiKeyTag.KeyList],
      transformResponse: (response: APIKey[], _, apiKeyId) =>
        response.find((data) => data.id === apiKeyId),
    }),
    // * createAPIKey(body)
    createAPIKey: build.mutation<APIKeyWithSecret, CreateAPIKeyBody>({
      query: (data) => ({
        url: Endpoint.CreateAPIKey,
        options: {
          method: 'POST',
          data,
        },
      }),
      invalidatesTags: [ApiKeyTag.KeyList],
    }),
    // * deleteAPIKey(apiKeyId)
    deleteAPIKey: build.mutation<void, string>({
      query: (apiKeyId) => ({
        url: Endpoint.DeleteAPIKey.replace(':apiKeyId', apiKeyId),
        options: {
          method: 'DELETE',
        },
      }),
      invalidatesTags: [ApiKeyTag.KeyList],
    }),
    // * regenerateAPIKey(apiKeyId)
    regenerateAPIKey: build.mutation<APIKeyWithSecret, string>({
      query: (apiKeyId) => ({
        url: Endpoint.RegenerateAPIKey.replace(':apiKeyId', apiKeyId),
        options: {
          method: 'POST',
        },
      }),
      invalidatesTags: [ApiKeyTag.KeyList],
    }),
    // * assignRoleToAPIKey({ apiKeyId, role })
    assignRoleToAPIKey: build.mutation<
      void,
      { apiKeyId: string; role: string }
    >({
      query: ({ apiKeyId, role }) => ({
        url: Endpoint.UpdateAPIKeyRole.replace(':apiKeyId', apiKeyId).replace(
          ':role',
          role,
        ),
        options: {
          method: 'POST',
        },
      }),
      invalidatesTags: [UserTag.UserList],
    }),
    // * removeRoleFromAPIKey({ apiKeyId, role })
    removeRoleFromAPIKey: build.mutation<
      void,
      { apiKeyId: string; role: string }
    >({
      query: ({ apiKeyId, role }) => ({
        url: Endpoint.UpdateAPIKeyRole.replace(':apiKeyId', apiKeyId).replace(
          ':role',
          role,
        ),
        options: {
          method: 'DELETE',
        },
      }),
      invalidatesTags: [UserTag.UserList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAPIKeyListQuery,
  useGetAPIKeyQuery,
  useCreateAPIKeyMutation,
  useDeleteAPIKeyMutation,
  useRegenerateAPIKeyMutation,
  useAssignRoleToAPIKeyMutation,
  useRemoveRoleFromAPIKeyMutation,
} = apiKeyApi;
