import { Col, Row, Space, Typography } from 'antd';
import { camelCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { useGetDefinitionsQuery } from 'api/configuration';
import Figure from 'components/ui/atoms/Figure';
import Card from 'components/ui/molecules/Card';
import Background from 'components/ui/organisms/Background';
import { Routes } from 'views/Devices/views/Provision/components/Routing/Routes';
import { getFullPath as getSettingsFullPath } from 'views/Settings/views/Importer/components/Routing';
import Button from './components/Button';
import { getImporterStyle } from './config';

import styles from './styles.module.scss';

const { Title, Paragraph } = Typography;

const ImporterList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const definitionsQuery = useGetDefinitionsQuery({});

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(definitionsQuery.isFetching);
  }, [definitionsQuery.isFetching]);

  const buttons = Object.values(definitionsQuery.data || {}).map(
    ({ importerName, name }) => {
      const ccImporterName = camelCase(importerName);
      const { icon, color, altText, altLabel } =
        getImporterStyle(ccImporterName);

      return (
        <Button
          alt-label={altLabel}
          alt-text={altText}
          key={ccImporterName}
          iconComponent={icon}
          iconColor={color}
          data-testid="button"
          onClick={() => {
            navigate({
              pathname: Routes.CANDIDATE_LIST,
              search: createSearchParams({
                importerName: ccImporterName,
              }).toString(),
            });
          }}
        >
          {name}
        </Button>
      );
    },
  );

  return (
    <Background
      title={
        <>
          <FormattedMessage defaultMessage="Register a new" />
          <br />
          <FormattedMessage defaultMessage="device" />
        </>
      }
    >
      <Row align="middle" justify="space-between">
        <Col span={24}>
          <Card>
            <Figure
              position={{ top: '5vh', left: '15vw' }}
              size="3vw"
              opacity={20}
            />
            <Figure
              position={{ top: '70px', right: '15vw' }}
              size="5vw"
              opacity={30}
            />
            <Figure
              type="minus"
              size="3vw"
              opacity={30}
              position={{ left: '10vw', bottom: '6vh' }}
            />
            <div className={styles.text}>
              <Paragraph>
                <FormattedMessage defaultMessage="Welcome to device management and set up. Here you can manage API connections to device vendors and view discovered devices that have not been set up within the Metrikus platform." />
              </Paragraph>
              <Title level={4}>
                <FormattedMessage defaultMessage="What next?" />
              </Title>
              <Paragraph>
                <ul>
                  <li>
                    <FormattedMessage defaultMessage="Select a product that you would like to set up from the list below. If you have not set up a connection to the vendor API, you will be asked to do so first." />
                  </li>
                  <li>
                    <FormattedMessage defaultMessage='Any devices associated with the provided vendor API key will be listed under "Candidates". They can then be named and assigned to a location during the set up process.' />
                  </li>
                  <li>
                    <FormattedMessage
                      defaultMessage={
                        'Once a device has been set up, it will no longer be listed in the "Candidates" list.'
                      }
                    />
                  </li>
                </ul>
              </Paragraph>
            </div>
          </Card>
        </Col>
      </Row>
      <Card>
        <Space size="large" className={styles.buttonsWrap}>
          {isLoading && (
            <>
              <Button isLoading />
              <Button isLoading />
            </>
          )}
          {!isLoading && buttons.length > 0 && <>{buttons}</>}
          {!isLoading && !buttons.length && (
            <Title className={styles.noImportersLabel} level={5}>
              <FormattedMessage defaultMessage="The organisation has no active data source subscriptions. Please contact Metrikus support" />
            </Title>
          )}
        </Space>
      </Card>
      <Row align="middle" justify="space-between">
        <Col span={24}>
          <FormattedMessage defaultMessage="Need to manage your connectors?" />{' '}
          <Link to={getSettingsFullPath()}>
            <FormattedMessage defaultMessage="Click here" />
          </Link>
        </Col>
      </Row>
    </Background>
  );
};

export default ImporterList;
