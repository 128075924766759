import {
  Form as AntdForm,
  Input,
  Layout,
  message,
  Button,
  Select,
  Radio,
  Space,
  Tag,
  Typography,
  Checkbox,
} from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { AiOutlineFileSync } from 'react-icons/ai';

import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getDefaultReportTypeScoreBand,
  getReportTemplates,
  getReportTemplateToLabelMap,
  getReportTypeToLabelMap,
  ReportConfig,
  ReportType,
  Schedule,
  ScheduleToLabelMap,
  useCreateReportConfigurationMutation,
  useGenerateReportMutation,
  useGetReportConfigurationQuery,
  useUpdateReportConfigurationMutation,
} from 'api/report';
import { useGetSpaceNodeListQuery } from 'api/space';
import { Routes as RootRoutes } from 'components/Routing/Routes';
import Loader from 'components/ui/atoms/Loader';
import Modal from 'components/ui/molecules/Modal';
import globalStyles from 'styles/global.module.scss';

import { confirmPopup, isValidEmail } from 'utilities/utilities';

import LocationSelector from '../../../../components/ui/molecules/LocationSelector';
import styles from './styles.module.scss';
import {
  getSuccessMessage,
  SuccessMessageType,
  updateFormValues,
} from './utilities';

const { Title } = Typography;
const { useForm, Item } = AntdForm;
const { Option } = Select;
const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Content } = Layout;
const { TextArea } = Input;

type Props = {
  orgId?: string;
  title: string;
  activeLocationId?: string;
  mainActiveBuildingId?: string;
  mainActiveFloorId?: string;
};

const Form: React.FC<Props> = ({
  orgId,
  title,
  activeLocationId,
  mainActiveBuildingId,
  mainActiveFloorId,
}) => {
  const { reportConfigurationId = '' } = useParams<'reportConfigurationId'>();

  const navigate = useNavigate();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopulatingData, setIsPopulatingData] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [emails, setEmails] = useState<string[]>([]);
  const [unsubscribed, setUnsubscribed] = useState<string[]>([]);
  const [tempEmail, setTempEmail] = useState<string>('');
  const [locationId, setLocationId] = useState<string | undefined>(
    activeLocationId,
  );
  const [buildingSelected, setBuildingSelected] = useState<string | undefined>(
    mainActiveBuildingId,
  );
  const [floorSelected, setFloorSelected] = useState<string | undefined>(
    mainActiveFloorId,
  );
  const [optInGenerateReport, setOptInGeneratedReport] =
    useState<boolean>(true);
  const [selectedReportType, setSelectedReportType] = useState<ReportType>(
    ReportType.IndoorAirQuality,
  );
  const reportsConfigurationQuery = useGetReportConfigurationQuery(
    reportConfigurationId,
    {
      skip: !reportConfigurationId,
    },
  );
  const { data: spaceNodes } = useGetSpaceNodeListQuery(
    { orgId },
    { skip: !orgId },
  );

  const [createReportConfiguration] = useCreateReportConfigurationMutation();
  const [updateReportConfiguration] = useUpdateReportConfigurationMutation();
  const [generateReport] = useGenerateReportMutation();

  const [form] = useForm();

  const backToList = (): void => {
    setIsVisible(false);

    setTimeout(() => navigate(`/${RootRoutes.INSIGHTS}`), 400);
  };

  const handleFormSubmit = () => {
    if (typeof locationId === 'undefined') return;

    setIsLoading(true);

    form
      .validateFields()
      .then((data) => {
        const reportData = {
          name: data.name,
          description: data.description ?? '',
          emails,
          values: {
            locationId,
            period: data.schedule,
            template: data.reportTemplate,
            scoreBand: getDefaultReportTypeScoreBand(selectedReportType),
          },
        };

        if (reportConfigurationId) {
          updateReportConfiguration({
            reportConfigurationId,
            data: reportData,
          })
            .unwrap()
            .then(() => {
              message.success(getSuccessMessage(SuccessMessageType.Update));

              backToList();
            });
        } else if (orgId) {
          createReportConfiguration({
            ...reportData,
            orgId,
            reportType: data.reportType,
          })
            .unwrap()
            .then((resp) => {
              if (optInGenerateReport) {
                message.success(
                  getSuccessMessage(SuccessMessageType.CreateAndGenerate),
                );
                generateReport(resp.id).unwrap();
              } else
                message.success(getSuccessMessage(SuccessMessageType.Create));

              backToList();
            });
        }
      })
      .catch(() => {
        // do nothing
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addEmailAddress = (emailToAdd: string) => {
    const newEmails = [...emails, emailToAdd];

    setEmails(newEmails);
    setTempEmail('');

    form.setFieldsValue({ emails: newEmails });
  };

  const checkEmailValidAndAdd = (emailToAdd: string) => {
    const emailToAddLowercase = emailToAdd.toLocaleLowerCase();

    if (tempEmail.length < 1) return;

    if (!isValidEmail(emailToAddLowercase)) {
      message.error(intl.formatMessage({ defaultMessage: 'Invalid email.' }));
      return;
    }

    if (emails.includes(emailToAddLowercase)) {
      message.error(
        intl.formatMessage({ defaultMessage: 'Email address already exists.' }),
      );
      return;
    }

    if (unsubscribed.includes(emailToAddLowercase)) {
      confirmPopup(
        intl.formatMessage({
          defaultMessage:
            'Are you sure you want to resubscribe this email address?',
        }),
        () => addEmailAddress(emailToAddLowercase),
        intl,
      );
      return;
    }

    addEmailAddress(emailToAddLowercase);
  };

  const removeEmailAddress = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    emailToRemove: string,
  ) => {
    e.preventDefault();
    const newEmails = emails.filter((email) => email !== emailToRemove);
    setEmails(newEmails);
  };

  // update form state
  useEffect(() => {
    if (!spaceNodes) return;

    form.setFieldsValue({
      schedule: Schedule.Weekly,
      reportType: ReportType.IndoorAirQuality,
    });

    if (!reportConfigurationId) {
      form.setFieldsValue({
        building: mainActiveBuildingId,
        floor: mainActiveFloorId,
        room: [mainActiveBuildingId, mainActiveFloorId].includes(
          activeLocationId,
        )
          ? undefined
          : activeLocationId,
      });
      return;
    }

    if (
      reportsConfigurationQuery.isFetching ||
      reportsConfigurationQuery.isUninitialized
    )
      setIsPopulatingData(true);
    else {
      const reportData = reportsConfigurationQuery.data as ReportConfig;
      const values = updateFormValues(reportData, spaceNodes);

      form.setFieldsValue(values);

      setLocationId(reportData.values.locationId);
      setEmails(reportData.emails || []);
      setUnsubscribed(reportData.unsubscribed || []);
      setIsPopulatingData(false);
    }
  }, [
    reportsConfigurationQuery,
    spaceNodes,
    activeLocationId,
    mainActiveBuildingId,
    mainActiveFloorId,
    reportConfigurationId,
    form,
  ]);

  return (
    <Content data-testid="view-report-form">
      <Modal
        title={
          <Title level={5} className={styles.title}>
            <AiOutlineFileSync className={styles.titleIcon} />
            <span>{title}</span>
          </Title>
        }
        visible={isVisible}
        data-testid="report-form"
        footer={[
          <Button
            key="save"
            type="primary"
            shape="round"
            loading={isLoading}
            onClick={handleFormSubmit}
            data-testid="submit-button"
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>,
          <Button
            key="cancel"
            type="ghost"
            shape="round"
            onClick={backToList}
            disabled={isLoading}
            data-testid="cancel-button"
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>,
        ]}
        bodyStyle={{
          paddingTop: '0px',
        }}
      >
        <Loader
          text={intl.formatMessage({
            defaultMessage: 'Loading report data...',
          })}
          visible={isPopulatingData}
        >
          <AntdForm
            name="basic"
            form={form}
            layout="vertical"
            requiredMark={false}
          >
            <Item
              label={<FormattedMessage defaultMessage="Report name" />}
              name="name"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required." />
                  ),
                },
              ]}
            >
              <Input
                data-testid="name-input"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Report name',
                })}
              />
            </Item>
            <Item
              label={<FormattedMessage defaultMessage="Report description" />}
              name="description"
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                data-testid="description-input"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Report description',
                })}
              />
            </Item>
            <Item
              label={<FormattedMessage defaultMessage="Report type" />}
              name="reportType"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required." />
                  ),
                },
              ]}
            >
              <Select
                placeholder={
                  <FormattedMessage defaultMessage="Select a report type" />
                }
                disabled={!!reportConfigurationId}
                data-testid="report-type-select"
                onChange={setSelectedReportType}
              >
                {Object.values(ReportType).map((reportType) => (
                  <Option
                    key={reportType}
                    data-testid={`type-select-${reportType}`}
                    value={reportType}
                  >
                    <span>{getReportTypeToLabelMap(reportType)}</span>
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              label={<FormattedMessage defaultMessage="Report template" />}
              name="reportTemplate"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required." />
                  ),
                },
              ]}
            >
              <Select
                placeholder={
                  <FormattedMessage defaultMessage="Select a report template" />
                }
                data-testid="report-template-select"
              >
                {getReportTemplates(selectedReportType).map(
                  (reportTemplate) => (
                    <Option
                      key={reportTemplate}
                      data-testid={`template-select-${reportTemplate}`}
                      value={reportTemplate}
                    >
                      {getReportTemplateToLabelMap(
                        selectedReportType,
                        reportTemplate,
                      )}
                    </Option>
                  ),
                )}
              </Select>
            </Item>
            <LocationSelector
              spaceNodes={spaceNodes}
              form={form}
              disabled={!!reportConfigurationId}
              buildingSelected={buildingSelected}
              floorSelected={floorSelected}
              setBuildingSelected={setBuildingSelected}
              setFloorSelected={setFloorSelected}
              setLocationId={setLocationId}
            />
            <Item
              label={
                <FormattedMessage defaultMessage="When do you want this report to be generated?" />
              }
              name="schedule"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required." />
                  ),
                },
              ]}
            >
              <RadioGroup
                disabled={!!reportConfigurationId}
                value={Schedule.Weekly}
                data-testid="schedule-radiogroup"
              >
                <Space wrap={true}>
                  {Object.values(Schedule).map((schedule) => (
                    <RadioButton
                      key={schedule}
                      value={schedule}
                      data-testid={`schedule-radiogroup-${schedule}`}
                    >
                      {ScheduleToLabelMap[schedule as Schedule]}
                    </RadioButton>
                  ))}
                </Space>
              </RadioGroup>
            </Item>
            <Item
              label={
                <FormattedMessage defaultMessage="Who do you want to receive this report?" />
              }
              name="emails"
              rules={[
                {
                  required: true,
                  validator: () => {
                    if (emails.length === 0) {
                      return Promise.reject(
                        intl.formatMessage({
                          defaultMessage: 'An email address must be added.',
                        }),
                      );
                    }

                    return Promise.resolve();
                  },
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Space
                className={globalStyles.fullWidth}
                size="large"
                direction="vertical"
              >
                <Space wrap={true} data-testid="email-list">
                  {emails.map((email) => {
                    return (
                      <Tag
                        key={email}
                        className={styles.emailTag}
                        closable={true}
                        onClose={(e) => removeEmailAddress(e, email)}
                        data-testid="email-address"
                      >
                        {email}
                      </Tag>
                    );
                  })}
                </Space>
                <Space
                  className={cn(styles.emailInput, globalStyles.fullWidth)}
                >
                  <Input
                    value={tempEmail}
                    onChange={(e) => setTempEmail(e.target.value)}
                    onPressEnter={() => checkEmailValidAndAdd(tempEmail)}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Email address',
                    })}
                    data-testid="email-input"
                  />
                  <Space className={globalStyles.fullWidth} align="center">
                    <Button
                      className={globalStyles.fullWidth}
                      onClick={() => checkEmailValidAndAdd(tempEmail)}
                      disabled={tempEmail.length < 1}
                      data-testid="add-email-button"
                    >
                      <FormattedMessage defaultMessage="Add email address" />
                    </Button>
                  </Space>
                </Space>
              </Space>
            </Item>
            {reportConfigurationId === '' && (
              <Item name="optInGenerateReport">
                <Checkbox
                  checked={optInGenerateReport}
                  onChange={() => setOptInGeneratedReport(!optInGenerateReport)}
                  data-testid="generate-report-checkbox"
                >
                  <FormattedMessage defaultMessage="Would you like a report generated for the previous period?" />
                </Checkbox>
              </Item>
            )}
          </AntdForm>
        </Loader>
      </Modal>
    </Content>
  );
};

export default Form;
