import classNames from 'classnames';
import Color from 'color';
import { memo, ReactElement } from 'react';
import styles from './styles.module.scss';

type Props = {
  render?: (x: number, y: number, ratio: number | null) => ReactElement;
  posX: number;
  posY: number;
  value: number | null;
  color: string;
  height?: string;
  square?: boolean;
  onCellClick?: (x: number, y: number) => void;
};

const Cell: React.FC<Props> = ({
  render = (posX, posY, value) => (
    <div title={`Pos(${posX}, ${posY}) = ${value}`}>{value}</div>
  ),
  posX,
  posY,
  color,
  square = false,
  height = '2rem',
  value,
  onCellClick,
}) => {
  const cellCssValue = {
    '--height': height,
    '--background': color,
    '--fontColor': Color(color).isDark() ? '#fff' : '#000',
  } as React.CSSProperties;

  return (
    <div
      data-testid="heatmap-cell"
      className={classNames(styles.cell, {
        [styles.cellSquare]: square,
        [styles.cellClickable]: onCellClick,
      })}
      onClick={() => (onCellClick || (() => undefined))(posX, posY)}
      role="presentation"
      style={cellCssValue}
    >
      {render(posX, posY, value)}
    </div>
  );
};

export default memo(Cell);
