import { connect } from 'react-redux';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  space: {
    locationTray: { isSaving },
  },
}: RootState) => ({
  isSaving,
});

const storeConnection = connect(mapStateToProps);
export default storeConnection;
