import { Button, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactChild } from 'react';
import { BsShieldLock } from 'react-icons/bs';
import { FiRefreshCcw } from 'react-icons/fi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import { APIKey } from 'api/apiKey';
import { Org } from 'api/org';
import { TableRow } from 'components/ui/molecules/Table';

export type APIKeyRow = TableRow &
  APIKey & {
    org?: Org;
  };

export const columns = (
  onClickRoles: (key: string) => void,
  onClickRegenerate: (key: string) => void,
  onClickDelete: (key: string) => void,
): ColumnsType<APIKeyRow> => [
  {
    title: <FormattedMessage defaultMessage="Name" />,
    key: 'name',
    ellipsis: {
      showTitle: false,
    },
    render: ({ name }, { meta, hideActions }: APIKeyRow): ReactChild => {
      if (hideActions) return name;
      return <>{meta && meta.name?.trim() ? meta.name : '---'}</>;
    },
  },
  {
    title: <FormattedMessage defaultMessage="Description" />,
    key: 'description',
    ellipsis: {
      showTitle: false,
    },
    render: ({ name }, { meta, hideActions }: APIKeyRow): ReactChild => {
      if (hideActions) return name;
      return <>{meta && meta.description?.trim() ? meta.description : '---'}</>;
    },
  },
  {
    title: <FormattedMessage defaultMessage="Organisation" />,
    key: 'orgNode',
    render: ({ orgId, org }: APIKeyRow): ReactChild => {
      return <>{org ? org.meta.name : orgId}</>;
    },
  },
  {
    title: '',
    key: 'action',
    width: 160,
    render: ({ hideActions, key: apiKeyId }: APIKeyRow): null | ReactChild => {
      if (hideActions) return null;
      return (
        <Space size="middle" data-testid="buttons-actions">
          <Tooltip title={<FormattedMessage defaultMessage="Roles" />}>
            <Button
              type="link"
              icon={<BsShieldLock />}
              onClick={() => onClickRoles(apiKeyId)}
              data-testid={`roles-button-${apiKeyId}`}
            />
          </Tooltip>
          <Tooltip title={<FormattedMessage defaultMessage="Regenerate" />}>
            <Button
              type="link"
              icon={<FiRefreshCcw />}
              onClick={() => onClickRegenerate(apiKeyId)}
              data-testid={`regenerate-button-${apiKeyId}`}
            />
          </Tooltip>
          <Tooltip title={<FormattedMessage defaultMessage="Delete" />}>
            <Button
              type="link"
              shape="circle"
              icon={<RiDeleteBin7Line />}
              onClick={() => onClickDelete(apiKeyId)}
              data-testid={`delete-button-${apiKeyId}`}
            />
          </Tooltip>
        </Space>
      );
    },
  },
];
