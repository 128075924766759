import Icon, { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { BsBuilding } from 'react-icons/bs';
import { RiHome6Line } from 'react-icons/ri';

import { useIntl, FormattedMessage } from 'react-intl';
import { SpaceNode, useGetSpaceNodeListQuery } from 'api/space';
import { ReactComponent as Buildings } from 'assets/svg/buildings.svg';

import { getIntl } from 'components/Intl';
import {
  getSelectedBuilding,
  getTrayConfig,
  LocationTrayTab,
} from 'components/LocationTray/utils';
import Loader from 'components/ui/atoms/Loader';
import Tabs, { Layout, Tab } from 'components/ui/molecules/Tabs';
import {
  LocationSelectorStateConfig,
  LocationSelectorTray,
} from 'ducks/space/slice';
import useResizeWatch from 'utilities/hooks/useResizeWatch';
import styles from './styles.module.scss';
import BuildingSelector from './views/BuildingSelector';
import SpaceCreator from './views/SpaceCreator';
import SpaceEditor from './views/SpaceEditor';

import SpaceSelector from './views/SpaceSelector';

const BREAKPOINT_SMALL = 547;

export enum LocationTraySize {
  unset = 'unset',
  default = 'default',
  small = 'small',
}

export const getLocationTrayTabLabelMap = (
  selectedBuilding: SpaceNode | undefined,
) => ({
  [LocationTrayTab.Space]: selectedBuilding?.meta.name || '',
  [LocationTrayTab.Create]: getIntl().formatMessage({
    defaultMessage: 'Building setup',
  }),
  [LocationTrayTab.Edit]: getIntl().formatMessage({
    defaultMessage: 'Building setup',
  }),
  [LocationTrayTab.Building]: getIntl().formatMessage({
    defaultMessage: 'Building selection',
  }),
});

const getTabs = (selectedBuildingId: string | undefined): Tab[] => {
  const intl = getIntl();
  const ariaBuildingSelector = intl.formatMessage({
    defaultMessage: 'Building Selector',
  });
  const ariaSpaceSelector = intl.formatMessage({
    defaultMessage: 'Space Selector',
  });
  const ariaAdd = intl.formatMessage({
    defaultMessage: 'Add Building',
  });
  const ariaEdit = intl.formatMessage({
    defaultMessage: 'Edit Building',
  });
  return [
    {
      key: LocationTrayTab.Building,
      tab: (
        <Tooltip
          title={intl.formatMessage({
            defaultMessage: 'Select building',
          })}
        >
          <Icon component={BsBuilding} aria-label={ariaBuildingSelector} />
        </Tooltip>
      ),
    },
    {
      key: LocationTrayTab.Space,
      tab: (
        <Tooltip
          title={intl.formatMessage({
            defaultMessage: 'Select space',
          })}
        >
          <Icon component={RiHome6Line} aria-label={ariaSpaceSelector} />
        </Tooltip>
      ),
    },
    {
      key: LocationTrayTab.Create,
      tab: (
        <Tooltip title={ariaAdd}>
          <PlusOutlined aria-label={ariaAdd} />
        </Tooltip>
      ),
    },
    {
      key: LocationTrayTab.Edit,
      tab: (
        <Tooltip title={ariaEdit}>
          <EditOutlined aria-label={ariaEdit} />
        </Tooltip>
      ),
      disabled: isNil(selectedBuildingId) || selectedBuildingId === '',
    },
  ];
};

type Props = {
  orgId?: string;
  locationSelectorTray: LocationSelectorTray;
  selectorsSettings: { [selectorType: string]: LocationSelectorStateConfig };
  onActiveLocationIdChanged: (spaceId: string) => void;
  setSelectedBuildingId: (
    locationSelectorTray: LocationSelectorTray,
    spaceId: string,
  ) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: (isFieldsChanged: boolean) => void;
};

const LocationTray: React.FC<Props> = ({
  orgId,
  locationSelectorTray,
  selectorsSettings,
  onActiveLocationIdChanged,
  setSelectedBuildingId,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const [activeTab, setActiveTab] = useState<LocationTrayTab>(
    LocationTrayTab.Space,
  );
  const [tabLabel, setTabLabel] = useState<React.ReactChild>('');
  const [size, setSize] = useState<LocationTraySize>(LocationTraySize.unset);
  const [dynamicTabs, setDynamicTabs] = useState<React.ReactFragment>(<></>);
  const locationTrayRef = useRef(null);

  const intl = useIntl();

  const { data: spaceNodes, isFetching } = useGetSpaceNodeListQuery(
    { orgId },
    { skip: !orgId },
  );

  const config = getTrayConfig(selectorsSettings, locationSelectorTray);
  const { selectedBuildingId } = config;

  const selectedBuilding = useMemo(
    () => (!spaceNodes ? undefined : getSelectedBuilding(spaceNodes, config)),
    [spaceNodes, config],
  );

  const onBuildingSelected = (buildingId: string) => {
    setSelectedBuildingId(locationSelectorTray, buildingId);
    setActiveTab(LocationTrayTab.Space);
  };

  useResizeWatch((entry: ResizeObserverEntry) => {
    const { width } = entry.contentRect;

    setSize(
      width <= BREAKPOINT_SMALL
        ? LocationTraySize.small
        : LocationTraySize.default,
    );
  }, locationTrayRef);

  useEffect(() => {
    if (isFetching) return;

    if (selectedBuildingId && activeTab === LocationTrayTab.Building) {
      setActiveTab(LocationTrayTab.Space);
    } else if (!selectedBuildingId && activeTab === LocationTrayTab.Space) {
      setActiveTab(LocationTrayTab.Building);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuildingId, isFetching]);

  const getView = (tab: LocationTrayTab) => {
    switch (tab) {
      case LocationTrayTab.Space:
        return (
          <SpaceSelector
            locationSelectorTray={locationSelectorTray}
            setActiveLocationId={onActiveLocationIdChanged}
          />
        );
      case LocationTrayTab.Create:
        return (
          <SpaceCreator
            locationSelectorTray={locationSelectorTray}
            setView={setActiveTab}
          />
        );
      case LocationTrayTab.Edit:
        return (
          <SpaceEditor
            locationSelectorTray={locationSelectorTray}
            setDynamicTabs={setDynamicTabs}
            onCreateClick={setActiveTab}
          />
        );
      case LocationTrayTab.Building:
      default:
        return (
          <BuildingSelector
            traySize={size}
            onBuildingSelected={onBuildingSelected}
            onCreateClick={setActiveTab}
          />
        );
    }
  };

  useEffect(() => {
    setTabLabel(getLocationTrayTabLabelMap(selectedBuilding)[activeTab]);
  }, [activeTab, selectedBuilding]);

  const captionWrapperSpan = size === LocationTraySize.default ? 6 : 4;
  const captionCenterContainerSpan =
    size === LocationTraySize.default ? 12 : 10;

  const handleTabChange = (tabKey: string) => {
    if (!unsavedChanges) setActiveTab(tabKey as LocationTrayTab);
  };

  const tabs = useMemo(() => {
    const tabsList = getTabs(selectedBuildingId);
    if (!unsavedChanges) return tabsList;

    return tabsList.map(({ key, tab, disabled = false }) => ({
      key,
      tab: disabled ? (
        tab
      ) : (
        <Popconfirm
          data-testid="pop-confirm"
          title={
            <FormattedMessage defaultMessage="You have unsaved changes. Are you sure you wish to leave this tab?" />
          }
          onConfirm={() => {
            setUnsavedChanges(false);
            setActiveTab(key as LocationTrayTab);
          }}
          okText={<FormattedMessage defaultMessage="Yes" />}
          cancelText={<FormattedMessage defaultMessage="No" />}
        >
          {tab}
        </Popconfirm>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedChanges, selectedBuildingId]);

  const aria = intl.formatMessage({ defaultMessage: 'building icon' });

  return (
    <div
      ref={locationTrayRef}
      className={styles.locationTray}
      data-testid="location-tray"
    >
      <Row align="middle">
        <Col className={styles.locationTrayCaption} span={captionWrapperSpan}>
          <Icon
            component={Buildings}
            className={styles.icon}
            aria-label={aria}
            alt-text={aria}
          />
          {size === LocationTraySize.default && tabLabel}
        </Col>
        <Col
          data-testid="location-tray-caption-centered-content"
          span={captionCenterContainerSpan}
        >
          {dynamicTabs}
        </Col>
        <Col data-testid="location-tray-caption-tabs" offset={1}>
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            layout={Layout.THIN}
          />
        </Col>
      </Row>
      <div className={styles.locationTrayBody}>
        {isFetching && !spaceNodes && (
          <Loader
            isAbsolute={true}
            text={intl.formatMessage({ defaultMessage: 'Loading spaces' })}
            visible={true}
          />
        )}
        {getView(activeTab)}
      </div>
    </div>
  );
};

export default LocationTray;
