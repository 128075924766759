import { snakeCase } from 'lodash';
import { baseApi } from 'api/api';
import processRtkqEntry, { RawRTKQResponse } from 'api/utils';
import store from 'ducks/store';
import { ConfigurationTag } from './tags';

export enum Endpoint {
  GetConfiguration = '/importer/configuration',
  GetConfigurationDefinitions = '/importer/configuration/definitions',
  DeleteConfiguration = '/importer/configuration/:configurationId',
  CreateConfiguration = '/importer/configuration',
  UpdateConfiguration = '/importer/configuration',
  UploadConfigurationFile = '/importer/configuration/:configurationId/files/:fileKey',
  GetConfigurationSecret = '/importer/configuration/:configurationId/secret/:secretKey',
}

export enum FieldDataType {
  STR = 'str',
  INT = 'int',
}

export enum FieldUnique {
  NOT_UNIQUE = 'not_unique',
  GLOBAL = 'global',
  PER_ORG = 'per_org',
}

export type Configuration = {
  orgId: string;
  orgTree: string | null;
  importerName: string;
  enabled: boolean;
  id: string;
  locationId?: string;
  productId: string;
  provisionStrategy?: string;
  configurationValues: Record<string, string>;
  configurationFiles: Record<string, string>;
  dataLastReceived?: string;
  pullSchedule?: Record<string, string>;
  meta: Record<string, string>;
};

export type ConfigurationValues = Record<string, string | number>;

export type SetConfigurationRequest = {
  importerName?: string;
  orgId?: string;
  productId?: string;
  configurationValues: ConfigurationValues;
  meta: {
    name: string;
    description: string;
  };
};

export type Field = {
  key: string;
  name: string;
  description: string;
  default: string | null;
  dataType: FieldDataType;
  required: boolean;
  autogenerate: boolean;
  autogenerateCanOverride: boolean;
  secret: boolean;
  maxLength: null | number;
  minLength: null | number;
  unique: FieldUnique;
};

export type ConfigurationFile = {
  key: string;
  name: string;
  description: string;
  maxSizeBytes: number;
  mimetypes: string[];
};

export type ConfigurationDefinition = {
  importerName: string;
  associatedProducts: string[];
  dataAcquisitionStrategies: string[];
  name: string;
  description: string;
  defaultPullSchedule: Record<string, string> | null;
  fields: Record<string, Field>;
  files: Record<string, ConfigurationFile>;
};

export type ConfigurationSecret = {
  [key: string]: string;
};

export type GetConfigurationResponse = Promise<Configuration>;

export type GetConfigurationListResponse = Promise<Configuration[]>;

export type GetConfigurationDefinitionResponse = Promise<
  Record<string, ConfigurationDefinition> | undefined
>;

export type GetConfigurationSecretResponse = Promise<ConfigurationSecret>;

export type ConfigurationListParams = {
  configurationId?: string;
  productId?: string;
  importerName?: string[];
};

export const configurationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getConfigurationList({ configurationId, productId, importerName})
    getConfigurationList: build.query<Configuration[], ConfigurationListParams>(
      {
        query: ({ configurationId, productId, importerName }) => ({
          url: Endpoint.GetConfiguration,
          options: {
            method: 'GET',
            params: {
              configurationId,
              productId,
              importerName: importerName?.map(snakeCase),
            },
          },
        }),
        providesTags: [ConfigurationTag.ConfigurationList],
      },
    ),
    // * deleteConfiguration(configurationId)
    deleteConfiguration: build.mutation<void, string>({
      query: (configurationId) => ({
        url: Endpoint.DeleteConfiguration.replace(
          ':configurationId',
          configurationId,
        ),
        options: {
          method: 'DELETE',
        },
      }),
      invalidatesTags: [ConfigurationTag.ConfigurationList],
    }),
    // * getDefinitions({ productId, importerName })
    getDefinitions: build.query<
      Record<string, ConfigurationDefinition> | undefined,
      { productId?: string; importerName?: string }
    >({
      query: ({ productId, importerName }) => ({
        url: Endpoint.GetConfigurationDefinitions,
        options: {
          method: 'GET',
          params: { productId, importerName },
        },
      }),
      providesTags: [ConfigurationTag.DefinitionList],
    }),
    // * getDefinitionListForProduct(productId)
    getDefinitionListForProduct: build.query<
      ConfigurationDefinition | undefined,
      string
    >({
      query: (productId) => ({
        url: Endpoint.GetConfigurationDefinitions,
        options: {
          method: 'GET',
          params: { product_id: productId },
        },
      }),
      transformResponse: (response: ConfigurationDefinition[]) => {
        if (!response) return undefined;
        return Object.values(response)[0];
      },
      providesTags: [ConfigurationTag.DefinitionList],
    }),
    // * createConfiguration(configuration)
    createConfiguration: build.mutation<Configuration, SetConfigurationRequest>(
      {
        query: (configuration) => ({
          url: Endpoint.CreateConfiguration,
          options: {
            method: 'POST',
            data: configuration,
          },
        }),
        invalidatesTags: [ConfigurationTag.ConfigurationList],
      },
    ),
    // * updateConfiguration(configuration, configurationId)
    updateConfiguration: build.mutation<
      Configuration,
      { configuration: SetConfigurationRequest; configurationId: string }
    >({
      query: ({ configuration, configurationId }) => ({
        url: Endpoint.UpdateConfiguration,
        options: {
          method: 'PATCH',
          data: configuration,
          params: {
            configuration_id: configurationId,
          },
        },
      }),
      invalidatesTags: [ConfigurationTag.ConfigurationList],
    }),
    // * getSecretValue(secretKey, configurationId)
    getSecretValue: build.query<
      ConfigurationSecret,
      {
        secretKey: string;
        configurationId: string | undefined;
      }
    >({
      query: ({ secretKey, configurationId }) => ({
        url: Endpoint.GetConfigurationSecret.replace(
          ':configurationId',
          configurationId ?? '',
        ).replace(':secretKey', secretKey),
        options: {
          method: 'GET',
        },
      }),
      providesTags: [ConfigurationTag.ConfigurationSecret],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDefinitionsQuery,
  useLazyGetConfigurationListQuery,
  useDeleteConfigurationMutation,
  useGetConfigurationListQuery,
  useCreateConfigurationMutation,
  useUpdateConfigurationMutation,
  useLazyGetSecretValueQuery,
} = configurationApi;

export const getDefinitions = (
  productId?: string,
  importerName?: string,
): Promise<RawRTKQResponse<GetConfigurationDefinitionResponse>> =>
  processRtkqEntry<GetConfigurationDefinitionResponse, any>(
    configurationApi,
    store,
    'getDefinitions',
    [{ productId, importerName }],
  );

export const getSecretValue = (
  secretKey: string,
  configurationId?: string,
): Promise<RawRTKQResponse<ConfigurationSecret>> =>
  processRtkqEntry<ConfigurationSecret, any>(
    configurationApi,
    store,
    'getSecretValue',
    [{ secretKey, configurationId, timestamp: Date.now() }],
  );
