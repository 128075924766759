import React, { ReactChild } from 'react';
import { Card as AntdCard } from 'antd';
import cn from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children?: ReactChild | ReactChild[];
  className?: string;
  testId?: string;
};

const Card: React.FC<Props> = ({ children, className, testId }) => {
  return (
    <AntdCard data-testid={testId} className={cn(styles.card, className)}>
      {children}
    </AntdCard>
  );
};

export default Card;
