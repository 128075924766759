/* eslint-disable react/jsx-props-no-spreading */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Row,
  Form,
  Space,
  Input,
  InputNumber,
  message,
  InputRef,
} from 'antd';
import cn from 'classnames';
import { useState, useEffect, useRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  SpaceNode,
  SpaceNodeCategory,
  useCreateSpaceNodesMutation,
  useDeleteSpaceNodesMutation,
  useUpdateSpaceNodeMutation,
} from 'api/space';

import { handleEnter } from 'components/LocationTray/utils';
import SaveButton from '../SaveButton';
import sharedStyles from '../sharedStyles.module.scss';
import styles from './styles.module.scss';
import {
  FloorRow,
  resetInputRows,
  getFloorChanges,
  uniqueFloorNumber,
  validateFloor,
} from './utils';

const { Item, List, ErrorList } = Form;

type Props = {
  selectedBuilding?: SpaceNode;
  floors: SpaceNode[];
  unsavedChanges: boolean;
  setLocationTrayIsSaving: (isSaving: boolean) => void;
  setUnsavedChanges: (isFieldsChanged: boolean) => void;
  onFormContinue: () => void;
};

const BuildingFloorsForm: React.FC<Props> = ({
  selectedBuilding,
  floors,
  unsavedChanges,
  setUnsavedChanges,
  setLocationTrayIsSaving,
  onFormContinue,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [existingFloors, setExistingFloors] = useState<FloorRow[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [newFloorName, setNewFloorName] = useState('');
  const [newFloorNumber, setNewFloorNumber] = useState<number>();

  const [createSpaceNodes, createSpaceNodesResult] =
    useCreateSpaceNodesMutation();
  const [updateSpaceNode, updateSpaceNodeResult] = useUpdateSpaceNodeMutation();
  const [deleteSpaceNodes, deleteSpaceNodesResult] =
    useDeleteSpaceNodesMutation();

  useEffect(() => {
    if (
      createSpaceNodesResult.isLoading ||
      updateSpaceNodeResult.isLoading ||
      deleteSpaceNodesResult.isLoading
    ) {
      setLocationTrayIsSaving(true);
      return;
    }

    if (
      createSpaceNodesResult.isError ||
      updateSpaceNodeResult.isError ||
      deleteSpaceNodesResult.isError
    ) {
      setLocationTrayIsSaving(false);
      resetInputRows(floors, setExistingFloors, form, setUnsavedChanges);
      return;
    }

    if (
      createSpaceNodesResult.isSuccess ||
      updateSpaceNodeResult.isSuccess ||
      deleteSpaceNodesResult.isSuccess
    ) {
      message.success(
        intl.formatMessage({ defaultMessage: 'Floors updated.' }),
      );
      setLocationTrayIsSaving(false);
      setFormSubmitted(true);
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createSpaceNodesResult,
    deleteSpaceNodesResult,
    updateSpaceNodeResult,
    form,
  ]);

  const roomsCount = useMemo(
    () =>
      floors
        .map(({ children }) => children?.length || 0)
        .reduce((prev, curr) => prev + curr, 0),
    [floors],
  );

  // If the floor list changes, reset the form
  useEffect(() => {
    if (floors) resetInputRows(floors, setExistingFloors, form);
  }, [floors, form]);

  const onSubmit = async (
    { rows }: Record<string, FloorRow[]>,
    originals: FloorRow[],
  ) => {
    const { added, removed, modified } = getFloorChanges(rows, originals);

    if (added.length > 0) {
      createSpaceNodes(
        added.map(({ name, number }) => ({
          parentId: selectedBuilding?.id,
          category: SpaceNodeCategory.FLOOR,
          meta: {
            name: name.trim(),
            description: '',
          },
          floorNumber: number,
        })),
      );
    }

    if (removed.length > 0) {
      deleteSpaceNodes(
        removed.map((row) => row.id || '').filter((id) => id !== ''),
      );
    }

    if (modified.length > 0) {
      modified
        .filter(({ id }) => Boolean(id))
        .forEach(({ id, name, number }) => {
          updateSpaceNode({
            spaceId: id as string,
            body: {
              floorNumber: number,
              meta: {
                name,
                description: '',
              },
            },
          });
        });
    }
  };

  const formChanged = () => {
    const { added, removed, modified } = getFloorChanges(
      form.getFieldsValue().rows,
      existingFloors,
    );

    setUnsavedChanges(
      added.length > 0 || removed.length > 0 || modified.length > 0,
    );
  };

  const floorNameInput = useRef<InputRef>(null);

  const addFloor = () => {
    const values = form.getFieldsValue();
    if (!validateFloor(newFloorName, newFloorNumber, values.rows)) return;

    values.rows.push({
      name: newFloorName,
      number: newFloorNumber,
    });

    values.rows.sort((a: any, b: any) => (a.number > b.number ? -1 : 1));

    form.setFieldsValue(values);
    setNewFloorName('');
    setNewFloorNumber(undefined);
    setUnsavedChanges(true);
    if (floorNameInput.current) floorNameInput.current.focus();
  };

  return (
    <Form
      form={form}
      onFinish={(values) => onSubmit(values, existingFloors)}
      onFieldsChange={formChanged}
      onChange={() => {
        setUnsavedChanges(false);
        setFormSubmitted(false);
      }}
      data-testid="edit-floors-form"
      className={styles.form}
    >
      <div className={sharedStyles.scrollableArea}>
        <Space
          className={cn(styles.floorInputRowWithoutMargins, styles.labels)}
          align="baseline"
        >
          <Item className={sharedStyles.subHeader}>
            <FormattedMessage defaultMessage="Add floor" />
          </Item>
          <Item className={sharedStyles.subHeader}>
            <FormattedMessage defaultMessage="floor #" />
          </Item>
        </Space>
        <div>
          <Space align="baseline" className={styles.floorInputRow}>
            <Item
              rules={[
                {
                  required: Boolean(newFloorName),
                  message: intl.formatMessage({
                    defaultMessage: 'Please enter a floor name',
                  }),
                },
              ]}
            >
              <Input
                ref={floorNameInput}
                data-testid="input-floor-name"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Name your floor',
                })}
                name="newFloorName"
                value={newFloorName}
                onChange={(e) => setNewFloorName(e.target.value)}
                onPressEnter={addFloor}
                onKeyDown={handleEnter}
              />
            </Item>
            <Item>
              <InputNumber
                name="newFloorNumber"
                value={newFloorNumber}
                data-testid="input-floor-num"
                placeholder="#"
                precision={0}
                onChange={(e) => {
                  if (typeof e === 'number') setNewFloorNumber(+e);
                }}
                onPressEnter={addFloor}
                onKeyDown={handleEnter}
              />
            </Item>
            <Item>
              <PlusCircleOutlined
                data-testid="add-button"
                className={sharedStyles.addButton}
                onClick={addFloor}
              />
            </Item>
          </Space>
        </div>
        <br />

        <List name="rows">
          {(fields, { remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space
                  key={key}
                  className={styles.floorInputRow}
                  align="baseline"
                >
                  <Item
                    {...restField}
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'Missing floor name' }]}
                  >
                    <Input placeholder="Name" />
                  </Item>
                  <Item
                    {...restField}
                    name={[name, 'number']}
                    rules={[
                      { required: true, message: 'Missing floor number' },
                      (args) => uniqueFloorNumber(args, index),
                    ]}
                  >
                    <InputNumber placeholder="#" precision={0} />
                  </Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      setUnsavedChanges(true);
                      remove(name);
                    }}
                    className={sharedStyles.deleteButton}
                    data-testid={`remove-button-${index}`}
                  />
                  <ErrorList errors={errors} />
                </Space>
              ))}
            </>
          )}
        </List>
      </div>
      <Item className={sharedStyles.formSubmitRow}>
        <Row justify="center">
          <Button
            type="ghost"
            className={sharedStyles.formButton}
            onClick={() => {
              resetInputRows(
                floors,
                setExistingFloors,
                form,
                setUnsavedChanges,
              );
              setUnsavedChanges(false);
            }}
            data-testid="discard-button"
            disabled={!unsavedChanges}
          >
            <FormattedMessage defaultMessage="Discard" />
          </Button>
          <SaveButton isSaved={formSubmitted} isDisabled={!unsavedChanges} />
          {!roomsCount ? (
            <Button
              type="primary"
              className={sharedStyles.formButton}
              onClick={() => onFormContinue()}
              data-testid="next-button"
              disabled={unsavedChanges || floors.length === 0}
            >
              <FormattedMessage defaultMessage="Next" />
            </Button>
          ) : null}
        </Row>
      </Item>
    </Form>
  );
};

export default BuildingFloorsForm;
