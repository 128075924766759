import { connect } from 'react-redux';
import { setFloorplanUploadProgress } from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  auth: { currentUser },
  space: {
    floorplanUploadProgress,
    locationTray: { selectorsSettings },
  },
}: RootState) => ({
  floorplanUploadProgress,
  orgId: currentUser?.orgId,
  mainActiveBuildingId: selectorsSettings?.main?.selectedBuildingId,
  mainActiveFloorId: selectorsSettings?.main?.selectedFloorId,
});

const mapDispatchToProps = {
  setFloorplanUploadProgress,
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
