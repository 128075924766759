import { Space, Tooltip, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactChild } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

import { getIntl } from 'components/Intl';
import TimeBadge from 'components/ui/atoms/TimeBadge';
import { TableRow } from 'components/ui/molecules/Table';

export type ConfigurationRow = TableRow & {
  importer: string;
  importerName: string;
  credentialName: string;
  dataLastReceived?: string;
  lastActive?: any;
  org: string;
};

export const columns = <T,>(
  onClickEdit: (key: string) => void,
  onClickDelete: (key: string) => void,
): ColumnsType<T> => {
  const intl = getIntl();
  return [
    {
      title: <FormattedMessage defaultMessage="Name" />,
      dataIndex: 'credentialName',
      key: 'credentialName',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Integration Type" />,
      dataIndex: 'importerName',
      key: 'importerName',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Organisation" />,
      key: 'org',
      dataIndex: 'org',
      ellipsis: true,
    },
    {
      title: <FormattedMessage defaultMessage="Last Active" />,
      key: 'lastActive',
      width: 120,
      render: ({
        dataLastReceived,
      }: ConfigurationRow): ReactChild | undefined => {
        if (typeof dataLastReceived === 'string' || dataLastReceived === null) {
          return <TimeBadge timestamp={dataLastReceived} />;
        }

        return dataLastReceived;
      },
    },
    {
      title: '',
      key: 'action',
      width: 120,
      render: ({
        hideActions,
        key: configurationId,
        credentialName,
      }: ConfigurationRow): null | ReactChild => {
        if (hideActions) return null;

        const ariaEdit = intl.formatMessage(
          {
            defaultMessage: 'edit {val} connector',
          },
          { val: `${credentialName}` },
        );
        const ariaDelete = intl.formatMessage(
          {
            defaultMessage: 'delete {val} connector',
          },
          { val: `${credentialName}` },
        );
        return (
          <Space size="middle" data-testid="buttons-actions">
            <Tooltip title={<FormattedMessage defaultMessage="Edit" />}>
              <Button
                type="link"
                icon={<FiEdit2 />}
                onClick={() => onClickEdit(configurationId)}
                data-testid={`edit-button-${configurationId}`}
                alt-text={ariaEdit}
                aria-label={ariaEdit}
              />
            </Tooltip>
            <Tooltip title={<FormattedMessage defaultMessage="Delete" />}>
              <Button
                type="link"
                shape="circle"
                alt-text={ariaDelete}
                aria-label={ariaDelete}
                icon={<RiDeleteBin7Line />}
                onClick={() => onClickDelete(configurationId)}
                data-testid={`delete-button-${configurationId}`}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};
