import { useNavigate, useParams } from 'react-router-dom';
import { getFullPath } from 'views/Settings/views/Bms/components/Routing';
import { Routes } from 'views/Settings/views/Bms/components/Routing/Routes';
import SinkForm from 'views/Settings/views/Bms/components/SinkForm';

const EditSink: React.FC = () => {
  const { sinkId } = useParams<'sinkId'>();
  const navigate = useNavigate();

  const backToList = () =>
    setTimeout(() => navigate(getFullPath(Routes.DATA_SINKS_LIST, true)), 400); // wait for fade out animation

  return (
    <SinkForm
      sinkId={sinkId}
      isVisible={true}
      onCloseModal={backToList}
      onCancel={backToList}
      onSubmitted={backToList}
    />
  );
};

export default EditSink;
