import { ComponentType, ForwardRefExoticComponent, SVGProps } from 'react';
import Icon from '@ant-design/icons';
import cn from 'classnames';

import { MdOutlineSearchOff } from 'react-icons/md';

import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import styles from './styles.module.scss';

type Props = {
  description?: React.ReactChild;
  icon?:
    | ComponentType<SVGProps<SVGSVGElement> | CustomIconComponentProps>
    | ForwardRefExoticComponent<CustomIconComponentProps>
    | undefined;
  iconClassName?: string;
  labelClassName?: string;
};

export const Empty: React.FC<Props> = ({
  icon,
  description,
  labelClassName,
  iconClassName,
}) => {
  return (
    <div data-testid="empty-wrapper" className={styles.container}>
      <div className={cn(labelClassName)}>{description}</div>
      <div className={cn(styles.icon, iconClassName)}>
        <Icon component={icon || MdOutlineSearchOff} />
      </div>
    </div>
  );
};

export default Empty;
