import { ChartOptions } from 'chart.js';

export const MIN_BAR_HEIGHT = 32;
export const BORDER_RADIUS = 8;
export const BAR_HEIGHT = 8;

export const BAR_DATASET_COLOR = '#165dff';
export const GRID_COLOR = '#E5E6EB';
export const BAR_EMPTY_DATASET_COLOR = '#2f2f2f';

export const getDefaultDatasetConfig = (
  formatterCallback: (value: number | null) => string,
) => ({
  label: '',
  minBarLength: MIN_BAR_HEIGHT,
  datalabels: {
    color: 'white',
    formatter: formatterCallback,
  },
});

export const config: ChartOptions<'bar'> = {
  skipNull: true,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      anchor: 'start',
      align: 'top',
      formatter: (value: number) => (value > 0 ? value : ''),
    },
  },
  elements: {
    bar: {
      backgroundColor: BAR_DATASET_COLOR,
      borderRadius: BORDER_RADIUS,
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          weight: 'bold',
        },
      },
      grid: {
        display: false,
        borderColor: GRID_COLOR,
      },
    },
    y: {
      ticks: {
        display: false,
      },
      grid: {
        borderDash: [3],
        drawBorder: false,
      },
    },
  },
};
