/* eslint-disable no-nested-ternary */
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { Input, Space, Spin } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';

import styles from './styles.module.scss';

type Props = {
  hasSecret: boolean;
  getSecret: () => Promise<string | undefined>;
  onChange?: (value: string) => void;
};

export const HIDDEN_VALUE = '••••••••••';

const SecretInput: React.FC<Props> = ({ hasSecret, getSecret, onChange }) => {
  const [inputValue, setInputValue] = useState<string | undefined>(
    !hasSecret ? undefined : HIDDEN_VALUE,
  );

  const [visible, setVisible] = useState<boolean>(!hasSecret);
  const [disabled, setDisabled] = useState<boolean>(hasSecret);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (inputValue !== HIDDEN_VALUE && typeof inputValue !== 'undefined')
      onChange?.(inputValue);
  }, [inputValue]);

  const handleInput = (vis: boolean) => {
    setVisible(vis);
    setDisabled(!vis);
  };

  const handlePasswordVisibleClick = () => {
    if (hasSecret) {
      setLoading(true);
      getSecret()
        .then((val) => {
          if (typeof val === 'undefined') return;
          setInputValue(val);
          handleInput(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      handleInput(true);
    }
  };

  const handlePasswordHideClick = () => {
    handleInput(false);
  };

  const handlePasswordChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setInputValue(ev.target.value);
  };

  return (
    <Space direction="horizontal" className={styles.password}>
      <Input
        value={visible ? inputValue : HIDDEN_VALUE}
        disabled={disabled}
        onChange={handlePasswordChange}
        data-testid="password"
      />
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : visible ? (
        <EyeTwoTone onClick={handlePasswordHideClick} data-testid="hide-btn" />
      ) : (
        <EyeInvisibleOutlined
          onClick={handlePasswordVisibleClick}
          data-testid="show-btn"
        />
      )}
    </Space>
  );
};

export default SecretInput;
