import { connect } from 'react-redux';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  auth: { currentUser },
  space: { activeLocationId },
}: RootState) => ({
  activeLocationId,
  orgId: currentUser?.orgId,
});

const storeConnection = connect(mapStateToProps, {});
export default storeConnection;
