import { TEST_BUILDING, TEST_FLOORS, TEST_ROOMS } from 'api/space/mocks';
import { ReportConfig, ReportType, Schedule } from './report';
import { IAQReportTemplate, IAQScoreBand } from './reportIAQ';

export const reportConfiguration = [
  {
    id: 'rpc-3',
    orgId: 'org-1',
    reportType: ReportType.IndoorAirQuality,
    locationId: TEST_ROOMS[0].id,
    runSchedule: {
      minute: '0',
      hour: '1',
      dayOfMonth: '1',
      monthOfYear: '*',
      dayOfWeek: '1',
    },
    values: {
      locationId: TEST_ROOMS[0].id,
      period: Schedule.Monthly,
      template: IAQReportTemplate.Overview,
      scoreBand: IAQScoreBand.Standard,
    },
    lastRan: '2021-05-01T01:00:00+00:00',
    enabled: true,
    emails: ['scrambled@gmail.com'],
    unsubscribed: ['unscrambled@gmail.com'],
    name: 'Report Config 3',
    description: 'Report config #3',
    created: '2021-12-15T11:58:31.522304+00:00',
    modified: '2021-12-16T09:41:31.696962+00:00',
  },
];

export const reportConfigurationList = [
  {
    id: 'rpc-1',
    orgId: 'org-1',
    locationId: TEST_FLOORS[0].id,
    reportType: 'non_existent',
    runSchedule: {
      minute: '0',
      hour: '1',
      dayOfMonth: '*',
      monthOfYear: '*',
      dayOfWeek: '1',
    },
    values: {
      locationId: TEST_FLOORS[0].id,
      period: Schedule.Weekly,
      template: IAQReportTemplate.ParameterBreakdown,
      scoreBand: IAQScoreBand.Standard,
    },
    lastRan: null,
    enabled: true,
    emails: ['eggs@gmail.com', 'scrambled@gmail.com'],
    unsubscribed: [],
    name: 'Report Config 1',
    description: 'Report config #1',
    created: '2021-12-15T11:58:31.522304+00:00',
    modified: '2021-12-16T09:41:31.696962+00:00',
  },
  {
    id: 'rpc-2',
    orgId: 'org-1',
    reportType: ReportType.IndoorAirQuality,
    runSchedule: {
      minute: '0',
      hour: '1',
      dayOfMonth: '1',
      monthOfYear: '*',
      dayOfWeek: '1',
    },
    values: {
      locationId: TEST_FLOORS[1].id,
      period: Schedule.Monthly,
      template: IAQReportTemplate.Overview,
      scoreBand: IAQScoreBand.Standard,
    },
    lastRan: '2021-05-01T03:00:00+00:00',
    enabled: true,
    emails: ['poached@gmail.com'],
    unsubscribed: [],
    name: 'Report Config 2',
    description: 'Report config #2',
    created: '2021-12-15T11:58:31.522304+00:00',
    modified: '2021-12-16T09:41:31.696962+00:00',
  },
  ...reportConfiguration,
];

export const reportConfigurationCreate = {
  name: 'New Report Configuration',
  description: 'New report config description.',
  building: TEST_BUILDING.id,
  floor: TEST_FLOORS[1].id,
  room: undefined,
  locationId: TEST_FLOORS[1].id,
  schedule: Schedule.Monthly,
  reportType: ReportType.IndoorAirQuality,
  template: IAQReportTemplate.ParameterBreakdown,
  emails: ['fried@gmail.com', 'poached@gmail.com'],
};

export const reportConfigurationRow: ReportConfig = {
  id: 'rpc-1',
  name: 'Report Configuration Test',
  description: 'Report configuration test',
  orgId: 'org-1',
  lastRan: '',
  reportType: ReportType.IndoorAirQuality,
  enabled: true,
  emails: ['eggs'],
  unsubscribed: ['unscrambled@gmail.com'],
  values: {
    locationId: TEST_ROOMS[0].id,
    period: Schedule.Weekly,
    template: IAQReportTemplate.Overview,
    scoreBand: IAQScoreBand.Standard,
  },
};

export const generatedReportsByReportConfigId = [
  {
    name: 'Report 1',
    description: 'string',
    created: '2022-08-23T10:26:43.919481+00:00',
    modified: '2022-08-23T10:26:43.919481+00:00',
    id: 'rpt-2',
    org_id: 'org-1',
    report_type: 'indoor_air_quality',
    run_time: '2022-08-23T10:26:35.219000+00:00',
    start: '2022-08-15T00:00:00+00:00',
    end: '2022-08-21T23:59:59+00:00',
    source_configuration_id: 'rpc-1',
    version: 1,
  },
  {
    name: 'Report 2',
    description: 'string',
    created: '2022-08-24T10:26:51.476616+00:00',
    modified: '2022-08-24T10:26:51.476616+00:00',
    id: 'rpt-3',
    org_id: 'org-1',
    report_type: 'indoor_air_quality',
    run_time: '2022-08-24T10:26:35.219000+00:00',
    start: '2022-08-15T00:00:00+00:00',
    end: '2022-08-21T23:59:59+00:00',
    source_configuration_id: 'rpc-1',
    version: 1,
  },
  {
    name: 'Report 3',
    description: 'string',
    created: '2022-08-24T10:26:55.457609+00:00',
    modified: '2022-08-24T10:26:55.457609+00:00',
    id: 'rpt-4',
    org_id: 'org-1',
    report_type: 'indoor_air_quality',
    run_time: '2022-08-24T10:26:35.219000+00:00',
    start: '2022-08-15T00:00:00+00:00',
    end: '2022-08-21T23:59:59+00:00',
    source_configuration_id: 'rpc-1',
    version: 1,
  },
];

export const mockReport = {
  name: 'Indoor Air Quality',
  description: '',
  created: '2022-11-08T11:27:36.174647+00:00',
  modified: '2022-11-08T11:27:36.174647+00:00',
  id: 'rpt-2',
  orgId: 'org-1',
  reportType: 'indoor_air_quality',
  runTime: '2022-11-08T11:27:36.192197+00:00',
  start: '2022-10-31T00:00:00+00:00',
  end: '2022-11-06T23:59:59+00:00',
  sourceConfigurationId: 'rpc-1',
  version: 1,
  failed: false,
  content: {
    telemetryProperties: ['co2', 'humidity', 'pm2.5', 'temperature', 'voc'],
    period: 'week',
    template: 'overview',
    address: 'fsdfsdfs',
    workingRange: {
      friday: { start: 0, end: 1440 },
      monday: { start: 0, end: 1440 },
      sunday: { start: 0, end: 1440 },
      tuesday: { start: 0, end: 1440 },
      saturday: { start: 0, end: 1440 },
      thursday: { start: 0, end: 1440 },
      wednesday: { start: 0, end: 1440 },
    },
    scoreBands: {
      co2: {
        '5': '<700',
        '4': '700-800',
        '3': '800-1000',
        '2': '1000-1500',
        '1': '1500+',
        '0': 'No data',
      },
      humidity: {
        '5': '40-60',
        '4': '35-40 | 60-65',
        '3': '30-35 | 65-70',
        '2': '25-30 | 70-75',
        '1': '<25 | 75+',
        '0': 'No data',
      },
      'pm2.5': {
        '5': '<12',
        '4': '12-15',
        '3': '15-16',
        '2': '16-18',
        '1': '18+',
        '0': 'No data',
      },
      temperature: {
        '5': '21-24',
        '4': '20-21 | 24-25',
        '3': '19-20 | 25-26',
        '2': '18-19 | 26-27',
        '1': '<18 | 27+',
        '0': 'No data',
      },
      voc: {
        '5': '<100',
        '4': '100-333',
        '3': '333-1000',
        '2': '1000-3333',
        '1': '3333+',
        '0': 'No data',
      },
    },
    summary: {
      overallScore: 88,
      highestDayScore: {
        value: 88,
        timestamps: ['2022-10-31T00:00:00+00:00'],
      },
      bestPerformingParameter: {
        overallPerformance: 14,
        value: 14.0,
        healthScore: 5,
        category: 'temperature',
      },
      worstPerformingParameter: {
        overallPerformance: 9,
        value: 8.0,
        healthScore: 3,
        category: 'voc',
      },
    },
    telemetryPropertiesNameAndUnit: {
      co2: { name: 'CO2', unit: 'ppm' },
      humidity: { name: 'Humidity', unit: '%' },
      'pm2.5': { name: 'PM2.5', unit: 'µg/m³' },
      temperature: { name: 'Temperature', unit: '°C' },
      voc: { name: 'VOC', unit: 'ppm' },
    },
    overallScore: 88,
    dailyScores: {
      '2022-10-31T00:00:00+00:00': 88,
      '2022-11-01T00:00:00+00:00': null,
      '2022-11-02T00:00:00+00:00': null,
      '2022-11-03T00:00:00+00:00': null,
      '2022-11-04T00:00:00+00:00': null,
      '2022-11-05T00:00:00+00:00': null,
      '2022-11-06T00:00:00+00:00': null,
    },
    averageValues: {
      co2: { mean: 824.0, healthScore: 3 },
      humidity: { mean: 48.0, healthScore: 5 },
      'pm2.5': { mean: 1.6, healthScore: 5 },
      temperature: { mean: 22.6, healthScore: 5 },
      voc: { mean: 496.0, healthScore: 3 },
    },
    parameterBreakdown: {
      co2: [
        { value: 7.0, healthScore: 5, label: '<700' },
        { value: 1.0, healthScore: 4, label: '700-800' },
        { value: 1.0, healthScore: 3, label: '800-1000' },
        { value: 5.0, healthScore: 2, label: '1000-1500' },
        { value: 0.0, healthScore: 1, label: '1500+' },
        { value: 86.0, healthScore: 0, label: 'No data' },
      ],
      humidity: [
        { value: 14.0, healthScore: 5, label: '40-60' },
        { value: 0.0, healthScore: 4, label: '35-40 | 60-65' },
        { value: 0.0, healthScore: 3, label: '30-35 | 65-70' },
        { value: 0.0, healthScore: 2, label: '25-30 | 70-75' },
        { value: 0.0, healthScore: 1, label: '<25 | 75+' },
        { value: 86.0, healthScore: 0, label: 'No data' },
      ],
      pm25: [
        { value: 14.0, healthScore: 5, label: '<12' },
        { value: 0.0, healthScore: 4, label: '12-15' },
        { value: 0.0, healthScore: 3, label: '15-16' },
        { value: 0.0, healthScore: 2, label: '16-18' },
        { value: 0.0, healthScore: 1, label: '18+' },
        { value: 86.0, healthScore: 0, label: 'No data' },
      ],
      temperature: [
        { value: 14.0, healthScore: 5, label: '21-24' },
        { value: 1.0, healthScore: 4, label: '20-21 | 24-25' },
        { value: 0.0, healthScore: 3, label: '19-20 | 25-26' },
        { value: 0.0, healthScore: 2, label: '18-19 | 26-27' },
        { value: 0.0, healthScore: 1, label: '<18 | 27+' },
        { value: 85.0, healthScore: 0, label: 'No data' },
      ],
      voc: [
        { value: 0.0, healthScore: 5, label: '<100' },
        { value: 5.0, healthScore: 4, label: '100-333' },
        { value: 8.0, healthScore: 3, label: '333-1000' },
        { value: 1.0, healthScore: 2, label: '1000-3333' },
        { value: 0.0, healthScore: 1, label: '3333+' },
        { value: 86.0, healthScore: 0, label: 'No data' },
      ],
    },
    hourlyParameterBreakdown: {
      co2: {
        healthScore: 0,
        value: 86,
        segments: [
          {
            mean: null,
            healthScore: null,
            timestamp: '2022-10-31T00:00:00+00:00',
            numberOfValues: 0,
          },
          {
            mean: null,
            healthScore: null,
            timestamp: '2022-10-31T01:00:00+00:00',
            numberOfValues: 0,
          },
          {
            mean: 448.0,
            healthScore: 5,
            timestamp: '2022-10-31T02:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 447.0,
            healthScore: 5,
            timestamp: '2022-10-31T03:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 446.0,
            healthScore: 5,
            timestamp: '2022-10-31T04:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 442.0,
            healthScore: 5,
            timestamp: '2022-10-31T05:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 437.0,
            healthScore: 5,
            timestamp: '2022-10-31T06:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 434.0,
            healthScore: 5,
            timestamp: '2022-10-31T07:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 450.0,
            healthScore: 5,
            timestamp: '2022-10-31T08:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 793.0,
            healthScore: 4,
            timestamp: '2022-10-31T09:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1163.0,
            healthScore: 2,
            timestamp: '2022-10-31T10:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1126.0,
            healthScore: 2,
            timestamp: '2022-10-31T11:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1194.0,
            healthScore: 2,
            timestamp: '2022-10-31T12:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1150.0,
            healthScore: 2,
            timestamp: '2022-10-31T13:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1394.0,
            healthScore: 2,
            timestamp: '2022-10-31T14:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1334.0,
            healthScore: 2,
            timestamp: '2022-10-31T15:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1374.0,
            healthScore: 2,
            timestamp: '2022-10-31T16:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1432.0,
            healthScore: 2,
            timestamp: '2022-10-31T17:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1284.0,
            healthScore: 2,
            timestamp: '2022-10-31T18:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 969.0,
            healthScore: 3,
            timestamp: '2022-10-31T19:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 774.0,
            healthScore: 4,
            timestamp: '2022-10-31T20:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 653.0,
            healthScore: 5,
            timestamp: '2022-10-31T21:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 573.0,
            healthScore: 5,
            timestamp: '2022-10-31T22:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 522.0,
            healthScore: 5,
            timestamp: '2022-10-31T23:00:00+00:00',
            numberOfValues: 1,
          },
        ],
      },
      humidity: {
        healthScore: 0,
        value: 86,
        segments: [
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T00:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T01:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T02:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T03:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 47.0,
            healthScore: 5,
            timestamp: '2022-10-31T04:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 47.0,
            healthScore: 5,
            timestamp: '2022-10-31T05:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 47.0,
            healthScore: 5,
            timestamp: '2022-10-31T06:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 46.0,
            healthScore: 5,
            timestamp: '2022-10-31T07:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 45.0,
            healthScore: 5,
            timestamp: '2022-10-31T08:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 47.0,
            healthScore: 5,
            timestamp: '2022-10-31T09:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 50.0,
            healthScore: 5,
            timestamp: '2022-10-31T10:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T11:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T12:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T13:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 51.0,
            healthScore: 5,
            timestamp: '2022-10-31T14:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 49.0,
            healthScore: 5,
            timestamp: '2022-10-31T15:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 49.0,
            healthScore: 5,
            timestamp: '2022-10-31T16:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 51.0,
            healthScore: 5,
            timestamp: '2022-10-31T17:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T18:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 46.0,
            healthScore: 5,
            timestamp: '2022-10-31T19:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 48.0,
            healthScore: 5,
            timestamp: '2022-10-31T20:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 49.0,
            healthScore: 5,
            timestamp: '2022-10-31T21:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 49.0,
            healthScore: 5,
            timestamp: '2022-10-31T22:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 49.0,
            healthScore: 5,
            timestamp: '2022-10-31T23:00:00+00:00',
            numberOfValues: 1,
          },
        ],
      },
      'pm2.5': {
        healthScore: 0,
        value: 86,
        segments: [
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T00:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T01:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T02:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T03:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T04:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T05:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T06:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T07:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.1,
            healthScore: 5,
            timestamp: '2022-10-31T08:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.8,
            healthScore: 5,
            timestamp: '2022-10-31T09:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.4,
            healthScore: 5,
            timestamp: '2022-10-31T10:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.7,
            healthScore: 5,
            timestamp: '2022-10-31T11:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.5,
            healthScore: 5,
            timestamp: '2022-10-31T12:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.2,
            healthScore: 5,
            timestamp: '2022-10-31T13:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 2.7,
            healthScore: 5,
            timestamp: '2022-10-31T14:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 4.5,
            healthScore: 5,
            timestamp: '2022-10-31T15:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 3.8,
            healthScore: 5,
            timestamp: '2022-10-31T16:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 2.5,
            healthScore: 5,
            timestamp: '2022-10-31T17:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.7,
            healthScore: 5,
            timestamp: '2022-10-31T18:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 2.0,
            healthScore: 5,
            timestamp: '2022-10-31T19:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.5,
            healthScore: 5,
            timestamp: '2022-10-31T20:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.1,
            healthScore: 5,
            timestamp: '2022-10-31T21:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T22:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1.0,
            healthScore: 5,
            timestamp: '2022-10-31T23:00:00+00:00',
            numberOfValues: 1,
          },
        ],
      },
      temperature: {
        healthScore: 0,
        value: 86,
        segments: [
          {
            mean: 22.0,
            healthScore: 5,
            timestamp: '2022-10-31T00:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 21.9,
            healthScore: 5,
            timestamp: '2022-10-31T01:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 21.9,
            healthScore: 5,
            timestamp: '2022-10-31T02:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 21.9,
            healthScore: 5,
            timestamp: '2022-10-31T03:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 21.9,
            healthScore: 5,
            timestamp: '2022-10-31T04:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 21.8,
            healthScore: 5,
            timestamp: '2022-10-31T05:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 21.9,
            healthScore: 5,
            timestamp: '2022-10-31T06:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.2,
            healthScore: 5,
            timestamp: '2022-10-31T07:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.9,
            healthScore: 5,
            timestamp: '2022-10-31T08:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.6,
            healthScore: 5,
            timestamp: '2022-10-31T09:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 21.8,
            healthScore: 5,
            timestamp: '2022-10-31T10:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.6,
            healthScore: 5,
            timestamp: '2022-10-31T11:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.7,
            healthScore: 5,
            timestamp: '2022-10-31T12:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 23.0,
            healthScore: 5,
            timestamp: '2022-10-31T13:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.2,
            healthScore: 5,
            timestamp: '2022-10-31T14:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.8,
            healthScore: 5,
            timestamp: '2022-10-31T15:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.6,
            healthScore: 5,
            timestamp: '2022-10-31T16:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 22.9,
            healthScore: 5,
            timestamp: '2022-10-31T17:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 23.4,
            healthScore: 5,
            timestamp: '2022-10-31T18:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 24.1,
            healthScore: 4,
            timestamp: '2022-10-31T19:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 23.3,
            healthScore: 5,
            timestamp: '2022-10-31T20:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 23.1,
            healthScore: 5,
            timestamp: '2022-10-31T21:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 23.1,
            healthScore: 5,
            timestamp: '2022-10-31T22:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 23.1,
            healthScore: 5,
            timestamp: '2022-10-31T23:00:00+00:00',
            numberOfValues: 1,
          },
        ],
      },
      voc: {
        healthScore: 0,
        value: 86,
        segments: [
          {
            mean: 307.0,
            healthScore: 4,
            timestamp: '2022-10-31T00:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 285.0,
            healthScore: 4,
            timestamp: '2022-10-31T01:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 274.0,
            healthScore: 4,
            timestamp: '2022-10-31T02:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 266.0,
            healthScore: 4,
            timestamp: '2022-10-31T03:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 260.0,
            healthScore: 4,
            timestamp: '2022-10-31T04:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 253.0,
            healthScore: 4,
            timestamp: '2022-10-31T05:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 247.0,
            healthScore: 4,
            timestamp: '2022-10-31T06:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 242.0,
            healthScore: 4,
            timestamp: '2022-10-31T07:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 286.0,
            healthScore: 4,
            timestamp: '2022-10-31T08:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 506.0,
            healthScore: 3,
            timestamp: '2022-10-31T09:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 453.0,
            healthScore: 3,
            timestamp: '2022-10-31T10:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1357.0,
            healthScore: 2,
            timestamp: '2022-10-31T11:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 1458.0,
            healthScore: 2,
            timestamp: '2022-10-31T12:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 727.0,
            healthScore: 3,
            timestamp: '2022-10-31T13:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 542.0,
            healthScore: 3,
            timestamp: '2022-10-31T14:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 530.0,
            healthScore: 3,
            timestamp: '2022-10-31T15:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 528.0,
            healthScore: 3,
            timestamp: '2022-10-31T16:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 536.0,
            healthScore: 3,
            timestamp: '2022-10-31T17:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 534.0,
            healthScore: 3,
            timestamp: '2022-10-31T18:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 535.0,
            healthScore: 3,
            timestamp: '2022-10-31T19:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 515.0,
            healthScore: 3,
            timestamp: '2022-10-31T20:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 465.0,
            healthScore: 3,
            timestamp: '2022-10-31T21:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 413.0,
            healthScore: 3,
            timestamp: '2022-10-31T22:00:00+00:00',
            numberOfValues: 1,
          },
          {
            mean: 374.0,
            healthScore: 3,
            timestamp: '2022-10-31T23:00:00+00:00',
            numberOfValues: 1,
          },
        ],
      },
    },
  },
};
