import { Button } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import sharedStyles from '../sharedStyles.module.scss';
import styles from './styles.module.scss';

type Props = {
  isSaved?: boolean;
  isDisabled?: boolean;
};

const SaveButton: React.FC<Props> = ({
  isSaved = false,
  isDisabled = true,
}) => (
  <Button
    type="primary"
    className={sharedStyles.formButton}
    htmlType="submit"
    data-testid="save-button"
    disabled={isDisabled}
  >
    <FormattedMessage defaultMessage="Save" />
    <CSSTransition
      in={isSaved}
      unmountOnExit
      addEndListener={(node, done) =>
        node.addEventListener('transitionend', done, false)
      }
      classNames={{
        enter: styles.scaleEnter,
        enterActive: styles.scaleEnterActive,
        exit: styles.scaleExit,
        exitActive: styles.scaleExitActive,
      }}
    >
      <AiOutlineCheck
        role="figure"
        style={{ color: 'inherit', marginLeft: '5px' }}
      />
    </CSSTransition>
  </Button>
);

export default SaveButton;
