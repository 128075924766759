import {
  Button,
  Modal,
  Typography,
  Form as AntdForm,
  FormInstance,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useDeleteFloorPlanMutation,
  useGetFloorPlanQuery,
  useGetSpaceNodeListQuery,
  useUploadFloorPlanMutation,
} from 'api/space';
import Loader from 'components/ui/atoms/Loader';
import ImageUploadField from 'components/ui/molecules/ImageUploadField';
import LocationSelector from 'components/ui/molecules/LocationSelector';

const { useForm, Item } = AntdForm;
const { Title } = Typography;

export enum RequestStatus {
  Idle,
  Processing,
  Success,
  Failed,
}

const getSaveButtonLabel = (
  requestStatus: RequestStatus,
  form: FormInstance,
): ReactElement => {
  const { file } = form.getFieldsValue();
  if (file?.remove) {
    return requestStatus !== RequestStatus.Processing ? (
      <FormattedMessage defaultMessage="Remove" />
    ) : (
      <FormattedMessage defaultMessage="Removing" />
    );
  }

  return requestStatus !== RequestStatus.Processing ? (
    <FormattedMessage defaultMessage="Upload" />
  ) : (
    <FormattedMessage defaultMessage="Uploading" />
  );
};

type Props = {
  isVisible: boolean;
  orgId?: string;
  mainActiveBuildingId?: string;
  mainActiveFloorId?: string;
  floorplanUploadProgress: number;
  setFloorplanUploadProgress: (progress: number) => void;
  onCloseModal: () => void;
  onAction: () => void;
};

const UploadModal: React.FC<Props> = ({
  isVisible,
  orgId,
  mainActiveBuildingId,
  mainActiveFloorId,
  floorplanUploadProgress,
  setFloorplanUploadProgress,
  onCloseModal,
  onAction,
}) => {
  const [buildingSelected, setBuildingSelected] = useState<string | undefined>(
    mainActiveBuildingId,
  );
  const [floorSelected, setFloorSelected] = useState<string | undefined>(
    mainActiveFloorId,
  );
  const [form] = useForm();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.Idle,
  );
  const [canSave, setCanSave] = useState(false);
  const intl = useIntl();

  const [uploadFloorplan, uploadResponse] = useUploadFloorPlanMutation();
  const [deleteFloorplan, deleteResponse] = useDeleteFloorPlanMutation();

  const { data: spaceNodes } = useGetSpaceNodeListQuery(
    { orgId },
    { skip: !orgId },
  );

  const { data: floorplan, isFetching: isFloorplanLoading } =
    useGetFloorPlanQuery(floorSelected || '', {
      skip:
        !floorSelected ||
        !spaceNodes ||
        (spaceNodes && !spaceNodes[floorSelected]?.systemMeta.floorplan),
    });

  useEffect(() => {
    if (!form) return;

    form.setFieldsValue({ file: undefined });
  }, [form, buildingSelected]);

  useEffect(() => {
    if (!form || !floorSelected || !spaceNodes) return;

    if (!spaceNodes[floorSelected]?.systemMeta.floorplan || !floorplan) {
      form.setFieldsValue({ file: undefined });
      return;
    }

    form.setFieldsValue({
      file: {
        imageUrl: floorplan.data.url,
        displayName: floorplan.displayName,
        size: floorplan.data.size,
      },
    });

    setRequestStatus(RequestStatus.Idle);
  }, [floorSelected, floorplan, form, spaceNodes]);

  useEffect(() => {
    if (!form) return;

    form.setFieldsValue({
      building: mainActiveBuildingId,
      floor: mainActiveFloorId,
    });
  }, [form, mainActiveBuildingId, mainActiveFloorId]);

  // handle status change
  useEffect(() => {
    if (uploadResponse.isLoading || deleteResponse.isLoading) {
      setRequestStatus(RequestStatus.Processing);
      return;
    }
    if (uploadResponse.isSuccess || deleteResponse.isSuccess) {
      setRequestStatus(RequestStatus.Success);
      if (uploadResponse.isSuccess) onAction();
      return;
    }
    if (uploadResponse.isError || deleteResponse.isError) {
      setRequestStatus(RequestStatus.Failed);
      return;
    }

    setRequestStatus(RequestStatus.Idle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResponse, deleteResponse]);

  const handleFieldsChange = ([data]: any) => {
    const { fileToUpload, remove } = data.value;
    setRequestStatus(RequestStatus.Idle);
    setFloorplanUploadProgress(0);
    setCanSave(fileToUpload || remove);
  };

  const handleSave = () => {
    if (typeof floorSelected === 'undefined' || !spaceNodes) return;
    const { file } = form.getFieldsValue();

    if (file.remove) {
      const floorplanToDelete = spaceNodes[floorSelected].systemMeta.floorplan;

      if (floorplanToDelete) {
        deleteFloorplan({
          filename: floorplanToDelete,
          spaceId: floorSelected,
        }).then(() => {
          setCanSave(false);
          form.setFieldsValue({ file: undefined });
        });
      } else {
        setCanSave(false);
        form.setFieldsValue({ file: undefined });
      }
    } else {
      uploadFloorplan({
        spaceId: floorSelected,
        displayName: file.displayName,
        file: file.fileToUpload,
      }).then(() => {
        form.setFieldsValue({ file: undefined });
      });
    }
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onCloseModal}
      forceRender
      title={
        <Title level={5}>
          <FormattedMessage defaultMessage="Upload floor plan" />
        </Title>
      }
      footer={[
        <Button type="ghost" key="cancel" onClick={onCloseModal}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>,
        <Button
          type="primary"
          loading={requestStatus === RequestStatus.Processing}
          disabled={!canSave}
          onClick={handleSave}
          key="upload"
          data-testid="upload-floor-plan-submit"
          aria-label={intl.formatMessage({
            defaultMessage: 'Submit floorplan',
          })}
          alt-text={intl.formatMessage({
            defaultMessage: 'Submit floorplan',
          })}
        >
          {getSaveButtonLabel(requestStatus, form)}
        </Button>,
      ]}
    >
      <AntdForm
        layout="vertical"
        requiredMark={false}
        form={form}
        data-testid="floor-plan-form"
        onFieldsChange={handleFieldsChange}
      >
        <LocationSelector
          spaceNodes={spaceNodes}
          form={form}
          buildingSelected={buildingSelected}
          floorSelected={floorSelected}
          hideRooms={true}
          setBuildingSelected={setBuildingSelected}
          setFloorSelected={setFloorSelected}
        />
        <Loader visible={isFloorplanLoading}>
          <Item
            label={<FormattedMessage defaultMessage="Upload file" />}
            name="file"
          >
            <ImageUploadField
              uploadProgress={floorplanUploadProgress}
              requestStatus={requestStatus}
            />
          </Item>
        </Loader>
      </AntdForm>
    </Modal>
  );
};

export default UploadModal;
