import {
  Event,
  initialize,
  VariationValue,
} from '@harnessio/ff-javascript-client-sdk';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { User } from 'api/user';
import { RootState } from 'ducks/store';
import { Flag } from './config';

declare const process: {
  env: {
    REACT_APP_HARNESS_CLIENT_ID: string;
  };
};

type ReturnType<T> = T extends Flag
  ? VariationValue
  : T extends Flag[]
  ? Record<Flag, VariationValue>
  : never;

const useFlags = <T extends Flag | Flag[]>(
  flagName: T,
  debug = false,
): ReturnType<T> => {
  const user = useSelector<RootState, User | undefined>(
    ({ auth }) => auth.currentUser,
    shallowEqual,
  );
  const [featureFlags, setFeatureFlags] = useState<
    Partial<Record<Flag, VariationValue>>
  >({});

  const getFilteredFlags = () => {
    if (isArray(flagName))
      return Object.fromEntries(
        Object.entries(featureFlags).filter(([flagKey]) =>
          flagName.includes(flagKey as Flag),
        ),
      );

    return featureFlags[flagName as Flag];
  };

  const replaceUnhandledCharacters = (value: string): string =>
    value.replace(/[&/\\#,+()$~%'":*?<>{}]/g, '');

  useEffect(() => {
    if (!user)
      return () => {
        // do nothing
      };

    const flagClient = initialize(
      process.env.REACT_APP_HARNESS_CLIENT_ID,
      {
        identifier: `target_${user.userId.replace('|', '_')}`,
        name: replaceUnhandledCharacters(user.name),
        attributes: {
          userEmail: replaceUnhandledCharacters(user.email),
          userOrgId: user.orgId,
          host: window.location.href,
        },
      },
      {
        debug,
      },
    );

    flagClient.on(Event.READY, (flags) => {
      setFeatureFlags(flags);

      flagClient.on(Event.CHANGED, (flagInfo) => {
        if (flagInfo.deleted) {
          setFeatureFlags((currentFeatureFlags) => {
            // eslint-disable-next-line no-param-reassign
            delete currentFeatureFlags[flagInfo.flag as Flag];
            return { ...currentFeatureFlags };
          });
        } else {
          setFeatureFlags((currentFeatureFlags) => ({
            ...currentFeatureFlags,
            [flagInfo.flag]: flagInfo.value,
          }));
        }
      });
    });

    return () => {
      try {
        /* istanbul ignore else */
        if (flagClient) flagClient.close();
      } catch (error) {
        console.warn(error instanceof Error ? error.message : error);
      }
    };
  }, [debug, user]);

  return getFilteredFlags() as ReturnType<T>;
};

export default useFlags;
