/* eslint-disable import/no-extraneous-dependencies */
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Card, Empty, Table as AntdTable, TablePaginationConfig } from 'antd';
import { TableProps } from 'antd/lib/table';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { TableRow } from './config';

import styles from './styles.module.scss';

interface Props<T> extends TableProps<T> {
  data: T[];
  emptyText?: ReactElement | string | undefined;
  autoScroll?: boolean;
  pagination?: TablePaginationConfig;
}

const Table = <T extends TableRow>({
  data,
  columns,
  showHeader = true,
  rowSelection,
  onRow,
  emptyText,
  rowClassName,
  autoScroll,
  pagination,
}: Props<T>): JSX.Element => {
  const [height, setHeight] = useState(0);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!autoScroll) return undefined;

    const resizeListener = () => {
      /* istanbul ignore else */
      if (wrapperRef.current) {
        const wrapperElem = wrapperRef.current as HTMLElement;
        const headerElem = wrapperElem.querySelector('.ant-table-header');
        const paginationElem = wrapperElem.querySelector('.ant-pagination');
        const getHeight = (htmlElem: Element) => {
          const {
            height: heightStl,
            marginTop,
            marginBottom,
            paddingTop,
            paddingBottom,
          } = window.getComputedStyle(htmlElem);

          return (
            (parseFloat(heightStl) || 0) +
            (parseFloat(marginTop) || 0) +
            (parseFloat(marginBottom) || 0) +
            (parseFloat(paddingTop) || 0) +
            (parseFloat(paddingBottom) || 0)
          );
        };

        let newHeight = getHeight(wrapperElem);

        /* istanbul ignore else */
        if (headerElem) newHeight -= getHeight(headerElem);
        if (paginationElem) newHeight -= getHeight(paginationElem);
        if (!Number.isNaN(newHeight) && newHeight > 0) setHeight(newHeight);
      }
    };

    window.addEventListener('resize', resizeListener);
    resizeListener();

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [data, autoScroll]);

  const locale = {
    emptyText: emptyText || (
      <Empty description={<FormattedMessage defaultMessage="No Records" />} />
    ),
  };

  const table = (
    <Card bordered={false} className={styles.card}>
      <AntdTable
        className={cn({ [styles.rowPointer]: !!onRow })}
        rowClassName={rowClassName}
        dataSource={data}
        columns={columns}
        rowSelection={rowSelection}
        showHeader={showHeader}
        pagination={{
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          ...pagination,
        }}
        onRow={onRow}
        locale={locale}
        scroll={autoScroll && height ? { y: height } : undefined}
        data-testid="table"
      />
    </Card>
  );

  if (autoScroll)
    return (
      <div
        ref={wrapperRef}
        className={styles.wrapperTable}
        data-testid="table-scroll-wrapper"
      >
        {table}
      </div>
    );

  return table;
};

export default Table;
