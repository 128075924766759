import {
  Badge,
  Button,
  Col,
  Empty,
  message,
  Popover,
  Row,
  Typography,
} from 'antd';
import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  useDeleteTelemetryFeedMutation,
  useGetDataSinkListQuery,
} from 'api/dataSink';
import { useGetDataSourceListQuery } from 'api/dataSource';
import { useGetSpaceNodeListQuery } from 'api/space';
import { useGetTelemetryPropertyListQuery } from 'api/telemetryProperty';

import Card from 'components/ui/molecules/Card';
import Search from 'components/ui/molecules/Search';
import Table from 'components/ui/molecules/Table';
import globalStyles from 'styles/global.module.scss';
import { confirmPopup, getTableSkeleton } from 'utilities/utilities';

import { Routes } from 'views/Settings/views/Bms/components/Routing/Routes';

import { NOT_RELEVANT } from 'views/Settings/views/Bms/utilities';

import Filter, { FilterData } from './components/Filter';

import {
  columnsDataSink,
  columnsSinkDetails,
  DataSinkRow,
  SinkDetailsRow,
} from './config';
import styles from './styles.module.scss';
import { filterByIsActive, filterBySearchString } from './utilities';

const { Title } = Typography;

type Props = {
  orgId?: string;
};

const DataSinks: React.FC<Props> = ({ orgId }) => {
  const intl = useIntl();
  const [searchString, setSearchString] = useState('');
  const [dataSinks, setDataSinks] = useState<DataSinkRow[]>(
    getTableSkeleton(['name', 'id', 'lifecycle', 'location', 'sources'], 3),
  );
  const [filteredDataSinks, setFilteredDataSinks] = useState<DataSinkRow[]>([]);
  const [selectedDataSink, setSelectedDataSink] = useState<DataSinkRow>();
  const [sinkDetails, setSinkDetails] = useState<SinkDetailsRow[]>([]);
  const [filterData, setFilterData] = useState<FilterData>({
    isActive: NOT_RELEVANT,
  });
  const navigate = useNavigate();

  const selectedDataSourceIds = useMemo(() => {
    if (!selectedDataSink) return [];

    return Array.from(
      new Set(selectedDataSink.sources.map(({ dataSourceId }) => dataSourceId)),
    );
  }, [selectedDataSink]);

  const {
    data: telemetryPropertyList,
    isFetching: isTelemetryPropertyListLoading,
  } = useGetTelemetryPropertyListQuery({});

  const { data: dataSourceList, isFetching: isDataSourceListLoading } =
    useGetDataSourceListQuery(
      {
        dataSourceId: selectedDataSourceIds,
      },
      {
        skip: !selectedDataSourceIds.length,
      },
    );

  const { data: spaceNodes, isFetching: isSpaceNodesLoading } =
    useGetSpaceNodeListQuery({ orgId }, { skip: !orgId });

  const { data: dataSinkList, isFetching: isDataSinkListLoading } =
    useGetDataSinkListQuery(
      {},
      {
        skip: !spaceNodes || !telemetryPropertyList,
      },
    );

  const [deleteTelemetryFeed] = useDeleteTelemetryFeedMutation();

  const isLoading =
    isSpaceNodesLoading ||
    isDataSinkListLoading ||
    isTelemetryPropertyListLoading;

  // set data sink list
  useEffect(() => {
    if (isLoading) {
      setDataSinks(
        getTableSkeleton(['name', 'id', 'lifecycle', 'location', 'sources'], 3),
      );
      return;
    }

    if (!dataSinkList || !spaceNodes) {
      setDataSinks([]);
      return;
    }

    setDataSinks(
      dataSinkList.map(
        ({ meta: { name }, id, lifecycle, locationId, feeds }) => ({
          name,
          searchName: name.toLowerCase(),
          key: id,
          id,
          lifecycle,
          locationId,
          location: spaceNodes[locationId]?.meta.name,
          sources: feeds,
        }),
      ),
    );
  }, [dataSinkList, spaceNodes, isLoading]);

  // apply filter rules on data sink list
  useEffect(() => {
    if (isLoading) return;

    setFilteredDataSinks(
      dataSinks
        .filter(filterBySearchString(searchString))
        .filter(filterByIsActive(filterData.isActive)),
    );
  }, [dataSinks, searchString, filterData, isLoading]);

  // fetch data source list
  useEffect(() => {
    if (!selectedDataSink || !telemetryPropertyList || isLoading) return;

    if (isDataSourceListLoading) {
      setSinkDetails(
        getTableSkeleton(
          ['name', 'externalId', 'telemetryProperties', 'id'],
          3,
        ),
      );
      return;
    }

    if (
      selectedDataSink.sources.length === 0 ||
      !selectedDataSink.id ||
      !dataSourceList
    ) {
      setSinkDetails([]);
      return;
    }

    const processedSinkDetails = dataSourceList.map(
      ({ id, meta: { name }, externalId, properties }) => ({
        id,
        key: id,
        name,
        externalId,
        telemetryFeedId: selectedDataSink.sources.find(
          ({ dataSourceId }) => dataSourceId === id,
        )?.id,
        telemetryProperties: Object.keys(properties).map(
          (key) =>
            telemetryPropertyList.find(({ category }) => category === key)?.meta
              ?.name || key,
        ),
      }),
    );

    setSinkDetails(processedSinkDetails);
  }, [
    dataSourceList,
    isDataSourceListLoading,
    selectedDataSink,
    telemetryPropertyList,
    isLoading,
  ]);

  const getFiltersCount = useCallback(
    () => (filterData.isActive === NOT_RELEVANT ? 0 : 1),
    [filterData],
  );

  const onRowsSelectionChange = (
    selectedRowKeys: Key[],
    selectedRows: DataSinkRow[],
  ) => {
    setSelectedDataSink(selectedRows[0]);
  };

  const handleOnRow = (record: DataSinkRow) => ({
    onClick: () => setSelectedDataSink(record),
  });

  const onClickUnlink = (telemetryFeedId: any) => {
    confirmPopup(
      intl.formatMessage({
        defaultMessage:
          'Are you sure you want to unlink this data source from data sink?',
      }),
      () => {
        if (!selectedDataSink) return;

        deleteTelemetryFeed({
          dataSinkId: selectedDataSink.id,
          telemetryFeedId,
        })
          .unwrap()
          .then(() => {
            message.success(
              intl.formatMessage({
                defaultMessage:
                  'The data source has been unlinked from data sink.',
              }),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
      intl,
    );
  };

  const onClickEdit = (key: string) => {
    navigate(Routes.DATA_SINK_EDIT.replace(':sinkId', key));
  };

  return (
    <>
      <Title>
        <FormattedMessage defaultMessage="Data sink management" />
      </Title>
      <Card className={styles.card}>
        <Row align="middle" justify="space-between">
          <Col span={8} className={styles.columnLeft}>
            <Search onChange={setSearchString} />
          </Col>
          <Col span={8} className={globalStyles.textRight}>
            <Popover
              placement="left"
              content={<Filter onFilter={setFilterData} />}
              title={<FormattedMessage defaultMessage="Filter" />}
              trigger="click"
            >
              <Badge count={getFiltersCount()}>
                <Button
                  shape="round"
                  icon={<IoIosArrowBack />}
                  data-testid="btn-filter"
                >
                  <FormattedMessage defaultMessage="Filter" />
                </Button>
              </Badge>
            </Popover>
          </Col>
        </Row>
        <Table<DataSinkRow>
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedDataSink?.id ? [selectedDataSink.id] : [],
            onChange: onRowsSelectionChange,
          }}
          onRow={handleOnRow}
          data={filteredDataSinks}
          columns={columnsDataSink(onClickEdit)}
          pagination={{ pageSize: 100 }}
          emptyText={
            <Empty
              description={
                searchString.length || getFiltersCount() > 0 ? (
                  <FormattedMessage defaultMessage="No data sinks found that meets your criteria." />
                ) : (
                  <FormattedMessage defaultMessage="No records." />
                )
              }
            />
          }
        />
      </Card>
      <Card className={styles.card}>
        <>
          {selectedDataSink && (
            <Title level={5} className={styles.title}>
              <FormattedMessage
                defaultMessage="{sinkName} selected"
                values={{ sinkName: selectedDataSink.name }}
              />
            </Title>
          )}

          <Table<SinkDetailsRow>
            data={sinkDetails}
            columns={columnsSinkDetails(onClickUnlink)}
            emptyText={
              selectedDataSink ? (
                <FormattedMessage defaultMessage="No data sources for selected data sink." />
              ) : (
                <FormattedMessage defaultMessage="Please select at least one data sink from the above list." />
              )
            }
            pagination={{ pageSize: 100 }}
          />
        </>
      </Card>
      <Outlet />
    </>
  );
};

export default DataSinks;
