import { Empty, Skeleton, Space, Typography } from 'antd';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ReportType,
  useGetGeneratedReportsByReportConfigIdQuery,
  useGetReportConfigurationQuery,
} from 'api/report';
import { getLocationName, useGetSpaceNodeListQuery } from 'api/space';
import PdfProvider from 'components/PdfProvider';
import { PrintState } from 'components/PdfProvider/PdfProvider';
import { Routes as RootRoutes } from 'components/Routing/Routes';
import Loader from 'components/ui/atoms/Loader';
import Modal from 'components/ui/molecules/Modal';
import Table from 'components/ui/molecules/Table/Table';
import globalStyles from 'styles/global.module.scss';
import { getTableSkeleton } from 'utilities/utilities';
import ReportTypes from 'views/Insights/views/ReportTypes';
import { columns, ReportRow } from './config';

import styles from './style.module.scss';

const { Title, Text } = Typography;

type Props = {
  orgId?: string;
};

const Download: React.FC<Props> = ({ orgId }) => {
  const [reports, setReports] = useState<ReportRow[]>([]);
  const { reportConfigurationId } = useParams<'reportConfigurationId'>();
  const [reportIdToPrint, setReportIdToPrint] = useState<string | null>(null);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const navigate = useNavigate();
  const intl = useIntl();

  const {
    data: reportConfiguration,
    isFetching: isReportConfigurationsLoading,
  } = useGetReportConfigurationQuery(reportConfigurationId || '', {
    skip: !reportConfigurationId,
  });
  const {
    data: generatedReportsList,
    isFetching: isGeneratedReportListLoading,
  } = useGetGeneratedReportsByReportConfigIdQuery(
    {
      orgId: orgId || '',
      reportConfigurationId: reportConfigurationId || '',
    },
    {
      skip: !reportConfigurationId || !orgId,
    },
  );
  const { data: spaceNodes, isFetching: isSpaceNodesLoading } =
    useGetSpaceNodeListQuery({ orgId }, { skip: !orgId });

  const isLoading =
    isReportConfigurationsLoading ||
    isGeneratedReportListLoading ||
    isSpaceNodesLoading;

  // handle generated reports list query
  useEffect(() => {
    if (isLoading) {
      setReports(getTableSkeleton(['created'], 3));
      return;
    }

    if (!generatedReportsList) {
      setReports([]);
      return;
    }

    setReports(
      generatedReportsList.map(({ id, name, created }) => ({
        id,
        name,
        created,
        key: id,
      })),
    );
  }, [generatedReportsList, isLoading]);

  const backToList = () => navigate(`/${RootRoutes.INSIGHTS}`);

  const onClickDownload = (id: string) => {
    setReportIdToPrint(id);
  };

  const handleProviderState = (state: PrintState) => {
    setIsLoadingReport(state === PrintState.Ready);

    if ([PrintState.Success, PrintState.Failure].includes(state))
      setReportIdToPrint(null);
  };

  const headerSize = 55;
  const rowSize = 53;
  const padding = 45;
  const bodyStyle = {
    padding: '0 9px 24px',
    minHeight: '210px',
    maxHeight: '90vh',
    height: `${reports.length * rowSize + headerSize + padding}px`,
  };

  return (
    <div data-testid="download">
      <Modal
        visible={true}
        onCancel={backToList}
        width="400px"
        bodyStyle={bodyStyle}
        title={
          <Space
            size={0}
            direction="vertical"
            className={styles.modalTitle}
            data-testid="modalTitle"
          >
            <Skeleton
              loading={!reportConfiguration}
              active
              paragraph={{ rows: 1, width: 350 }}
            >
              <Title level={4}>{reportConfiguration?.name}</Title>
              <Text type="secondary">
                {reportConfiguration &&
                  getLocationName(
                    reportConfiguration.values.locationId,
                    spaceNodes || {},
                  )}
              </Text>
            </Skeleton>
          </Space>
        }
      >
        <Loader
          text={intl.formatMessage({ defaultMessage: 'Loading report' })}
          visible={isLoadingReport}
        >
          <div
            className={cn(styles.downloadTableWrapper, {
              [styles.emptyBorder]: reports.length === 0,
            })}
          >
            <Table<ReportRow>
              showHeader={reports.length !== 0}
              data={reports}
              rowClassName={globalStyles.tableRow}
              columns={columns(onClickDownload)}
              autoScroll={true}
              emptyText={
                <Empty
                  description={
                    <FormattedMessage defaultMessage="No reports have been found." />
                  }
                  className={globalStyles.center}
                />
              }
            />

            <PdfProvider onPrintStateChanged={handleProviderState}>
              {reportIdToPrint && reportConfiguration && (
                <ReportTypes
                  reportConfig={reportConfiguration}
                  reportType={reportConfiguration.reportType as ReportType}
                  reportId={reportIdToPrint}
                />
              )}
            </PdfProvider>
          </div>
        </Loader>
      </Modal>
    </div>
  );
};

export default Download;
