import storeConnection from './container';
import LocationTray, { LocationTraySize } from './LocationTray';

export default storeConnection(LocationTray);
export { LocationTraySize };
export {
  LocationTrayTab,
  getSelectedBuilding,
  getSelectedFloors,
  getTrayConfig,
} from './utils';
