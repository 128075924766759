import Icon from '@ant-design/icons';
import { Modal, Row, Col, Space, Typography, Tag, List } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetDataSourceByIdQuery,
  useGetTelemetryLatestQuery,
} from 'api/dataSource';
import { useGetProductByIdQuery } from 'api/product';
import { useGetSpaceNodeListQuery } from 'api/space';
import { Routes as RootRoutes } from 'components/Routing/Routes';
import Loader from 'components/ui/atoms/Loader';
import { getAttributeKeyTitle } from 'utilities/product';

import { LifecycleColorMap } from '../DeviceList/config';
import { getProductStyle } from '../Provision/views/ImporterList/config';
import TelemetryTile from './components/TelemetryTile';

import styles from './styles.module.scss';

const { Title, Text } = Typography;
const { Item } = List;

type Props = {
  orgId?: string;
};

const DeviceDetails: React.FC<Props> = ({ orgId }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { deviceId = '' } = useParams<'deviceId'>();
  const [visible, setVisible] = useState(true);

  const { data: spaceNodes = {}, isLoading: spaceNodesIsLoading } =
    useGetSpaceNodeListQuery({ orgId }, { skip: !orgId });

  const { data: device, isLoading: deviceIsLoading } =
    useGetDataSourceByIdQuery(deviceId, { skip: !deviceId });

  const { data: telemetry, isLoading: telemetryIsLoading } =
    useGetTelemetryLatestQuery(deviceId, { skip: !deviceId });

  const { data: product, isLoading: productIsLoading } = useGetProductByIdQuery(
    device?.productId || '',
    {
      skip: !device?.productId,
    },
  );

  const isPopulatingData =
    deviceIsLoading ||
    telemetryIsLoading ||
    productIsLoading ||
    spaceNodesIsLoading;

  const handleOnCancel = () => {
    setVisible(false);
    setTimeout(() => {
      navigate(`/${RootRoutes.DEVICES}`);
    }, 400); // wait for fade out animation
  };

  const detailsContent = () => {
    if (!product || !device) return null;

    const { attributes, locationId } = device;
    const { icon, color } = getProductStyle(product.provider);
    const location = locationId ? spaceNodes[locationId] : null;
    const parentLocation =
      location && location.parentId ? spaceNodes[location.parentId] : null;

    let locationString = location?.meta.name || '';

    if (location && parentLocation)
      locationString += ` - ${parentLocation.meta.name}`;

    const attributesList: [string, string][] =
      product.displayAttributes?.map(({ name, attributeKey }) => [
        attributeKey,
        name,
      ]) || [];
    return (
      <>
        <Row justify="center">
          <Col span={24}>
            <Space direction="horizontal" className={styles.centeredSpace}>
              <Icon
                component={icon}
                style={{
                  color,
                  fontSize: '35px',
                }}
              />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={styles.deviceDetailsWrapper}>
            <Title level={3} className={styles.deviceName}>
              {device.meta.name}
            </Title>
            <Title level={5} className={styles.deviceLocation}>
              {locationString}
            </Title>
            <p className={styles.deviceStatus}>
              <Tag
                className={styles.statusTag}
                color={LifecycleColorMap[device.lifecycle]}
              >
                {device.lifecycle}
              </Tag>
            </p>
          </Col>
        </Row>
        {attributesList.length > 0 && (
          <Row>
            <Col span={24}>
              <List
                size="small"
                data-testid="attribute-list"
                dataSource={attributesList}
                renderItem={([attrKey, attrName]) => (
                  <Item>
                    <Text ellipsis={true} className={styles.attributeName}>
                      {getAttributeKeyTitle(attrKey, attrName)}
                    </Text>
                    <Text
                      ellipsis={{ tooltip: true }}
                      className={styles.attributeValue}
                    >
                      {attributes[attrKey] ?? ''}
                    </Text>
                  </Item>
                )}
              />
            </Col>
          </Row>
        )}
        {telemetry && (
          <Row>
            <Col span={24}>
              <div className={styles.tilesWrapper}>
                {Object.entries(telemetry).map(([key, data]) => (
                  <TelemetryTile
                    telemetry={data}
                    property={product?.properties?.find(
                      ({ propertyKey }) => propertyKey === data.propertyKey,
                    )}
                    key={`tile_${key}`}
                  />
                ))}
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <div data-testid="view-user-form">
      <Modal
        open={visible}
        data-testid="user-form"
        footer={null}
        closable
        onCancel={handleOnCancel}
      >
        <Space direction="horizontal" className={styles.centeredSpace}>
          <Loader
            text={intl.formatMessage({
              defaultMessage: 'Loading device data...',
            })}
            visible={isPopulatingData}
          />
        </Space>
        {!isPopulatingData && detailsContent()}
      </Modal>
    </div>
  );
};

export default DeviceDetails;
