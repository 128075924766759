import { Layout, Typography, Button, Row, Col, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { useGetOrgTreeQuery } from 'api/org';
import { useDeleteUserMutation, useGetUserListQuery } from 'api/user';
import Card from 'components/ui/molecules/Card';
import Search from 'components/ui/molecules/Search';
import Table from 'components/ui/molecules/Table';
import { confirmPopup, getTableSkeleton } from 'utilities/utilities';
import { Routes } from 'views/Settings/views/User/components/Routing/Routes';

import { columns, UserRow } from './config';

import styles from './styles.module.scss';
import { filterBySearchString } from './utils';

const { Title } = Typography;

const List: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [searchString, setSearchString] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserRow[]>([]);
  const [users, setUsers] = useState<UserRow[]>(
    getTableSkeleton(['name', 'email', 'orgNode'], 3),
  );

  const [deleteUser] = useDeleteUserMutation();
  const { data: userList, isError, isFetching } = useGetUserListQuery();
  const { data: orgNodes } = useGetOrgTreeQuery();

  useEffect(() => {
    if (!users.length) {
      setFilteredUsers([]);
    } else if (searchString.length && typeof users[0].name === 'string') {
      setFilteredUsers(users.filter(filterBySearchString(searchString)));
    }
  }, [searchString, users]);

  useEffect(() => {
    if (isError) {
      setUsers([]);
      return;
    }

    if (isFetching) {
      setUsers(getTableSkeleton(['name', 'email', 'orgNode'], 3));
      return;
    }

    if (userList && orgNodes) {
      const mapped = userList
        .map(({ userId, name, email, orgId }) => ({
          key: userId,
          name,
          email,
          orgId,
          org: (orgNodes || {})[orgId],
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setUsers(mapped);
    }
  }, [isFetching, isError, userList, orgNodes]);

  const handleOnClickEdit = (userId: string): void => {
    navigate(Routes.EDIT.replace(':userId', userId));
  };

  const handleOnClickRoles = (userId: string): void => {
    navigate(Routes.ROLES.replace(':userId', userId));
  };

  const handleOnClickDelete = (userId: string, email: string): void => {
    confirmPopup(
      intl.formatMessage(
        {
          defaultMessage:
            'Are you sure you want to delete this user? ({email})',
        },
        { email },
      ),
      () => {
        deleteUser(userId)
          .unwrap()
          .then(() => {
            message.success(
              intl.formatMessage({
                defaultMessage: 'The user has been deleted.',
              }),
            );
          });
      },
      intl,
    );
  };

  return (
    <Layout data-testid="user-list">
      <Row align="middle" justify="space-between" className={styles.tableTop}>
        <Col xl={9} lg={12} sm={24}>
          <Search
            placeholder={intl.formatMessage({
              defaultMessage: 'Filter by Name, Email or Organisation',
            })}
            onChange={setSearchString}
          />
        </Col>
      </Row>
      <Card className={styles.card}>
        <Row align="middle" justify="space-between">
          <Col>
            <Title>
              <FormattedMessage defaultMessage="Users" />
            </Title>
          </Col>
          <Col>
            <Link to={{ pathname: Routes.ADD }}>
              <Button
                data-testid="button-add"
                type="primary"
                shape="round"
                icon={<AiOutlinePlus />}
              >
                <FormattedMessage defaultMessage="New user" />
              </Button>
            </Link>
          </Col>
        </Row>
        <Table<UserRow>
          data={searchString.length > 0 ? filteredUsers : users}
          columns={columns(
            handleOnClickRoles,
            handleOnClickEdit,
            handleOnClickDelete,
          )}
        />
      </Card>
      <Outlet />
    </Layout>
  );
};

export default List;
