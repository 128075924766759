import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { LocationTrayTab } from 'components/LocationTray';
import CardBase from 'components/ui/atoms/CardBase';
import Loader from 'components/ui/atoms/Loader';
import { LocationSelectorTray } from 'ducks/space/slice';

import BuildingDetailsForm from '../../components/BuildingDetailsForm';
import Footer from '../../components/Footer';
import FormTitle from '../../components/FormTitle';

import sharedStyles from '../../components/sharedStyles.module.scss';

type Props = {
  locationSelectorTray: LocationSelectorTray;
  isSaving?: boolean;
  setView: (view: LocationTrayTab) => void;
};

const SpaceCreator: React.FC<Props> = ({
  locationSelectorTray,
  isSaving,
  setView,
}) => {
  const intl = useIntl();

  return (
    <div className={sharedStyles.container}>
      <CardBase
        className={sharedStyles.formCard}
        testId="building-composer-form-card-create"
      >
        <Loader
          visible={isSaving}
          style={{
            overflow: 'hidden',
          }}
          text={intl.formatMessage({ defaultMessage: 'Saving...' })}
        >
          <div className={cn(sharedStyles.form)}>
            <div className={sharedStyles.formScroll}>
              <FormTitle
                testId="building-details-title"
                label={<FormattedMessage defaultMessage="Building details" />}
              />
              <BuildingDetailsForm
                locationSelectorTray={locationSelectorTray}
                floors={[]}
                create={true}
                onFormContinue={() => setView(LocationTrayTab.Edit)}
              />
            </div>
          </div>
        </Loader>
        <Footer />
      </CardBase>
    </div>
  );
};

export default SpaceCreator;
