import { Button, Result } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';

type Props = {
  isVisible: boolean;
  onAction: () => void;
};

const SuccessModal: React.FC<Props> = ({ isVisible, onAction }) => {
  const intl = useIntl();
  return (
    <Modal visible={isVisible}>
      <Result
        status="success"
        title={<FormattedMessage defaultMessage="Success" />}
        subTitle={
          <FormattedMessage defaultMessage="Your floor plan was succesfully uploaded and is ready to go." />
        }
        extra={
          <Button
            type="primary"
            onClick={onAction}
            aria-label={intl.formatMessage({
              defaultMessage: 'Close',
            })}
            alt-text={intl.formatMessage({
              defaultMessage: 'Close',
            })}
          >
            <FormattedMessage defaultMessage="Close" />
          </Button>
        }
      />
    </Modal>
  );
};

export default SuccessModal;
