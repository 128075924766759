import { Space, Tooltip, Button } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import React, { ReactChild } from 'react';
import { BsShieldLock } from 'react-icons/bs';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import { Org } from 'api/org';
import { getIntl } from 'components/Intl';
import { TableRow } from 'components/ui/molecules/Table';

export type UserRow = TableRow & {
  name: string;
  userId?: string;
  email: string;
  orgId: string;
  org?: Org;
  orgNode?: ReactChild;
};

export const columns = (
  onClickRoles: (key: string) => void,
  onClickEdit: (key: string) => void,
  onClickDelete: (key: string, email: string) => void,
): ColumnsType<UserRow> => {
  const intl = getIntl();
  return [
    {
      title: <FormattedMessage defaultMessage="Name" />,
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Email" />,
      dataIndex: 'email',
      key: 'email',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Organisation" />,
      key: 'orgNode',
      render: (
        value: string,
        { orgId, org, hideActions, orgNode }: UserRow,
      ): ReactChild => {
        if (orgNode) {
          return orgNode;
        }
        if (hideActions) return value;
        return <>{org ? org.meta.name : orgId}</>;
      },
    },
    {
      title: '',
      key: 'action',
      width: 160,
      render: ({
        hideActions,
        key: userId,
        email,
        name,
      }: UserRow): null | ReactChild => {
        if (hideActions) return null;
        const ariaRoles = intl.formatMessage(
          {
            defaultMessage: 'roles for {val}',
          },
          { val: `${name}` },
        );
        const ariaEdit = intl.formatMessage(
          {
            defaultMessage: 'edit user {val}',
          },
          { val: `${name}` },
        );
        const ariaDelete = intl.formatMessage(
          {
            defaultMessage: 'delete user {val}',
          },
          { val: `${name}` },
        );
        return (
          <Space size="middle" data-testid="buttons-actions">
            <Tooltip title={<FormattedMessage defaultMessage="Roles" />}>
              <Button
                type="link"
                icon={
                  <BsShieldLock alt-text={ariaRoles} aria-label={ariaRoles} />
                }
                onClick={() => onClickRoles(userId)}
                data-testid={`roles-button-${userId}`}
              />
            </Tooltip>
            <Tooltip title={<FormattedMessage defaultMessage="Edit" />}>
              <Button
                type="link"
                icon={<FiEdit2 alt-text={ariaEdit} aria-label={ariaEdit} />}
                onClick={() => onClickEdit(userId)}
                data-testid={`edit-button-${userId}`}
              />
            </Tooltip>
            <Tooltip title={<FormattedMessage defaultMessage="Delete" />}>
              <Button
                type="link"
                shape="circle"
                icon={
                  <RiDeleteBin7Line
                    alt-text={ariaDelete}
                    aria-label={ariaDelete}
                  />
                }
                onClick={() => onClickDelete(userId, email)}
                data-testid={`delete-button-${userId}`}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};
