import { connect } from 'react-redux';
import {
  LocationSelectorTray,
  openLocationTray,
  setActiveLocationId,
} from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  space: {
    locationTray: { selectorsSettings },
  },
}: RootState) => ({
  isTrayOpen: selectorsSettings[LocationSelectorTray.MAIN].open,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    onActiveLocationIdChanged: (spaceNodeId: string) => {
      dispatch(setActiveLocationId(spaceNodeId));
    },
    openLocationTray: (open: boolean) => {
      dispatch(
        openLocationTray({
          type: LocationSelectorTray.MAIN,
          payloadValue: open,
        }),
      );
    },
  };
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
