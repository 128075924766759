import { Button } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useMemo, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import {
  useNavigate,
  useParams,
  useLocation,
  createSearchParams,
} from 'react-router-dom';
import Modal from 'components/ui/molecules/Modal/Modal';
import { getFullPath as getDevicesFullPath } from 'views/Devices/components/Routing';
import { Routes as DeviceRoutes } from 'views/Devices/components/Routing/Routes';
import { getFullPath } from 'views/Devices/views/Provision/components/Routing';
import { Routes } from 'views/Devices/views/Provision/components/Routing/Routes';
import {
  TEMP_DEVICE_LS_BUILDING_ID,
  TEMP_DEVICE_LS_FLOOR_ID,
} from 'views/Devices/views/Provision/views/DeviceCandidateModal/components/Form';

import modalStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

type State = {
  reloadList: boolean;
};

const Success: React.FC = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams<'deviceId'>();
  const [isVisible, setIsVisible] = useState(true);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const importerName = searchParams.get('importerName') || undefined;

  const handleClick = (
    pathname: string,
    state?: State,
    clearLastDeviceLocation = true,
  ) => {
    setIsVisible(false);
    if (clearLastDeviceLocation) {
      localStorage.removeItem(TEMP_DEVICE_LS_FLOOR_ID);
      localStorage.removeItem(TEMP_DEVICE_LS_BUILDING_ID);
    }

    setTimeout(() => {
      navigate(
        {
          pathname,
          search: createSearchParams({
            importerName: importerName || '',
          }).toString(),
        },
        { state },
      );
    }, 400); // wait for fade out animation
  };

  return (
    <Modal
      title={
        <>
          <AiOutlineCheck size="25px" className={modalStyles.icoSuccess} />
          <FormattedMessage defaultMessage="Congratulations" />
        </>
      }
      visible={isVisible}
      footer={[
        <Button
          key="save"
          type="primary"
          shape="round"
          onClick={() =>
            handleClick(
              getDevicesFullPath(DeviceRoutes.DETAILS).replace(
                ':deviceId',
                deviceId as string,
              ),
            )
          }
        >
          <FormattedMessage defaultMessage="View device" />
        </Button>,
        <Button
          key="view"
          type="ghost"
          shape="round"
          onClick={() =>
            handleClick(
              getFullPath(Routes.CANDIDATE_LIST),
              {
                reloadList: true,
              },
              false,
            )
          }
        >
          <FormattedMessage defaultMessage="Add another" />
        </Button>,
        <Button
          key="add"
          type="ghost"
          shape="round"
          onClick={() => handleClick(getFullPath(Routes.DEVICE_ADD))}
        >
          <FormattedMessage defaultMessage="Go home" />
        </Button>,
      ]}
    >
      <Paragraph className={styles.text}>
        <FormattedMessage defaultMessage="Your device is now set up and ready to view" />
      </Paragraph>
    </Modal>
  );
};

export default Success;
