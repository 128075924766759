import { Popconfirm } from 'antd';
import Button from 'antd/lib/button/button';
import Empty from 'antd/lib/empty';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { useGetSpaceNodeTreeQuery } from 'api/space';
import { getTrayConfig, LocationTrayTab } from 'components/LocationTray';

import CardBase from 'components/ui/atoms/CardBase';
import Loader from 'components/ui/atoms/Loader';

import Tabs from 'components/ui/molecules/Tabs';
import {
  LocationSelectorStateConfig,
  LocationSelectorTray,
} from 'ducks/space/slice';
import BuildingDetailsForm from '../../components/BuildingDetailsForm';
import BuildingFloorsForm from '../../components/BuildingFloorsForm';
import BuildingRoomsForm from '../../components/BuildingRoomsForm';
import Footer from '../../components/Footer';
import FormTitle from '../../components/FormTitle';

import sharedStyles from '../../components/sharedStyles.module.scss';
import styles from './styles.module.scss';

enum BuildingFormView {
  Unset = -1,
  Building = 0,
  Floors = 1,
  Rooms = 2,
}

const tabKeyToTabTitleMap: Record<BuildingFormView, React.ReactNode> = {
  [BuildingFormView.Unset]: null,
  [BuildingFormView.Building]: <FormattedMessage defaultMessage="Building" />,
  [BuildingFormView.Floors]: <FormattedMessage defaultMessage="Floors" />,
  [BuildingFormView.Rooms]: <FormattedMessage defaultMessage="Rooms" />,
};

type Props = {
  isSaving: boolean;
  locationSelectorTray: LocationSelectorTray;
  selectorsSettings: { [selectorType: string]: LocationSelectorStateConfig };
  unsavedChanges: boolean;
  setUnsavedChanges: (isFieldsChanged: boolean) => void;
  onCreateClick: (activeTab: LocationTrayTab) => void;
  setDynamicTabs: (tabs: React.ReactFragment) => void;
};

const SpaceEditor: React.FC<Props> = ({
  isSaving,
  locationSelectorTray,
  selectorsSettings,
  onCreateClick,
  setDynamicTabs,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const [formPage, setFormPage] = useState(BuildingFormView.Unset);

  const config = getTrayConfig(selectorsSettings, locationSelectorTray);

  const { data: spaceNodeTree = [], isFetching } = useGetSpaceNodeTreeQuery(
    config.selectedBuildingId,
    {
      skip: !config?.selectedBuildingId,
    },
  );

  const floors = spaceNodeTree[0]?.children || [];
  const selectedBuilding = Object.values(spaceNodeTree)[0];

  const intl = useIntl();

  let form;

  const formTabs = useMemo(
    () => (
      <Tabs
        activeTab={formPage.toString()}
        onTabChange={(key) => {
          if (!unsavedChanges) setFormPage(+key);
        }}
        centered={true}
        className={styles.tabs}
        tabs={Object.keys(BuildingFormView)
          .filter((key) =>
            Boolean(BuildingFormView[parseInt(key, 10) as BuildingFormView]),
          )
          .map((key) => {
            const label =
              tabKeyToTabTitleMap[parseInt(key, 10) as BuildingFormView];

            return {
              key,
              tab: unsavedChanges ? (
                <Popconfirm
                  title={
                    <FormattedMessage defaultMessage="You have unsaved changes. Are you sure you wish to leave this tab?" />
                  }
                  onConfirm={() => {
                    setUnsavedChanges(false);
                    setFormPage(+key);
                  }}
                  okText={<FormattedMessage defaultMessage="Yes" />}
                  cancelText={<FormattedMessage defaultMessage="No" />}
                >
                  <span>{label}</span>
                </Popconfirm>
              ) : (
                label
              ),
            };
          })}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formPage, unsavedChanges],
  );

  useEffect(() => {
    return () => {
      setDynamicTabs(<></>);
    };
  }, []);

  useEffect(() => {
    setDynamicTabs(formTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTabs]);

  switch (formPage) {
    case BuildingFormView.Building:
      form = (
        <>
          <FormTitle
            testId="building-details-title"
            label={<FormattedMessage defaultMessage="Building details" />}
          />
          <BuildingDetailsForm
            create={false}
            selectedBuilding={selectedBuilding}
            floors={floors}
            onFormContinue={() => setFormPage(BuildingFormView.Floors)}
            locationSelectorTray={locationSelectorTray}
          />
        </>
      );
      break;
    case BuildingFormView.Floors:
      form = (
        <>
          <FormTitle
            testId="floor-details-title"
            label={<FormattedMessage defaultMessage="Floor details" />}
          />
          <BuildingFloorsForm
            selectedBuilding={selectedBuilding}
            onFormContinue={() => setFormPage(BuildingFormView.Rooms)}
            floors={floors}
          />
        </>
      );
      break;
    case BuildingFormView.Rooms:
      form = (
        <>
          <FormTitle
            testId="room-details-title"
            label={<FormattedMessage defaultMessage="Room details" />}
          />
          <BuildingRoomsForm
            floors={floors || []}
            onFormContinue={() => setFormPage(BuildingFormView.Floors)}
          />
          ;
        </>
      );
      break;
    default:
  }

  useEffect(() => {
    if (selectedBuilding && formPage === BuildingFormView.Unset)
      setFormPage(BuildingFormView.Building);
  }, [formPage, selectedBuilding]);

  const floorCount = selectedBuilding?.children?.length;
  const roomCount = selectedBuilding?.children?.reduce(
    (total, floor) => total + (floor.children?.length || 0),
    0,
  );

  if (spaceNodeTree.length === 0 && isFetching)
    return (
      <div data-testid="space-editor" className={sharedStyles.container}>
        <Loader
          visible={true}
          isAbsolute={true}
          text={intl.formatMessage({ defaultMessage: 'Loading...' })}
        />
      </div>
    );

  if (typeof selectedBuilding === 'undefined' && !isFetching)
    return (
      <Empty
        className={styles.empty}
        description={
          <FormattedMessage defaultMessage="No building to edit..." />
        }
      >
        <Button
          type="primary"
          onClick={() => onCreateClick(LocationTrayTab.Create)}
        >
          <FormattedMessage defaultMessage="Create building" />
        </Button>
      </Empty>
    );

  return (
    <div data-testid="space-editor" className={sharedStyles.container}>
      {form && (
        <CardBase className={sharedStyles.formCard}>
          <Loader
            visible={isSaving}
            style={{
              overflow: 'hidden',
            }}
            text={intl.formatMessage({ defaultMessage: 'Saving...' })}
          >
            <div className={sharedStyles.form}>
              <div className={sharedStyles.formScroll}>
                <SwitchTransition>
                  <CSSTransition
                    timeout={300}
                    key={`page-${formPage}`}
                    addEndListener={(node, done) =>
                      node.addEventListener('transitionend', done, false)
                    }
                    classNames="fade"
                    style={{ height: '100%' }}
                  >
                    <div>{form}</div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </Loader>
          <Footer
            selectedBuilding={selectedBuilding}
            floorCount={floorCount}
            roomCount={roomCount}
          />
        </CardBase>
      )}
    </div>
  );
};

export default SpaceEditor;
