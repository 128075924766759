import React from 'react';
import Routing from './components/Routing';

const User: React.FC = () => {
  return (
    <div data-testid="view-user">
      <Routing />
    </div>
  );
};

export default User;
