import { baseApi } from 'api/api';
import { TelemetryPropertyListTag } from './tags';

export enum Endpoint {
  GetTelemetryPropertyList = '/telemetry_property',
}

export enum TelemetryPropertyDataType {
  FLOAT = 'float',
  STRING = 'string',
}

export type TelemetryProperty = {
  category?: string;
  unit?: string;
  id?: string;
  meta?: Record<string, string>;
  dataType: TelemetryPropertyDataType;
};

export type GetTelemetryPropertyListResponse = Promise<TelemetryProperty[]>;

export const telemetryPropertyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getTelemetryPropertyList(telemetryPropertyId, productId)
    getTelemetryPropertyList: build.query<
      TelemetryProperty[],
      {
        telemetryPropertyId?: string[];
        productId?: string;
      }
    >({
      query: (params) => ({
        url: Endpoint.GetTelemetryPropertyList,
        options: {
          method: 'GET',
          params: {
            telemetryPropertyId: params?.telemetryPropertyId,
            productId: params?.productId,
          },
        },
      }),
      providesTags: [TelemetryPropertyListTag.TelemetryPropertyList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTelemetryPropertyListQuery,
  useGetTelemetryPropertyListQuery,
} = telemetryPropertyApi;
