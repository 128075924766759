import { Button, Typography } from 'antd';
import { FaClipboard } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/ui/molecules/Modal';

import styles from '../../styles.module.scss';

const { Title } = Typography;

type Props = {
  isVisible: boolean;
  onAction: () => void;
};

const NoFloorPlanModal: React.FC<Props> = ({ isVisible, onAction }) => {
  const intl = useIntl();
  return (
    <Modal
      title={
        <>
          <FaClipboard className={styles.ico} />
          <FormattedMessage defaultMessage="Upload floor plan" />
        </>
      }
      backgroundClickable
      visible={isVisible}
      footer={[
        <Button
          key="button"
          data-testid="btn-upload-floor-plan"
          type="primary"
          onClick={onAction}
          aria-label={intl.formatMessage({
            defaultMessage: 'Upload floorplan',
          })}
          alt-text={intl.formatMessage({
            defaultMessage: 'Upload floorplan',
          })}
        >
          <FormattedMessage defaultMessage="Upload floor plan" />
        </Button>,
      ]}
    >
      <Title className={styles.text} level={5}>
        <FormattedMessage defaultMessage="You have no floor plans, please upload a floor plan." />
      </Title>
    </Modal>
  );
};

export default NoFloorPlanModal;
