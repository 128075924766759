import { Button, Col, Layout, message, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useDeleteAPIKeyMutation, useGetAPIKeyListQuery } from 'api/apiKey';
import { useGetOrgTreeQuery } from 'api/org';
import Card from 'components/ui/molecules/Card';
import Table from 'components/ui/molecules/Table';
import { confirmPopup, getTableSkeleton } from 'utilities/utilities';
import { Routes } from 'views/Settings/views/ApiKey/components/Routing/Routes';

import { APIKeyRow, columns } from './config';
import styles from './styles.module.scss';

const { Title } = Typography;

const List: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [apiKeys, setApiKeys] = useState<APIKeyRow[]>(
    getTableSkeleton(['name', 'description', 'orgId'], 3),
  );
  const [deleteAPIKey] = useDeleteAPIKeyMutation();
  const {
    data: apiKeyList,
    isFetching: isApiKeyListFetching,
    isError: isApiKeyListError,
  } = useGetAPIKeyListQuery();
  const {
    data: orgTree,
    isFetching: isOrgTreeFetching,
    isError: isOrgTreeError,
  } = useGetOrgTreeQuery();

  useEffect(() => {
    if (isApiKeyListFetching || isOrgTreeFetching) {
      setApiKeys(getTableSkeleton(['name', 'description', 'orgId'], 3));
      return;
    }

    if (isApiKeyListError || isOrgTreeError || !orgTree || !apiKeyList) {
      setApiKeys([]);
      return;
    }

    setApiKeys(
      apiKeyList
        .map((apiKey) => ({
          ...apiKey,
          key: apiKey.id,
          org: orgTree[apiKey.orgId],
        }))
        .sort((a, b) => a.meta?.name?.localeCompare(b.meta?.name) || 1),
    );
  }, [
    apiKeyList,
    isApiKeyListError,
    isApiKeyListFetching,
    isOrgTreeError,
    isOrgTreeFetching,
    orgTree,
  ]);

  const handleOnClickRoles = (apiKeyId: string): void => {
    navigate(Routes.ROLES.replace(':apiKeyId', apiKeyId));
  };

  const handleOnClickRegenerate = (apiKeyId: string): void => {
    navigate(Routes.REGENERATE.replace(':apiKeyId', apiKeyId));
  };

  const handleOnClickDelete = (apiKeyId: string): void => {
    confirmPopup(
      intl.formatMessage({
        defaultMessage: 'Are you sure you want to delete this API key?',
      }),
      () =>
        deleteAPIKey(apiKeyId)
          .unwrap()
          .then(() => {
            message.success(
              intl.formatMessage({
                defaultMessage: 'The API key has been deleted.',
              }),
            );
          }),
      intl,
    );
  };

  return (
    <Layout data-testid="api-key-list">
      <Card className={styles.card}>
        <Row align="middle" justify="space-between">
          <Col>
            <Title>
              <FormattedMessage defaultMessage="API Keys" />
            </Title>
          </Col>
          <Col>
            <Link to={{ pathname: Routes.ADD }}>
              <Button
                data-testid="button-add"
                type="primary"
                shape="round"
                icon={<AiOutlinePlus />}
              >
                <FormattedMessage defaultMessage="New API Key" />
              </Button>
            </Link>
          </Col>
        </Row>
        <Table<APIKeyRow>
          data={apiKeys}
          columns={columns(
            handleOnClickRoles,
            handleOnClickRegenerate,
            handleOnClickDelete,
          )}
        />
      </Card>
      <Outlet />
    </Layout>
  );
};

export default List;
