import React, { useEffect, useRef } from 'react';

//   callback
type useObserverCallback = (entry: ResizeObserverEntry) => void;

const useObserver = (
  callback: useObserverCallback,
  element: React.MutableRefObject<any>,
) => {
  const current = element && element.current;
  const observer = useRef<ResizeObserver | null>(null);

  const wrappedCallback = (entries: ResizeObserverEntry[]) => {
    if (entries.length === 0) return;
    callback(entries[0]);
  };

  const observe = () => {
    if (element && element.current && observer.current) {
      observer.current.observe(element.current);
    }
  };

  useEffect(() => {
    if (observer && observer.current && current) {
      observer.current.unobserve(current);
    }

    observer.current = new ResizeObserver(wrappedCallback);
    observe();

    return () => {
      if (observer && observer.current && element && element.current) {
        observer.current.unobserve(element.current);
      }
    };
  }, [current]);
};

export default useObserver;
