import { Badge } from 'antd';
import React from 'react';

import { BsFillBellFill } from 'react-icons/bs';

import styles from './styles.module.scss';

const Notificator: React.FC = () => {
  return (
    <Badge count={1} dot data-testid="notificator-badge">
      <BsFillBellFill
        className={styles.icon}
        role="img"
        title="notification bell"
      />
    </Badge>
  );
};

export default Notificator;
