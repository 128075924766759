import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMap } from 'react-leaflet';
import { MapContext, ZOOM_DELTA, ZOOM_STEPS } from '../../LeafletMap';

import styles from './styles.module.scss';

const ZoomInfo: React.FC = () => {
  const map = useMap();
  const mapContext = useContext(MapContext);
  const [zoomValue, setZoomValue] = useState<number | null>(null);

  const updateZoomValue = () => {
    if (!map) return;

    setZoomValue(
      (Math.abs(map.getZoom() - map.getMinZoom()) /
        (map.getMinZoom() + ZOOM_STEPS * ZOOM_DELTA - map.getMinZoom())) *
        100,
    );
  };

  useEffect(() => {
    if (!map || !mapContext.maxZoom) return;

    map.on('zoomend', updateZoomValue);

    // eslint-disable-next-line consistent-return
    return () => {
      map.off('zoomend', updateZoomValue);
    };
  }, [map, mapContext.maxZoom]);

  if (zoomValue === null) return <></>;

  return (
    <div data-testid="zoom-label" className={styles.container}>
      <FormattedMessage
        defaultMessage="Zoom: {value}"
        values={{ value: `${(zoomValue || 0).toFixed(0)}%` }}
      />
    </div>
  );
};

export default ZoomInfo;
