import { Form as AntdForm, Input, message, Button, TreeSelect } from 'antd';

import { snakeCase } from 'lodash';
import React, { ReactChild, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOrgTreeQuery } from 'api/org';
import { orgTreeSelectData } from 'api/org/utils';
import {
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from 'api/user';

import Loader from 'components/ui/atoms/Loader';
import Modal from 'components/ui/molecules/Modal';
import { formatObjectKeys } from 'utilities/utilities';
import { getFullPath } from '../../components/Routing/Routing';

const { useForm, Item } = AntdForm;

type Props = {
  title: string | ReactChild;
};

const Form: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const orgNodesQuery = useGetOrgTreeQuery();
  const orgTreeSelect = useMemo(
    () => orgTreeSelectData(orgNodesQuery.data || {}),
    [orgNodesQuery.isSuccess],
  );
  const { userId } = useParams<'userId'>();
  const [form] = useForm();
  const [isVisible, setIsVisible] = useState(true);
  const getUser = useGetUserQuery(userId as string, {
    skip: !userId,
  });
  const [createUser, createUserState] = useCreateUserMutation();
  const [updateUser, updateUserState] = useUpdateUserMutation();
  const isLoading = useMemo(
    () => createUserState.isLoading || updateUserState.isLoading,
    [createUserState.isLoading, updateUserState.isLoading],
  );

  const backToList = (): void => {
    setIsVisible(false);

    setTimeout(() => navigate(getFullPath(null, true)), 400); // wait for fade out animation
  };

  useEffect(() => {
    if (getUser.isSuccess)
      form.setFieldsValue(formatObjectKeys(getUser.data, snakeCase));
  }, [getUser, form]);

  const handleSaveUser = (promise: Promise<any>): void => {
    promise
      .then(() => {
        message.success(
          intl.formatMessage({ defaultMessage: 'The user has been saved.' }),
        );
        backToList();
      })
      .catch((err) => {
        message.error(
          err?.data?.message ||
            intl.formatMessage({
              defaultMessage: 'An error occurred while saving the user.',
            }),
        );
      });
  };
  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then((values) => {
        if (userId)
          handleSaveUser(updateUser({ user: values, userId }).unwrap());
        else handleSaveUser(createUser(values).unwrap());
      })
      .catch(() => {
        // Do nothing
      });
  };

  return (
    <div data-testid="view-user-form">
      <Modal
        title={title}
        visible={isVisible}
        data-testid="user-form"
        footer={[
          <Button
            key="save"
            type="primary"
            shape="round"
            loading={isLoading}
            onClick={handleFormSubmit}
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>,
          <Button
            key="cancel"
            type="ghost"
            shape="round"
            onClick={backToList}
            disabled={isLoading}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>,
        ]}
      >
        <Loader
          text={intl.formatMessage({
            defaultMessage: 'Loading user data...',
          })}
          visible={getUser.isFetching}
        >
          <AntdForm name="basic" form={form} layout="vertical">
            <Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required" />
                  ),
                },
              ]}
            >
              <Input />
            </Item>

            <Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required" />
                  ),
                },
                {
                  type: 'email',
                  message: (
                    <FormattedMessage defaultMessage="Please insert a valid email address" />
                  ),
                },
              ]}
            >
              <Input disabled={!!userId} />
            </Item>

            <Item
              label="Organisation"
              name="org_id"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="This field is required" />
                  ),
                },
              ]}
            >
              <TreeSelect
                style={{ width: '100%' }}
                treeLine={{ showLeafIcon: false }}
                treeData={orgTreeSelect}
                data-testid="tree-select"
              />
            </Item>
          </AntdForm>
        </Loader>
      </Modal>
    </div>
  );
};

export default Form;
