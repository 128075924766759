/* eslint-disable camelcase */
import { camelCase } from 'lodash';
import { baseApi } from 'api/api';
import { TelemetryFeed } from 'api/dataSink';
import { formatObjectKeys } from 'utilities/utilities';
import { DataSourceTag } from './tags';

export enum Endpoint {
  GetDataSources = '/data_source',
  DeleteDataSourceCandidate = '/data_source/candidate',
  UpdateDataSource = '/data_source/:deviceId',
  ProvisionDataSource = '/data_source/lifecycle/provision',
  GetTelemetryLatest = '/data_source/:deviceId/telemetry/latest',
}

export type DataSource = {
  id: string;
  externalId: string;
  orgId: string;
  locationId: string;
  productId: string;
  meta: DataSourceMeta;
  systemMeta: DataSourceSystemMeta;
  attributes: Record<string, string>;
  feeds: TelemetryFeed[];
  properties: DataSourceProperty;
  lifecycle: DataSourceLifecycle;
};

export enum DataSourceLifecycle {
  CANDIDATE = 'candidate',
  OPERATIONAL = 'operational',
  DETACHED = 'detached',
  DISABLED = 'disabled',
  RMA = 'rma',
}

export enum DataSourceImporterType {
  BMS = 'bms',
}

type DataSourceProperty = {
  [key: string]: {
    unit: string;
  };
};

type DataSourceMeta = {
  name: string;
  description: string;
  created: string;
  modified: string;
};

type DataSourceSystemMeta = {
  importerName?: string;
};

export type TelemetryData = Record<string, TelemetryRecord>;

export type TelemetryRecord = {
  propertyKey: string;
  category: string;
  unit: string;
  time_stamp: string;
  value: number;
};

export type UpdateDataSourcePayload = {
  name?: string;
  description?: string;
  external_id?: string;
  location_id?: string;
  product_id?: string;
  org_id?: string;
};

export type ProvisionDataSourcePayload = {
  name?: string;
  description?: string;
  locationId?: string;
  productId?: string;
  orgId: string;
};

export type GetDataSourceListPayload = {
  dataSourceId?: string | string[];
  orgId?: string;
  spaceId?: string | string[];
  productId?: string;
  lifecycle?: DataSourceLifecycle | DataSourceLifecycle[];
  limit?: number;
  offset?: number;
  importerName?: DataSourceImporterType;
};

// due to key collision in default response processing
// we need to parse the response manually
const parseTelemetryLatest = (raw: Record<string, any>) =>
  Object.keys(raw).reduce(
    (acc, key) => ({
      ...acc,
      [key]: formatObjectKeys(raw[key], camelCase),
    }),
    {} as Record<string, any>,
  );

export const dataSourceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getTelemetryLatest(deviceId)
    getTelemetryLatest: build.query<TelemetryData, string>({
      query: (deviceId) => ({
        url: Endpoint.GetTelemetryLatest.replace(':deviceId', deviceId),
        options: {
          method: 'GET',
        },
        parseResponseFunction: parseTelemetryLatest,
      }),
      providesTags: [DataSourceTag.DataSourceTelemetryCollection],
    }),
    // * deleteDataSourceCandidate(candidateId)
    deleteDataSourceCandidate: build.mutation<void, string>({
      query: (candidateId) => ({
        url: Endpoint.DeleteDataSourceCandidate,
        options: {
          method: 'DELETE',
          params: {
            candidate_id: candidateId,
          },
        },
      }),
      invalidatesTags: [DataSourceTag.DataSourceList],
    }),
    // * updateDataSource({ device, deviceId })
    updateDataSource: build.mutation<
      DataSource,
      { device: UpdateDataSourcePayload; deviceId: string }
    >({
      query: ({ device, deviceId }) => ({
        url: Endpoint.UpdateDataSource.replace(':deviceId', deviceId),
        options: {
          method: 'PATCH',
          data: device,
        },
      }),
      invalidatesTags: [DataSourceTag.DataSourceList],
    }),
    // * provisionDataSource({ data, candidateId })
    provisionDataSource: build.mutation<
      DataSource,
      { data: ProvisionDataSourcePayload; candidateId: string }
    >({
      query: ({ data, candidateId }) => ({
        url: Endpoint.ProvisionDataSource,
        options: {
          method: 'POST',
          params: {
            candidateId,
            ...data,
          },
        },
      }),
      invalidatesTags: [DataSourceTag.DataSourceList],
    }),
    // * getDataSourceList({ orgId, dataSourceId, spaceId, productId, lifecycle, offset, limit })
    getDataSourceList: build.query<DataSource[], GetDataSourceListPayload>({
      query: (params) => ({
        url: Endpoint.GetDataSources,
        options: {
          method: 'GET',
          params: {
            orgId: params.orgId,
            dataSourceId: params.dataSourceId,
            spaceId: params.spaceId,
            productId: params.productId,
            lifecycle: params.lifecycle,
            offset: params.offset || 0,
            importerName: params.importerName,
            limit: params.limit || 5000,
          },
        },
      }),
      providesTags: [DataSourceTag.DataSourceList],
    }),
    // * getDataSourceById(dataSourceId)
    getDataSourceById: build.query<DataSource | undefined, string>({
      query: (dataSourceId) => ({
        url: Endpoint.GetDataSources,
        options: {
          method: 'GET',
          params: {
            dataSourceId,
          },
        },
      }),
      transformResponse: (response: DataSource[]) => {
        return response?.length === 1 ? response[0] : undefined;
      },
      providesTags: [DataSourceTag.DataSourceList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDataSourceByIdQuery,
  useGetDataSourceListQuery,
  useUpdateDataSourceMutation,
  useProvisionDataSourceMutation,
  useLazyGetDataSourceListQuery,
  useGetTelemetryLatestQuery,
} = dataSourceApi;
