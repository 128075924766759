import { baseApi } from 'api/api';
import { Meta } from 'api/meta';
import { DataSinkTag } from './tags';

export enum Endpoint {
  GetDataSinks = '/data_sink',
  GetTelemetryFeeds = '/data_sink/:dataSinkId/feeds',
  CreateTelemetryFeed = '/data_sink/:dataSinkId/feed',
  DeleteTelemetryFeed = '/data_sink/:dataSinkId/feed/:telemetryFeedId',
  CreateDataSink = '/data_sink',
  EditDataSink = '/data_sink/:dataSinkId',
}

export type DataSink = {
  id: string;
  orgId: string;
  locationId: string;
  typeId: string;
  lifecycle: DataSinkLifecycle;
  feeds: TelemetryFeed[];
  meta: Meta;
};

export type TelemetryFeed = {
  id?: string;
  dataSinkId?: string;
  dataSourceId: string;
  telemetryPropertyId: string;
  activeFrom: string;
  activeTo: string;
};

export enum DataSinkLifecycle {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export type CreateDataSinkRequest = Omit<
  DataSink,
  'id' | 'feeds' | 'lifecycle'
>;

export type EditDataSinkRequest = Omit<DataSink, 'id' | 'feeds' | 'lifecycle'>;

export type CreateTelemetryFeedData = {
  activeFrom?: string;
  activeTo?: string;
  dataSourceId: string;
  telemetryPropertyId: string;
};

export type GetDataSinkListPayload = {
  locationId?: string;
  dataSinkId?: string;
  includeDeleted?: boolean;
  orgId?: string;
};

export const dataSinkApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getDataSinkList(payload)
    getDataSinkList: build.query<DataSink[], GetDataSinkListPayload>({
      query: (payload) => ({
        url: Endpoint.GetDataSinks,
        options: {
          method: 'GET',
          params: payload,
        },
      }),
      providesTags: [DataSinkTag.DataSinkList],
    }),
    // * getDataSinkById(dataSinkId)
    getDataSinkById: build.query<DataSink | undefined, string>({
      query: (dataSinkId) => ({
        url: Endpoint.GetDataSinks,
        options: {
          method: 'GET',
          params: {
            dataSinkId,
          },
        },
      }),
      providesTags: [DataSinkTag.DataSinkList],
      transformResponse: (response: DataSink[], _, dataSinkId) =>
        response.find((data) => data.id === dataSinkId),
    }),
    // * getTelemetryFeeds(dataSinkId)
    getTelemetryFeeds: build.query<TelemetryFeed[], string>({
      query: (dataSinkId) => ({
        url: Endpoint.GetTelemetryFeeds.replace(':dataSinkId', dataSinkId),
        options: {
          method: 'GET',
        },
      }),
      providesTags: [DataSinkTag.TelemetryFeedList],
    }),
    // * deleteTelemetryFeed({ dataSinkId, telemetryFeedId })
    deleteTelemetryFeed: build.mutation<
      void,
      { dataSinkId: string; telemetryFeedId: string }
    >({
      query: ({ dataSinkId, telemetryFeedId }) => ({
        url: Endpoint.DeleteTelemetryFeed.replace(
          ':dataSinkId',
          dataSinkId,
        ).replace(':telemetryFeedId', telemetryFeedId),
        options: {
          method: 'DELETE',
        },
      }),
      invalidatesTags: [
        DataSinkTag.TelemetryFeedList,
        DataSinkTag.DataSinkList,
      ],
    }),
    // * createTelemetryFeed({ dataSinkId, data })
    createTelemetryFeed: build.mutation<
      DataSink,
      {
        dataSinkId: string;
        data: CreateTelemetryFeedData | CreateTelemetryFeedData[];
      }
    >({
      query: ({ dataSinkId, data }) => ({
        url: Endpoint.CreateTelemetryFeed.replace(':dataSinkId', dataSinkId),
        options: {
          method: 'POST',
          data,
        },
      }),
      invalidatesTags: [
        DataSinkTag.TelemetryFeedList,
        DataSinkTag.DataSinkList,
      ],
    }),
    // * createDataSink(data)
    createDataSink: build.mutation<DataSink, CreateDataSinkRequest>({
      query: (data) => ({
        url: Endpoint.CreateDataSink,
        options: {
          method: 'POST',
          data,
        },
      }),
      invalidatesTags: [DataSinkTag.DataSinkList],
    }),
    // * editDataSink({ dataSinkId, data })
    editDataSink: build.mutation<
      DataSink,
      { dataSinkId: string; data: EditDataSinkRequest }
    >({
      query: ({ dataSinkId, data }) => ({
        url: Endpoint.EditDataSink.replace(':dataSinkId', dataSinkId),
        options: {
          method: 'PATCH',
          data,
        },
      }),
      invalidatesTags: [DataSinkTag.DataSinkList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDataSinkListQuery,
  useLazyGetDataSinkByIdQuery,
  useDeleteTelemetryFeedMutation,
  useCreateTelemetryFeedMutation,
  useCreateDataSinkMutation,
  useEditDataSinkMutation,
} = dataSinkApi;

export default dataSinkApi;
