import { Button, Image, Progress, Tooltip, Typography } from 'antd';
import { useMemo, ReactElement } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { BsFileEarmarkImage } from 'react-icons/bs';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import Card from 'components/ui/molecules/Card';
import { RequestStatus } from '../../ImageUploadField';
import styles from './styles.module.scss';
import { getColor } from './utils';

const { Title } = Typography;

type Props = {
  imageUrl?: string;
  progress?: number;
  leftText?: string | ReactElement;
  rightText?: string | ReactElement;
  markedToRemove: boolean;
  requestStatus: RequestStatus;
  onRemove: () => void;
};

const UploadItem: React.FC<Props> = ({
  imageUrl,
  leftText = '',
  rightText = '',
  requestStatus,
  markedToRemove,
  progress,
  onRemove,
}) => {
  const [strokeColor, trailColor] = useMemo(
    () => getColor(requestStatus),
    [requestStatus],
  );

  return (
    <div className={styles.wrapper} data-testid="upload-item">
      <Card className={styles.card}>
        <Image
          src={imageUrl}
          preview={imageUrl ? { mask: <BiSearch /> } : false}
          placeholder={<BsFileEarmarkImage />}
          className={styles.img}
        />
        <div className={styles.rightContent}>
          <div className={styles.labelsContainer}>
            <Title ellipsis level={5}>
              {leftText}
            </Title>
            <Title level={5} className={styles.rightText}>
              {rightText}
            </Title>
          </div>
          {typeof progress !== 'undefined' && (
            <Progress
              percent={progress}
              size="small"
              status={
                requestStatus === RequestStatus.Processing
                  ? 'active'
                  : undefined
              }
              trailColor={trailColor}
              strokeColor={strokeColor}
              showInfo={false}
            />
          )}
        </div>
      </Card>
      <Tooltip
        title={
          markedToRemove ? (
            <FormattedMessage defaultMessage="Undo file removal" />
          ) : (
            <FormattedMessage defaultMessage="Remove file" />
          )
        }
      >
        <Button
          data-testid="remove-button"
          className={styles.button}
          type="link"
          disabled={requestStatus === RequestStatus.Processing}
          icon={markedToRemove ? <RiArrowGoBackLine /> : <AiFillCloseCircle />}
          onClick={onRemove}
        />
      </Tooltip>
    </div>
  );
};

export default UploadItem;
