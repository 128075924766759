/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum LocationSelectorTray {
  MAIN = 'main',
  TEMPORARY = 'temporary',
}
export type LocationSelectorTrayType = keyof typeof LocationSelectorTray;
export type LocationTrayMode = 'view' | 'edit';
export type LocationTrayFloorViews = 'perspective' | 'angled';
export type LocationSelectorStateConfig = {
  open: boolean;
  selectedBuildingId: string;
  selectedFloorId: string;
};
export interface LocationTrayPayload<TPayload> {
  payloadValue: TPayload;
  type: LocationSelectorTray;
}

// Initial state type
export type SpaceState = {
  floorplanUploadProgress: number;
  locationTray: {
    mode: LocationTrayMode;
    showGhostFloors: boolean;
    floorsView: LocationTrayFloorViews;
    isSaving: boolean;
    selectorsSettings: Record<
      LocationSelectorTray,
      LocationSelectorStateConfig
    >;
    unsavedChanges: boolean;
  };
  activeLocationId: string;
};

const SEL_BLD_ID_LS_KEY = 'store:space:locationTray:selectedBuildingId';
const SEL_FLR_ID_LS_KEY = 'store:space:locationTray:selectedFloorId';
const ACTIVE_LOCATION_ID_LS_KEY = 'store:space:activeLocationId';

// Create the Redux store 'slice' (combines state and reducers, makes action
// creators). Uses 'Immer' under the hood, so state can be 'mutated' in place.
// (It doesn't really mutate, it uses a draft system).
const spaceSlice = createSlice({
  name: 'space',
  initialState: {
    floorplanUploadProgress: 0,
    locationTray: {
      mode: 'view',
      showGhostFloors: true,
      floorsView: 'perspective',
      isSaving: false,
      unsavedChanges: false,
      selectorsSettings: {
        main: {
          open: false,
          selectedBuildingId: localStorage.getItem(SEL_BLD_ID_LS_KEY) || '',
          selectedFloorId: localStorage.getItem(SEL_FLR_ID_LS_KEY) || '',
        },
        temporary: {
          open: false,
          selectedBuildingId: '',
          selectedFloorId: '',
        },
      },
    },
    activeLocationId: localStorage.getItem(ACTIVE_LOCATION_ID_LS_KEY) || '',
  } as SpaceState,
  reducers: {
    setFloorplanUploadProgress: (state, { payload }: PayloadAction<number>) => {
      state.floorplanUploadProgress = payload;
    },
    setLocationTrayIsSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.locationTray.isSaving = payload;
    },
    openLocationTray: (
      state,
      { payload }: PayloadAction<LocationTrayPayload<boolean>>,
    ) => {
      const settings = state.locationTray.selectorsSettings[payload.type];
      settings.open = payload.payloadValue;
    },
    setSelectedBuildingId: (
      state,
      { payload }: PayloadAction<LocationTrayPayload<string>>,
    ) => {
      const settings = state.locationTray.selectorsSettings[payload.type];
      settings.selectedBuildingId = payload.payloadValue;
      settings.selectedFloorId = '';
      // sync 'main' with localStorage
      if (payload.type === LocationSelectorTray.MAIN) {
        localStorage.setItem(SEL_BLD_ID_LS_KEY, payload.payloadValue);
        localStorage.setItem(SEL_FLR_ID_LS_KEY, '');
      }
    },
    setSelectedFloorId: (
      state,
      { payload }: PayloadAction<LocationTrayPayload<string>>,
    ) => {
      const settings = state.locationTray.selectorsSettings[payload.type];
      settings.selectedFloorId = payload.payloadValue;
      // sync 'main' with localStorage
      if (payload.type === LocationSelectorTray.MAIN) {
        localStorage.setItem(SEL_FLR_ID_LS_KEY, payload.payloadValue);
      }
    },
    setActiveLocationId: (state, { payload }: PayloadAction<string>) => {
      state.activeLocationId = payload;
      localStorage.setItem(ACTIVE_LOCATION_ID_LS_KEY, payload);
    },
    setShowGhostFloors: (state, { payload }: PayloadAction<boolean>) => {
      state.locationTray.showGhostFloors = payload;
    },
    setFloorsView: (
      state,
      { payload }: PayloadAction<LocationTrayFloorViews>,
    ) => {
      state.locationTray.floorsView = payload;
    },
    setLocationTrayMode: (
      state,
      { payload }: PayloadAction<LocationTrayMode>,
    ) => {
      state.locationTray.mode = payload;
    },
    setUnsavedChanges: (state, { payload }: PayloadAction<boolean>) => {
      state.locationTray.unsavedChanges = payload;
    },
  },
});

// Export the reducer and all synchronous actions
export const space = spaceSlice.reducer;
export const {
  openLocationTray,
  setSelectedBuildingId,
  setSelectedFloorId,
  setActiveLocationId,
  setShowGhostFloors,
  setFloorsView,
  setLocationTrayMode,
  setUnsavedChanges,
  setFloorplanUploadProgress,
  setLocationTrayIsSaving,
} = spaceSlice.actions;
