import Icon from '@ant-design/icons';
import { GB, ES } from 'country-flag-icons/react/3x2';
import { ReactElement } from 'react';
import { getIntl } from 'components/Intl';

type Flag = {
  label: string;
  flag: ReactElement;
};

type FlagList = Record<string, Flag>;

export const getLanguages = (): FlagList => {
  const { formatMessage } = getIntl();
  return {
    en: {
      label: formatMessage({ defaultMessage: 'English' }),
      flag: (
        <Icon
          component={() => <GB />}
          alt-text={formatMessage({ defaultMessage: 'Switch to English' })}
          aria-label={formatMessage({ defaultMessage: 'UK flag' })}
        />
      ),
    },
    es: {
      label: formatMessage({ defaultMessage: 'Spanish' }),
      flag: (
        <Icon
          component={() => <ES />}
          alt-text={formatMessage({ defaultMessage: 'Switch to Spanish' })}
          aria-label={formatMessage({ defaultMessage: 'Spanish flag' })}
        />
      ),
    },
  };
};

export type languagesMapKey = keyof FlagList;
