import { BaseQueryFn, createApi } from '@reduxjs/toolkit/dist/query/react';
import { message } from 'antd';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { camelCase, snakeCase } from 'lodash';
import { getIntl } from 'components/Intl';
import {
  enumToArray,
  formatObjectKeys,
  paramsSerializer as axiosParamSerializer,
} from 'utilities/utilities';
import { ApiKeyTag } from './apiKey/tags';
import { ConfigurationTag } from './configuration/tags';
import { DataSinkTag } from './dataSink/tags';
import { DataSinkTypeTag } from './dataSinkType/tags';
import { DataSourceTag } from './dataSource/tags';
import { OrgTag } from './org/tags';
import { ProductTag } from './product/tags';
import { ReportTag } from './report/tags';
import { SpaceTag } from './space/tags';
import { TelemetryPropertyListTag } from './telemetryProperty/tags';
import { UserTag } from './user/tags';

type ApiError = {
  detail: {
    message: string;
    vars: Record<string, string>;
  };
  // eslint-disable-next-line camelcase
  error_id: string;
  vars: Record<string, string>;
};

export const defaultErrorHandler = (err: AxiosError<ApiError>): Error => {
  if (typeof err === 'undefined') return new Error('Unknown Error');
  if (typeof err === 'string') return new Error(err);

  if (err.response?.data?.detail) {
    if (err.response.data.detail.message) {
      let msg: string = err.response.data.detail.message;

      if (err.response.data.detail.vars) {
        Object.keys(err.response.data.detail.vars).forEach((key) => {
          msg = msg.replace(
            `{${key}}`,
            err.response?.data.detail.vars[key] || '',
          );
        });
      }

      message.error(msg);
    } else if (!err.response.data.error_id) {
      message.error(err.response.data.detail.toString());
    } else {
      const args: Record<string, any> = {
        defaultMessage: err.response.data.detail,
        id: err.response.data.error_id,
      };
      message.error(getIntl().formatMessage(args, err.response.data.vars));
    }
  }

  return new Error(err?.message);
};

export const getApiBaseUrl = () =>
  `${process.env.REACT_APP_API_URL_BASE_PLATFORM}` +
  `${process.env.REACT_APP_API_URL_VERSION}`;

const doRequest = async <Data,>(
  url: string,
  requestOptions?: AxiosRequestConfig,
  parseResponseFunction?: (data: Data) => Data,
): Promise<Data> => {
  const options = requestOptions;

  try {
    if (options?.params) {
      options.params = formatObjectKeys(options.params, snakeCase);
      options.paramsSerializer = axiosParamSerializer;
    }

    if (options?.data && !(options.data instanceof FormData))
      options.data = formatObjectKeys(options.data, snakeCase);

    const { data } = await axios({
      url: `${getApiBaseUrl()}${url}`,
      ...options,
    });

    return (
      parseResponseFunction
        ? parseResponseFunction(data)
        : formatObjectKeys(data, camelCase)
    ) as Data;
  } catch (err: any) {
    throw defaultErrorHandler(err);
  }
};

export const doRtkqRequest =
  (): BaseQueryFn<
    {
      url: string;
      options: AxiosRequestConfig;
      parseResponseFunction?: (data: any) => any;
    },
    unknown,
    unknown
  > =>
  async ({ url, options, parseResponseFunction }) => {
    const requestOptions = options;

    try {
      if (options?.params) {
        requestOptions.params = formatObjectKeys(options.params, snakeCase);
        requestOptions.paramsSerializer = axiosParamSerializer;
      }

      if (options?.data)
        requestOptions.data = formatObjectKeys(options.data, snakeCase);

      const response = await axios({
        url: `${getApiBaseUrl()}${url}`,
        ...requestOptions,
      });

      const responseData = response.data;

      return {
        data: parseResponseFunction
          ? parseResponseFunction(responseData)
          : formatObjectKeys(responseData, camelCase),
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      defaultErrorHandler(err);
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const tags = [
  ...enumToArray(UserTag),
  ...enumToArray(ApiKeyTag),
  ...enumToArray(OrgTag),
  ...enumToArray(DataSinkTag),
  ...enumToArray(DataSourceTag),
  ...enumToArray(DataSinkTypeTag),
  ...enumToArray(ConfigurationTag),
  ...enumToArray(TelemetryPropertyListTag),
  ...enumToArray(ReportTag),
  ...enumToArray(ProductTag),
  ...enumToArray(SpaceTag),
];

export const baseApi = createApi({
  tagTypes: tags,
  baseQuery: doRtkqRequest(),
  endpoints: () => ({}),
});
export type BaseApiType = typeof baseApi;

export default doRequest;
