import doRequest from 'api/api';

export enum Endpoint {
  GetPdf = '/pdf/generate',
}

export const getPDF = async (html: string): Promise<Blob> =>
  doRequest<Blob>(
    Endpoint.GetPdf,
    {
      method: 'POST',
      responseType: 'blob',
      data: { html },
    },
    (data) => data,
  );
