import React from 'react';
import Routing from './components/Routing';

const Importer: React.FC = () => {
  return (
    <div data-testid="view-importer">
      <Routing />
    </div>
  );
};

export default Importer;
