import Icon from '@ant-design/icons';
import { Skeleton } from 'antd';
import Color from 'color';
import React, { ComponentType } from 'react';
import { useIntl } from 'react-intl';

import styles from './styles.module.scss';

type Props = {
  iconComponent?: ComponentType;
  iconColor?: string;
  onClick?: () => void;
  isLoading?: boolean;
};

const Button: React.FC<Props> = ({
  children,
  iconComponent,
  iconColor,
  onClick,
  isLoading = false,
}) => {
  const intl = useIntl();
  const aria = intl.formatMessage({
    defaultMessage: 'Connector icon',
  });
  const colorObj = Color(iconColor);
  const lightenFactor = colorObj.luminosity() > 0.6 ? 0.1 : 0.2;
  const colorStyle = {
    '--color': iconColor,
    '--color-shadow': colorObj.darken(0.03).hex(),
  } as React.CSSProperties;

  if (isLoading) {
    return (
      <button
        disabled
        aria-label="button"
        type="button"
        className={styles.button}
        data-testid="button-skeleton"
      >
        <Skeleton.Avatar active shape="circle" size={40} />
        <Skeleton round active paragraph={false} />
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.button}
      style={colorStyle}
      data-testid="button"
    >
      <p
        className={styles.iconWrap}
        style={{
          backgroundColor: colorObj.lighten(lightenFactor).hex(),
        }}
      >
        {typeof iconComponent !== 'undefined' && (
          <Icon
            data-testid="icon"
            aria-label={aria}
            alt-text={aria}
            component={iconComponent}
            style={{
              color: iconColor,
              fontSize: '20px',
            }}
          />
        )}
      </p>
      {children}
    </button>
  );
};

export default Button;
