import { ReactChild } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Space } from 'antd';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { GoCloudDownload } from 'react-icons/go';

import { TableRow } from 'components/ui/molecules/Table';

export type ReportRow = TableRow & {
  id: string;
  name: string;
  created: string;
};

export const columns = (
  onClickDownload: (id: string) => void,
): ColumnsType<ReportRow> => [
  {
    title: <FormattedMessage defaultMessage="Date generated" />,
    key: 'created',
    render: ({ created, hideActions }: ReportRow): ReactChild =>
      hideActions ? created : dayjs(created).format('DD/MM/YYYY'),
  },
  {
    key: 'action',
    width: 50,
    render: ({ key, hideActions, id }: ReportRow): null | ReactChild => {
      if (hideActions) return null;
      return (
        <Space size="middle" data-testid="buttons-actions">
          <Button
            data-testid={`button-download-${key}`}
            type="link"
            icon={<GoCloudDownload />}
            onClick={() => onClickDownload(id)}
          />
        </Space>
      );
    },
  },
];
