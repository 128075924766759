import { Store } from 'ducks/store';
import { BaseApiType } from './api';

export type RawRTKQResponse<DataType> = {
  data: DataType;
  endpointName: string;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isUninitialized: boolean;
  status: string;
};

type ActionPromise<ReturnType> = Promise<ReturnType> & {
  unsubscribe: () => void;
};

export const processRtkqEntry = <DataType, ArgsType extends Array<any>>(
  api: BaseApiType,
  store: Store,
  endpointKey: string,
  args?: ArgsType,
): Promise<RawRTKQResponse<DataType>> =>
  new Promise((resolve, reject) => {
    const action = store.dispatch(
      (
        api.endpoints as Record<
          string,
          { initiate: (...a: ArgsType | never[]) => any }
        >
      )[endpointKey].initiate(...(args || [])),
    ) as ActionPromise<RawRTKQResponse<DataType>>;
    action.then(resolve).catch(reject).finally(action.unsubscribe);
  });

export default processRtkqEntry;
