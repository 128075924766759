import React from 'react';
import { AiOutlineKey } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { Routes as RootRoutes } from 'components/Routing/Routes';
import { Routes as SettingRoutes } from 'views/Settings/components/Routing/Routes';

import Form from 'views/Settings/views/ApiKey/views/Form';
import List from 'views/Settings/views/ApiKey/views/List';
import Roles from 'views/Settings/views/ApiKey/views/Roles';

import { Routes } from './Routes';
import styles from './styles.module.scss';

export const getFullPath = (
  route: Routes | null = null,
  absolute = false,
): string => {
  let fullPath = `/${SettingRoutes.API_KEY}`;

  if (route && [Routes.ADD, Routes.REGENERATE, Routes.ROLES].includes(route))
    fullPath += `/${route}`;

  if (absolute) fullPath = `/${RootRoutes.SETTINGS}${fullPath}`;

  return fullPath;
};

const Icon = () => <AiOutlineKey size="30px" className={styles.ico} />;

const Routing: React.FC = () => {
  return (
    <ReactRoutes>
      <Route path="*" data-testid="route-api-key-list" element={<List />}>
        <Route
          path={Routes.ADD}
          data-testid="route-api-key-add"
          element={
            <Form
              title={
                <>
                  <Icon />
                  <FormattedMessage defaultMessage="New API Key" />
                </>
              }
            />
          }
        />
        <Route
          path={Routes.REGENERATE}
          data-testid="route-api-key-regenerate"
          element={
            <Form
              title={
                <>
                  <Icon />
                  <FormattedMessage defaultMessage="Regenerate API Key" />
                </>
              }
            />
          }
        />
        <Route
          path={Routes.ROLES}
          data-testid="route-api-key-roles"
          element={
            <Roles
              title={
                <>
                  <Icon />
                  <FormattedMessage defaultMessage="Roles" />
                </>
              }
            />
          }
        />
      </Route>
    </ReactRoutes>
  );
};

export default Routing;
