import { connect } from 'react-redux';
import { LocationSelectorTray, openLocationTray } from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  auth: { currentUser },
  space: { activeLocationId },
}: RootState) => {
  return {
    orgId: currentUser?.orgId,
    activeLocationId,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  openLocationTray: () => {
    dispatch(
      openLocationTray({
        type: LocationSelectorTray.MAIN,
        payloadValue: true,
      }),
    );
  },
});

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
