import { Typography } from 'antd';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from 'components/ui/molecules/Card';

import styles from './styles.module.scss';

const { Title, Paragraph } = Typography;

export type Props = {
  overallScore?: number;
  highestScore?: number;
  bestParameterName?: string;
  bestParameterValue?: number;
  worstParameterName?: string;
  worstParameterValue?: number;
  dayName?: string;
};

const SummaryText: React.FC<Props> = ({
  overallScore,
  highestScore,
  bestParameterName,
  bestParameterValue,
  worstParameterName,
  worstParameterValue,
  dayName,
}) => {
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const requiredParams = [
      overallScore,
      highestScore,
      bestParameterName,
      bestParameterValue,
      worstParameterName,
      worstParameterValue,
      dayName,
    ];

    const notEmptyParams = requiredParams.filter(
      (item) => !isNil(item) && item !== '',
    );

    setHasData(notEmptyParams.length === requiredParams.length);
  }, [
    overallScore,
    highestScore,
    bestParameterName,
    bestParameterValue,
    worstParameterName,
    worstParameterValue,
    dayName,
  ]);

  return (
    <Card className={styles.summaryCard}>
      <Title className={styles.title} level={5}>
        <FormattedMessage defaultMessage="Summary" />
      </Title>
      <div className={styles.contentWrapper}>
        <Paragraph className={styles.summaryText}>
          {hasData ? (
            `Your overall score was ${overallScore}%. ${dayName} had the highest indoor air quality
          score of ${highestScore}%. The best performing parameter was ${bestParameterName} with ${bestParameterValue}% of
          readings in the excellent range and the worst performing parameter was
          ${worstParameterName} with ${worstParameterValue}% of readings in the excellent range.`
          ) : (
            <FormattedMessage defaultMessage="Not enough data to generate summary" />
          )}
        </Paragraph>
      </div>
      <Paragraph className={styles.blogText}>
        <FormattedMessage
          defaultMessage="Check out this{link}to learn more about how to improve these scores."
          values={{
            link: (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="#" target="_blank">
                <FormattedMessage defaultMessage="link" />
              </a>
            ),
          }}
        />
      </Paragraph>
    </Card>
  );
};

export default SummaryText;
