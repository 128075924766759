import { connect } from 'react-redux';
import { setUnsavedChanges, setLocationTrayIsSaving } from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  space: {
    locationTray: { unsavedChanges },
  },
}: RootState) => ({
  unsavedChanges,
});

const mapDispatchToProps = {
  setUnsavedChanges,
  setLocationTrayIsSaving,
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
