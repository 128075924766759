import { Space } from 'antd';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import styles from './styles.module.scss';

export enum VerticalPlacement {
  Top,
  Bottom,
}

export enum HorizontalPlacement {
  Left,
  Right,
}

type Props = {
  className?: string;
  positionVertical?: VerticalPlacement;
  positionHorizontal?: HorizontalPlacement;
};

const toggleMapEvents = (map: L.Map, eventsDisabled: boolean): void => {
  if (eventsDisabled) {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
  } else {
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
  }
};

const ControlBar: React.FC<Props> = ({
  className,
  children,
  positionVertical = VerticalPlacement.Bottom,
  positionHorizontal = HorizontalPlacement.Left,
}) => {
  const map = useMap();
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    if (!map) return;
    toggleMapEvents(map, isMouseOver);
  }, [map, isMouseOver]);

  return (
    <div
      data-testid="control-bar"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      className={cn(
        [styles.toolbar],
        { [styles.bottom]: positionVertical === VerticalPlacement.Bottom },
        { [styles.top]: positionVertical === VerticalPlacement.Top },
        { [styles.left]: positionHorizontal === HorizontalPlacement.Left },
        { [styles.right]: positionHorizontal === HorizontalPlacement.Right },
        className,
      )}
    >
      <Space>{children}</Space>
    </div>
  );
};

export default ControlBar;
