import {
  Button,
  Form as AntdForm,
  Input,
  Result,
  TreeSelect,
  Typography,
} from 'antd';

import React, { ReactChild, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  APIKeyWithSecret,
  useCreateAPIKeyMutation,
  useRegenerateAPIKeyMutation,
} from 'api/apiKey';
import { useGetOrgTreeQuery } from 'api/org';
import { orgTreeSelectData } from 'api/org/utils';
import Modal from 'components/ui/molecules/Modal';
import { getFullPath } from 'views/Settings/views/ApiKey/components/Routing';

import styles from './styles.module.scss';

const { useForm, Item } = AntdForm;
const { Paragraph, Text } = Typography;

type Props = {
  title: string | ReactChild;
};

const Form: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const orgNodesQuery = useGetOrgTreeQuery();
  const orgTreeSelect = useMemo(
    () => orgTreeSelectData(orgNodesQuery.data || {}),
    [orgNodesQuery.isSuccess],
  );
  const { apiKeyId } = useParams<'apiKeyId'>();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [createdAPIKey, setCreatedAPIKey] = useState<APIKeyWithSecret>();
  const [createAPIKey] = useCreateAPIKeyMutation();
  const [regenerateAPIKey] = useRegenerateAPIKeyMutation();

  const backToList = (): void => {
    setIsVisible(false);

    // Return to the list after waiting for the fade out animation
    setTimeout(() => navigate(getFullPath(null, true)), 400);
  };

  const handleCreateAPIKey = (values: any): void => {
    setIsLoading(true);

    createAPIKey({
      meta: {
        name: values.name,
        description: values.description,
      },
      orgId: values.org_id,
    })
      .unwrap()
      .then((apiKey) => setCreatedAPIKey(apiKey as APIKeyWithSecret))
      .catch(() => {
        // Do nothing
      })
      .finally(() => setIsLoading(false));
  };

  const handleRegenerateAPIKey = (id: string): void => {
    setIsLoading(true);

    regenerateAPIKey(id)
      .unwrap()
      .then((apiKey) => setCreatedAPIKey(apiKey))
      .catch(() => {
        // Do nothing
      })
      .finally(() => setIsLoading(false));
  };

  const handleFormSubmit = (): void => {
    if (createdAPIKey) {
      backToList();
    } else if (apiKeyId) {
      handleRegenerateAPIKey(apiKeyId);
    } else {
      form.validateFields().then((values) => handleCreateAPIKey(values));
    }
  };

  let okButtonText = <FormattedMessage defaultMessage="Create" />;

  if (createdAPIKey) {
    okButtonText = <FormattedMessage defaultMessage="Done" />;
  } else if (apiKeyId) {
    okButtonText = <FormattedMessage defaultMessage="Regenerate" />;
  }

  const modalButtons = [
    <Button
      key="save"
      type="primary"
      shape="round"
      loading={isLoading}
      onClick={handleFormSubmit}
      data-testid="submit-button"
    >
      {okButtonText}
    </Button>,
  ];

  if (createdAPIKey === undefined) {
    modalButtons.push(
      <Button
        key="cancel"
        type="ghost"
        shape="round"
        onClick={backToList}
        disabled={isLoading}
        data-testid="cancel-button"
      >
        <FormattedMessage defaultMessage="Cancel" />
      </Button>,
    );
  }

  let modalContent = <></>;

  if (createdAPIKey) {
    modalContent = (
      <Result
        className={styles.apiKeyCreateSuccess}
        status="success"
        title={
          apiKeyId ? (
            <FormattedMessage defaultMessage="API Key Regenerated" />
          ) : (
            <FormattedMessage defaultMessage="API Key Created" />
          )
        }
        extra={
          <>
            <Paragraph
              copyable={{ text: createdAPIKey.key }}
              className={styles.apiKeyCopy}
            >
              <Text code data-testid="created-api-key">
                {createdAPIKey.key}
              </Text>
            </Paragraph>
            <Paragraph>
              <FormattedMessage defaultMessage="Copy this value immediately. It will not be shown again." />
            </Paragraph>
          </>
        }
        data-testid="create-regenerate-result"
      />
    );
  } else if (apiKeyId) {
    modalContent = (
      <Result
        status="warning"
        title={<FormattedMessage defaultMessage="Regenerate API Key" />}
        subTitle={
          <FormattedMessage defaultMessage="Are you sure you want to regenerate this API key? Any active uses of this key will stop working and will need to be updated." />
        }
        data-testid="regenerate-warning"
      />
    );
  } else
    modalContent = (
      <AntdForm name="basic" form={form} layout="vertical">
        <Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="This field is required" />
              ),
            },
          ]}
        >
          <Input data-testid="input-name" />
        </Item>
        <Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="This field is required" />
              ),
            },
          ]}
        >
          <Input data-testid="input-description" />
        </Item>
        <Item
          label="Organisation"
          name="org_id"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage defaultMessage="This field is required" />
              ),
            },
          ]}
        >
          <TreeSelect
            style={{ width: '100%' }}
            treeLine={{ showLeafIcon: false }}
            treeData={orgTreeSelect}
            data-testid="tree-select"
          />
        </Item>
      </AntdForm>
    );

  return (
    <Modal
      title={title}
      visible={isVisible}
      data-testid="api-key-form"
      footer={modalButtons}
    >
      {modalContent}
    </Modal>
  );
};

export default Form;
