import { Button, Form, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { NOT_RELEVANT } from 'views/Settings/views/Bms/utilities';

import globalStyles from 'styles/global.module.scss';
import styles from './styles.module.scss';

export type FilterData = {
  isActive: boolean | string;
};

type Props = {
  onFilter: (filterData: FilterData) => void;
};

const { Item, useForm } = Form;
const { Group } = Radio;

const isActiveOptions = [
  { label: <FormattedMessage defaultMessage="Yes" />, value: true },
  { label: <FormattedMessage defaultMessage="No" />, value: false },
  {
    label: <FormattedMessage defaultMessage="Not relevant" />,
    value: NOT_RELEVANT,
  },
];

const Filter: React.FC<Props> = ({ onFilter }) => {
  const [form] = useForm();
  const [filterOptions, setFilterOptions] = useState<FilterData>({
    isActive: NOT_RELEVANT,
  });

  useEffect(() => {
    onFilter(filterOptions);
    form.setFieldsValue({ ...filterOptions });
  }, [filterOptions]);

  return (
    <Form
      form={form}
      name="basic"
      layout="horizontal"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      className={styles.form}
    >
      <Item
        label={<FormattedMessage defaultMessage="Is active" />}
        name="isActive"
      >
        <Group
          options={isActiveOptions}
          onChange={(event) => {
            setFilterOptions((current) => ({
              ...current,
              isActive: event.target.value,
            }));
          }}
        />
      </Item>
      <Space
        align="end"
        direction="vertical"
        className={globalStyles.fullWidth}
      >
        <Button
          className={styles.toRight}
          onClick={() =>
            setFilterOptions({
              isActive: NOT_RELEVANT,
            })
          }
          type="link"
        >
          <FormattedMessage defaultMessage="clear filters" />
        </Button>
      </Space>
    </Form>
  );
};

export default Filter;
