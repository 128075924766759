import { FormattedMessage } from 'react-intl';

import { ReactComponent as Device } from 'assets/svg/device.svg';
import { ReactComponent as Home } from 'assets/svg/home.svg';
import { ReactComponent as Insights } from 'assets/svg/insights.svg';
import { ReactComponent as Map } from 'assets/svg/map.svg';
import { ReactComponent as Settings } from 'assets/svg/settings.svg';

import { Routes } from 'components/Routing/Routes';

export const menuItems = [
  {
    label: <FormattedMessage defaultMessage="Home" />,
    icon: Home,
    url: Routes.HOME,
    ariaLabel: 'home',
    exact: true,
  },
  {
    label: <FormattedMessage defaultMessage="Map" />,
    icon: Map,
    url: Routes.MAP,
    ariaLabel: 'map',
  },
  {
    label: <FormattedMessage defaultMessage="Insights" />,
    icon: Insights,
    url: Routes.INSIGHTS,
    ariaLabel: 'insights',
  },
  {
    label: <FormattedMessage defaultMessage="Devices" />,
    icon: Device,
    url: Routes.DEVICES,
    ariaLabel: 'devices',
  },
  {
    label: <FormattedMessage defaultMessage="Settings" />,
    icon: Settings,
    url: Routes.SETTINGS,
    ariaLabel: 'settings',
  },
];
