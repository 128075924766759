import Icon from '@ant-design/icons';
import { Card, Col, Row, Typography } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';

import { BsBox } from 'react-icons/bs';
import { ImStack } from 'react-icons/im';

import { SpaceNode, SpaceNodeCategory } from 'api/space';
import { ReactComponent as Buildings } from 'assets/svg/buildings.svg';

import { getIntl } from 'components/Intl';
import styles from './styles.module.scss';

const { Title, Text } = Typography;

const getIcon = (spaceNode: SpaceNode) => {
  const intl = getIntl();
  const ariaBuildingIcon = intl.formatMessage({
    defaultMessage: 'Building Icon',
  });
  const ariaRoomIcon = intl.formatMessage({
    defaultMessage: 'Room Icon',
  });
  switch (spaceNode.category) {
    case SpaceNodeCategory.ROOM:
      return (
        <Icon
          component={BsBox}
          className={styles.icon}
          aria-label={ariaRoomIcon}
        />
      );
    case SpaceNodeCategory.FLOOR:
      return <Icon component={ImStack} className={styles.icon} />;
    default:
      return (
        <Icon
          component={Buildings}
          className={styles.icon}
          aria-label={ariaBuildingIcon}
        />
      );
  }
};

type Props = {
  spaceNode: SpaceNode;
  onClick: (spaceId: string) => void;
  className?: string;
};

const SpaceCard: React.FC<Props> = ({ spaceNode, onClick, className }) => {
  const { name, description } = spaceNode.meta;
  const { streetAddress } = spaceNode.systemMeta;

  const icon = useMemo(() => {
    return getIcon(spaceNode);
  }, [spaceNode]);

  return (
    <Card
      className={cn(styles.spaceCard, className)}
      hoverable
      bordered={false}
      onClick={() => onClick(spaceNode.id)}
      data-testid={`space-card-${spaceNode.id}`}
    >
      <Row align="middle" style={{ minHeight: 80 }}>
        <Col className={styles.contentLeftLimit} flex="none">
          {icon}
        </Col>
        <Col
          className={styles.contentRightLimit}
          flex="auto"
          style={{ minWidth: 0 }}
        >
          <Title level={5} ellipsis={{ tooltip: true }}>
            {name}
          </Title>
          {!!description && (
            <Text
              data-testid="description"
              className={styles.description}
              ellipsis={{ tooltip: true }}
            >
              {description}
              {streetAddress ? `, ${streetAddress}` : ''}
            </Text>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default SpaceCard;
