import React, { useEffect, useMemo } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useGetFloorPlanQuery, useGetSpaceNodeListQuery } from 'api/space';

import ButtonsGroup from 'components/ui/atoms/ButtonsGroup';
import Loader from 'components/ui/atoms/Loader';
import Breadcrumb from 'components/ui/atoms/LocationBreadcrumb';
import { selectActiveSpaces } from 'ducks/space/selectors';
import styles from 'styles/global.module.scss';
import { Routes } from 'views/Map/components/Routing/Routes';
import LeafletMap from './components/LeafletMap';
import CenterButton from './components/LeafletMap/components/CenterButton';
import ZoomInButton from './components/LeafletMap/components/ZoomInButton';
import ZoomInfo from './components/LeafletMap/components/ZoomInfo';
import ZoomOutButton from './components/LeafletMap/components/ZoomOutButton';

type Props = {
  orgId?: string;
  activeLocationId?: string;
};

const FloorPlan: React.FC<Props> = ({ activeLocationId, orgId }) => {
  const navigate = useNavigate();

  const { data: spaceNodes, isFetching: isSpaceNodesLoading } =
    useGetSpaceNodeListQuery({ orgId }, { skip: !orgId });

  const { selectedBuilding, selectedFloor } = useMemo(
    () =>
      !spaceNodes || !activeLocationId
        ? { selectedBuilding: undefined, selectedFloor: undefined }
        : selectActiveSpaces(spaceNodes, activeLocationId),
    [activeLocationId, spaceNodes],
  );

  const { data: floorplan, isFetching: isFloorplanLoading } =
    useGetFloorPlanQuery(selectedFloor?.id || '', {
      skip: !selectedFloor?.systemMeta.floorplan,
    });

  const isLoading = useMemo(
    () => isSpaceNodesLoading || isFloorplanLoading,
    [isSpaceNodesLoading, isFloorplanLoading],
  );

  useEffect(() => {
    if (!selectedFloor) return;

    if (!selectedFloor.systemMeta.floorplan) {
      navigate(Routes.UPLOAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloor]);

  return (
    <div className={styles.fullSizeFlexContainer} data-testid="view-floor-plan">
      <Loader visible={isLoading}>
        <>
          {!isFloorplanLoading && floorplan && (
            <LeafletMap
              topLeftControls={
                selectedBuilding && selectedFloor
                  ? [<Breadcrumb key="breadcrumb" />]
                  : undefined
              }
              bottomLeftControls={[
                <ButtonsGroup key="buttons-group">
                  <CenterButton />
                  <ZoomInButton />
                  <ZoomOutButton />
                </ButtonsGroup>,
                <ZoomInfo key="zoom-info" />,
              ]}
              imageSrc={floorplan.data.url}
            />
          )}
          <Outlet />
        </>
      </Loader>
    </div>
  );
};

export default FloorPlan;
