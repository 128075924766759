import { Button, Empty } from 'antd';
import { useEffect, useState, Key, Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetDataSinkListQuery } from 'api/dataSink';
import { useGetSpaceNodeListQuery } from 'api/space';
import Modal from 'components/ui/molecules/Modal';
import Search from 'components/ui/molecules/Search';
import Table from 'components/ui/molecules/Table';
import { getTableSkeleton } from 'utilities/utilities';

import { ViewType } from '../../AssignToSinkModal';
import { columns, DataSinkRow } from './config';
import styles from './styles.module.scss';

type Props = {
  isVisible: boolean;
  orgId?: string;
  onViewTypeChange: Dispatch<SetStateAction<ViewType | undefined>>;
  onCloseModal: () => void;
  onSinkSelected: (dataSinkId: string[]) => void;
};

const SinkList: React.FC<Props> = ({
  isVisible,
  orgId,
  onViewTypeChange,
  onCloseModal,
  onSinkSelected,
}) => {
  const [dataSinks, setDataSinks] = useState<DataSinkRow[]>(
    getTableSkeleton(['name', 'sources', 'location'], 3),
  );
  const [filteredDataSinks, setFilteredDataSinks] = useState<DataSinkRow[]>([]);
  const [selectedDataSinkKeys, setSelectedDataSinkKeys] = useState<Key[]>([]);
  const [searchString, setSearchString] = useState('');

  const { data: dataSinkList, isLoading: isDataSinkListLoading } =
    useGetDataSinkListQuery({ orgId }, { skip: !orgId });

  const { data: spaceNodes, isLoading: isSpaceNodesLoading } =
    useGetSpaceNodeListQuery({ orgId }, { skip: !orgId });

  const isLoading = isDataSinkListLoading || isSpaceNodesLoading;

  // fetch and populate list
  useEffect(() => {
    if (isLoading) {
      setDataSinks(getTableSkeleton(['name', 'sources', 'location'], 3));
      return;
    }

    if (!spaceNodes || !dataSinkList) {
      setDataSinks([]);
      return;
    }

    setDataSinks(
      dataSinkList.map(({ meta: { name }, id, locationId, feeds }) => ({
        name,
        locationId,
        key: id,
        searchName: name.toLowerCase(),
        location: spaceNodes[locationId]?.meta.name,
        sources: feeds.length,
      })),
    );
  }, [dataSinkList, isLoading, spaceNodes]);

  // handle search field
  useEffect(() => {
    if (isLoading) return;

    if (searchString.length) {
      setFilteredDataSinks(
        dataSinks.filter(({ searchName }) => searchName.includes(searchString)),
      );
    }
  }, [searchString, dataSinks, isLoading]);

  useEffect(() => {
    // trigger window resize after animation end
    // to recalculate height for autoscroll table
    if (isVisible)
      setTimeout(() => window.dispatchEvent(new Event('resize')), 400);
  }, [isVisible]);

  const onRowsSelectionChange = (selectedRowKeys: Key[]) => {
    setSelectedDataSinkKeys(selectedRowKeys);
  };

  const onClickDelete = (dataSourceKey: any) => {
    setSelectedDataSinkKeys((currentKeys) =>
      currentKeys.filter((key) => key !== dataSourceKey),
    );
  };

  const handleOnRow = (record: DataSinkRow) => ({
    onClick: () => {
      if (selectedDataSinkKeys.includes(record.key)) {
        onClickDelete(record.key);
      } else {
        setSelectedDataSinkKeys([...selectedDataSinkKeys, record.key]);
      }
    },
  });

  return (
    <Modal
      visible={isVisible}
      closable={true}
      onCancel={onCloseModal}
      title={<FormattedMessage defaultMessage="Link to existing data sink" />}
      footer={[
        <Button
          key="btn-link"
          shape="round"
          type="primary"
          data-testid="btn-link"
          disabled={selectedDataSinkKeys.length === 0}
          onClick={() => onSinkSelected(selectedDataSinkKeys as string[])}
        >
          <FormattedMessage defaultMessage="Link" />
        </Button>,
        <Button
          key="btn-back"
          shape="round"
          data-testid="btn-back"
          onClick={() => onViewTypeChange(ViewType.HOME)}
        >
          <FormattedMessage defaultMessage="Back" />
        </Button>,
      ]}
    >
      <>
        <Search onChange={setSearchString} />

        <div className={styles.content}>
          <Table<DataSinkRow>
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedDataSinkKeys,
              onChange: onRowsSelectionChange,
            }}
            onRow={handleOnRow}
            data={searchString.length ? filteredDataSinks : dataSinks}
            columns={columns}
            pagination={{ pageSize: 100 }}
            autoScroll={true}
            emptyText={
              <Empty
                description={
                  searchString.length ? (
                    <FormattedMessage defaultMessage="No data sink found that meets your criteria." />
                  ) : (
                    <FormattedMessage defaultMessage="No records." />
                  )
                }
              />
            }
          />
        </div>
      </>
    </Modal>
  );
};

export default SinkList;
