import Icon from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom';

import { ReactComponent as DeviceIco } from 'assets/svg/device.svg';
import AnimatedRoutes from 'components/AnimatedRoutes';
import { Routes as RootRoutes } from 'components/Routing/Routes';
import DeviceDetails from 'views/Devices/views/DeviceDetails';
import DeviceForm from 'views/Devices/views/DeviceForm';
import DeviceList from 'views/Devices/views/DeviceList';
import Provision from 'views/Devices/views/Provision';

import { Routes } from './Routes';
import styles from './styles.module.scss';

export const getFullPath = (
  route: Routes | null = null,
  absolute = false,
): string => {
  let fullPath = '';

  if (route && [Routes.ADD, Routes.EDIT, Routes.DETAILS].includes(route))
    fullPath += `/${route}`;

  if (absolute) fullPath = `/${RootRoutes.DEVICES}${fullPath}`;

  return fullPath;
};

export const Ico = () => <Icon component={DeviceIco} className={styles.ico} />;

const Routing: React.FC = () => {
  return (
    <AnimatedRoutes pathLevelWatch={3}>
      <Route path="*" element={<DeviceList />}>
        <Route path={Routes.DETAILS} element={<DeviceDetails />} />
        <Route
          path={Routes.EDIT}
          element={
            <DeviceForm
              title={
                <>
                  <Ico />
                  <FormattedMessage defaultMessage="Edit Device" />
                </>
              }
            />
          }
        />
      </Route>
      <Route path={`${Routes.ADD}/*`} element={<Provision />} />
    </AnimatedRoutes>
  );
};

export default Routing;
