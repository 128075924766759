import Icon from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import cn from 'classnames';
import React, { ComponentType, ReactElement } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

type PropsMenuItem = {
  label: ReactElement<IntlShape> | string;
  icon: ComponentType;
  url: string;
  ariaLabel: string | undefined;
};

type PropsMenu = {
  items: PropsMenuItem[];
};

const MenuItem: React.FC<PropsMenuItem> = ({ label, icon, url, ariaLabel }) => {
  const intl = useIntl();
  return (
    <>
      <Tooltip placement="bottom" title={label}>
        <NavLink
          data-testid="menu-item"
          to={url}
          className={({ isActive }) => cn({ [styles.linkActive]: isActive })}
          aria-label={ariaLabel}
        >
          <Icon
            component={icon}
            className={styles.linkIcon}
            aria-label={`${ariaLabel} icon`}
            alt-text={intl.formatMessage(
              {
                defaultMessage: 'Icon for {val}',
              },
              { val: ariaLabel },
            )}
          />
        </NavLink>
      </Tooltip>
    </>
  );
};

const Menu: React.FC<PropsMenu> = ({ items }) => {
  return (
    <Space className={styles.wrapper} size="large" role="navigation">
      {items.map(({ label, icon, url, ariaLabel }) => (
        <MenuItem
          key={url}
          label={label}
          icon={icon}
          url={url}
          ariaLabel={ariaLabel}
        />
      ))}
    </Space>
  );
};

export default Menu;
