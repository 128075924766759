/* eslint-disable react/no-array-index-key */
import { Typography } from 'antd';
import classNames from 'classnames';
import Color from 'color';
import { ReactElement } from 'react';
import ChartLegend, { LegendItem } from 'components/ui/atoms/ChartLegend';
import globalStyles from 'styles/global.module.scss';
import Cell, { CellData } from './components/Cell';
import XLabels from './components/XLabels';
import YLabels from './components/YLabels';

import { xLabelsPositions, yLabelsPositions } from './config';
import styles from './styles.module.scss';
import { getMinMax } from './utils';

const { Title } = Typography;

export type HeatMapGridProps = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  cellColor?: string;
  legendData?: LegendItem[];
  data: CellData[][];
  xLabels?: string[];
  yLabels?: string[];
  cellHeight?: string;
  square?: boolean;
  xLabelsPos?: xLabelsPositions;
  yLabelsPos?: yLabelsPositions;
  cellRender?: (x: number, y: number, value: number | null) => ReactElement;
  onCellClick?: (x: number, y: number) => void;
};

const HeatMapGrid: React.FC<HeatMapGridProps> = ({
  title,
  subtitle,
  cellColor = globalStyles.colorMain,
  legendData,
  data,
  xLabels,
  yLabels,
  xLabelsPos = xLabelsPositions.TOP,
  yLabelsPos = yLabelsPositions.LEFT,
  square = false, // Note: sets cell shape to square and stops cell stretching.
  cellHeight = '48px',
  cellRender,
  onCellClick,
}) => {
  const { min, diff } = getMinMax(data);
  const isXLabelReverse = xLabelsPos === xLabelsPositions.BOTTOM;
  const isYLabelReverse = yLabelsPos === yLabelsPositions.RIGHT;
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <Title className={styles.title} level={5}>
            {title}
          </Title>
          {subtitle && (
            <Title className={styles.subtitle} level={5}>
              {subtitle}
            </Title>
          )}
        </div>
        {legendData && (
          <ChartLegend className={styles.chaartLegend} data={legendData} />
        )}
      </div>
      <div
        className={classNames(styles.row, {
          [styles.rowReverse]: isYLabelReverse,
        })}
      >
        {yLabels && (
          <YLabels
            isXLabelReverse={isXLabelReverse}
            isYLabelReverse={isYLabelReverse}
            labels={yLabels}
            cellHeight={cellHeight}
          />
        )}
        <div
          className={classNames(styles.column, {
            [styles.columnReverse]: isXLabelReverse,
            [styles.columnGrow]: !square,
          })}
        >
          {xLabels && (
            <XLabels labels={xLabels} cellHeight={cellHeight} square={square} />
          )}
          <div className={styles.column}>
            {data.map((rowItems, xi) => (
              <div className={styles.row} key={xi}>
                {rowItems.map(({ value, color }, yi) => {
                  const tmpColor =
                    typeof color !== 'undefined'
                      ? color
                      : Color(cellColor)
                          .lighten(((value || 0) - min) / diff)
                          .hex();
                  return (
                    <Cell
                      key={`${xi}-${yi}`}
                      posX={xi}
                      posY={yi}
                      onCellClick={onCellClick}
                      value={value}
                      height={cellHeight}
                      square={square}
                      render={cellRender}
                      color={tmpColor}
                    />
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeatMapGrid;
