import { Radio, RadioChangeEvent } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import Routing from './components/Routing';
import { Routes } from './components/Routing/Routes';

import styles from './styles.module.scss';

const { Group, Button } = Radio;

const Bms: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onViewChange = ({ target: { value } }: RadioChangeEvent) => {
    navigate(value);
  };

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.menuWrapper}>
        <Group
          className="ant-radio-group-shadow"
          value={
            pathname.includes(Routes.DATA_SINKS_LIST)
              ? Routes.DATA_SINKS_LIST
              : Routes.DATA_SOURCES_LIST
          }
          onChange={onViewChange}
        >
          <Button value={Routes.DATA_SOURCES_LIST}>
            <FormattedMessage defaultMessage="Data sources" />
          </Button>
          <Button value={Routes.DATA_SINKS_LIST}>
            <FormattedMessage defaultMessage="Data sinks" />
          </Button>
        </Group>
      </div>
      <Routing />
    </div>
  );
};

export default Bms;
