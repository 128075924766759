import { Breadcrumb as AntdBreadcrumb } from 'antd';
import cn from 'classnames';
import { useMemo } from 'react';
import { useGetSpaceNodeListQuery } from 'api/space';

import { selectActiveSpaces } from 'ducks/space/selectors';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  separator?: string;
  orgId?: string;
  activeLocationId?: string;
};

const LocationBreadcrumb: React.FC<Props> = ({
  orgId,
  activeLocationId,
  className,
  separator,
}) => {
  const { data: spaceNodes } = useGetSpaceNodeListQuery(
    { orgId },
    { skip: !orgId },
  );

  const { selectedBuilding, selectedFloor } = useMemo(
    () =>
      !spaceNodes || !activeLocationId
        ? { selectedBuilding: undefined, selectedFloor: undefined }
        : selectActiveSpaces(spaceNodes, activeLocationId),
    [activeLocationId, spaceNodes],
  );

  return (
    <AntdBreadcrumb
      className={cn(styles.container, className)}
      separator={separator}
    >
      {selectedBuilding && (
        <AntdBreadcrumb.Item>{selectedBuilding.meta.name}</AntdBreadcrumb.Item>
      )}
      {selectedFloor && (
        <AntdBreadcrumb.Item>{selectedFloor.meta.name}</AntdBreadcrumb.Item>
      )}
    </AntdBreadcrumb>
  );
};

export default LocationBreadcrumb;
