import { Layout, Typography, Button, Row, Col } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { DataSourceLifecycle, useGetDataSourceListQuery } from 'api/dataSource';
import { useGetProductListQuery } from 'api/product';
import { SpaceNodeCategory, useGetSpaceNodeListQuery } from 'api/space';
import Search from 'components/ui/molecules/Search';
import Table from 'components/ui/molecules/Table';
import {
  selectActiveSpaces,
  selectSpaceDescendantIds,
} from 'ducks/space/selectors';

import globalStyles from 'styles/global.module.scss';
import { getAttributeKeyTitle } from 'utilities/product';
import { getTableSkeleton } from 'utilities/utilities';
import { Routes } from 'views/Devices/components/Routing/Routes';

import { EmptyMessage } from './components/EmptyMessage';
import { breadcrumbsDelimiter, columns, DeviceRow } from './config';

import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
  orgId?: string;
  activeLocationId: string;
  openLocationTray: (payload: boolean) => void;
};

const DeviceList: React.FC<Props> = ({
  orgId,
  activeLocationId,
  openLocationTray,
}) => {
  const [searchString, setSearchString] = useState('');
  const [devices, setDevices] = useState<DeviceRow[]>([]);
  const [searchLabels, setSearchLabels] = useState<string[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<DeviceRow[]>(
    getTableSkeleton(['name', 'location', 'created'], 3),
  );

  const navigate = useNavigate();
  const intl = useIntl();

  // get product list
  const {
    data: products,
    isError: productsIsError,
    isLoading: productsIsLoading,
  } = useGetProductListQuery();

  // get space nodes when orgId is not empty
  const {
    data: spaceNodes,
    isError: spaceNodesIsError,
    isLoading: spaceNodesIsLoading,
  } = useGetSpaceNodeListQuery(
    { orgId },
    {
      skip: !orgId,
    },
  );

  // select selected space nodes when spaceNodes is not empty
  const { selectedSpace, selectedBuilding, selectedFloor } = useMemo(() => {
    if (!spaceNodes)
      return {
        selectedSpace: undefined,
        selectedBuilding: undefined,
        selectedFloor: undefined,
      };
    return selectActiveSpaces(spaceNodes, activeLocationId);
  }, [spaceNodes, activeLocationId]);

  // select the space descendant ids when spaceNodes is not empty
  const spaceDescendantIds = useMemo(() => {
    if (!spaceNodes) return [];
    return selectSpaceDescendantIds(spaceNodes, activeLocationId);
  }, [spaceNodes, activeLocationId]);

  // get data source list when spaceDescendantIds list is not empty
  const {
    data: dataSource,
    isError: dataSourceIsError,
    isLoading: dataSourceIsLoading,
  } = useGetDataSourceListQuery(
    {
      spaceId: spaceDescendantIds,
      lifecycle: [
        DataSourceLifecycle.OPERATIONAL,
        DataSourceLifecycle.DETACHED,
        DataSourceLifecycle.DISABLED,
        DataSourceLifecycle.RMA,
      ],
    },
    {
      skip: !spaceDescendantIds.length,
    },
  );

  const isDataLoading =
    productsIsLoading || spaceNodesIsLoading || dataSourceIsLoading;
  const isDataError = productsIsError || spaceNodesIsError || dataSourceIsError;

  // transform the data source data to the table needs
  useEffect(() => {
    // set loading state
    if (isDataLoading) {
      setFilteredDevices(getTableSkeleton(['name', 'location', 'created'], 3));
      return;
    }

    // set empty list when error
    if (isDataError || !spaceNodes || !dataSource || !products) {
      setDevices([]);
      return;
    }

    // setup devices list
    setDevices(
      dataSource.map(
        ({
          id,
          productId,
          locationId: deviceLocationId,
          attributes,
          meta: { name, created },
        }) => {
          const deviceSpace = spaceNodes[deviceLocationId];
          const locationName = deviceSpace?.meta.name;
          const productItem = products[productId];
          const productSpecificAttributes = !productItem
            ? []
            : productItem.displayAttributes.map(
                ({ name: attributeName, attributeKey }) => [
                  attributeKey,
                  attributeName,
                ],
              );

          setSearchLabels(
            productSpecificAttributes
              .map(([columnKey, columnName]) =>
                getAttributeKeyTitle(columnKey, columnName),
              )
              .filter(Boolean),
          );

          return {
            key: id,
            name,
            searchDump: [
              name.toLowerCase(),
              ...productSpecificAttributes.map(([attributeKey]) =>
                attributes[attributeKey]
                  ? attributes[attributeKey].toString().toLowerCase()
                  : '',
              ),
            ],
            created,
            locationId: deviceLocationId,
            location:
              selectedSpace?.category !== SpaceNodeCategory.ROOM &&
              deviceSpace?.category !== SpaceNodeCategory.FLOOR
                ? `${selectedFloor?.meta.name}${breadcrumbsDelimiter}${locationName}`
                : locationName,
          };
        },
      ),
    );
  }, [
    products,
    spaceNodes,
    dataSource,
    selectedSpace,
    selectedFloor,
    isDataLoading,
    isDataError,
  ]);

  // filter the devices list by search field
  useEffect(() => {
    if (isDataLoading) return;

    if (devices.length === 0) {
      setFilteredDevices([]);
      return;
    }

    if (devices[0].hideActions) return;

    setFilteredDevices(
      !searchString
        ? devices
        : devices.filter(({ searchDump }) =>
            searchDump.find((str) => str.includes(searchString)),
          ),
    );
  }, [devices, searchString, isDataLoading]);

  const handleOnClickEdit = (deviceId: string): void => {
    navigate(Routes.EDIT.replace(':deviceId', deviceId));
  };

  const handleOnRow = (record: Record<string, unknown>) => {
    return {
      onClick: (e: any): void => {
        /* istanbul ignore else */
        if (!(e.target instanceof SVGElement)) {
          navigate(Routes.DETAILS.replace(':deviceId', record.key as string));
        }
      },
    };
  };

  const labelSearch = intl.formatMessage(
    {
      defaultMessage: 'Filter by Device Name, {elements}',
    },
    {
      elements: intl.formatList(searchLabels, {
        type: 'disjunction',
      }),
    },
  );

  const nameSelectedSpace: string[] = [];
  if (selectedBuilding) nameSelectedSpace.push(selectedBuilding.meta.name);
  if (selectedFloor) nameSelectedSpace.push(selectedFloor.meta.name);
  if (selectedSpace?.category === SpaceNodeCategory.ROOM)
    nameSelectedSpace.push(selectedSpace.meta.name);

  return (
    <Layout
      data-testid="view-devices-list"
      className={classNames(globalStyles.fullPageLayout, styles.deviceList)}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Title ellipsis={true} data-testid="device-title">
            <FormattedMessage defaultMessage="Your Devices" />
          </Title>

          {activeLocationId && nameSelectedSpace.length > 0 && (
            <Title ellipsis={true} data-testid="device-breadcrumbs" level={4}>
              {nameSelectedSpace.join(breadcrumbsDelimiter)}
            </Title>
          )}
        </Col>
        <Col>
          <Link to={{ pathname: Routes.ADD }}>
            <Button
              data-testid="button-add"
              type="primary"
              shape="round"
              icon={<AiOutlinePlus />}
            >
              <FormattedMessage defaultMessage="Add device" />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className={styles.tableTop}>
        <Col xl={9} lg={12} sm={24}>
          <Search placeholder={labelSearch} onChange={setSearchString} />
        </Col>
      </Row>
      <Table<DeviceRow>
        showHeader={true}
        data={filteredDevices}
        autoScroll={true}
        columns={columns(handleOnClickEdit)}
        emptyText={
          <EmptyMessage
            activeLocationId={activeLocationId}
            openLocationTray={openLocationTray}
          />
        }
        onRow={handleOnRow}
      />
      <Outlet />
    </Layout>
  );
};

export default DeviceList;
