import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import cn from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';

import { IoMdArrowRoundUp } from 'react-icons/io';
import styles from './styles.module.scss';

const Toaster: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [isHoverable, setIsHoverable] = useState(true);
  const { isAuthenticated, user, logout } = useAuth0();

  useEffect(() => {
    if (isOpened) setIsHoverable(false);
    else
      setTimeout(() => {
        setIsHoverable(true);
      }, 300);
  }, [isOpened]);

  return (
    <Card
      data-testid="toaster-wrapper"
      className={cn(styles.wrapper, { [styles.open]: isOpened })}
      title={
        <IoMdArrowRoundUp
          data-testid="toaster-toggler"
          className={cn(styles.arrow, { [styles.arrowDown]: isOpened })}
          onClick={() => isOpened && setIsOpened(!isOpened)}
        />
      }
      hoverable={isHoverable}
      bordered={false}
      onClick={() => !isOpened && setIsOpened(!isOpened)}
    >
      Have a good day {user?.name} ☕️
      {isAuthenticated && (
        <Button onClick={() => logout({ returnTo: window.location.origin })}>
          Log Out
        </Button>
      )}
    </Card>
  );
};

export default Toaster;
