/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConfigState = {
  foo: string;
};

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    foo: '',
  } as ConfigState,
  reducers: {
    setConfig: (state, { payload }: PayloadAction<ConfigState>) => {
      state.foo = payload.foo;
    },
  },
});

// Export the reducer and all synchronous actions
export const config = configSlice.reducer;
export const { setConfig } = configSlice.actions;
