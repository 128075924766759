import { useRef, useEffect } from 'react';

/**
 * Custom hook for checking if a component is mounted
 * Can be helpful when we don't want to trigger useEffect callback
 * on the component first render
 *
 * @returns boolen
 */
const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export default useIsMount;
