import { connect } from 'react-redux';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  auth: { currentUser },
  space: {
    activeLocationId,
    locationTray: { selectorsSettings },
  },
}: RootState) => ({
  orgId: currentUser?.orgId,
  activeLocationId,
  mainActiveFloorId: selectorsSettings?.main?.selectedFloorId,
  mainActiveBuildingId: selectorsSettings?.main?.selectedBuildingId,
});

const storeConnection = connect(mapStateToProps);
export default storeConnection;
