import { RequestStatus } from '../../ImageUploadField';

export const getColor = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.Failed:
      return ['#fb4c4c', '#FEC8C8'];
    case RequestStatus.Idle:
      return ['#afb8bf', '#E9E9E9'];
    case RequestStatus.Processing:
    case RequestStatus.Success:
    default:
      return ['#34c759', '#D6F4DE'];
  }
};
