import classNames from 'classnames';
import { memo } from 'react';
import styles from './styles.module.scss';

type Props = {
  labels: string[];
  square?: boolean;
  cellHeight?: string;
};

const XLabels: React.FC<Props> = ({ labels, cellHeight, square = false }) => {
  const cellCssValue = {
    '--value': square ? cellHeight : `${100 / labels.length}%`,
  } as React.CSSProperties;

  return (
    <div data-testid="heatmap-xlabels" className={styles.wrapper}>
      {labels.map((label) => (
        <div
          key={`${label}`}
          className={classNames(styles.cell, { [styles.cellGrow]: square })}
          style={cellCssValue}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export default memo(XLabels);
