import { Col, Row, Select, Radio, Space, Button } from 'antd';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { MdClear } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getReportTypeToLabelMap,
  ReportType,
  Schedule,
  ScheduleToLabelMap,
} from 'api/report';

import globalStyles from 'styles/global.module.scss';

import { FilterDataOptions } from 'views/Insights/views/ReportConfigurationList/utilities';

import styles from './styles.module.scss';

const { Option } = Select;
const { Group: RadioGroup, Button: RadioButton } = Radio;
const { div: AnimatedDiv } = motion;

type Props = {
  frequencyFilterValue: string | null;
  onFilterOptionsChange: (
    option: FilterDataOptions.frequency | FilterDataOptions.reportType,
    value: string | null,
  ) => void;
};

const Filter: React.FC<Props> = ({
  frequencyFilterValue,
  onFilterOptionsChange,
}) => {
  const intl = useIntl();
  const [displayClearFilter, setDisplayClearFilter] = useState<boolean>(false);

  useEffect(() => {
    setDisplayClearFilter(!!frequencyFilterValue);
  }, [frequencyFilterValue]);

  return (
    <Row
      align="middle"
      justify="start"
      className={styles.filter}
      data-testid="filter-header"
    >
      <Col span={6}>
        <Select
          placeholder={
            <FormattedMessage defaultMessage="Select type of report" />
          }
          allowClear
          onChange={(val) =>
            onFilterOptionsChange(FilterDataOptions.reportType, val || '')
          }
          className={styles.select}
          data-testid="report-type-select"
          aria-label={intl.formatMessage({
            defaultMessage: 'report type',
          })}
          alt-text={intl.formatMessage({
            defaultMessage:
              'drop down to choose report type, for example: Indoor Air Quality',
          })}
        >
          {Object.values(ReportType).map((reportType) => (
            <Option
              key={reportType}
              data-testid={`report-type-option-${reportType}`}
              value={reportType}
            >
              {getReportTypeToLabelMap(reportType)}
            </Option>
          ))}
        </Select>
      </Col>
      <Col className={cn(styles.frequency, globalStyles.cardHeader)}>
        <span className={styles.frequencyTitle}>
          <FormattedMessage defaultMessage="Frequency" />
        </span>
        <RadioGroup
          onChange={(ev) =>
            onFilterOptionsChange(FilterDataOptions.frequency, ev.target.value)
          }
          value={frequencyFilterValue}
          data-testid="schedule-select"
        >
          <Space wrap={true}>
            {Object.values(Schedule).map((schedule: string) => (
              <RadioButton
                key={schedule}
                value={schedule}
                className={styles.frequencyBtn}
                data-testid={`schedule-option-${schedule}`}
              >
                {ScheduleToLabelMap[schedule as Schedule]}
              </RadioButton>
            ))}
          </Space>
        </RadioGroup>
      </Col>
      <AnimatePresence exitBeforeEnter>
        {displayClearFilter && (
          <AnimatedDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Button
              onClick={() =>
                onFilterOptionsChange(FilterDataOptions.frequency, null)
              }
              data-testid="button-clear"
              type="ghost"
              shape="round"
              size="small"
              icon={<MdClear />}
              className={cn(styles.clearBtn, globalStyles.center)}
              danger
            >
              <FormattedMessage defaultMessage="Clear" />
            </Button>
          </AnimatedDiv>
        )}
      </AnimatePresence>
    </Row>
  );
};

export default Filter;
