import { ChartOptions } from 'chart.js';

export const BORDER_RADIUS_OUTER = 12;
export const BORDER_RADIUS_INNER = 5;

export const getDefaultDatasetConfig = (isFirst: boolean, isLast: boolean) => ({
  borderSkipped: false,
  borderWidth: 2,
  borderColor: '#fff',
  borderRadius: {
    topLeft: isFirst ? BORDER_RADIUS_OUTER : BORDER_RADIUS_INNER,
    bottomLeft: isFirst ? BORDER_RADIUS_OUTER : BORDER_RADIUS_INNER,
    topRight: isLast ? BORDER_RADIUS_OUTER : BORDER_RADIUS_INNER,
    bottomRight: isLast ? BORDER_RADIUS_OUTER : BORDER_RADIUS_INNER,
  },
});

const getConfig = (onAnimationComplete: () => void): ChartOptions<'bar'> =>
  ({
    animation: {
      onComplete: onAnimationComplete,
    },
    indexAxis: 'y',
    layout: {
      padding: {
        left: -8,
        bottom: -10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 1,
    barPercentage: 1,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
          drawBorder: false,
        },
        stacked: true,
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 0,
          display: false,
          drawBorder: false,
        },
        stacked: true,
      },
    },
  } as ChartOptions<'bar'>);

export default getConfig;
