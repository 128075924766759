import { Skeleton } from 'antd';
import Modal from 'components/ui/molecules/Modal';

type Props = {
  isVisible: boolean;
};

const { Button } = Skeleton;

const SkeletonModal: React.FC<Props> = ({ isVisible }) => {
  return (
    <Modal
      title={<Skeleton active={true} paragraph={false} />}
      visible={isVisible}
      backgroundClickable={true}
      footer={[<Button key="button" active={true} />]}
    >
      <Skeleton active={true} />
    </Modal>
  );
};

export default SkeletonModal;
