import { connect } from 'react-redux';
import {
  LocationSelectorTray,
  setSelectedBuildingId,
  setUnsavedChanges,
} from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  auth: { currentUser },
  space: {
    locationTray: { selectorsSettings, unsavedChanges },
  },
}: RootState) => ({
  orgId: currentUser?.orgId,
  selectorsSettings,
  unsavedChanges,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedBuildingId: (
      locationSelectorTray: LocationSelectorTray,
      spaceId: string,
    ) => {
      dispatch(
        setSelectedBuildingId({
          type: locationSelectorTray,
          payloadValue: spaceId,
        }),
      );
    },
    setUnsavedChanges: (isUnsavedChanges: boolean) => {
      return dispatch(setUnsavedChanges(isUnsavedChanges));
    },
  };
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
