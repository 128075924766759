import { Key, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateTelemetryFeedMutation } from 'api/dataSink';

import { PropertyRow } from '../../config';
import Confirm from './components/Confirm';
import CreateSink from './components/CreateSink';
import Home from './components/Home/Home';
import SinkList from './components/SinkList';

type DataSourceIds = {
  dataSourceId: string;
  properties: PropertyRow[];
};

type Props = {
  dataSourceIds: DataSourceIds[];
  onClose: () => void;
};

export enum ViewType {
  HOME,
  CREATE_SINK,
  SINK_LIST,
  CONFIRMATION,
}

const AssignToSinkModal: React.FC<Props> = ({ dataSourceIds, onClose }) => {
  const [dataSinkIds, setDataSinkIds] = useState<Key[]>([]);
  const [viewType, setViewType] = useState<ViewType | undefined>(ViewType.HOME);
  const navigate = useNavigate();
  const [createTelemetryFeed] = useCreateTelemetryFeedMutation();

  const closeModal = (redirectTo?: string) => {
    setViewType(undefined);
    setTimeout(() => {
      onClose();
      if (redirectTo) {
        navigate(redirectTo);
      }
    }, 400); // wait for fade out animation
  };

  useEffect(() => {
    if (dataSinkIds.length > 0 && dataSourceIds.length > 0) {
      const promises = dataSinkIds
        .map((dataSinkId) =>
          dataSourceIds.map(({ dataSourceId, properties }) => {
            const data = properties.map(({ propertyId, propertyCategory }) => ({
              dataSourceId,
              telemetryPropertyId: propertyId,
              propertyKey: propertyCategory,
            }));

            return createTelemetryFeed({
              dataSinkId: dataSinkId as string,
              data,
            }).unwrap();
          }),
        )
        .flat();

      Promise.all(promises)
        .then(() => {
          setViewType(ViewType.CONFIRMATION);
        })
        .catch(() => {
          // do nothing
        });
    }
  }, [dataSinkIds, dataSourceIds]);

  return (
    <>
      <Home
        isVisible={viewType === ViewType.HOME}
        dataLength={dataSourceIds.length}
        onViewTypeChange={setViewType}
        onCloseModal={() => closeModal()}
      />
      <CreateSink
        isVisible={viewType === ViewType.CREATE_SINK}
        onViewTypeChange={setViewType}
        onSinkCreated={(id) => setDataSinkIds(id)}
        onCloseModal={() => closeModal()}
      />
      <SinkList
        isVisible={viewType === ViewType.SINK_LIST}
        onCloseModal={() => closeModal()}
        onViewTypeChange={setViewType}
        onSinkSelected={(id) => setDataSinkIds(id)}
      />
      <Confirm
        isVisible={viewType === ViewType.CONFIRMATION}
        onRedirect={(url) => closeModal(url)}
      />
    </>
  );
};

export default AssignToSinkModal;
