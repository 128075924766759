import { connect } from 'react-redux';
import { setUser } from 'ducks/auth/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({ auth: { currentUser } }: RootState) => ({
  currentUser,
});

const mapDispatchToProps = { setUser };

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
