import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpaceNodeCategory, useGetSpaceNodeListQuery } from 'api/space';
import { selectActiveSpaces } from 'ducks/space/selectors';
import { Routes } from 'views/Map/components/Routing/Routes';

import NoBuildingModal from './components/NoBuildingModal';
import NoFloorPlanModal from './components/NoFloorPlanModal';
import NoFloorsModal from './components/NoFloorsModal';
import NoSelectedFloorModal from './components/NoSelectedFloorModal';
import SkeletonModal from './components/SkeletonModal';
import SuccessModal from './components/SuccessModal';
import UploadModal from './components/UploadModal';

export enum ViewType {
  EMPTY,
  NO_BUILDING,
  NO_FLOORS,
  NO_SELECTED_FLOOR,
  NO_FLOORPLAN,
  FORM,
  SUCCESS,
}

type Props = {
  orgId?: string;
  activeLocationId?: string;
  openLocationTray: () => void;
};

const FloorPlanUpload: React.FC<Props> = ({
  orgId,
  activeLocationId,
  openLocationTray,
}) => {
  const [viewType, setViewType] = useState<ViewType>();
  const navigate = useNavigate();

  const { data: spaceNodes, isFetching: isSpaceNodesFetching } =
    useGetSpaceNodeListQuery({ orgId }, { skip: !orgId });

  const { selectedFloor } = useMemo(
    () => selectActiveSpaces(spaceNodes || {}, activeLocationId || ''),
    [spaceNodes, activeLocationId],
  );

  const overallSpaceNodesLength = useMemo(
    () => Object.keys(spaceNodes || {}).length,
    [spaceNodes],
  );

  const floorsLength = useMemo(
    () =>
      Object.values(spaceNodes || {}).filter(
        ({ category }) => category === SpaceNodeCategory.FLOOR,
      ).length,
    [spaceNodes],
  );

  useEffect(() => {
    if (viewType && [ViewType.SUCCESS, ViewType.FORM].includes(viewType))
      return;

    if (isSpaceNodesFetching) {
      setViewType(ViewType.EMPTY);
    } else if (!overallSpaceNodesLength) {
      setViewType(ViewType.NO_BUILDING);
    } else if (!floorsLength) {
      setViewType(ViewType.NO_FLOORS);
    } else if (!selectedFloor) {
      setViewType(ViewType.NO_SELECTED_FLOOR);
    } else if (!selectedFloor.systemMeta?.floorplan) {
      setViewType(ViewType.NO_FLOORPLAN);
    } else {
      setViewType(ViewType.FORM);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloor, isSpaceNodesFetching]);

  const goToMap = () => navigate(`/${Routes.MAP}`);

  return (
    <>
      <SkeletonModal
        isVisible={
          isSpaceNodesFetching &&
          !(viewType && [ViewType.SUCCESS, ViewType.FORM].includes(viewType))
        }
      />
      <NoBuildingModal
        isVisible={viewType === ViewType.NO_BUILDING}
        onAction={openLocationTray}
      />
      <NoFloorsModal
        isVisible={viewType === ViewType.NO_FLOORS}
        onAction={openLocationTray}
      />
      <NoSelectedFloorModal
        isVisible={viewType === ViewType.NO_SELECTED_FLOOR}
        onAction={openLocationTray}
      />
      <NoFloorPlanModal
        isVisible={viewType === ViewType.NO_FLOORPLAN}
        onAction={() => setViewType(ViewType.FORM)}
      />
      <UploadModal
        isVisible={viewType === ViewType.FORM}
        onCloseModal={() => goToMap()}
        onAction={() => setViewType(ViewType.SUCCESS)}
      />
      <SuccessModal
        isVisible={viewType === ViewType.SUCCESS}
        onAction={() => goToMap()}
      />
    </>
  );
};

export default FloorPlanUpload;
