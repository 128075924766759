import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Input, Upload } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getApiBaseUrl } from 'api/api';
import { ConfigurationFile } from 'api/configuration';

import styles from './styles.module.scss';

const { Group } = Input;

type Props = {
  configFile: ConfigurationFile;
  value?: string;
  url: string;
  onChange?: (response: any) => void;
};

// File upload input designed to work inside an Antd Form
const FileUploadField: React.FC<Props> = ({
  configFile,
  value,
  url,
  onChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [headers, setHeaders] = useState<Record<string, string>>({});
  const { getAccessTokenSilently } = useAuth0();

  // File upload request needs authorization header, beforeUpload returns a
  // promise, must resolve before making upload request
  const beforeUpload = async () => {
    const token = await getAccessTokenSilently();
    setHeaders({ Authorization: `Bearer ${token}` });
    return true;
  };

  // Antd form connects to the onChange prop. Calling onChange sets the string
  // value displayed in the text input by setting the form field value
  const onChangeInner = ({ file }: any) => {
    setIsLoading(true);

    if (onChange) {
      if (file.response) {
        const filename = file.response.configuration_files[configFile.key];

        if (filename) {
          setIsLoading(false);
          onChange(filename);
        }
      } else {
        onChange(undefined);
      }
    }
  };

  return (
    <Group compact>
      <Input
        value={value}
        style={{ width: '79%' }}
        disabled
        addonAfter={isLoading ? <LoadingOutlined /> : undefined}
      />
      <Upload
        action={getApiBaseUrl() + url}
        showUploadList={false}
        accept={configFile.mimetypes.join(',')}
        beforeUpload={beforeUpload}
        headers={headers}
        onChange={onChangeInner}
      >
        <Button icon={<UploadOutlined className={styles.uploadIcon} />}>
          <FormattedMessage defaultMessage="Upload" />
        </Button>
      </Upload>
    </Group>
  );
};

export default FileUploadField;
