import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Skeleton } from 'antd';
import { AxiosResponse } from 'axios';
import { isArray, isObject, reduce } from 'lodash';
import qs from 'qs';
import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

import globalStyles from 'styles/global.module.scss';

const { confirm } = Modal;

export const formatObjectKeys = (
  obj:
    | Record<string, unknown>
    | ArrayBuffer
    | Array<any>
    | AxiosResponse<any>
    | URLSearchParams,
  formatFn: (param: string) => string,
): Record<string, unknown> | Array<any> | URLSearchParams | ArrayBuffer => {
  if (!isObject(obj) || obj instanceof ArrayBuffer) return obj;
  if (isArray(obj)) return obj.map((v) => formatObjectKeys(v, formatFn));
  if (obj instanceof URLSearchParams) return obj;

  return reduce(
    obj,
    (r, v, k) => {
      return {
        ...r,
        ...(typeof v === 'undefined'
          ? {}
          : {
              [k.includes('-') ? k : formatFn(k)]: formatObjectKeys(
                v,
                formatFn,
              ),
            }),
      };
    },
    {},
  );
};

export const paramsSerializer = (params: any): string =>
  qs.stringify(params, { arrayFormat: 'repeat' });

export const getTableSkeleton = (keys: string[], rows: number): any => {
  return Array.from({ length: rows }, (_, idx) =>
    keys.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: <Skeleton data-testid="skeleton" active paragraph={false} />,
      }),
      { key: idx, hideActions: true },
    ),
  );
};

export const confirmPopup = (
  content: ReactNode | string,
  onClickOk: () => void,
  intl: IntlShape,
): void => {
  confirm({
    title: intl.formatMessage({
      defaultMessage: 'Confirm Action',
    }),
    icon: <ExclamationCircleOutlined />,
    content,
    okText: intl.formatMessage({
      defaultMessage: 'Yes',
    }),
    okType: 'danger',
    cancelText: intl.formatMessage({
      defaultMessage: 'No',
    }),
    onOk() {
      onClickOk();
    },
  });
};

export const sortByParam = <K extends string, T extends Record<K, string>>(
  param: K,
): ((a: T, b: T) => number) => {
  return (a, b) => {
    if (a[param] < b[param]) {
      return -1;
    }
    if (a[param] > b[param]) {
      return 1;
    }
    return 0;
  };
};

export const idToInteger = (id: string): number => {
  return parseInt(id.split('-')[1], 10);
};

export const avatarColours = [
  '#00AA55',
  '#009FD4',
  '#B381B3',
  '#736EEE',
  '#E3BC00',
  '#D47500',
  '#DC2A2A',
];

export const avatarColour = (name?: string): string => {
  if (typeof name === 'undefined') return globalStyles.colorMain;
  return avatarColours[name.charCodeAt(0) % avatarColours.length];
};

export const isValidEmail = (email: string): boolean => {
  const re = /^\w+([.-]?\w+)+@\w+([.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
  return re.test(String(email).toLowerCase());
};

export const startsWithVowel = (name: string | undefined): boolean => {
  return (
    typeof name !== 'undefined' && 'aAeEiIoOuU'.split('').includes(name[0])
  );
};

export const timestampTimezoneUnaware = (timestamp: string): string =>
  timestamp.split('+')[0];

export const enumToArray = <T extends string>(e: Record<string, any>): T[] =>
  Object.keys(e)
    .map((k) => e[k])
    .filter((v) => typeof v === 'string') as T[];
