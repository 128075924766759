import { Dispatch, SetStateAction } from 'react';
import SinkForm from 'views/Settings/views/Bms/components/SinkForm';
import { ViewType } from '../../AssignToSinkModal';

type Props = {
  isVisible: boolean;
  onSinkCreated: (dataSinkId: string[]) => void;
  onViewTypeChange: Dispatch<SetStateAction<ViewType | undefined>>;
  onCloseModal: () => void;
};

const CreateSink: React.FC<Props> = ({
  isVisible,
  onSinkCreated,
  onCloseModal,
  onViewTypeChange,
}) => {
  return (
    <SinkForm
      isVisible={isVisible}
      onCloseModal={onCloseModal}
      onCancel={() => onViewTypeChange(ViewType.HOME)}
      onSubmitted={(data: any) => onSinkCreated([data.id])}
    />
  );
};

export default CreateSink;
