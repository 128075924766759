import { Helmet as HelmetComponent } from 'react-helmet';

type Props = {
  title?: string;
};
const Helmet: React.FC<Props> = ({ title = '' }) => {
  const titleList: string[] = [];

  if (process.env.REACT_APP_TITLE) titleList.push(process.env.REACT_APP_TITLE);
  if (title) titleList.push(title);

  return <HelmetComponent title={titleList.join(' - ')} />;
};

export default Helmet;
