import React from 'react';

import PdfProvider, { GenerationMode } from 'components/PdfProvider';
import Routing from './components/Routing';

const Pdf: React.FC = () => {
  return (
    <PdfProvider generationMode={GenerationMode.Backend}>
      <Routing />
    </PdfProvider>
  );
};

export default Pdf;
