import { Button, Form, Input, TreeSelect, Select } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  DataSink,
  useCreateDataSinkMutation,
  useEditDataSinkMutation,
  useLazyGetDataSinkByIdQuery,
} from 'api/dataSink';
import { DataSinkType, useGetDataSinkTypeListQuery } from 'api/dataSinkType';
import { useGetSpaceNodeListQuery } from 'api/space';
import { prepareData } from 'api/space/utils';

import Loader from 'components/ui/atoms/Loader';
import Modal from 'components/ui/molecules/Modal';

const { Option } = Select;
const { useForm, Item } = Form;

type Props = {
  orgId?: string;
  sinkId?: string;
  isVisible: boolean;
  onCloseModal: () => void;
  onCancel: () => void;
  onSubmitted: (data: any) => void;
};

const SinkForm: React.FC<Props> = ({
  orgId,
  sinkId,
  isVisible,
  onCloseModal,
  onCancel,
  onSubmitted,
}) => {
  const [form] = useForm();
  const [dataSinkTypes, setDataSinkTypes] = useState<DataSinkType[]>([]);
  const [space, setSpace] = useState({
    selected: undefined,
    treeData: [],
  });
  const [isPopulating, setIsPopulating] = useState<boolean>(false);
  const [sinkDetails, setSinkDetails] = useState<DataSink | undefined>();
  const [getDataSinkById] = useLazyGetDataSinkByIdQuery();
  const dataSinkTypeList = useGetDataSinkTypeListQuery(undefined);
  const [editDataSink] = useEditDataSinkMutation();
  const [createDataSink] = useCreateDataSinkMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isTypeAllowed = typeof sinkId === 'undefined';

  const { data: spaceNodes } = useGetSpaceNodeListQuery(
    { orgId },
    { skip: !orgId },
  );

  // fetch sink data
  useEffect(() => {
    if (!sinkId) return;

    setIsPopulating(true);
    getDataSinkById(sinkId)
      .unwrap()
      .then((sink) => {
        form.setFieldsValue({
          name: sink?.meta.name,
          locationId: sink?.locationId,
          typeId: sink?.typeId,
        });
        setSinkDetails(sink);
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => setIsPopulating(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sinkId]);

  useEffect(() => {
    if (dataSinkTypeList.data) {
      setDataSinkTypes(dataSinkTypeList.data);
    }
  }, [dataSinkTypeList.data]);

  // fetch & process buildings
  useEffect(() => {
    if (!spaceNodes) return;

    setSpace(({ selected }) => ({
      selected,
      treeData: prepareData(Object.values(spaceNodes)) as never[],
    }));
  }, [spaceNodes]);

  const handleFormSubmit = (action: Promise<any>): any => {
    setIsLoading(true);

    action
      .then((value) => onSubmitted(value))
      .catch(() => {
        // do nothing
      })
      .finally(() => setIsLoading(false));
  };

  const onFormSubmit = (): void => {
    if (!orgId && !sinkId) return;

    form.validateFields().then((values) => {
      if (sinkId) {
        const sink = {
          ...(sinkDetails as DataSink),
          locationId: values.locationId,
          meta: {
            ...(sinkDetails as DataSink).meta,
            name: values.name,
          },
        };

        handleFormSubmit(
          editDataSink({ dataSinkId: sink.id, data: sink }).unwrap(),
        );
      } else if (orgId)
        handleFormSubmit(
          createDataSink({
            orgId,
            locationId: values.locationId,
            typeId: values.typeId,
            meta: { name: values.name, description: values.name },
          }).unwrap(),
        );
    });
  };

  return (
    <Modal
      visible={isVisible}
      closable={true}
      onCancel={onCloseModal}
      title={<FormattedMessage defaultMessage="Save data sink" />}
      footer={[
        <Button
          key="btn-save"
          loading={isLoading}
          shape="round"
          type="primary"
          data-testid="btn-save"
          onClick={onFormSubmit}
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>,
        <Button
          key="btn-back"
          loading={isLoading}
          shape="round"
          data-testid="btn-back"
          onClick={onCancel}
        >
          <FormattedMessage defaultMessage="Back" />
        </Button>,
      ]}
    >
      <Loader visible={isPopulating}>
        <Form form={form} layout="vertical">
          <Item
            label={<FormattedMessage defaultMessage="Name" />}
            name="name"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="This field is required" />
                ),
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            label={<FormattedMessage defaultMessage="Type" />}
            name="typeId"
            rules={[
              {
                required: isTypeAllowed,
                message: (
                  <FormattedMessage defaultMessage="This field is required" />
                ),
              },
            ]}
          >
            <Select disabled={!isTypeAllowed} data-testid="type-field">
              {dataSinkTypes.map(({ id, name: typeName }) => (
                <Option key={id} value={id}>
                  {typeName}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            label={<FormattedMessage defaultMessage="Location" />}
            name="locationId"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="This field is required" />
                ),
              },
            ]}
          >
            <TreeSelect
              treeDataSimpleMode
              style={{ width: '100%' }}
              treeLine={{ showLeafIcon: false }}
              treeData={space.treeData}
              data-testid="tree-select"
            />
          </Item>
        </Form>
      </Loader>
    </Modal>
  );
};

export default SinkForm;
