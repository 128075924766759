import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import { Upload } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState, ReactElement } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';

import UploadArea from './components/UploadArea';
import UploadItem from './components/UploadItem';
import styles from './styles.module.scss';
import {
  getFileDetailsLabel,
  getThumbnailFromFile,
  validateFile,
} from './utils';

export enum RequestStatus {
  Idle,
  Processing,
  Success,
  Failed,
}

export type ImageUploadFieldValue = {
  imageUrl?: string;
  displayName?: string;
  size?: number;
  fileToUpload?: RcFile;
  remove?: boolean;
};

export type Props = {
  value?: ImageUploadFieldValue;
  onChange?: (value: ImageUploadFieldValue) => void;
  fileSizeLimitInMB?: number;
  allowedFileTypes?: string[];
  uploadProgress: number;
  requestStatus: RequestStatus;
};

export interface FileInfo {
  imageUrl: string;
  displayName: string;
  size: number;
}

const ImageUploadField: React.FC<Props> = ({
  value,
  uploadProgress,
  requestStatus,
  onChange = () => undefined,
  fileSizeLimitInMB = 10,
  allowedFileTypes = ['image/png'],
}) => {
  const [fileInfo, setFileInfo] = useState<FileInfo | undefined>();
  const [fileInvalidMessage, setFileInvalidMessage] = useState<
    string | ReactElement
  >();

  const dialogEnabled = useMemo(
    () => isEmpty(value) && !fileInfo,
    [value, fileInfo],
  );

  const fileSize = useMemo(
    () => fileInfo?.size || value?.size,
    [fileInfo, value],
  );

  const imageUrl = useMemo(
    () => fileInfo?.imageUrl || value?.imageUrl,
    [fileInfo, value],
  );

  const allowedFileTypesLabel: ReactElement = useMemo(
    () => (
      <FormattedList
        type="disjunction"
        value={allowedFileTypes.map((mimeType) =>
          mimeType.split('/')[1].toUpperCase(),
        )}
      />
    ),
    [allowedFileTypes],
  );

  const textRight: string | ReactElement = useMemo(() => {
    if (requestStatus === RequestStatus.Failed)
      return <FormattedMessage defaultMessage="Upload failed" />;

    const uploadProgressLabel =
      requestStatus === RequestStatus.Processing
        ? `${uploadProgress > 100 ? 100 : uploadProgress}% `
        : '';

    const fileSizeLabel =
      !fileInvalidMessage && fileSize
        ? getFileDetailsLabel(fileSize, uploadProgress)
        : '';

    return (
      <>
        {uploadProgressLabel}
        {fileSizeLabel}
      </>
    );
  }, [fileSize, requestStatus, fileInvalidMessage, uploadProgress]);

  const handleRemove = async () => {
    if (fileInfo) {
      setFileInfo(undefined);
      onChange({});
    } else if (!isEmpty(value)) {
      onChange({ ...value, remove: !value?.remove });
    }
  };

  useEffect(() => {
    setFileInvalidMessage('');
    setFileInfo(undefined);

    if (value?.fileToUpload) {
      getThumbnailFromFile(value.fileToUpload).then((image) => {
        setFileInfo({
          imageUrl: image,
          displayName: value?.fileToUpload?.name || '',
          size: value?.fileToUpload?.size || 0,
        });
      });
    }
  }, [value]);

  const props: UploadProps = {
    accept: allowedFileTypes.join(','),
    beforeUpload: (file) => {
      getThumbnailFromFile(file).then((image) => {
        setFileInfo({
          imageUrl: image,
          displayName: file.name,
          size: file.size,
        });
      });

      const errorMessage = validateFile(
        file,
        fileSizeLimitInMB,
        allowedFileTypes,
        allowedFileTypesLabel,
      );

      if (errorMessage) {
        setFileInvalidMessage(errorMessage);
        onChange(value || {});
      } else {
        setFileInvalidMessage('');
        onChange({ fileToUpload: file, displayName: file.name });
      }

      return false;
    },
    fileList: value?.fileToUpload ? [value.fileToUpload] : [],
  };

  return (
    <Upload
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      openFileDialogOnClick={dialogEnabled}
      className={styles.container}
      showUploadList={false}
    >
      {isEmpty(value) && !fileInfo ? (
        <UploadArea
          allowedFileTypesLabel={allowedFileTypesLabel}
          fileSizeLimitInMB={fileSizeLimitInMB}
        />
      ) : (
        <UploadItem
          progress={fileInfo ? uploadProgress : undefined}
          imageUrl={imageUrl}
          leftText={
            fileInvalidMessage || value?.displayName || fileInfo?.displayName
          }
          rightText={textRight}
          markedToRemove={!!value?.remove}
          requestStatus={requestStatus}
          onRemove={handleRemove}
        />
      )}
    </Upload>
  );
};

export default ImageUploadField;
