import Icon from '@ant-design/icons';
import { Typography, Button } from 'antd';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SpaceNodeCollection, useGetSpaceNodeListQuery } from 'api/space';
import { ReactComponent as Buildings } from 'assets/svg/buildings.svg';
import { getIntl } from 'components/Intl';
import { LocationTraySize, LocationTrayTab } from 'components/LocationTray';

import Search from 'components/ui/molecules/Search';
import { selectBuildings } from 'ducks/space/selectors';
import BuildingCollection from './components/BuildingCollection';

import styles from './styles.module.scss';
import { getRecentlySelectedBuildings } from './utils';

const { Title } = Typography;

export enum DisplayState {
  RecentlySelected,
  Filter,
  FilterNoResults,
  All,
  None,
}

const getDisplayStateLabel = (
  state: DisplayState,
  buildingSearchResultsCount: number,
) => {
  switch (state) {
    case DisplayState.RecentlySelected:
      return getIntl().formatMessage({ defaultMessage: 'Recently selected' });
    case DisplayState.Filter:
      return getIntl().formatMessage(
        {
          defaultMessage:
            '{count, plural, =0 {No buildings found} one {# building found} other {# buildings found}}',
        },
        { count: buildingSearchResultsCount },
      );
    case DisplayState.All:
      return getIntl().formatMessage({ defaultMessage: 'All buildings' });
    case DisplayState.FilterNoResults:
    case DisplayState.None:
    default:
      return '';
  }
};

export type Props = {
  orgId?: string;
  onCreateClick: (activeTab: LocationTrayTab) => void;
  onBuildingSelected: (buildingId: string) => void;
  traySize: LocationTraySize;
};

const BuildingSelector: React.FC<Props> = ({
  orgId,
  onCreateClick,
  onBuildingSelected,
  traySize,
}) => {
  const [buildingSearchInput, setBuildingSearchInput] = useState('');
  const [buildingSearchResultsCount, setBuildingSearchResultsCount] =
    useState(0);
  const [recentlySelected, setRecentlySelected] = useState<SpaceNodeCollection>(
    {},
  );
  const [buildingsSource, setBuildingsSource] = useState<SpaceNodeCollection>(
    {},
  );
  const [displayState, setDisplayState] = useState<DisplayState>(
    DisplayState.None,
  );

  const intl = useIntl();
  const aria = intl.formatMessage({ defaultMessage: 'Building icon' });

  const { data: spaceNodes, isFetching } = useGetSpaceNodeListQuery(
    { orgId },
    { skip: !orgId },
  );

  const buildings = useMemo(
    () => (!spaceNodes ? {} : selectBuildings(spaceNodes)),
    [spaceNodes],
  );

  useEffect(() => {
    if (isEmpty(buildings)) return;
    setRecentlySelected(getRecentlySelectedBuildings(buildings));
  }, [buildings]);

  useEffect(() => {
    if (isFetching || isEmpty(buildings)) return;

    if (buildingSearchInput.length === 0) {
      setDisplayState(DisplayState.All);
      setBuildingsSource(buildings);
    } else {
      setDisplayState(DisplayState.Filter);
      setBuildingsSource(buildings);
    }
  }, [isFetching, buildings, recentlySelected, buildingSearchInput]);

  useEffect(() => {
    if (displayState !== DisplayState.Filter) return;

    if (buildingSearchResultsCount === 0) {
      setDisplayState(DisplayState.FilterNoResults);
    } else {
      setDisplayState(DisplayState.Filter);
    }
  }, [buildingSearchResultsCount, displayState]);

  if (!isFetching && isEmpty(buildings)) {
    return (
      <div
        className={styles.view}
        data-testid="building-selector-view-no-buildings"
      >
        <Icon
          component={Buildings}
          className={cn(styles.iconCenter, styles.iconCenterNoBuildings)}
          aria-label={aria}
        />
        <Title level={2} className={styles.stateLabel}>
          <FormattedMessage defaultMessage="Create a building to get started" />
        </Title>
        <Button
          className={cn(
            styles.createBuildingButton,
            styles.createBuildingButtonNoBuildings,
          )}
          type="primary"
          shape="round"
          onClick={() => onCreateClick(LocationTrayTab.Create)}
        >
          <FormattedMessage defaultMessage="Create a new building" />
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.view} data-testid="building-selector-view">
      <Icon
        component={Buildings}
        className={styles.iconCenter}
        aria-label={aria}
      />
      <Title level={4}>
        <FormattedMessage defaultMessage="Select a building or create a new one" />
      </Title>
      <Search
        className={styles.filterBuildingsInput}
        placeholder={intl.formatMessage({
          defaultMessage: 'Search by building name or address',
        })}
        onChange={setBuildingSearchInput}
      />
      <Button
        className={styles.createBuildingButton}
        type="primary"
        shape="round"
        onClick={() => onCreateClick(LocationTrayTab.Create)}
        data-testid="create-building-button"
      >
        <FormattedMessage defaultMessage="Create a new building" />
      </Button>
      {displayState !== DisplayState.FilterNoResults && (
        <Title level={5} className={styles.stateLabel}>
          {getDisplayStateLabel(displayState, buildingSearchResultsCount)}
        </Title>
      )}
      <div className={styles.buildingCollectionContainer}>
        {!isFetching && (
          <BuildingCollection
            traySize={traySize}
            filterBy={buildingSearchInput}
            onBuildingSelect={onBuildingSelected}
            buildings={buildingsSource}
            onResultsCountChanged={(count: number) =>
              setBuildingSearchResultsCount(count)
            }
          />
        )}
      </div>
    </div>
  );
};

export default BuildingSelector;
