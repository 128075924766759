import { Typography } from 'antd';
import { ReactElement } from 'react';

import { FaClipboard } from 'react-icons/fa';

import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
  testId: string;
  label: ReactElement | string;
};

const FormTitle: React.FC<Props> = ({ testId, label }) => (
  <div className={styles.formTitle}>
    <Title
      level={4}
      ellipsis={true}
      className={styles.title}
      data-testid={testId}
    >
      <FaClipboard className={styles.setupIcon} />
      <span>{label}</span>
    </Title>
  </div>
);

export default FormTitle;
