import { Alert, Modal, Select } from 'antd';
import { difference } from 'lodash';
import React, { ReactChild, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAssignRoleToAPIKeyMutation,
  useGetAPIKeyQuery,
  useRemoveRoleFromAPIKeyMutation,
} from 'api/apiKey';
import { useGetRolesQuery, useGetUserQuery } from 'api/user';

import Loader from 'components/ui/atoms/Loader';
import { getFullPath } from 'views/Settings/views/ApiKey/components/Routing';

const { Option } = Select;

type Props = {
  title: string | ReactChild;
};

const Roles: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { apiKeyId = '' } = useParams<'apiKeyId'>();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isServiceAccountSynced, setServiceAccountSynced] = useState(false);
  const [options, setOptions] = useState<ReactChild[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const roles = useGetRolesQuery();
  const { data: apiKey, isFetching: isApiKeyFetching } = useGetAPIKeyQuery(
    apiKeyId,
    { skip: !apiKeyId },
  );
  const serviceAccount = useGetUserQuery(apiKey?.serviceAccountId || '', {
    skip: !apiKey?.serviceAccountId,
  });

  const [assignRoleTrigger] = useAssignRoleToAPIKeyMutation();
  const [removeRoleTrigger] = useRemoveRoleFromAPIKeyMutation();

  const backToList = useCallback((): void => {
    setIsVisible(false);

    setTimeout(() => navigate(getFullPath(null, true)), 400); // wait for fade out animation
  }, []);

  useEffect(() => {
    if (!apiKeyId) backToList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeyId]);

  // map roles to options
  useEffect(() => {
    if (!roles?.data) return;

    const availableRoles = Object.keys(roles.data);

    setOptions(
      availableRoles.map((role: string) => (
        <Option value={role} key={role}>
          {role}
        </Option>
      )),
    );
  }, [roles.data]);

  // update loading state
  useEffect(() => {
    setIsLoading(
      serviceAccount.isFetching || roles.isFetching || isApiKeyFetching,
    );
  }, [serviceAccount.isFetching, roles.isFetching, isApiKeyFetching]);

  // update selected options
  useEffect(() => {
    const { data, isFetching, isSuccess } = serviceAccount;

    if (isFetching || !isSuccess || !data) return;

    if (!data.synced) {
      setServiceAccountSynced(false);
      return;
    }

    setSelectedOptions(data.roles || []);
    setServiceAccountSynced(true);
  }, [serviceAccount]);

  const handleChange = async (currentValues: string[]) => {
    const added = difference(currentValues, selectedOptions)[0];
    const removed = difference(selectedOptions, currentValues)[0];

    try {
      setSelectedOptions(currentValues);

      if (!apiKeyId) return;

      if (added) await assignRoleTrigger({ apiKeyId, role: added }).unwrap();
      if (removed) {
        await removeRoleTrigger({ apiKeyId, role: removed }).unwrap();
      }
    } catch (err) {
      setSelectedOptions(selectedOptions);
    }
  };

  return (
    <div data-testid="view-roles-form">
      <Modal
        title={title}
        open={isVisible}
        closable={true}
        onCancel={backToList}
        data-testid="roles-form"
        footer={false}
      >
        <Loader
          text={intl.formatMessage({ defaultMessage: 'Loading data...' })}
          visible={isLoading}
        >
          <>
            {!isServiceAccountSynced && !isLoading && (
              <Alert
                banner
                message={
                  <FormattedMessage defaultMessage="This API key has not been synced yet. Please try again in 15 minutes." />
                }
              />
            )}
            {(isServiceAccountSynced || isLoading) && (
              <Select
                mode="multiple"
                bordered={false}
                data-testid="role-select"
                placeholder={
                  <FormattedMessage defaultMessage="Please select roles" />
                }
                value={selectedOptions}
                onChange={handleChange}
                style={{ width: '100%' }}
                disabled={isLoading}
              >
                {options}
              </Select>
            )}
          </>
        </Loader>
      </Modal>
    </div>
  );
};

export default Roles;
