import { connect } from 'react-redux';
import {
  setSelectedFloorId,
  openLocationTray,
  LocationSelectorTray,
} from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  auth: { currentUser },
  space: {
    locationTray: { selectorsSettings },
  },
}: RootState) => ({
  orgId: currentUser?.orgId,
  selectorsSettings,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedFloorId: (
      locationSelectorTray: LocationSelectorTray,
      spaceNodeId: string,
    ) => {
      dispatch(
        setSelectedFloorId({
          type: locationSelectorTray,
          payloadValue: spaceNodeId,
        }),
      );
    },
    openLocationTray: (
      locationSelectorTray: LocationSelectorTray,
      open: boolean,
    ) => {
      dispatch(
        openLocationTray({
          type: locationSelectorTray,
          payloadValue: open,
        }),
      );
    },
  };
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
