import { Button, Col, Layout, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, Outlet } from 'react-router-dom';

import { OrgNodes, useGetOrgTreeQuery } from 'api/org';
import Card from 'components/ui/molecules/Card';
import Table from 'components/ui/molecules/Table';
import { getTableSkeleton } from 'utilities/utilities';
import { getFullPath } from 'views/Settings/views/Org/components/Routing';
import { Routes } from 'views/Settings/views/Org/components/Routing/Routes';

import { columns, OrgRow } from './config';
import styles from './styles.module.scss';

const { Title } = Typography;

const List: React.FC = () => {
  const navigate = useNavigate();
  const [orgs, setOrgs] = useState<OrgRow[]>(
    getTableSkeleton(['name', 'category', 'lifecycle'], 3),
  );
  const getOrgNodes = useGetOrgTreeQuery();

  const mapResponseToTableRows = (data: OrgNodes) => {
    setOrgs(
      Object.values(data).map(({ meta, lifecycle, id }) => ({
        name: meta.name,
        lifecycle,
        key: id,
      })),
    );
  };

  const updateTable = () => {
    if (getOrgNodes.isFetching || getOrgNodes.isUninitialized) {
      getTableSkeleton(['name', 'category', 'lifecycle'], 3);
    } else if (getOrgNodes.isSuccess) mapResponseToTableRows(getOrgNodes.data);
  };

  useEffect(() => {
    updateTable();
  }, [getOrgNodes]);

  const handleOnClickEdit = (orgIdToEdit: string): void => {
    navigate(getFullPath(Routes.EDIT, true).replace(':orgId', orgIdToEdit));
  };

  return (
    <Layout data-testid="org-list">
      <Card className={styles.card}>
        <Row align="middle" justify="space-between">
          <Col>
            <Title>
              <FormattedMessage defaultMessage="Organisations" />
            </Title>
          </Col>
          <Col>
            <Link to={{ pathname: Routes.ADD }}>
              <Button
                data-testid="button-add"
                type="primary"
                shape="round"
                icon={<AiOutlinePlus />}
              >
                <FormattedMessage defaultMessage="New organisation" />
              </Button>
            </Link>
          </Col>
        </Row>
        <Table<OrgRow> data={orgs} columns={columns(handleOnClickEdit)} />
      </Card>
      <Outlet />
    </Layout>
  );
};

export default List;
