import React from 'react';
import { useIntl } from 'react-intl';
import Helmet from 'components/Helmet';
import Toaster from 'components/ui/molecules/Toaster';
import useFlags, { Flag } from 'utilities/hooks/useFlags';

const Home: React.FC = () => {
  const intl = useIntl();
  const isToasterEnabled = useFlags(Flag.TOASTER_VISIBILITY);

  return (
    <div data-testid="view-home">
      <Helmet title={intl.formatMessage({ defaultMessage: 'Home' })} />
      <h1>HOME</h1>
      {isToasterEnabled && <Toaster />}
    </div>
  );
};

export default Home;
