import { ReportTemplate, ReportConfig } from 'api/report';
import { SpaceNode, SpaceNodeCategory } from 'api/space';
import { getIntl } from 'components/Intl';

type FormValues = {
  name?: string;
  description?: string;
  type?: string;
  schedule?: string;
  emails?: string[];
  building?: string;
  floor?: string;
  room?: string;
  reportTemplate?: ReportTemplate;
};

export const updateFormValues = (
  reportConfigData: ReportConfig,
  spaceData: { [spaceId: string]: SpaceNode },
): FormValues => {
  const formValues: FormValues = {
    name: reportConfigData.name,
    description: reportConfigData.description,
    type: reportConfigData.reportType,
    schedule: reportConfigData.values.period,
    reportTemplate: reportConfigData.values.template,
  };

  const space = spaceData[reportConfigData.values.locationId];
  if (typeof space !== 'undefined') {
    space.path.split('.').forEach((id: string) => {
      const spaceName = spaceData[`spc-${id}`]?.meta?.name;
      switch (spaceData[`spc-${id}`]?.category) {
        case SpaceNodeCategory.BUILDING:
          formValues.building = spaceName;
          break;
        case SpaceNodeCategory.FLOOR:
          formValues.floor = spaceName;
          break;
        default:
          formValues.room = spaceName;
      }
    });
  }

  return formValues;
};

export enum SuccessMessageType {
  Update,
  Create,
  CreateAndGenerate,
}

export const getSuccessMessage = (type: SuccessMessageType): string =>
  ({
    [SuccessMessageType.Update]: getIntl().formatMessage({
      defaultMessage: 'Successfully updated scheduled report.',
    }),
    [SuccessMessageType.Create]: getIntl().formatMessage({
      defaultMessage: 'Successfully created scheduled report.',
    }),
    [SuccessMessageType.CreateAndGenerate]: getIntl().formatMessage({
      defaultMessage:
        'Successfully created scheduled report and your report will be available to view shortly.',
    }),
  }[type]);
