import { Button, Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { ReactChild } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { GoCloudDownload } from 'react-icons/go';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

import {
  getReportTypeToLabelMap,
  ReportType,
  Schedule,
  ScheduleToLabelMap,
} from 'api/report';
import { getIntl } from 'components/Intl';
import { TableRow } from 'components/ui/molecules/Table';

import styles from './styles.module.scss';

const { Title, Text } = Typography;

const schedules = Object.values(Schedule);

export type ReportConfigRow = TableRow & {
  id: string;
  name: string;
  description: string;
  lastRan?: string;
  frequency: Schedule;
  reportType: string;
  color?: string;
  locationName?: string;
};

export const columns = (
  onClickDownload: (key: string, name: string) => void,
  onClickEdit: (key: string) => void,
  onClickDelete: (key: string) => void,
): ColumnsType<ReportConfigRow> => {
  const intl = getIntl();
  return [
    {
      key: 'color',
      width: 64,
      render: ({ color, hideActions }: ReportConfigRow): null | ReactChild =>
        hideActions ? null : (
          <div
            className={styles.squareTypeIndicator}
            style={{ backgroundColor: color }}
          />
        ),
    },
    {
      title: <FormattedMessage defaultMessage="Name" />,
      key: 'name',
      sorter: (a, b) => a.name.toString().localeCompare(b.name),
      render: ({ name, locationName }: ReportConfigRow): ReactChild => {
        return (
          <>
            <Title level={5} style={{ marginBottom: '0px' }}>
              {name}
            </Title>
            {locationName ? <Text>{locationName}</Text> : ''}
          </>
        );
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Type" />,
      key: 'reportType',
      sorter: (a, b) => a.reportType.localeCompare(b.reportType),
      render: ({
        color,
        reportType,
        hideActions,
      }: ReportConfigRow): string | ReactChild => {
        return hideActions ? (
          reportType
        ) : (
          <Tag color={color}>
            {getReportTypeToLabelMap(reportType as ReportType)}
          </Tag>
        );
      },
    },
    {
      title: <FormattedMessage defaultMessage="Last Generated" />,
      key: 'lastRan',
      sorter: (a, b) => {
        if (!a.lastRan) return 1;
        if (!b.lastRan) return -1;

        return dayjs(a.lastRan).diff(dayjs(b.lastRan));
      },
      ellipsis: {
        showTitle: false,
      },
      render: ({
        lastRan,
        hideActions,
      }: ReportConfigRow): undefined | null | string => {
        if (hideActions) return lastRan;

        return typeof lastRan === 'undefined'
          ? null
          : dayjs(lastRan).format('DD-MM-YYYY');
      },
    },
    {
      title: <FormattedMessage defaultMessage="Frequency" />,
      key: 'frequency',
      sorter: (a, b) =>
        schedules.indexOf(a.frequency) - schedules.indexOf(b.frequency),
      ellipsis: {
        showTitle: false,
      },
      render: ({
        frequency,
        hideActions,
      }: ReportConfigRow): Schedule | ReactChild =>
        hideActions ? frequency : ScheduleToLabelMap[frequency],
    },
    {
      key: 'action',
      width: 160,
      render: ({
        key,
        hideActions,
        name,
      }: ReportConfigRow): null | ReactChild => {
        if (hideActions) return null;
        return (
          <Space size="middle" data-testid="buttons-actions">
            <Button
              data-testid={`button-download-${key}`}
              type="link"
              icon={<GoCloudDownload />}
              onClick={() => onClickDownload(key, name)}
              aria-label={intl.formatMessage({
                defaultMessage: 'download report',
              })}
            />
            <Button
              data-testid={`button-edit-${key}`}
              type="link"
              icon={<FiEdit2 />}
              onClick={() => onClickEdit(key)}
              aria-label={intl.formatMessage({
                defaultMessage: 'edit type',
              })}
            />
            <Button
              data-testid={`button-delete-${key}`}
              type="link"
              icon={<RiDeleteBin7Line />}
              onClick={() => onClickDelete(key)}
              aria-label={intl.formatMessage({
                defaultMessage: 'delete report',
              })}
            />
          </Space>
        );
      },
    },
  ];
};
