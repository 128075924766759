import { Button } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import globalStyles from 'styles/global.module.scss';

const AddConfigurationBtn: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      data-testid="button-add"
      type="primary"
      shape="round"
      icon={<AiOutlinePlus />}
      style={{ display: 'flex' }}
      className={globalStyles.center}
      onClick={() => {
        navigate('add');
      }}
    >
      <FormattedMessage defaultMessage="Add configuration" />
    </Button>
  );
};

export default AddConfigurationBtn;
