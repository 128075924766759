import { Navigate, Route, useLocation } from 'react-router-dom';

import AnimatedRouter from 'components/AnimatedRoutes';
import { Routes as RootRoutes } from 'components/Routing/Routes';
import { Routes as SettingRoutes } from 'views/Settings/components/Routing/Routes';

import DataSinks from 'views/Settings/views/Bms/views/DataSinks';
import EditSink from 'views/Settings/views/Bms/views/DataSinks/components/EditSink';
import DataSources from 'views/Settings/views/Bms/views/DataSources';

import { Routes } from './Routes';

export const getFullPath = (
  route: Routes | null = null,
  absolute = false,
): string => {
  let fullPath = `/${SettingRoutes.BMS}`;

  if (
    route &&
    [Routes.DATA_SOURCES_LIST, Routes.DATA_SINKS_LIST].includes(route)
  )
    fullPath += `/${route}`;

  if (route === Routes.DATA_SINK_EDIT)
    fullPath += `/${Routes.DATA_SINKS_LIST}/${Routes.DATA_SINK_EDIT}`;

  if (absolute) fullPath = `/${RootRoutes.SETTINGS}${fullPath}`;
  return fullPath;
};

const Routing: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <AnimatedRouter pathLevelWatch={3}>
      <Route
        path="/"
        element={
          pathname === getFullPath(null, true) && (
            <Navigate
              replace
              to={getFullPath(Routes.DATA_SOURCES_LIST, true)}
            />
          )
        }
      />
      <Route
        path={Routes.DATA_SOURCES_LIST}
        data-testid="route-bms-data-sources-list"
        element={<DataSources />}
      />
      <Route
        path={`${Routes.DATA_SINKS_LIST}/*`}
        data-testid="route-bms-data-sinks-list"
        element={<DataSinks />}
      >
        <Route
          path={Routes.DATA_SINK_EDIT}
          data-testid="route-bms-data-sink-edit"
          element={<EditSink />}
        />
      </Route>
    </AnimatedRouter>
  );
};

export default Routing;
