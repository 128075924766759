import cn from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className: string;
  testId?: string;
};

const CardBase: React.FC<Props> = ({ className, children, testId }) => {
  return (
    <div className={cn(className, styles.cardBase)} data-testid={testId}>
      {children}
    </div>
  );
};

export default CardBase;
