import { useAuth0 } from '@auth0/auth0-react';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Routes } from 'components/Routing/Routes';

import styles from './styles.module.scss';

const { Title } = Typography;

const Navbar: React.FC = () => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <div data-testid="settings-navbar" className={styles.navbar}>
      <Row align="middle">
        <Col span={5}>
          <Title level={3}>
            <FormattedMessage defaultMessage="Settings" />
          </Title>
        </Col>
        <Col flex={1} className={styles.columnMid}>
          {/* <Search /> // TODO: unhide when needed */}
        </Col>
        <Col span={5} className={styles.columnRight}>
          {isAuthenticated && (
            <Link to={{ pathname: Routes.SETTINGS }}>
              <Button
                data-testid="logout"
                type="text"
                icon={<FiLogOut />}
                onClick={() => logout({ returnTo: window.location.origin })}
                color="gray"
                className={styles.logoutButton}
              >
                <FormattedMessage defaultMessage="Logout" />
              </Button>
            </Link>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Navbar;
