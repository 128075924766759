import cn from 'classnames';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalStyles from 'styles/global.module.scss';

import Search from 'components/ui/molecules/Search';

import AddConfigurationBtn from 'views/Insights/views/ReportConfigurationList/components/AddConfigurationBtn';
import { FilterDataOptions } from 'views/Insights/views/ReportConfigurationList/utilities';

import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
  onSearch: (
    filterOption: FilterDataOptions.searchTerm,
    searchString: string,
  ) => void;
};

const Header: React.FC<Props> = ({ onSearch }) => (
  <Row
    align="middle"
    justify="space-between"
    className={cn(styles.header, globalStyles.cardHeader)}
    data-testid="report-header"
  >
    <Col xs={5} sm={5} md={6} lg={5} xl={4} xxl={3}>
      <Title level={3} className={styles.title}>
        <FormattedMessage defaultMessage="Report library" />
      </Title>
    </Col>
    <Col xs={12} sm={13} md={12} lg={15} xl={15} xxl={14}>
      <Search
        className={styles.search}
        onChange={(val) => onSearch(FilterDataOptions.searchTerm, val)}
        data-testid="search-input"
      />
    </Col>
    <Col xs={7} sm={6} md={6} lg={4} xl={5} xxl={7}>
      <AddConfigurationBtn />
    </Col>
  </Row>
);

export default Header;
