import { Empty, Space, Typography } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useMemo, useState, ReactElement } from 'react';

import { FormattedMessage } from 'react-intl';
import { Report, ScheduleToLabelMap, useGetReportQuery } from 'api/report';
import { IAQReportTemplate } from 'api/report/reportIAQ';
import { getIntl } from 'components/Intl';
import { PdfProviderContext } from 'components/PdfProvider/PdfProvider';
import BarChart from 'components/ui/atoms/BarChart';
import Card from 'components/ui/molecules/Card';
import HeatMapGridCollection from 'components/ui/molecules/HeatMapGridCollection';
import LiquidFillGaugeCollection from 'components/ui/molecules/LiquidFillGaugeCollection';
import StackedBarChartCollection from 'components/ui/molecules/StackedBarChartCollection';

import OverallValue from './OverallValue';

import styles from './styles.module.scss';
import SummaryText from './SummaryText';
import {
  prepareBubbleData,
  prepareDailyScore,
  prepareHeatmapData,
  prepareParametersBreakdown,
  prepareSummaryParams,
} from './utils';

const { Title } = Typography;

type Props = {
  reportId: string;
};

const IndoorAirQuality: React.FC<Props> = ({ reportId }) => {
  const [report, setReport] = useState<Report>();
  const [errorMessage, setErrorMessage] = useState<ReactElement>();
  const [contentProcessed, setContentProcessed] = useState<boolean>(false);
  const { setChildrenReadyToPrint } = useContext(PdfProviderContext);
  const reportQuery = useGetReportQuery(reportId, {
    skip: !reportId,
  });

  useEffect(() => {
    if (reportQuery.isSuccess) {
      if (!isEmpty(reportQuery.data.content)) {
        setReport(reportQuery.data as Report);
      } else {
        setErrorMessage(
          <FormattedMessage defaultMessage="No data for this report" />,
        );
        setReport(undefined);
      }
    } else {
      setErrorMessage(<FormattedMessage defaultMessage="Report not found" />);
      setReport(undefined);
    }
  }, [reportQuery]);

  useEffect(() => {
    if ((report && contentProcessed) || typeof errorMessage !== 'undefined') {
      setTimeout(() => setChildrenReadyToPrint(true), 500);
    }
  }, [report, contentProcessed, errorMessage]);

  const telemetryProps = useMemo(
    () => (report ? report.content.telemetryPropertiesNameAndUnit : {}),
    [report],
  );

  const reportTimeSpan = useMemo(() => {
    const dailyScoresObj = report?.content?.dailyScores;
    if (!dailyScoresObj) return { start: '', end: '' };

    const dailyScores = Object.keys(dailyScoresObj);
    const formatDate = 'DD/MM/YY';
    return {
      start: dayjs(dailyScores[0]).format(formatDate),
      end: dayjs(dailyScores[dailyScores.length - 1]).format(formatDate),
    };
  }, [report]);

  const heatmapMode = useMemo(
    () => report?.content?.template !== IAQReportTemplate.Overview,
    [report],
  );

  const bubbleData = useMemo(
    () => prepareBubbleData(telemetryProps, report),
    [report, telemetryProps],
  );
  const dailyScoreBarsData = useMemo(() => prepareDailyScore(report), [report]);
  const stackedBarChartData = useMemo(
    () => prepareParametersBreakdown(!heatmapMode ? report : undefined),
    [report, heatmapMode],
  );
  const summaryParams = useMemo(
    () => prepareSummaryParams(telemetryProps, report),
    [telemetryProps, report],
  );
  const heatmapData = useMemo(
    () => prepareHeatmapData(telemetryProps, heatmapMode ? report : undefined),
    [telemetryProps, report, heatmapMode],
  );

  if (!report)
    return (
      <div data-testid="empty-indoor-air-quality">
        <Empty description={errorMessage} />
      </div>
    );

  return (
    <Space className={styles.container} direction="vertical" size={18}>
      <Card testId="report-card-title">
        <Space className={cn(styles.topContainer, styles.spaceContainer)}>
          <div>
            <Title>{report.name}</Title>
            <Title level={5}>
              {ScheduleToLabelMap[report.content.period]}{' '}
              <FormattedMessage defaultMessage="IAQ Report" />
            </Title>
          </div>

          <div className={styles.contentRight}>
            {report?.content.address && (
              <Title level={5}>{report.content.address}</Title>
            )}
            {reportTimeSpan && (
              <Title level={5}>
                {reportTimeSpan.start} - {reportTimeSpan.end}
              </Title>
            )}
          </div>
        </Space>
      </Card>

      <Card testId="report-card-overall-score">
        <Space className={styles.spaceContainer}>
          <OverallValue value={report?.content?.overallScore} />
          <LiquidFillGaugeCollection bubbles={bubbleData} />
        </Space>
      </Card>
      <Space
        className={cn(styles.spaceContainer, styles.spaceContainerEqualColumns)}
        data-testid="report-card-daily-scores"
        size={18}
      >
        <Card className={styles.barChartContainer}>
          <BarChart
            title={<FormattedMessage defaultMessage="Daily Scores" />}
            dataSet={dailyScoreBarsData}
            noDataLabel={getIntl().formatMessage({ defaultMessage: 'No data' })}
            unit="%"
          />
        </Card>

        <SummaryText
          overallScore={summaryParams.overallScore}
          highestScore={summaryParams.highestScore}
          bestParameterValue={summaryParams.bestParameterValue}
          bestParameterName={summaryParams.bestParameterName}
          worstParameterName={summaryParams.worstParameterName}
          worstParameterValue={summaryParams.worstParameterValue}
          dayName={summaryParams.dayName}
        />
      </Space>
      {heatmapMode ? (
        <HeatMapGridCollection
          data={heatmapData}
          operationalHours={report.content.workingRange || {}}
          scoreBands={report.content.scoreBands || {}}
          onContentLoaded={() => setContentProcessed(true)}
        />
      ) : (
        <Card testId="report-card-params">
          <div className={styles.parametersBreakdownTitle}>
            <Title level={4}>
              <FormattedMessage defaultMessage="Parameter breakdown" />
            </Title>
            <Title level={5}>
              <FormattedMessage defaultMessage="A parameter breakdown of the percentage of time spent within each score band out of the combined working hours." />
            </Title>
          </div>
          <StackedBarChartCollection
            data={stackedBarChartData}
            columns={2}
            showNoDataPlaceholder={true}
            onContentLoaded={() => setContentProcessed(true)}
          />
        </Card>
      )}
    </Space>
  );
};

export default IndoorAirQuality;
