import React, { useEffect, useMemo, useState } from 'react';
import { User, useUpdateUserRestoreIdMutation } from 'api/user';
import { Routes } from 'components/Routing/Routes';

type Props = {
  children: React.ReactChild | React.ReactChild[];
  token: string;
  url: string;
  currentUser?: User;
};

type Resp = {
  success: boolean;
  status: number;
  data?: any;
};

const Freshchat: React.FC<Props> = ({ children, token, url, currentUser }) => {
  const [freshchatLoaded, setFreshchatLoaded] = useState(false);
  const [updateUserRestoreId] = useUpdateUserRestoreIdMutation();
  const hiddenConfig = useMemo(
    () =>
      window.location.href.includes(Routes.PDF)
        ? {
            config: {
              headerProperty: {
                hideChatButton: true,
              },
            },
          }
        : {},
    [window.location.href],
  );

  const onFreshchatLoad = (user: User) => {
    const { fcWidget } = window as any;

    const names = user.name.split(' ');
    const firstName = names.length !== 0 ? names[0] : '';
    const lastName = names.length > 1 ? names.slice(1).join(' ') : '';

    fcWidget.init({
      token,
      host: url,
      externalId: user.userId,
      restoreId: user?.restoreId ?? null,
      ...hiddenConfig,
    });
    fcWidget.user.get((usersResp: Resp) => {
      const userRespStatus = usersResp && usersResp.status;
      if (userRespStatus !== 200) {
        fcWidget.user
          .create({
            firstName,
            lastName,
            email: user.email,
          })
          .then((createResp: Resp) => {
            const restoreId = createResp?.data?.restoreId;
            if (createResp.status === 200 && typeof restoreId !== 'undefined')
              updateUserRestoreId({ user: restoreId, userId: user.userId })
                .unwrap()
                .catch((err: any) => {
                  console.error('Failed to update freshchat restore ID.', err);
                });
          });
      }
    });

    setFreshchatLoaded(true);
  };

  useEffect(() => {
    if (!freshchatLoaded && typeof currentUser !== 'undefined') {
      const script = document.createElement('script');
      script.src = `https://wchat.freshchat.com/js/widget.js`;
      script.async = true;
      script.onload = () => onFreshchatLoad(currentUser);
      document.body.appendChild(script);
    }
  }, [token, url, currentUser]);

  return <>{children}</>;
};

export default Freshchat;
