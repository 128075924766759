import L, { LatLng, LatLngBoundsExpression, PointExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { createContext, ReactElement, useEffect, useState } from 'react';
import { ImageOverlay, MapContainer } from 'react-leaflet';

import Controls from './components/Controls';
import styles from './styles.module.scss';

import { getGridLayer, getImageSize } from './utils';

export const ZOOM_DELTA = 1;
export const ZOOM_STEPS = 10;

type Props = {
  imageSrc?: string;
  scale?: number;
  topLeftControls?: ReactElement[];
  topRightControls?: ReactElement[];
  bottomLeftControls?: ReactElement[];
  bottomRightControls?: ReactElement[];
};

type MapContextType = {
  bounds: LatLngBoundsExpression | undefined;
  center: LatLng | undefined;
  minZoom: number;
  maxZoom: number;
};

export const MapContext = createContext<MapContextType>({
  bounds: undefined,
  center: undefined,
  minZoom: -Infinity,
  maxZoom: Infinity,
});

const LeafletMap: React.FC<Props> = ({
  imageSrc,
  topLeftControls,
  topRightControls,
  bottomLeftControls,
  bottomRightControls,
  scale = 1,
}) => {
  const [map, setMap] = useState<L.Map | null>(null);
  const [bounds, setBounds] = useState<LatLngBoundsExpression>();
  const [center, setCenter] = useState<LatLng>();
  const [minZoom, setMinZoom] = useState<number>(-Infinity);
  const [maxZoom, setMaxZoom] = useState<number>(Infinity);
  const [gridLayer, setGridLayer] = useState<L.GridLayer>();
  const [imgWidth, setImgWidth] = useState<number>();
  const [imgHeight, setImgHeight] = useState<number>();

  // set the floorplan image size
  useEffect(() => {
    if (!imageSrc) return;

    getImageSize(imageSrc).then(({ width, height }) => {
      setImgWidth(width);
      setImgHeight(height);
    });
  }, [imageSrc]);

  // set the map bounds based on the floorplan image size and scale
  useEffect(() => {
    if (!map || !imgWidth || !imgHeight) return;

    const boundsMinPoint: PointExpression = [0, 0];
    const boundsMaxPoint: PointExpression = [
      imgWidth / scale,
      imgHeight / scale,
    ];

    const calculatedBounds = L.latLngBounds([
      map.unproject(boundsMinPoint, 0),
      map.unproject(boundsMaxPoint, 0),
    ]);

    map.fitBounds(calculatedBounds);

    setBounds(calculatedBounds);
    setCenter(map.getCenter());
  }, [map, imgWidth, imgHeight, scale]);

  // set min zoom and add grid leayer
  useEffect(() => {
    if (!map || !bounds || minZoom !== -Infinity) return;

    const adjustedMinZoom = map.getBoundsZoom(bounds, false);

    setMinZoom(adjustedMinZoom);
    map.setMinZoom(adjustedMinZoom);
    map.setZoom(adjustedMinZoom);

    if (!gridLayer) {
      const gridLayerTemp = getGridLayer(minZoom, 64);
      map.addLayer(gridLayerTemp);
      setGridLayer(gridLayerTemp);
    }
  }, [map, bounds, minZoom, gridLayer]);

  // set max zoom based on min zoom and max ZOOM_STEPS
  useEffect(() => {
    if (!map || minZoom === -Infinity) return;

    const adjustedMaxZoom = minZoom + ZOOM_STEPS * ZOOM_DELTA;
    setMaxZoom(adjustedMaxZoom);
    map.setMaxZoom(adjustedMaxZoom);
  }, [map, minZoom]);

  if (!imageSrc || !imgWidth || !imgHeight) return null;

  return (
    <MapContext.Provider
      value={{
        bounds,
        center,
        minZoom,
        maxZoom,
      }}
    >
      <MapContainer
        className={styles.map}
        ref={setMap}
        preferCanvas={true}
        crs={L.CRS.Simple}
        minZoom={minZoom}
        attributionControl={false}
        zoomControl={false}
        zoomDelta={ZOOM_DELTA}
      >
        {imageSrc && bounds && <ImageOverlay url={imageSrc} bounds={bounds} />}
        <Controls
          topLeft={topLeftControls}
          topRight={topRightControls}
          bottomLeft={bottomLeftControls}
          bottomRight={bottomRightControls}
        />
      </MapContainer>
    </MapContext.Provider>
  );
};

export default LeafletMap;
