import { Layout } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import Helmet from 'components/Helmet';
import Routing from 'views/Insights/components/Routing';

const { Content } = Layout;

const Insights: React.FC = () => {
  const intl = useIntl();
  return (
    <Layout data-testid="view-insights">
      <Helmet title={intl.formatMessage({ defaultMessage: 'Insights' })} />
      <Content data-testid="insights-content">
        <Routing />
      </Content>
    </Layout>
  );
};

export default Insights;
