import { SpaceNodeCollection } from 'api/space';

const RECENTLY_SELECTED_BUILDING_IDS_KEY = 'RECENTLY_SELECTED_BUILDING_IDS_KEY';

export const getSelectedBuildingIds = (): string[] => {
  const selectedBuildingIds = localStorage.getItem(
    RECENTLY_SELECTED_BUILDING_IDS_KEY,
  );
  return selectedBuildingIds ? JSON.parse(selectedBuildingIds) : [];
};

export const getRecentlySelectedBuildings = (
  buildings: SpaceNodeCollection,
): SpaceNodeCollection => {
  const recentlySelectedIds = getSelectedBuildingIds();

  return recentlySelectedIds.reduce((acc, id) => {
    const building = buildings[id];
    if (building) {
      acc[id] = building;
    }
    return acc;
  }, {} as SpaceNodeCollection);
};

export const appendRecentlySelectedBuildingId = (buildingId: string): void => {
  const recentlySelectedIds = getSelectedBuildingIds();

  if (recentlySelectedIds.includes(buildingId)) {
    return;
  }

  recentlySelectedIds.push(buildingId);

  localStorage.setItem(
    RECENTLY_SELECTED_BUILDING_IDS_KEY,
    JSON.stringify(recentlySelectedIds),
  );
};
