import { createIntl, IntlShape, createIntlCache } from 'react-intl';
import translations from 'lang';

const intlInstances: Record<string, IntlShape> = {};

export type translationsKey = keyof typeof translations;
export const defaultLang: translationsKey = 'en';
export const storageKey = 'appUILang';

export const filterByAvailableLocale = (lang: string): translationsKey => {
  const langToCheck = lang.toLowerCase().split('-')[0];

  return (
    Object.keys(translations).includes(langToCheck) ? langToCheck : defaultLang
  ) as translationsKey;
};

export const setIntl = async (lang: translationsKey): Promise<void> => {
  if (!(lang in intlInstances)) {
    const cache = createIntlCache();
    const messages = await translations[lang];
    const intl = createIntl(
      {
        locale: lang,
        messages: messages.default,
      },
      cache,
    );

    intlInstances[lang] = intl;
  }

  intlInstances.current = intlInstances[lang];
};

export const getIntl = (): IntlShape => {
  return intlInstances.current;
};
