import { Space } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const ButtonsGroup: React.FC<Props> = ({ className, children }) => {
  return (
    <Space size={0} className={cn(styles.container, className)}>
      {children}
    </Space>
  );
};

export default ButtonsGroup;
