import { connect } from 'react-redux';
import { openLocationTray, LocationSelectorTray } from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  auth: { currentUser },
  space: { activeLocationId },
}: RootState) => ({
  orgId: currentUser?.orgId,
  activeLocationId,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    openLocationTray: (open: boolean) => {
      dispatch(
        openLocationTray({
          type: LocationSelectorTray.MAIN,
          payloadValue: open,
        }),
      );
    },
  };
};
const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
