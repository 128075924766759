import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from 'api/api';
import { auth, AuthState } from './auth/slice';
import { config, ConfigState } from './config/slice';
import { space, SpaceState } from './space/slice';

export type RootState = {
  auth: AuthState;
  config: ConfigState;
  space: SpaceState;
};

const store = configureStore({
  reducer: {
    auth,
    config,
    space,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

export type Store = typeof store;

export default store;
