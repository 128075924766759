import { Dropdown, Menu } from 'antd';
import { useContext } from 'react';

import { LangContext } from 'components/Intl';
import { getLanguages, languagesMapKey } from 'utilities/constants';

const LanguageSwitcherInline: React.FC = () => {
  const { lang: defaultLang, switchLang } = useContext(LangContext);

  const handleLangSelect = ({ key }: any) => switchLang(key);

  const languages = getLanguages();

  const languageItems = (Object.keys(languages) as languagesMapKey[]).map(
    (lang) => {
      const { label, flag } = languages[lang];
      return {
        label,
        key: lang,
        icon: flag,
      };
    },
  );

  return (
    <Dropdown
      menu={{ onClick: handleLangSelect, items: languageItems }}
      trigger={['click']}
    >
      <span>{languages[defaultLang as languagesMapKey].label}</span>
    </Dropdown>
  );
};

export default LanguageSwitcherInline;
