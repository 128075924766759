import { connect } from 'react-redux';
import {
  LocationSelectorTray,
  openLocationTray,
  setSelectedBuildingId,
  setSelectedFloorId,
} from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  space: {
    locationTray: { selectorsSettings },
    activeLocationId,
  },
}: RootState) => ({
  activeLocationId,
  isLocationSelectorVisible: selectorsSettings?.temporary?.open,
  temporaryFloorId: selectorsSettings?.temporary?.selectedFloorId,
  temporaryBuildingId: selectorsSettings?.temporary?.selectedBuildingId,
  mainActiveFloorId: selectorsSettings?.main?.selectedFloorId,
  mainActiveBuildingId: selectorsSettings?.main?.selectedBuildingId,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    openLocationSelector: (open: boolean) => {
      dispatch(
        openLocationTray({
          type: LocationSelectorTray.TEMPORARY,
          payloadValue: open,
        }),
      );
    },
    setActiveTemporaryFloorId: (floorId: string) => {
      dispatch(
        setSelectedFloorId({
          type: LocationSelectorTray.TEMPORARY,
          payloadValue: floorId,
        }),
      );
    },
    setActiveTemporaryBuildingId: (buildingId: string) => {
      dispatch(
        setSelectedBuildingId({
          type: LocationSelectorTray.TEMPORARY,
          payloadValue: buildingId,
        }),
      );
    },
  };
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
