import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';

import Modal from 'components/ui/molecules/Modal';

import { ViewType } from '../../AssignToSinkModal';

type Props = {
  dataLength: number;
  isVisible: boolean;
  onViewTypeChange: Dispatch<SetStateAction<ViewType | undefined>>;
  onCloseModal: () => void;
};

const Home: React.FC<Props> = ({
  dataLength,
  isVisible,
  onCloseModal,
  onViewTypeChange,
}) => {
  return (
    <Modal
      visible={isVisible}
      closable={true}
      onCancel={onCloseModal}
      title={
        <FormattedMessage
          values={{ count: dataLength }}
          defaultMessage="Assign {count, plural, one {# data source} other {# data sources}} to sink"
        />
      }
      footer={[
        <Button
          key={ViewType.SINK_LIST}
          shape="round"
          data-testid="btn-sink-list"
          onClick={() => onViewTypeChange(ViewType.SINK_LIST)}
        >
          <FormattedMessage defaultMessage="Use existing" />
        </Button>,
        <Button
          key={ViewType.CREATE_SINK}
          shape="round"
          data-testid="btn-create-sink"
          onClick={() => onViewTypeChange(ViewType.CREATE_SINK)}
        >
          <FormattedMessage defaultMessage="Create new" />
        </Button>,
      ]}
    >
      <FormattedMessage defaultMessage="Add to existing sink or create new?" />
    </Modal>
  );
};

export default Home;
