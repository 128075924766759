import { baseApi } from 'api/api';
import { UserTag } from './tags';

export enum Endpoint {
  GetCurrentUser = '/user/current',
  GetUserList = '/user',
  CreateUser = '/user',
  GetUser = '/user/:userId',
  DeleteUser = '/user/:userId',
  UpdateUser = '/user/:userId',
  UpdateUserRestoreId = '/user/current/restore_id',
  GetRoles = '/rbac/roles',
  UpdateUserRole = '/user/:userId/roles/:role',
}

export type User = {
  name: string;
  userId: string;
  identityProviders?: string[];
  orgId: string;
  orgTree: string | null;
  email: string;
  createdAt: string;
  restoreId?: string;
  roles?: string[];
  synced?: boolean;
};

export type Role = Record<string, boolean>;

export type GetUserResponse = User;

export type GetUserListResponse = User[];

export type GetRolesResponse = Record<string, Role>;

export type DeleteUserResponse = any;

export type SetUserRequest = {
  name: string;
  email: string;
  orgId?: string;
};

export type SetUserRestoreRequest = {
  restoreId?: string;
};

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // * getCurrentUser()
    getCurrentUser: build.query<GetUserResponse, void>({
      query: () => ({
        url: Endpoint.GetCurrentUser,
        options: { method: 'GET' },
      }),
      providesTags: [UserTag.CurrentUser],
    }),
    // * getUserList()
    getUserList: build.query<GetUserListResponse, void>({
      query: () => ({
        url: Endpoint.GetUserList,
        options: { method: 'GET' },
      }),
      providesTags: [UserTag.UserList, UserTag.UserRoles],
    }),
    // * deleteUser(userId)
    deleteUser: build.mutation<DeleteUserResponse, string>({
      query: (userId: string) => ({
        url: Endpoint.DeleteUser.replace(':userId', userId),
        options: { method: 'DELETE' },
      }),
      invalidatesTags: [UserTag.UserList],
    }),
    // * getUser(userId)
    getUser: build.query<GetUserResponse, string>({
      query: (userId: string) => ({
        url: Endpoint.GetUser.replace(':userId', userId),
        options: { method: 'GET' },
      }),
      providesTags: [UserTag.UserList, UserTag.UserRoles],
    }),
    // * createUser(user)
    createUser: build.mutation<GetUserResponse, SetUserRequest>({
      query: (user: SetUserRequest) => ({
        url: Endpoint.CreateUser,
        options: { method: 'POST', data: user },
      }),
      invalidatesTags: [UserTag.UserList],
    }),
    // * updateUser({ userId, user })
    updateUser: build.mutation<
      GetUserResponse,
      { user: SetUserRequest; userId: string }
    >({
      query: ({ user, userId }: { user: SetUserRequest; userId: string }) => ({
        url: Endpoint.UpdateUser.replace(':userId', userId),
        options: { method: 'PATCH', data: user },
      }),
      invalidatesTags: [UserTag.UserList],
    }),
    // * getRoles()
    getRoles: build.query<GetRolesResponse, void>({
      query: () => ({
        url: Endpoint.GetRoles,
        options: { method: 'GET' },
      }),
      providesTags: [UserTag.UserRoles],
    }),
    // * assignRoleToUser({ userId, role })
    assignRoleToUser: build.mutation<any, { userId: string; role: string }>({
      query: ({ userId, role }: { userId: string; role: string }) => ({
        url: Endpoint.UpdateUserRole.replace(':userId', userId).replace(
          ':role',
          role,
        ),
        options: { method: 'POST' },
      }),
      invalidatesTags: [UserTag.UserRoles, UserTag.UserList],
    }),
    // * removeRoleFromUser({ userId, role })
    removeRoleFromUser: build.mutation<any, { userId: string; role: string }>({
      query: ({ userId, role }: { userId: string; role: string }) => ({
        url: Endpoint.UpdateUserRole.replace(':userId', userId).replace(
          ':role',
          role,
        ),
        options: { method: 'DELETE' },
      }),
      invalidatesTags: [UserTag.UserRoles, UserTag.UserList],
    }),
    // * updateUserRestoreId({ userId, user })
    updateUserRestoreId: build.mutation<
      GetUserResponse,
      { userId: string; user: SetUserRestoreRequest }
    >({
      query: ({
        userId,
        user,
      }: {
        userId: string;
        user: SetUserRestoreRequest;
      }) => ({
        url: Endpoint.UpdateUserRestoreId.replace(':userId', userId),
        options: { method: 'PATCH', data: user },
      }),
      invalidatesTags: [UserTag.UserList, UserTag.UserRoles],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetUserListQuery,
  useDeleteUserMutation,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetRolesQuery,
  useAssignRoleToUserMutation,
  useRemoveRoleFromUserMutation,
  useUpdateUserRestoreIdMutation,
} = userApi;
