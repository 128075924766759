import classNames from 'classnames';
import { memo } from 'react';
import styles from './styles.module.scss';

type Props = {
  isXLabelReverse?: boolean;
  isYLabelReverse?: boolean;
  labels: string[];
  cellHeight: string;
};

const YLabels: React.FC<Props> = ({
  isXLabelReverse = false,
  isYLabelReverse = false,
  labels,
  cellHeight,
}) => {
  const cellCssValue = {
    '--value': cellHeight,
  } as React.CSSProperties;

  return (
    <div
      data-testid="heatmap-ylabels"
      className={classNames(styles.wrapper, {
        [styles.wrapperReverse]: isYLabelReverse,
        [styles.wrapperXReverse]: isXLabelReverse,
      })}
    >
      {labels.map((label) => (
        <div className={styles.cell} key={`${label}`} style={cellCssValue}>
          {label}
        </div>
      ))}
    </div>
  );
};

export default memo(YLabels);
