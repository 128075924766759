import cn from 'classnames';
import { useState, useEffect, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import HeatMapGrid, { HeatMapGridProps } from 'components/ui/atoms/HeatMapGrid';
import Card from 'components/ui/molecules/Card';

import styles from './styles.module.scss';

import { minutesToHours } from './utils';

export type GridProps = {
  [categoryKey: string]: {
    value: number;
    healthScore: number;
    segments: {
      healthScore: number;
      timestamp: string;
      mean: number | null;
    }[];
  };
};

export type ScoreBand = Record<number, string>;
export type ScoreBands = Record<string, ScoreBand>;

export type OperationalHours = Record<
  string,
  { start: number; end: number } | null
>;

export type HeatMapGridPropsWithKey = HeatMapGridProps & {
  telemetryPropertyKey: string;
};

export type Props = {
  data: HeatMapGridPropsWithKey[];
  operationalHours: OperationalHours;
  scoreBands: ScoreBands;
  onContentLoaded?: () => void;
};

const HeatMapGridCollection: React.FC<Props> = ({
  data = [],
  operationalHours = {},
  scoreBands = {},
  onContentLoaded,
}) => {
  const [parsedData, setParsedData] = useState<HeatMapGridPropsWithKey[]>([]);

  useEffect(() => {
    if (!data.length) return;

    setParsedData(data);
    onContentLoaded?.();
  }, [data, onContentLoaded, scoreBands]);

  const operationalHoursParsed = useMemo(
    () =>
      Object.values(operationalHours).map((cfg) => {
        if (!cfg) return [-1, -1];

        return [minutesToHours(cfg.start), minutesToHours(cfg.end)];
      }),
    [operationalHours],
  );

  return (
    <div className={styles.container}>
      {parsedData.map(({ telemetryPropertyKey, ...gridProps }) => {
        return (
          <Card
            testId={`heat-map-grid-${telemetryPropertyKey}`}
            key={telemetryPropertyKey}
          >
            <HeatMapGrid
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...gridProps}
              cellRender={(x: number, y: number, value: number | null) => {
                const ohRow = operationalHoursParsed[x];

                return (
                  <div
                    title={`Pos(${x}, ${y}) = ${value}`}
                    className={cn({
                      [styles.borderLeft]: ohRow && ohRow[0] === y,
                      [styles.borderRight]: ohRow && ohRow[1] === y,
                    })}
                  >
                    {value || <FormattedMessage defaultMessage="No data" />}
                  </div>
                );
              }}
            />
          </Card>
        );
      })}
    </div>
  );
};

export default HeatMapGridCollection;
