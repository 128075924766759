import { Tabs as AntdTabs } from 'antd';
import cn from 'classnames';

import styles from './styles.module.scss';

type onTabChange = (tab: string) => void;

export type Tab = {
  key: string;
  tab: React.ReactNode;
  disabled?: boolean;
};

export enum Layout {
  DEFAULT,
  THIN,
}

export type Props = {
  tabs: Tab[];
  activeTab: string;
  onTabChange: onTabChange;
  layout?: Layout;
  centered?: boolean;
  className?: string;
};

export const Tabs: React.FC<Props> = ({
  activeTab,
  onTabChange,
  tabs,
  layout = Layout.DEFAULT,
  centered,
  className,
}) => {
  return (
    <AntdTabs
      defaultActiveKey={activeTab}
      activeKey={activeTab}
      className={cn(
        { [styles.tabsContainer]: layout === Layout.THIN },
        { [styles.tabsContainerDefault]: layout === Layout.DEFAULT },
        className,
      )}
      onChange={onTabChange}
      centered={centered}
      items={tabs.map(({ disabled, key, tab }) => ({
        disabled,
        key,
        label: tab,
        'data-testid': `tab-${key}`,
      }))}
    />
  );
};

export default Tabs;
