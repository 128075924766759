import { connect } from 'react-redux';
import { setUnsavedChanges } from 'ducks/space/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  space: {
    locationTray: { selectorsSettings, isSaving, unsavedChanges },
  },
}: RootState) => ({
  selectorsSettings,
  isSaving,
  unsavedChanges,
});

const mapDispatchToProps = { setUnsavedChanges };

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
