import {
  Button,
  Form as AntForm,
  Input,
  List,
  Space,
  Typography,
  Divider,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  useGetDataSourceByIdQuery,
  useProvisionDataSourceMutation,
} from 'api/dataSource';
import { useGetProductByIdQuery } from 'api/product';
import { useGetSpaceNodeListQuery } from 'api/space';
import LocationTray from 'components/LocationTray';
import { Routes as RootRoutes } from 'components/Routing/Routes';
import Loader from 'components/ui/atoms/Loader';
import LocationDisplay from 'components/ui/molecules/LocationDisplay';
import Modal from 'components/ui/molecules/Modal';

import { LocationSelectorTray } from 'ducks/space/slice';
import { getAttributeKeyTitle } from 'utilities/product';
import { Routes } from 'views/Devices/views/Provision/components/Routing/Routes';

import styles from '../../styles.module.scss';

const DEFAULT_NAME_ATTRIBUTE = 'displayName';
export const TEMP_DEVICE_LS_FLOOR_ID = 'temp_device_ls_floor_id';
export const TEMP_DEVICE_LS_BUILDING_ID = 'temp_device_ls_building_id';

type Attribute = {
  name: string;
  value: string;
  attributeKey: string;
};

const { Text } = Typography;

type Props = {
  handleAfterSubmit: () => void;
  openLocationSelector: (open: boolean) => void;
  setActiveTemporaryBuildingId: (buildingId: string) => void;
  setActiveTemporaryFloorId: (floorId: string) => void;
  temporaryFloorId: string | undefined;
  temporaryBuildingId: string | undefined;
  isLocationSelectorVisible: boolean;
  activeLocationId: string | undefined;
  mainActiveBuildingId: string | undefined;
  mainActiveFloorId: string | undefined;
};

const Form: React.FC<Props> = ({
  handleAfterSubmit,
  openLocationSelector,
  setActiveTemporaryFloorId,
  setActiveTemporaryBuildingId,
  isLocationSelectorVisible,
  temporaryFloorId,
  temporaryBuildingId,
  mainActiveBuildingId,
  activeLocationId,
  mainActiveFloorId,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { useForm, Item } = AntForm;
  const { deviceId = '' } = useParams<'deviceId'>();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  const [form] = useForm();
  const [deviceActiveLocation, setDeviceActiveLocation] = useState<
    string | undefined
  >(undefined);
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');
  const [deviceOrgId, setDeviceOrgId] = useState<string | undefined>(undefined);
  const [provisionDataSource] = useProvisionDataSourceMutation();

  const { data: device, isLoading: deviceIsLoading } =
    useGetDataSourceByIdQuery(deviceId, { skip: !deviceId });

  const { data: product, isLoading: productIsLoading } = useGetProductByIdQuery(
    device?.productId || '',
    {
      skip: !device?.productId,
    },
  );

  const { data: spaceNodes = {}, isLoading: spaceNodesIsLoading } =
    useGetSpaceNodeListQuery(
      { orgId: deviceOrgId },
      {
        skip: !deviceOrgId,
      },
    );

  const isPopulatingData =
    spaceNodesIsLoading || productIsLoading || deviceIsLoading;

  useEffect(() => {
    if (!product || !device || !form) return;

    setDeviceOrgId(device.orgId);
    setAttributes(
      product.displayAttributes?.map(({ name, attributeKey }) => {
        return {
          attributeKey,
          name,
          value: device.attributes[attributeKey] ?? '',
        };
      }) || [],
    );

    const mutableData = {
      name: '',
      productId: device.productId,
      locationId:
        device.locationId ||
        localStorage.getItem(TEMP_DEVICE_LS_FLOOR_ID) ||
        activeLocationId ||
        '',
      orgId: device.orgId,
    };

    if (!form.getFieldValue('name')) {
      mutableData.name =
        device.meta.name || device.attributes[DEFAULT_NAME_ATTRIBUTE];
    }

    form.setFieldsValue(mutableData);

    if (mutableData.locationId) {
      setSelectedLocationId(mutableData.locationId);
      setDeviceActiveLocation(mutableData.locationId);
    }
  }, [product, device, activeLocationId, form]);

  useEffect(() => {
    if (typeof activeLocationId !== 'undefined') {
      setDeviceActiveLocation(activeLocationId);
    }

    const lastActiveFloorId = localStorage.getItem(TEMP_DEVICE_LS_FLOOR_ID);
    const lastActiveBuildingId = localStorage.getItem(
      TEMP_DEVICE_LS_BUILDING_ID,
    );

    if (lastActiveFloorId && lastActiveBuildingId) {
      setActiveTemporaryBuildingId(lastActiveBuildingId);
      setActiveTemporaryFloorId(lastActiveFloorId);
    } else if (mainActiveBuildingId && mainActiveFloorId) {
      setActiveTemporaryBuildingId(mainActiveBuildingId);
      setActiveTemporaryFloorId(mainActiveFloorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToList = (): void => {
    setIsVisible(false);
    setTimeout(() => {
      navigate({
        pathname: `/${RootRoutes.DEVICES}/${Routes.DEVICE_ADD}/${Routes.CANDIDATE_LIST}`,
        search,
      });
    }, 400); // wait for fade out animation
  };

  const handleSave = (promise: Promise<any>): void => {
    setIsLoading(true);
    promise
      .then(() => {
        setIsVisible(false);
        handleAfterSubmit();
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => setIsLoading(false));
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then((values) => {
        if (deviceId) {
          const deviceUpdateValues = { ...values };
          deviceUpdateValues.locationId = deviceActiveLocation;

          if (temporaryFloorId && temporaryBuildingId) {
            localStorage.setItem(TEMP_DEVICE_LS_FLOOR_ID, temporaryFloorId);
            localStorage.setItem(
              TEMP_DEVICE_LS_BUILDING_ID,
              temporaryBuildingId,
            );
          }

          handleSave(
            provisionDataSource({
              data: deviceUpdateValues,
              candidateId: deviceId,
            }).unwrap(),
          );
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const handleLocationChange = (): void => {
    openLocationSelector(true);
  };

  const deviceAndLocationInSameOrg = useMemo(
    () =>
      typeof deviceOrgId !== 'undefined' &&
      typeof selectedLocationId !== 'undefined' &&
      spaceNodes[selectedLocationId]?.orgId === deviceOrgId,
    [deviceOrgId, selectedLocationId, spaceNodes],
  );

  return (
    <Modal
      width="60%"
      title={
        <>
          <AiFillFileAdd size="25px" className={styles.ico} />
          <FormattedMessage defaultMessage="Set up your device" />
        </>
      }
      visible={isVisible}
      footer={[
        <Button
          key="save"
          type="primary"
          shape="round"
          loading={isLoading}
          onClick={handleFormSubmit}
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>,
        <Button
          key="cancel"
          type="ghost"
          shape="round"
          onClick={backToList}
          disabled={isLoading}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Button>,
      ]}
    >
      <div data-testid="view-device-candidate-form">
        <Loader
          text={intl.formatMessage({
            defaultMessage: 'Loading device data...',
          })}
          visible={isPopulatingData}
        >
          <AntForm name="basic" form={form} layout="vertical">
            <Space className={styles.space} size="large">
              <div>
                <Item
                  label={<FormattedMessage defaultMessage="Device name" />}
                  name="name"
                  tooltip={
                    <FormattedMessage defaultMessage="Please select a name for this device. The name should help you identify it in the future." />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage defaultMessage="This field is required" />
                      ),
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label={<FormattedMessage defaultMessage="Device Location" />}
                  name="locationId"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage defaultMessage="This field is required" />
                      ),
                    },
                    {
                      validator: () =>
                        deviceAndLocationInSameOrg
                          ? Promise.resolve()
                          : Promise.reject(),
                      message: (
                        <FormattedMessage defaultMessage="Cannot place device in a location owned by a different organisation." />
                      ),
                    },
                  ]}
                >
                  <>
                    <Button
                      data-testid="device-location-change-button"
                      onClick={handleLocationChange}
                    >
                      {activeLocationId === undefined && (
                        <FormattedMessage defaultMessage="Click here to select device location" />
                      )}
                      {activeLocationId !== undefined && (
                        <FormattedMessage defaultMessage="Change device location" />
                      )}
                    </Button>
                    {/* Device selector */}
                    <Modal
                      data-testid="device-location-change-modal"
                      width="auto"
                      visible={isLocationSelectorVisible}
                      onCancel={() => openLocationSelector(false)}
                      disableBackgroundBlur={true}
                      bodyStyle={{
                        overflow: 'hidden',
                        maxHeight: '90vh',
                        padding: '0 0 18px 0',
                      }}
                      centered={true}
                    >
                      <LocationTray
                        locationSelectorTray={LocationSelectorTray.TEMPORARY}
                        onActiveLocationIdChanged={(
                          newActiveLocation: string,
                        ) => {
                          setDeviceActiveLocation(newActiveLocation);
                          setSelectedLocationId(newActiveLocation);
                          form.setFieldsValue({
                            locationId: newActiveLocation,
                          });
                        }}
                      />
                    </Modal>
                  </>
                </Item>
                <LocationDisplay
                  selectedLocationId={selectedLocationId}
                  spaceNodes={spaceNodes}
                />
              </div>
              {attributes.length > 0 && (
                <>
                  <Divider type="vertical" style={{ height: '100%' }} />
                  <div>
                    <Item
                      label={
                        <FormattedMessage defaultMessage="Device Details" />
                      }
                    >
                      <List
                        size="small"
                        data-testid="attribute-list"
                        dataSource={attributes}
                        className={styles.list}
                        renderItem={({ name, value, attributeKey }) => (
                          <List.Item className={styles.listItem}>
                            <Text
                              ellipsis={false}
                              className={styles.attributeName}
                            >
                              {getAttributeKeyTitle(attributeKey, name)}
                            </Text>
                            <Text
                              ellipsis={{ tooltip: true }}
                              className={styles.attributeValue}
                            >
                              {value}
                            </Text>
                          </List.Item>
                        )}
                      />
                    </Item>
                  </div>
                </>
              )}
            </Space>
          </AntForm>
        </Loader>
      </div>
    </Modal>
  );
};

export default Form;
