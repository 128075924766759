import { Button, Space, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactChild } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { OrgLifecycle } from 'api/org';
import { getIntl } from 'components/Intl';
import { TableRow } from 'components/ui/molecules/Table';

export type OrgRow = TableRow & {
  name: string;
  lifecycle: string;
};

export const columns = (
  onClickEdit: (key: string) => void,
): ColumnsType<OrgRow> => {
  const intl = getIntl();
  return [
    {
      title: <FormattedMessage defaultMessage="Name" />,
      key: 'name',
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: <FormattedMessage defaultMessage="Lifecycle" />,
      key: 'lifecycle',
      render: (
        value: OrgRow,
        { hideActions, lifecycle }: OrgRow,
      ): ReactChild => {
        if (hideActions) return value.lifecycle;

        if (lifecycle === OrgLifecycle.ACTIVE)
          return (
            <Tag color="success">
              <FormattedMessage defaultMessage="Active" />
            </Tag>
          );

        if (lifecycle === OrgLifecycle.DELETED)
          return (
            <Tag color="error">
              <FormattedMessage defaultMessage="Deleted" />
            </Tag>
          );

        return <Tag>{lifecycle}</Tag>;
      },
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: ({
        hideActions,
        key: orgId,
        name,
      }: OrgRow): null | ReactChild => {
        if (hideActions) return null;

        const aria = intl.formatMessage(
          {
            defaultMessage: 'edit {val}',
          },
          { val: `${name}` },
        );
        return (
          <Space size="middle" data-testid="buttons-actions">
            <Tooltip title={<FormattedMessage defaultMessage="Edit" />}>
              <Button
                type="link"
                icon={<FiEdit2 alt-text={aria} aria-label={aria} />}
                onClick={() => onClickEdit(orgId)}
                data-testid={`edit-button-${orgId}`}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};
