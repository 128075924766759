import { Button } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/ui/molecules/Modal';
import { Routes } from 'views/Settings/views/Bms/components/Routing/Routes';

import styles from './styles.module.scss';

type Props = {
  isVisible: boolean;
  onRedirect: (url?: string) => void;
};

const Confirm: React.FC<Props> = ({ isVisible, onRedirect }) => {
  return (
    <Modal
      visible={isVisible}
      title={
        <>
          <AiOutlineCheck size="25px" className={styles.ico} />
          <FormattedMessage defaultMessage="Confirmation" />
        </>
      }
      footer={[
        <Button
          key="btn-sink-list"
          shape="round"
          data-testid="btn-sink-list"
          onClick={() => onRedirect(Routes.DATA_SINKS_LIST)}
        >
          <FormattedMessage defaultMessage="View sink" />
        </Button>,
        <Button
          key="btn-source-list"
          shape="round"
          data-testid="btn-source-list"
          onClick={() => onRedirect()}
        >
          <FormattedMessage defaultMessage="Return to list" />
        </Button>,
      ]}
    >
      <FormattedMessage defaultMessage="Data sources have been linked to data sink. Would you like to view it or return to data source list?" />
    </Modal>
  );
};

export default Confirm;
