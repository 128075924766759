/* eslint-disable react/jsx-props-no-spreading */
import { Form, Input, InputNumber, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { camelCase, snakeCase } from 'lodash';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ConfigurationDefinition,
  Field,
  FieldDataType,
  Endpoint,
  getSecretValue,
} from 'api/configuration';
import { getIntl } from 'components/Intl';
import SecretInput from 'components/ui/atoms/SecretInput';
import FileUploadField from 'components/ui/molecules/FileUploadField';

const { Item } = Form;
const { Option } = Select;

export const prepareImporterOptions =
  (callbackFn: (list: ReactElement[]) => void) =>
  (definitions: Record<string, ConfigurationDefinition>): void => {
    const listImporters = Object.keys(definitions).map((key: string) => {
      const definition = definitions[key];
      const importerName = camelCase(definition.importerName);

      return (
        <Option key={importerName} value={importerName}>
          {definition.name}
        </Option>
      );
    });

    callbackFn(listImporters);
  };

export const getRules = ({
  required,
  autogenerate,
  minLength,
  maxLength,
}: Field): Rule[] => {
  const rules = [];

  if (required && !autogenerate) {
    rules.push({
      required: true,
      message: <FormattedMessage defaultMessage="This field is required" />,
    });
  }

  if (minLength) {
    rules.push({
      min: minLength,
      message: (
        <FormattedMessage
          defaultMessage="Value must be at least {min} characters"
          values={{ min: minLength }}
        />
      ),
    });
  }

  if (maxLength) {
    rules.push({
      max: maxLength,
      message: (
        <FormattedMessage
          defaultMessage="Value cannot be longer than {max} characters"
          values={{ max: maxLength }}
        />
      ),
    });
  }

  return rules;
};

enum OverrideSelectOption {
  AUTO = 'auto',
  MANUAL = 'manual',
}

const getOverrideSelect = (
  key: string,
  overriding: boolean,
  setOverride: (key: string, active: boolean) => void,
): ReactElement => {
  return (
    <Select
      defaultValue={
        overriding ? OverrideSelectOption.MANUAL : OverrideSelectOption.AUTO
      }
      onChange={(v) => setOverride(key, v === OverrideSelectOption.MANUAL)}
    >
      <Option value={OverrideSelectOption.AUTO}>
        <FormattedMessage defaultMessage="Auto" />
      </Option>
      <Option value={OverrideSelectOption.MANUAL}>
        <FormattedMessage defaultMessage="Manual" />
      </Option>
    </Select>
  );
};

export const getField = (
  { key, dataType, secret, autogenerate, autogenerateCanOverride }: Field,
  configurationId: string | undefined,
  overrides: Record<string, boolean>,
  setOverride: (key: string, active: boolean) => void,
): ReactElement => {
  const props: Record<string, boolean | string | ReactElement> = {};
  props['data-testid'] = `configuration-input-${key}`;

  if (autogenerate && autogenerateCanOverride) {
    const overriding = overrides[key];
    props.addonAfter = getOverrideSelect(key, overriding, setOverride);
    props.disabled = !overriding;
    props.placeholder = !overriding
      ? getIntl().formatMessage({
          defaultMessage: 'This field will be generated automatically.',
        })
      : getIntl().formatMessage({
          defaultMessage: 'Enter a value to override the default.',
        });
  }

  if (dataType === FieldDataType.INT) return <InputNumber {...props} />;
  if (dataType === FieldDataType.STR && secret) {
    return (
      <SecretInput
        hasSecret={!!configurationId}
        getSecret={
          () =>
            getSecretValue(snakeCase(key), configurationId)
              .then((resp) => resp.data[camelCase(key)])
              .catch(() => undefined) // pragma: no cover
        }
      />
    );
  }

  // Disable inputs for complex data types for now
  if (typeof dataType !== 'string') props.disabled = true;

  return <Input {...props} />;
};

export const prepareFields =
  (callbackFn: (list: ReactElement[]) => void) =>
  (
    definition: ConfigurationDefinition,
    configurationId: string | undefined,
    overrides: Record<string, boolean>,
    setOverride: (key: string, active: boolean) => void,
  ): void => {
    if (!definition) return;

    const fieldList = Object.values(definition.fields)
      .filter(
        ({ autogenerate, autogenerateCanOverride }) =>
          !autogenerate || (autogenerate && autogenerateCanOverride),
      )
      .map((field) => (
        <Item
          label={field.name}
          tooltip={field.description}
          name={camelCase(field.key)}
          rules={getRules(field)}
          key={field.key}
        >
          {getField(field, configurationId, overrides, setOverride)}
        </Item>
      ));

    callbackFn(fieldList);
  };

export const prepareFiles =
  (callbackFn: (list: ReactElement[]) => void) =>
  (
    definition: ConfigurationDefinition,
    configurationId: string | undefined,
  ): void => {
    if (!definition || !configurationId) return;

    const fileList = Object.values(definition.files).map((file) => (
      <Item
        label={file.name}
        tooltip={file.description}
        key={file.key}
        name={camelCase(file.key)}
      >
        <FileUploadField
          configFile={file}
          url={Endpoint.UploadConfigurationFile.replace(
            ':configurationId',
            configurationId,
          ).replace(':fileKey', file.key)}
        />
      </Item>
    ));

    callbackFn(fileList);
  };
