import {
  SpaceNode,
  SpaceNodeCategory,
  SpaceNodeCollection,
  SPACE_NODE_ID_PREFIX,
} from 'api/space';

export type selectActiveSpacesReturn = {
  selectedSpace: undefined | SpaceNode;
  selectedBuilding: undefined | SpaceNode;
  selectedFloor: undefined | SpaceNode;
};

export const selectSpaceNode = (
  spaceNodes: SpaceNodeCollection,
  spaceNodeId: string,
): SpaceNode | undefined => spaceNodes[spaceNodeId];

export const selectBuildings = (
  spaceNodes: SpaceNodeCollection,
): SpaceNodeCollection =>
  Object.fromEntries(
    Object.entries(spaceNodes).filter(
      ([_, value]) => value.category === SpaceNodeCategory.BUILDING,
    ),
  );

export const selectActiveSpaces = (
  spaceNodes: SpaceNodeCollection,
  locationId: string,
): selectActiveSpacesReturn => {
  const selectedSpace = selectSpaceNode(spaceNodes, locationId);
  let selectedBuilding;
  let selectedFloor;

  if (typeof selectedSpace !== 'undefined') {
    if (selectedSpace.category === SpaceNodeCategory.FLOOR) {
      selectedFloor = selectedSpace;
      selectedBuilding = selectSpaceNode(
        spaceNodes,
        selectedSpace.parentId as string,
      );
    } else if (selectedSpace.category === SpaceNodeCategory.ROOM) {
      const [buildingId, floorId] = selectedSpace.path.split('.');
      selectedBuilding = selectSpaceNode(
        spaceNodes,
        `${SPACE_NODE_ID_PREFIX}${buildingId}`,
      );
      selectedFloor = selectSpaceNode(
        spaceNodes,
        `${SPACE_NODE_ID_PREFIX}${floorId}`,
      );
    }
  }

  return {
    selectedSpace,
    selectedBuilding,
    selectedFloor,
  };
};

export const selectSpaceDescendantIds = (
  spaceNodes: SpaceNodeCollection,
  locationId: string,
): string[] => {
  const selectedSpacePath = selectSpaceNode(spaceNodes, locationId)?.path;
  if (typeof selectedSpacePath === 'undefined') return [];

  return Object.entries(spaceNodes)
    .filter(([_, { path }]) => path.startsWith(selectedSpacePath))
    .map(([key]) => key);
};

export const selectFloorsByBuilding = (
  spaceNodes: SpaceNodeCollection,
  selectedBuildingId: string,
): SpaceNode[] =>
  Object.values(spaceNodes)
    .filter(
      ({ category, parentId }) =>
        category === SpaceNodeCategory.FLOOR && parentId === selectedBuildingId,
    )
    .sort((a, b) => {
      if (
        a.systemMeta.floorNumber === undefined ||
        b.systemMeta.floorNumber === undefined
      ) {
        return -1;
      }

      return a.systemMeta.floorNumber > b.systemMeta?.floorNumber ? 1 : -1;
    });

export const selectFloorsFlat = (
  spaceNodes: SpaceNodeCollection,
  selectedBuildingId: string,
): SpaceNodeCollection =>
  Object.fromEntries(
    Object.entries(spaceNodes).filter(
      ([, { category, parentId }]) =>
        category === SpaceNodeCategory.FLOOR && parentId === selectedBuildingId,
    ),
  );

export const selectRoomsFlat = (
  spaceNodes: SpaceNodeCollection,
  selectedBuildingId: string,
): SpaceNodeCollection =>
  Object.fromEntries(
    Object.entries(spaceNodes).filter(
      ([, { category, path }]) =>
        category === SpaceNodeCategory.ROOM &&
        `${SPACE_NODE_ID_PREFIX}${path.split('.')[0]}` === selectedBuildingId,
    ),
  );
