export enum Flag {
  TOASTER_VISIBILITY = 'toaster_visibility',
  HOME_TAB = 'home_tab',
  MAP_TAB = 'map_tab',
  INSIGHTS_TAB = 'insights_tab',
  DEVICES_TAB = 'devices_tab',
  SETTINGS_TAB = 'settings_tab',
  GLOBAL_SEARCH = 'global_search',
  BMS_MODULE_VISIBILITY = 'bms_module_visibility',
  ORG_MODULE_VISIBILITY = 'org_module_visibility',
}
