import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from 'components/App';
import Auth from 'components/Auth';
import Freshchat from 'components/Freshchat';
import Helmet from 'components/Helmet';
import Intl from 'components/Intl';
import Pendo from 'components/Pendo';
import store from 'ducks/store';

import reportWebVitals from './reportWebVitals';

declare const process: {
  env: {
    REACT_APP_AUTH0_CLIENT_ID: string;
    REACT_APP_AUTH0_DOMAIN: string;
    REACT_APP_AUTH0_AUDIENCE: string;
    REACT_APP_PENDO_API_KEY: string;
    REACT_APP_FRESHCHAT_TOKEN: string;
    REACT_APP_FRESHCHAT_URL: string;
    REACT_APP_TITLE: string;
  };
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Intl>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          redirectUri={window.location.origin}
        >
          <Auth>
            <Pendo apiKey={process.env.REACT_APP_PENDO_API_KEY}>
              <Freshchat
                token={process.env.REACT_APP_FRESHCHAT_TOKEN}
                url={process.env.REACT_APP_FRESHCHAT_URL}
              >
                <Helmet />
                <App />
              </Freshchat>
            </Pendo>
          </Auth>
        </Auth0Provider>
      </Intl>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
