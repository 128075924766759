import Icon from '@ant-design/icons';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';

import { BsBox } from 'react-icons/bs';
import { ImStack } from 'react-icons/im';

import { SpaceNodeCategory, SpaceNodeCollection } from 'api/space';
import { ReactComponent as Buildings } from 'assets/svg/buildings.svg';

import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  selectedLocationId: string;
  spaceNodes: SpaceNodeCollection;
};

const LocationDisplay: React.FC<Props> = ({
  selectedLocationId,
  spaceNodes,
}) => {
  const [spaceTree, setSpaceTree] = useState<string[]>([]);

  const icons = [
    <Icon component={Buildings} className={styles.icon} />,
    <ImStack className={styles.icon} />,
    <BsBox className={styles.icon} />,
  ];

  useEffect(() => {
    if (!selectedLocationId || !spaceNodes) {
      setSpaceTree([]);
      return;
    }

    const selectedSpace = spaceNodes[selectedLocationId];
    if (!selectedSpace) {
      setSpaceTree([]);
      return;
    }

    if (selectedSpace.category === SpaceNodeCategory.BUILDING)
      setSpaceTree([selectedSpace.meta.name]);
    else if (selectedSpace.category === SpaceNodeCategory.FLOOR) {
      const selectedBuilding = spaceNodes[selectedSpace.parentId as string];
      setSpaceTree([selectedBuilding.meta.name, selectedSpace.meta.name]);
    } else {
      const selectedFloor = spaceNodes[selectedSpace.parentId as string];
      const selectedBuilding = spaceNodes[selectedFloor.parentId as string];
      setSpaceTree([
        selectedBuilding.meta.name,
        selectedFloor.meta.name,
        selectedSpace.meta.name,
      ]);
    }
  }, [selectedLocationId, spaceNodes]);

  return (
    <div className={styles.wrapper}>
      {spaceTree.map((label, idx) => (
        <p key={label}>
          {icons[idx]}
          <Text ellipsis={{ tooltip: true }}>{label}</Text>
        </p>
      ))}
    </div>
  );
};

export default LocationDisplay;
